import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const TinderBtnsWrapper = styled.div`
  display: flex;
  .tinder-btn {
    height: 70px;
    width: 70px;
    border-radius: 50% !important;
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    background-color: white !important;
    border: none !important;
    margin: 10px;
  }

  .tinder-btn:disabled::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 50%;
  }
`
