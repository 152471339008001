/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
import { EditorState } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import { useTranslation } from 'react-i18next'
import React, { useState, useEffect, useMemo } from 'react'
import { convertToHTML } from 'draft-convert'
import toast from 'react-hot-toast'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { nanoid } from 'nanoid'
import Dropzone from 'react-dropzone'
import { bool, func, object, string } from 'prop-types'
import { Input, Label } from 'reactstrap'
import { Modal, Button } from 'antd'
import { capitalize, replaceInText } from '../../services/utils'
import AuthService from '../../services/AuthService'
import './EmailModal.css'
import RichEditor from '../RichEditor'

const Auth = new AuthService()

const defaultSubject = ''
const defaultText = ''

const propTypes = {
  isOpen: bool.isRequired,
  toggle: func.isRequired,
  updateCandidateMessages: func.isRequired,
  refreshParentData: func.isRequired,
  conversationId: string.isRequired,
  offer: object,
  messageId: string,
  messageSubject: string,
  text: string,
}
const defaultProps = {
  text: '',
  messageSubject: '',
  messageId: '',
  offer: null,
}

const EmailModal = (props) => {
  const {
    action,
    text,
    messageSubject,
    offer,
    isOpen,
    refetchCandidate,
    onSuccess,
    listCandidate,
  } = props
  const abortController = useMemo(() => new AbortController(), [])
  const { t } = useTranslation(['common', 'candidate'], { useSuspense: false })

  const uniqueCandidateOrNull =
    listCandidate?.length === 1 ? listCandidate[0] : null

  let initSubject = messageSubject || defaultSubject
  let initEditorState = EditorState.createEmpty()

  if (action) {
    if (action.subject) {
      initSubject = replaceInText(
        action.subject,
        false,
        uniqueCandidateOrNull,
        offer,
      )
    }
    if (action.text) {
      const text = replaceInText(
        action.text,
        true,
        uniqueCandidateOrNull,
        offer,
      )
      initEditorState = EditorState.createWithContent(stateFromHTML(text))
    }
  } else if (text) {
    const parsedText = replaceInText(text, true, uniqueCandidateOrNull, offer)
    initEditorState = EditorState.createWithContent(stateFromHTML(parsedText))
  } else {
    initSubject = replaceInText(
      defaultSubject,
      false,
      uniqueCandidateOrNull,
      offer,
    )
    initEditorState = EditorState.createWithContent(
      stateFromHTML(
        replaceInText(defaultText, true, uniqueCandidateOrNull, offer),
      ),
    )
  }

  const [messagesTemplates, setMessagesTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState('-1')
  const [subject, setSubject] = useState(initSubject)
  const [editorState, setEditorState] = useState(initEditorState)
  const [attachments, setAttachments] = useState([])
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)

  useEffect(() => {
    Auth.fetch('/reengagement-message-templates?format=mail&$limit=-1', {
      signal: abortController.signal,
    }).then((templates) => {
      setMessagesTemplates(templates)
    })
    return () => abortController.abort()
  }, [abortController])

  const handleSelectMessageTemplate = (e) => {
    setSelectedTemplate(e.target.value)

    if (e.target.value !== '-1') {
      const index = messagesTemplates.map((e) => e._id).indexOf(e.target.value)
      if (index < 0) {
        return
      }

      const { text, subject } = messagesTemplates[index]
      setSubject(subject)
      setEditorState(EditorState.createWithContent(stateFromHTML(text)))
    }
  }

  const handleSendEmail = async () => {
    setIsLoadingBtn(true)
    try {
      const promises = listCandidate.map((candidate) => {
        const formData = new FormData()
        formData.append('candidateId', candidate._id)
        formData.append('action', 'sendMail')
        formData.append(
          'query',
          JSON.stringify({
            to: [{ email: candidate.email }],
            subject: replaceInText(subject, false, candidate, offer),
            body: replaceInText(
              convertToHTML(editorState.getCurrentContent()),
              true,
              candidate,
              offer,
            ),
          }),
        )
        attachments.forEach((file) => {
          formData.append('attachments', file)
        })

        return Auth.fetchWithoutHeaders('/nylas', {
          method: 'POST',
          body: formData,
          signal: abortController.signal,
        })
      })
      await Promise.all(promises)

      // eslint-disable-next-line no-unused-expressions
      if (props.updateCandidateMessages) {
        props.updateCandidateMessages()
      }
      if (refetchCandidate) {
        refetchCandidate()
      }

      if (onSuccess) {
        onSuccess()
      }

      toast.success(t('common:sendEmail.toast.success'))
      props.toggle()
    } catch (e) {
      console.error(e)
      toast.error(t('common:sendEmail.toast.nylasError'))
      setIsLoadingBtn(false)
    }
  }

  const onDropAttachment = (files) => {
    setAttachments([...attachments, files[0]])
  }

  const removeAttachment = (index) => {
    const clone = attachments.slice()
    clone.splice(index, 1)
    setAttachments(clone)
  }

  const onCancel = () => {}

  const email = listCandidate?.length === 1 ? listCandidate[0]?.email : null

  return (
    <Modal
      visible={isOpen}
      onCancel={props.toggle}
      width={800}
      title={
        listCandidate?.length > 1
          ? t(
              'candidate:sendMailToXTalent',
              'Envoyer un email à {{count}} talents',
              {
                count: listCandidate?.length,
              },
            )
          : t(
              'candidate:sendMailToOneTalent',
              'Envoyer un email à {{firstName}}',
              {
                firstName: capitalize(listCandidate[0]?.firstname),
              },
            )
      }
      footer={
        <div>
          <Button
            className="grey-btn"
            disabled={isLoadingBtn}
            onClick={() => props.toggle()}
          >
            {t('common:cancel')}
          </Button>
          <Button
            className="basic-btn"
            loading={isLoadingBtn}
            disabled={!subject}
            onClick={() => handleSendEmail()}
          >
            {t('common:send')}
          </Button>
        </div>
      }
    >
      {!props.hideTemplates && (
        <div>
          <Label for="text">{t('common:importEmailTemplate')}</Label>
          <Input
            type="select"
            name="stepTemplate"
            value={selectedTemplate}
            onChange={handleSelectMessageTemplate}
          >
            <option value="-1" key="-1">
              {t('common:none')}
            </option>
            {messagesTemplates.map((e) => (
              <option value={e._id} key={e._id}>
                {e.name}
              </option>
            ))}
          </Input>
        </div>
      )}
      {email && (
        <div>
          <Label for="recipient">{t('common:to')}</Label>
          <Input type="text" name="recipient" defaultValue={email} disabled />
        </div>
      )}
      <Label for="subject">{t('common:object')}</Label>
      <Input
        type="text"
        name="subject"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <Label for="subject">{t('common:content')}</Label>
      <RichEditor
        editorState={editorState}
        setEditorState={setEditorState}
        mentions={false}
      />
      <Label for="subject">
        {t('common:sendEmail.inputs.file.label', 'Pièces jointes')}
      </Label>
      <div className="dropzone-wrapper dropzone-mail-attachments">
        <Dropzone
          onFileDialogCancel={onCancel.bind(this)}
          onDrop={onDropAttachment.bind(this)}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="email-dropzone dropzone-content">
                {t(
                  'common:sendEmail.inputs.file.add',
                  'Ajouter une pièce jointe',
                )}
              </div>
            </div>
          )}
        </Dropzone>
      </div>
      {attachments.map((attachment, index) => (
        <Button
          key={nanoid()}
          className="mb-2 mr-2 btn-icon btn-pill"
          outline
          color="light"
          onClick={() => removeAttachment(index)}
        >
          {attachment.name}
          <i className="pe-7s-trash btn-icon-wrapper"> </i>
        </Button>
      ))}
    </Modal>
  )
}

EmailModal.propTypes = propTypes
EmailModal.defaultProps = defaultProps
export default EmailModal
