/* eslint-disable react/forbid-prop-types */
import { Popconfirm, Tooltip, Tag } from 'antd'
import { bool, func, object, objectOf } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillPhone, AiOutlineGithub } from 'react-icons/ai'
import { FaInfoCircle, FaLinkedinIn } from 'react-icons/fa'
import { IoIosMail } from 'react-icons/io'
import { findByRegex, makeToastSuccess } from '../../services/utils'
import { createdFrom } from '../../services/utils'
import * as s from './styles'
import { MdOutlineInsertChartOutlined } from 'react-icons/md'

const types = {
  withButton: bool,
  updateCandidate: func,
}

const propTypes = {
  candidate: object.isRequired,
  options: objectOf(() => types),
}

const defaultProps = {
  options: {
    withButton: false,
    updateCandidate: () => {},
    candidateCreatedFrom: null,
  },
}

const ContactBar = ({ candidate, options }) => {
  const { t } = useTranslation(['common', 'reactivation'], {
    useSuspense: false,
  })

  const {
    withButton = false,
    updateCandidate = null,
    candidateCreatedFrom = null,
  } = options
  const { email, phoneNumber, linkedinUrl } = candidate

  const [linkedinIsOpen, setLinkedinIsOpen] = useState(false)
  const [githubIsOpen, setGithubIsOpen] = useState(false)

  const [github, setGithub] = useState(null)
  const [foundNumber, setFoundNumber] = useState(null)

  useEffect(() => {
    const { files, phoneNumber, parsedProfile } = candidate

    if (!phoneNumber && files?.length > 0) {
      const phoneNumberResult = findByRegex(files, 'phone')

      if (phoneNumberResult) {
        setFoundNumber(phoneNumberResult[0])
      } else if (parsedProfile?.phones?.length) {
        setFoundNumber(parsedProfile.phones[0].value)
      }
    }

    if (files?.length > 0) {
      const githubProfileResult = findByRegex(files, 'github')

      if (githubProfileResult) {
        setGithub(githubProfileResult[0])
      }
    }
  }, [candidate])

  // Config
  const sizeIcon = withButton ? 25 : 20
  const color = '#494e5a'
  const opacity = 0.2

  const phoneComp = (
    <div style={{ display: 'grid', position: 'relative' }}>
      <AiFillPhone
        color={color}
        size={sizeIcon}
        style={!phoneNumber && { opacity }}
      />
      <span style={{ position: 'absolute', right: 0, top: -7 }}>
        {!phoneNumber && foundNumber && (
          <FaInfoCircle size={sizeIcon / 2} color="#0070f3" />
        )}
      </span>
    </div>
  )

  return (
    <s.ContactBarWrapper>
      {withButton && (
        <Tooltip
          className={`${email?.length > 0 && 'cursor'}`}
          title={withButton && email}
          trigger={['hover']}
          placement="bottom"
        >
          <IoIosMail
            color={color}
            size={sizeIcon}
            style={email?.length === 0 && { opacity }}
            onClick={() => {
              navigator.clipboard.writeText(email)
              makeToastSuccess(
                t('common:copied', {
                  data: email,
                }),
              )
            }}
          />
        </Tooltip>
      )}
      {phoneNumber ? (
        <Tooltip
          className={`${phoneNumber && 'cursor'}`}
          title={withButton && phoneNumber}
          trigger="hover"
          placement="bottom"
          onClick={() => {
            navigator.clipboard.writeText(phoneNumber)
            makeToastSuccess(
              t('common:copied', {
                data: phoneNumber,
              }),
            )
          }}
        >
          {phoneComp}
        </Tooltip>
      ) : (
        <Popconfirm
          disabled={!(withButton && foundNumber)}
          className={`${withButton && foundNumber && 'cursor'}`}
          title={t('reactivation:numbersFound.title', {
            number: foundNumber,
          })}
          placement="bottom"
          overlayStyle={{
            width: 305,
            fontWeight: 600,
          }}
          cancelButtonProps={{
            className: 'grey-btn',
            style: { display: 'inline-block' },
          }}
          okButtonProps={{
            loading: updateCandidate?.isLoading,
            style: { display: 'inline-block', marginLeft: '10px' },
          }}
          cancelText={t('common:cancel')}
          okText={t('common:yes')}
          onConfirm={() => {
            updateCandidate.mutate({
              id: candidate._id,
              phoneNumber: foundNumber,
            })
          }}
        >
          {phoneComp}
        </Popconfirm>
      )}
      {linkedinUrl && (
        <Tooltip
          className={`${linkedinUrl && 'cursor'} ${
            linkedinUrl && linkedinIsOpen && 'clicked'
          }`}
          title={
            linkedinUrl &&
            withButton && (
              <a href={linkedinUrl} target="_blank" rel="noreferrer noopener">
                {linkedinUrl}
              </a>
            )
          }
          placement="bottom"
          trigger="click"
          onVisibleChange={(visible) => setLinkedinIsOpen(visible)}
        >
          <FaLinkedinIn
            color={color}
            size={sizeIcon}
            style={!linkedinUrl && { opacity }}
          />
        </Tooltip>
      )}
      {github && (
        <Tooltip
          className={`${github && 'cursor'} ${
            github && githubIsOpen && 'clicked'
          }`}
          title={
            github &&
            withButton && (
              <a
                href={
                  github.search(/^http[s]?\:\/\//) == -1
                    ? `https://${github}`
                    : github
                }
                target="_blank"
                rel="noreferrer noopener"
              >
                {github}
              </a>
            )
          }
          placement="bottom"
          trigger="click"
          onVisibleChange={(visible) => setGithubIsOpen(visible)}
        >
          <AiOutlineGithub
            color={color}
            size={sizeIcon}
            style={!github && { opacity }}
          />
        </Tooltip>
      )}
      {candidateCreatedFrom && (
        <Tag
          className="basic-tag"
          color={createdFrom(candidateCreatedFrom).color}
        >
          {createdFrom(candidateCreatedFrom).label}
        </Tag>
      )}
    </s.ContactBarWrapper>
  )
}

ContactBar.propTypes = propTypes
ContactBar.defaultProps = defaultProps
export default ContactBar
