/* eslint-disable max-classes-per-file */
import { Modal, Button, Empty, Divider } from 'antd'
import React, { Suspense, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Document, pdfjs } from 'react-pdf'
import DocumentContext from 'react-pdf/dist/esm/DocumentContext'
import { PageInternal } from 'react-pdf/dist/esm/Page'
import { FiPlus } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Loader from '../../Loader'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

import AddFileModal from '../AddFileModal'
import FilePreview from './FilePreview'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFDocumentWrapper = styled.div`
  canvas {
    width: 100% !important;
    height: auto !important;
    opacity: 0.9;
  }
`

const AllPagesInternal = (props) => {
  const { pdf } = props

  useEffect(() => {
    if (!pdf) {
      throw new Error(
        'Attempted to load a page, but no document was specified.',
      )
    }
  }, [pdf])

  const { numPages } = pdf

  return Array.from(new Array(numPages), (el, index) => (
    <PageInternal {...props} key={`page_${index + 1}`} pageNumber={index + 1} />
  ))
}

const AllPages = (props) => (
  <DocumentContext.Consumer>
    {(context) => (
      <AllPagesInternal
        {...context}
        {...props}
        // For backwards compatibility
        renderAnnotationLayer={
          typeof props.renderAnnotationLayer !== 'undefined'
            ? props.renderAnnotationLayer
            : props.renderAnnotations
        }
      />
    )}
  </DocumentContext.Consumer>
)

// Candidate comp is still using refreshCandidateFiles
// while CandidateOnly is using refetchCandidate from useQuery
// TODO: needs to be updated in Candidate to have the same behaviour
const Files = ({
  files,
  talentFiles = [],
  isCard,
  candidateId,
  refreshCandidateFiles,
  refetchCandidate,
}) => {
  const { t } = useTranslation(['common'], {
    useSuspense: false,
  })
  const user = useSelector((state) => state.user)
  const featuresAccess = user.company_id?.featuresAccess || ['crm']

  const [modal, setModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [addFileModal, setAddFileModal] = useState(false)

  const toggle = (file) => {
    setModal(!modal)
    setSelectedFile(file)
  }

  if (!files) {
    files = []
  }

  const content = (
    <div>
      <div
        className="btns-container"
        style={{
          width: '100%',
        }}
      >
        <Button className="grey-btn" onClick={() => setAddFileModal(true)}>
          <FiPlus size={16} data-testid="plus-icon" />
        </Button>
      </div>
      <div>
        {files
          .filter(
            (file) => file?.id !== talentFiles[talentFiles.length - 1]?.id,
          )
          .map((file) => (
            <FilePreview
              showDelete
              key={file._id}
              file={file}
              toggle={toggle}
              candidateId={candidateId}
              refreshCandidateFiles={refreshCandidateFiles}
              refetchCandidate={refetchCandidate}
            />
          ))}
        {!files.length && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Empty description={t('common:noData')} />
          </div>
        )}
      </div>
      {!!talentFiles.length && (
        <>
          <Divider />
          <h6>{t('common:talentResume')}</h6>
          <div>
            <FilePreview
              key={talentFiles[talentFiles.length - 1]._id}
              file={talentFiles[talentFiles.length - 1]}
              toggle={toggle}
              candidateId={candidateId}
              refreshCandidateFiles={refreshCandidateFiles}
              refetchCandidate={refetchCandidate}
            />
          </div>
        </>
      )}
    </div>
  )

  return (
    <Suspense fallback={<Loader text={t('common:loader.text')} />}>
      <div style={{ overflow: 'auto' }}>
        {isCard ? (
          <div style={{ display: 'flex', overflow: 'auto' }}>{content}</div>
        ) : (
          content
        )}

        {selectedFile !== null ? (
          <Modal
            footer={null}
            width={720}
            visible={modal}
            onCancel={() => toggle(null)}
          >
            {selectedFile.name}
            <PDFDocumentWrapper>
              <Document file={{ url: selectedFile.signedUrl }}>
                <AllPages />
              </Document>
            </PDFDocumentWrapper>
          </Modal>
        ) : (
          ''
        )}
        <AddFileModal
          candidateId={candidateId}
          toggle={() => setAddFileModal(false)}
          isOpen={addFileModal}
          refreshData={refreshCandidateFiles}
          refetchCandidate={refetchCandidate}
        />
      </div>
    </Suspense>
  )
}

Files.defaultProps = {
  isCard: true,
}
export default Files
