/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faEnvelope, faSms } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Tooltip, notification } from 'antd'
import { format } from 'date-fns'
import { Badge } from '@nextui-org/react'
import { nanoid } from 'nanoid'
import { arrayOf, func, object, string } from 'prop-types'
import React, { Suspense, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import Select, { components } from 'react-select'
import { FeathersContext } from '../../services/feathers'
import { makeToastError, makeToastSuccess } from '../../services/utils'
import ContactBar from '../ContactBar'
import CtasCandidateCard from '../CtasCandidateCard'
import Loader from '../Loader'
import TagSelectWithPopover from '../TagSelectWithPopover/TagSelectWithPopover'

const { Option } = components

const propTypes = {
  display: string,
  candidate: object,
  featuresAccess: arrayOf(string),
  updateCandidateTags: func,
  updateCandidateInParent: func,
  refetchCandidate: func,
  openResume: func,
  offer: object,
  selectProps: {},
}

const defaultProps = {
  display: '',
  candidate: {},
  featuresAccess: ['crm', 'talent-pools'],
  updateCandidateTags: () => {},
  updateCandidateInParent: (candidate) => {},
  refetchCandidate: () => {},
  openResume: () => {},
  offer: {},
  selectProps: undefined,
}

// RH-967 rh-962 typeError: S is not a function is here on click on option
const IconOption = (props) => {
  let icon
  const { data } = props

  const actions = data.actions || []

  const actionsIcons = []

  actions.forEach((action) => {
    const { type } = action

    if (type === 'sendMessage') {
      icon = <FontAwesomeIcon icon={faSms} />
    } else if (type === 'sendMail') {
      icon = <FontAwesomeIcon style={{ color: '#d44638' }} icon={faEnvelope} />
    }

    actionsIcons.push(icon)
  })

  return (
    <Option {...props} className="reengagement-template-option">
      {data.label}
      <div style={{ float: 'right', fontSize: '18px' }}>
        {actionsIcons.map((icon) => (
          <div key={nanoid()} style={{ marginLeft: '5px', lineHeight: '100%' }}>
            {icon}
          </div>
        ))}
      </div>
    </Option>
  )
}

const HeaderCandidateCard = ({
  display,
  candidate,
  offer,
  selectProps,
  featuresAccess,
  updateCandidateTags,
  updateCandidateInParent,
  refetchCandidate,
  openResume,
  conversation,
}) => {
  const { t } = useTranslation(['common', 'models', 'candidate'], {
    useSuspense: false,
  })
  const feathers = useContext(FeathersContext)

  const [_, setScore] = useState()
  const [hasBeenHired, setHasBeenHired] = useState(null)

  useEffect(() => {
    if (conversation) {
      // Trick because when receiving number 0 it was evaluated as falsy value
      const sc =
        conversation.score === false || conversation.score === undefined
          ? null
          : conversation.score

      setScore(sc)
    }
  }, [conversation])

  useEffect(() => {
    if (candidate?.hirings?.length) {
      // const today = new Date()
      // In some case date is used instead of timestamp
      // const hired = new Date(
      //   candidate.hirings[candidate.hirings.length - 1].timestamp ||
      //     candidate.hirings[candidate.hirings.length - 1].date,
      // )

      // if diff is less than 6 then it has been hired less than 6 month ago
      // const diff = differenceInCalendarMonths(today, hired)

      // if (diff < 6) {
      //   setHasBeenHired(candidate.hirings[candidate.hirings.length - 1])
      // }

      setHasBeenHired(candidate.hirings[candidate.hirings.length - 1])
    }
  }, [candidate])

  const launchUpdateJob = useMutation(
    () => feathers.service('update-phone-numbers-job').create({ data: {} }),
    {
      onSuccess: (data) => {
        makeToastSuccess(
          t('candidate:toast.success.update.x.talents', {
            x: data,
          }),
          notification.destroy(),
        )
      },
      onError: (error) => {
        makeToastError(t('common:errorHappened'), error)
      },
    },
  )

  const openNotification = () => {
    const args = {
      message: t('candidate:attentionNeeded'),
      placement: 'bottomLeft',
      description: (
        <div>
          {t('candidate:phoneNumberFound.title')}
          <div className="btns-container">
            <Button className="grey-btn" onClick={notification.destroy}>
              {t('common:no')}
            </Button>
            <Button
              className="basic-btn"
              loading={launchUpdateJob.isLoading}
              onClick={launchUpdateJob.mutate}
            >
              {t('common:yes')}
            </Button>
          </div>
        </div>
      ),
      duration: 0,
    }
    notification.open(args)
  }

  const updateCandidate = useMutation(
    ({ id, phoneNumber }) =>
      feathers.service('candidates').patch(id, {
        phoneNumber,
      }),
    {
      onSuccess: (candidate) => {
        makeToastSuccess(t('models:automaticResponse.toast.success'), () => {
          updateCandidateInParent(candidate)
          refetchCandidate()
        })

        // We remove one because we already updated one
        openNotification()
      },
      onError: (error) => {
        makeToastError(t('common:toasts.error'), error)
      },
    },
  )

  const {
    firstname,
    lastname,
    warmStatus,
    researchStatus,
    researchStatusUpdatedAt,
    parsedProfile,
  } = candidate

  const mostRecentPosition =
    parsedProfile?.positions?.length > 0 ? parsedProfile.positions[0] : ''

  const optionsContactBar = {
    updateCandidate,
    withButton: true,
    candidateCreatedFrom: conversation?.createdFrom,
  }

  return (
    <Suspense fallback={<Loader text={t('common:loader.text')} />}>
      <div className="profile-header-wrapper">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 10,
              alignItems: 'center',
              paddingBottom: 10,
            }}
          >
            <span className="candidate-fullname" style={{ display: 'block' }}>
              {firstname} {lastname}
            </span>
          </div>
          {mostRecentPosition && (
            <span
              style={{
                fontSize: 11,
                opacity: 0.6,
                fontWeight: 700,
                marginBottom: 5,
              }}
            >
              {mostRecentPosition?.title} @ {mostRecentPosition?.org}
            </span>
          )}
          <div
            style={{
              display: 'flex',
              gap: 5,
              alignItems: 'center',
              paddingBottom: '5px',
            }}
          >
            {hasBeenHired && (
              <Tooltip
                title={
                  <span>
                    {t('common:hiredThe', {
                      date: format(
                        new Date(hasBeenHired?.timestamp),
                        'dd-MM-yyyy',
                      ),
                    })}
                  </span>
                }
              >
                <Badge enableShadow disableOutline color="warning">
                  Placement
                </Badge>
              </Tooltip>
            )}
            <ContactBar candidate={candidate} options={optionsContactBar} />
            <TagSelectWithPopover
              key={candidate._id}
              candidate={candidate}
              tags={candidate.tags}
              updateParent={updateCandidateTags}
            />
          </div>
        </div>
        <CtasCandidateCard
          openResume={openResume}
          display={display}
          candidate={candidate}
          conversation={conversation}
          featuresAccess={featuresAccess}
          updateCandidateInParent={updateCandidateInParent}
          refetchCandidate={refetchCandidate}
          hasBeenHired={hasBeenHired}
        />
      </div>
      {Object.entries(offer).length && !offer.closed ? (
        <div
          style={{
            fontSize: '16px',
            marginTop: '10px',
            marginBottom: '0px',
          }}
        >
          <Select {...selectProps} components={{ Option: IconOption }} />
        </div>
      ) : (
        ''
      )}
    </Suspense>
  )
}

HeaderCandidateCard.propTypes = propTypes
HeaderCandidateCard.defaultProps = defaultProps
export default HeaderCandidateCard
