import React, { useContext, useState } from 'react'
import { Form, Input, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import {
  capitalize,
  makeToastError,
  makeToastSuccess,
} from '../../../services/utils'

import * as s from './styles'
import { FeathersContext } from '../../../services/feathers'

const EditCandidate = ({ candidate, closeModal, refreshParentData }) => {
  const { t } = useTranslation(['common', 'candidate', 'models'], {
    useSuspense: false,
  })
  const feathers = useContext(FeathersContext)

  const [form, setForm] = useState({
    firstname: capitalize(candidate.firstname),
    lastname: capitalize(candidate.lastname),
    email: candidate.email,
    phoneNumber: candidate.phoneNumber || '',
    linkedinUrl: candidate.linkedinUrl || '',
  })

  const handleSubmit = useMutation(
    () => feathers.service('candidates').patch(candidate._id, form),
    {
      onSuccess: (data) => {
        makeToastSuccess(t('models:automaticResponse.toast.success'))
        if (refreshParentData && closeModal) {
          refreshParentData(data)
          closeModal()
        }
      },
      onError: (error) => {
        makeToastError(t('candidate:toast.error.modify.candidate'), error)
      },
    },
  )

  const updateField = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <s.EditCandidateFormWrapper>
      <form onSubmit={handleSubmit.mutate}>
        <div>
          <Form.Item>
            <label htmlFor="firstname" className="required">
              {t('common:firstname')}
            </label>
            <Input
              placeholder="Prénom"
              type="text"
              onChange={updateField}
              value={form.firstname}
              name="firstname"
              id="firstname"
              autoComplete="off"
              autoFocus
            />
          </Form.Item>
          <Form.Item>
            <label htmlFor="lastname" className="required">
              {t('common:lastname')}
            </label>
            <Input
              placeholder="Nom"
              type="text"
              onChange={updateField}
              value={form.lastname}
              name="lastname"
              id="lastname"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item>
            <label htmlFor="email" className="required">
              Email
            </label>
            <Input
              placeholder="Email"
              type="mail"
              onChange={updateField}
              value={form.email}
              name="email"
              id="email"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item>
            <label htmlFor="phoneNumber">{t('common:phone')}</label>
            <Input
              placeholder="Téléphone"
              type="phone"
              onChange={updateField}
              value={form.phoneNumber}
              name="phoneNumber"
              id="phoneNumber"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item>
            <label htmlFor="linkedinUrl">Url Linkedin</label>
            <Input
              placeholder="Url Linkedin"
              type="text"
              onChange={updateField}
              value={form.linkedinUrl}
              name="linkedinUrl"
              id="linkedinUrl"
              autoComplete="off"
            />
          </Form.Item>
          <div className="actions">
            <Button className="grey-btn" onClick={() => closeModal()}>
              {t('common:cancel')}
            </Button>
            <Button
              disabled={!form.firstname || !form.lastname || !form.email}
              className="basic-btn"
              onClick={() => handleSubmit.mutate()}
              loading={handleSubmit.isLoading}
            >
              {t('common:modify')}
            </Button>
          </div>
        </div>
      </form>
    </s.EditCandidateFormWrapper>
  )
}

export default EditCandidate
