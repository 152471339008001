import React, { useState, useContext } from 'react'
import { useQuery, useMutation } from 'react-query'
import { Button } from 'antd'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import Loader from '../../Loader'
import { FeathersContext } from '../../../services/feathers'
import EmptyPool from '../../../assets/hollo/pool.svg'
import { SelectPoolStyles } from './styles'
import { reactSelectStyles } from '../../../services/utils'

const { Option } = Select

/**
 *
 * @param props
 * @returns {JSX.Element|string}
 * @constructor
 */
const SelectPool = ({
  setSelectedPool,
  closeModal,
  candidate,
  candidates,
  refreshParentData,
  updateAll,
  createCandidateModal,
  selectPosition = 'bottom',
}) => {
  const { t } = useTranslation(['common', 'talent', 'reactivation'], {
    useSuspense: false,
  })
  const feathers = useContext(FeathersContext)

  const [pool, setPool] = useState(null)

  const { isLoading, error, data } = useQuery('getPools', () =>
    feathers.service('candidate-pools').find({
      query: {
        $limit: -1,
      },
    }),
  )

  const { isLoading: isMoveLoading, mutate: moveCandidate } = useMutation(
    () => {
      if (updateAll?.selectAll && updateAll?.currentPool?._id === 'all') {
        throw new Error('Cannot move all candidates')
      }
      if (updateAll?.selectAll) {
        return feathers.service('candidates').patch(null, {
          currentPool: updateAll.currentPool,
          candidatePool_id: pool,
        })
      }

      if (candidates?.length) {
        const candidateIds = candidates
          .map((e) => e?._id)
          .filter((element) => element !== undefined)

        return feathers.service('candidates').patch(
          null,
          {
            candidatePool_id: pool.value,
          },
          {
            query: {
              _id: {
                $in: candidateIds,
              },
            },
          },
        )
      }

      if (candidate?._id) {
        return feathers.service('candidates').patch(candidate._id, {
          candidatePool_id: pool,
        })
      }
    },
    {
      onSuccess: () => {
        toast.success(
          t('reactivation:toast.talent.added.success', {
            withS: candidates?.length > 1 ? 's' : '',
          }),
        )
        if (refreshParentData) {
          refreshParentData()
        }
        closeModal()
      },
      onError: (e) => {
        console.error(e)
        console.error(e)
        toast.error(t('common:toasts.error'))
      },
    },
  )
  // It's for setting the value of chosen pool when creating candidate from navbar
  const handleCallbackPool = (value) => {
    setSelectedPool(value)
  }

  if (isLoading) {
    return (
      <div>
        <Loader text={t('common:loading')} />
      </div>
    )
  }
  if (error) return t('common:errorHappened')

  return (
    <SelectPoolStyles>
      {/* When create candidate from Navbar button we call the Select below without the image */}
      {!createCandidateModal && (
        <div className="visual">
          <img src={EmptyPool} height="160px" alt="add pool" />
        </div>
      )}

      <Select
        options={data.map((pool) => ({ value: pool._id, label: pool.name }))}
        value={pool}
        closeMenuOnSelect
        className="react-select"
        classNamePrefix="react-select"
        style={reactSelectStyles}
        menuPlacement={selectPosition}
        placeholder={t('reactivation:pools.select.placeholder')}
        onChange={(value) => {
          setPool(value)
          if (createCandidateModal) {
            handleCallbackPool(value)
          }
        }}
        defaultValue={candidate?.candidatePool_id}
        optionLabelProp="label"
      />
      {/* {data.map((poolItem) => (
          <Option value={poolItem._id} label={poolItem.name} key={nanoid()}>
            <div className="pool-option-label-item">{poolItem.name}</div>
          </Option> */}
      {/* ))} */}
      {/* When create candidate from Navbar button we call the Select above without the buttons cancel/confirm */}
      {!createCandidateModal && (
        <div className="action-wrapper">
          <Button className="grey-btn" onClick={() => closeModal()}>
            {t('common:cancel')}
          </Button>
          <Button
            loading={isMoveLoading}
            disabled={!pool}
            className="basic-btn"
            onClick={moveCandidate}
          >
            {t('common:confirm')}
          </Button>
        </div>
      )}
    </SelectPoolStyles>
  )
}

export default SelectPool
