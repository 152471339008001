import decode from 'jwt-decode'
import localForage from 'localforage'
import { app } from './feathers'

export default class AuthService {
  // Initializing important variables
  constructor() {
    this.domain = process.env.REACT_APP_API_URL || 'http://localhost:3000' // API server domain
    // this.domain = 'http://3.121.136.110' // API server domain
    this.fetch = this.fetch.bind(this) // React binding stuff
    this.login = this.login.bind(this)
    this.getProfile = this.getProfile.bind(this)
  }

  login(login, password) {
    return app
      .authenticate({
        strategy: 'local',
        login,
        password,
      })
      .then((res) => {
        this.setToken(res.accessToken) // necessary for localforage
        return Promise.resolve(res)
      })
      .catch((e) => {
        console.error('Authentication error', e)
      })
  }

  signup(login, firstname, lastname, email, password) {
    return this.fetch('/users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        login,
        firstname,
        lastname,
        email,
        password,
      }),
    }).then((res) => {
      this.setToken(res.accessToken) // Setting the token in localStorage
      return Promise.resolve(res)
    })
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken() // GEtting token from localstorage
    return !!token && !this.isTokenExpired(token) // handwaiving here
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token)
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired. N
        return true
      }
      return false
    } catch (err) {
      return false
    }
  }

  setToken(idToken) {
    // Saves user token to localStorage
    app.authentication.setAccessToken(idToken)
    localForage.setItem('id_token', idToken)
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem('id_token')
  }

  getDecodedToken() {
    const token = this.getToken()
    return decode(token)
  }

  logout() {
    // Clear user token and profile data from localStorage
    if (this.loggedIn()) {
      return this.fetch('/authentication', {
        method: 'DELETE',
      }).then((res) => {
        localStorage.removeItem('id_token')
        localForage.removeItem('id_token')
      })
    }

    return localStorage.removeItem('id_token')
  }

  getProfile() {
    // Using jwt-decode npm package to decode the token
    return decode(this.getToken())
  }

  fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers.Authorization = `Bearer ${this.getToken()}`
    }

    return fetch(`${this.domain}${url}`, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then((response) => response.json())
      .then((res) => {
        if (res.newAccessToken) {
          this.setToken(res.newAccessToken)
          return res.data
        }
        return res
      })
  }

  fetchWithoutHeaders(url, options) {
    const headers = {}
    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers.Authorization = `Bearer ${this.getToken()}`
    }

    return fetch(`${this.domain}${url}`, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then((response) => response.json())
      .then((res) => {
        if (res.newAccessToken) {
          this.setToken(res.newAccessToken)
          return res.data
        }
        return res
      })
  }

  async _checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response
    }
    throw await response.json()
  }
}
