import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import { useLocation } from 'react-router-dom'
import AuthService from '../../services/AuthService'
import LoadingRedirection from '../../assets/hollo/nylas-loading.svg'
import Loader from '../../components/Loader'
import HelmetComponent from '../../components/Helmet'
import { isDomainJ2BD } from '../../services/utils'
import j2bdLogo from '../../assets/hollo/j2bd.svg'
import holloLogo from '../../assets/hollo/LogoLoginHollo.svg'

const Auth = new AuthService()

const CallbackNylas = () => {
  const { t } = useTranslation(['common'], { useSuspense: false })

  const abortController = new AbortController()
  const location = useLocation()
  const [synchronizing, setSynchronizing] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const params = queryString.parse(location.search)
    const code = params.code || null

    const userId = localStorage.getItem('user_id')
    const companyId = localStorage.getItem('company_id')

    Auth.fetch('/nylas', {
      method: 'POST',
      body: JSON.stringify({
        action: 'getToken',
        query: {
          ...(userId ? { userId } : { companyId }),
          code,
        },
      }),
      signal: abortController.signal,
    })
      .then(() => {
        setSynchronizing(false)
        localStorage.setItem('nylasSynced', JSON.stringify(true))
        toast.success(
          t(
            'common:nylasAccount.toasts.success',
            'Vous pouvez désormais accéder à vos services mail',
          ),
        )
        localStorage.removeItem('user_id')
        localStorage.removeItem('company_id')
      })
      .catch(async (err) => {
        localStorage.removeItem('user_id')
        localStorage.removeItem('company_id')
        console.error(err)
        const errString = t(
          'common:error.internalServerError',
          'Une erreur est survenue',
        )
        toast.error(errString)
        setSynchronizing(false)
        setError(true)
      })
  }, [])

  useEffect(() => () => abortController.abort(), [])

  if (error) {
    return t('common:error.internalServerError', 'Une erreur est survenue')
  }
  return (
    <div
      style={{
        height: '100vh',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <HelmetComponent
        faviconUrl={isDomainJ2BD ? j2bdLogo : holloLogo}
        name={isDomainJ2BD ? 'J2BD Partners' : 'Hollo'}
      />
      {synchronizing ? (
        <Loader
          text={t(
            'common:nylasAccountSynchronizing',
            'Synchronisation du compte, veuillez patienter...',
          )}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            height={200}
            src={LoadingRedirection}
            alt="loading"
            style={{ marginBottom: '20px' }}
          />
          <p style={{ fontSize: '14px', fontWeight: '600', marginTop: '40px' }}>
            {t(
              'common:nylasAccountSynchronized',
              'Votre compte est bien synchronisé, vous pouvez à présent fermer cette onglet',
            )}
          </p>
        </div>
      )}
    </div>
  )
}

export default CallbackNylas
