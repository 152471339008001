import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const ResetPassword = styled.div`
  max-width: 450px;
  padding-top: 20vh;
  margin: auto;

  img {
    height: 3.5rem;
    position: absolute;
    top: 2rem;
    left: 8rem;
  }
  .resetPasswordForm {
    p.resetPasswordFormRules {
      color: #d92550;
      font-weight: 600;
      margin-bottom: 30px;
    }
    h3 {
      font-size: 22px;
      text-align: center;
      font-weight: 500;
      margin-bottom: 50px;
    }
    .ant-input {
      margin-top: 10px;
      height: 50px !important;
      display: block;
      opacity: 0.75;
      font-size: 16px;
      width: 100%;
      font-weight: 500;
      letter-spacing: 0.6px;
    }

    .ant-input:-webkit-autofill:focus {
      font-family: 'Open Sans', sans-serif !important;
      height: 50px !important;
      display: block;
      opacity: 0.75;
      font-size: 16px !important;
      font-weight: 500;
      letter-spacing: 0.6px;
      background-color: white !important;
    }
    .ant-btn {
      width: 100%;
      height: 50px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 600;
      background-color: #545cd8;
      border: none;
      margin-bottom: 15px;
    }
    label {
      margin-bottom: 1.5rem;
      font-weight: 600;
      font-size: 14px;
      width: 100%;
    }

    .loginLink {
      cursor: pointer;
      color: #40a9ff;
      text-decoration: underline;
      text-align: center;
      width: 100%;
      display: block;
      font-size: 14px;
      margin-top: 1rem;
    }
  }
`
