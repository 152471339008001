import { Modal } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import startTrial from '../../assets/hollo/start-trial.svg'
import { setUser } from '../../redux/actions/userActions'
import AuthService from '../../services/AuthService'

const Auth = new AuthService()

const StartTrialModal = () => {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)

  const [modal, setModal] = useState(false)

  useEffect(() => {
    if (user) {
      const { company_id: company } = user

      if (company?.access === 'trial' && !company?.trialExpiresAt) {
        setModal(true)
      }
    }
  }, [user])

  const handleStartTrial = () => {
    Auth.fetch(`/companies/${user.company_id._id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        trialExpiresAt: moment().add(14, 'days'),
      }),
    })
      .then((company) => {
        dispatch(
          setUser({
            ...user,
            company_id: company,
          }),
        )

        toast.success('Votre essai de 14 jours a démarré')
        setModal(false)
      })
      .catch((err) => {
        toast.error(
          "Erreur lors du lancement de l'essai, contactez un administrateur si le problème persiste",
        )
      })
  }

  if (!modal) {
    return null
  }

  return (
    <Modal
      footer={null}
      visible={modal}
      title="Démarrer votre essai"
      closable={false}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <img
          height={200}
          src={startTrial}
          alt="start-trial"
          style={{ marginBottom: '40px' }}
        />
        <p
          style={{
            fontSize: '15px',
            fontStyle: 'italic',
            marginBottom: '40px',
            textAlign: 'center',
          }}
        >
          Vers l’infini et au-delà !!
          <br />
          <br />
          Hollo évolue, et continue son chemin pour aider les entreprises à
          recruter les bons talents aux bons moments.
          <br />
          Pour des raisons stratégiques, nous allons transformer notre plan
          “freemium” par un “free trial” de 14 jours.
          <br />
          En tant qu’early adopters de la solution, nous avons élaboré des
          tarifs préférentiels pour vous remercier de vos feedbacks et continuer
          à vous aider à mieux recruter.
          <br />
          <br />
          On reste à votre disposition et avons hâte de vous dévoiler la suite !
        </p>
        <Button className="basic-btn" onClick={handleStartTrial}>
          Démarrer l'essai de 14 jours
        </Button>
      </div>
    </Modal>
  )
}

export default StartTrialModal
