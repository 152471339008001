import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-locize-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
  apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
  referenceLng: 'fr',
}

const i18Lng = localStorage.getItem('i18nextLng')

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: i18Lng || (navigator?.language || 'en').substring(0, 2),
    fallbackLng: 'en',
    fallbackNS: false,
    supportedLngs: ['fr', 'en'],
    wait: true,
    debug: false,
    backend: locizeOptions,
    saveMissing: true,
  })

if (window.Cypress) {
  window.i18n = i18n
}
export default i18n
