import produce from 'immer'

import {
  RESET_NEW_MESSAGE,
  SET_NEW_MESSAGE,
  SET_NEW_MESSAGE_NAME,
  SET_NEW_MESSAGE_TEXT,
  SET_NEW_MESSAGE_SUBJECT,
  SET_NEW_MESSAGE_FORMAT,
} from '../actions/newMessageActions'

const initialState = {
  id: null,
  name: '',
  text: '',
  subject: '',
  format: 'classic',
  rawContent: '',
}

export default produce((draft, action) => {
  switch (action.type) {
    case RESET_NEW_MESSAGE:
      return initialState

    case SET_NEW_MESSAGE: {
      const { message } = action

      draft.id = message._id ? message._id : initialState.id
      draft.name = message.name ? message.name : initialState.name
      draft.text = message.text ? message.text : initialState.text
      draft.subject = message.subject ? message.subject : initialState.subject
      draft.format = message.format ? message.format : initialState.format
      draft.type = message.type ? message.type : initialState.type
      draft.rawContent = message.rawContent
        ? message.rawContent
        : initialState.rawContent

      return
    }

    case SET_NEW_MESSAGE_NAME: {
      draft.name = action.name
      return
    }

    case SET_NEW_MESSAGE_TEXT: {
      draft.text = action.text
      return
    }

    case SET_NEW_MESSAGE_SUBJECT: {
      draft.subject = action.subject
      return
    }

    case SET_NEW_MESSAGE_FORMAT: {
      draft.format = action.format
      if (action.format === 'classic') {
        draft.subject = ''
      }
    }

    default: {
    }
  }
}, initialState)
