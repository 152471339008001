export const RESET_NEW_OFFER = 'RESET_NEW_OFFER'
export const SET_NEW_OFFER = 'SET_NEW_OFFER'

export const SET_NEW_OFFER_ID = 'SET_NEW_OFFER_ID'
export const SET_NEW_OFFER_NAME = 'SET_NEW_OFFER_NAME'
export const SET_NEW_OFFER_DISPLAY_NAME = 'SET_NEW_OFFER_DISPLAY_NAME'
export const SET_NEW_OFFER_TEAM = 'SET_NEW_OFFER_TEAM'
export const SET_NEW_OFFER_COMPANY = 'SET_NEW_OFFER_COMPANY'
export const SET_NEW_OFFER_LOCATION = 'SET_NEW_OFFER_LOCATION'
export const SET_NEW_OFFER_DESCRIPTION = 'SET_NEW_OFFER_DESCRIPTION'
export const SET_NEW_OFFER_CLIENT_DESCRIPTION =
  'SET_NEW_OFFER_CLIENT_DESCRIPTION'
export const SET_NEW_OFFER_CANDIDATE_DESCRIPTION =
  'SET_NEW_OFFER_CANDIDATE_DESCRIPTION'

export const SET_NEW_OFFER_CONTRACT_TYPE = 'SET_NEW_OFFER_CONTRACT_TYPE'
export const SET_NEW_OFFER_REMOTE_WORKING = 'SET_NEW_OFFER_REMOTE_WORKING'
export const SET_NEW_OFFER_ATS = 'SET_NEW_OFFER_ATS'
export const SET_NEW_OFFER_EXTERNAL_REF = 'SET_NEW_OFFER_EXTERNAL_REF'
export const SET_NEW_OFFER_EXTERNAL_DOCUMENT = 'SET_NEW_OFFER_EXTERNAL_DOCUMENT'

export const SET_NEW_OFFER_MANDATORY_TREE = 'SET_NEW_OFFER_MANDATORY_TREE'

export const SET_NEW_OFFER_REQUIREMENTS = 'SET_NEW_OFFER_REQUIREMENTS'
export const ADD_NEW_OFFER_REQUIREMENT = 'ADD_NEW_OFFER_REQUIREMENT'
export const REMOVE_NEW_OFFER_REQUIREMENT = 'REMOVE_NEW_OFFER_REQUIREMENT'

export const REMOVE_NEW_OFFER_SKILL = 'REMOVE_NEW_OFFER_SKILL'

export const SET_NEW_OFFER_STEP_NAME = 'SET_NEW_OFFER_STEP_NAME'
export const SET_NEW_OFFER_STEP_SKILL = 'SET_NEW_OFFER_STEP_SKILL'
export const SET_NEW_OFFER_STEP_REQUIREMENT = 'SET_NEW_OFFER_STEP_REQUIREMENT'
export const SET_NEW_OFFER_STEP_CONDITION = 'SET_NEW_OFFER_STEP_CONDITION'
export const SET_NEW_OFFER_STEP_TYPE = 'SET_NEW_OFFER_STEP_TYPE'
export const SET_NEW_OFFER_STEP_SENTENCE = 'SET_NEW_OFFER_STEP_SENTENCE'
export const SET_NEW_OFFER_STEP_END_CHAT = 'SET_NEW_OFFER_STEP_END_CHAT'
export const SET_NEW_OFFER_STEP_VARIABLE = 'SET_NEW_OFFER_STEP_VARIABLE'
export const SET_NEW_OFFER_STEP_WEIGHT = 'SET_NEW_OFFER_STEP_WEIGHT'
export const SET_NEW_OFFER_STEP_VIDEO_URL = 'SET_NEW_OFFER_STEP_VIDEO_URL'
export const SET_NEW_OFFER_STEP_ATTACHMENT_TYPE =
  'SET_NEW_OFFER_STEP_ATTACHMENT_TYPE'

export const ADD_NEW_OFFER_STEP_OPTION = 'ADD_NEW_OFFER_STEP_OPTION'
export const REMOVE_NEW_OFFER_STEP_OPTION = 'REMOVE_NEW_OFFER_STEP_OPTION'
export const SET_NEW_OFFER_STEP_OPTION_LABEL = 'SET_NEW_OFFER_STEP_OPTION_LABEL'
export const SET_NEW_OFFER_STEP_OPTION_VALUE = 'SET_NEW_OFFER_STEP_OPTION_VALUE'
export const SET_NEW_OFFER_STEP_OPTION_SCORE = 'SET_NEW_OFFER_STEP_OPTION_SCORE'
export const SET_NEW_OFFER_STEP_OPTION_DEALBREAKER =
  'SET_NEW_OFFER_STEP_OPTION_DEALBREAKER'

export const ADD_NEW_OFFER_STEP_OPTION_NUMBER =
  'ADD_NEW_OFFER_STEP_OPTION_NUMBER'
export const REMOVE_NEW_OFFER_STEP_OPTION_NUMBER =
  'REMOVE_NEW_OFFER_STEP_OPTION_NUMBER'
export const SET_NEW_OFFER_STEP_OPTION_NUMBER_MIN =
  'SET_NEW_OFFER_STEP_OPTION_NUMBER_MIN'
export const SET_NEW_OFFER_STEP_OPTION_NUMBER_MAX =
  'SET_NEW_OFFER_STEP_OPTION_NUMBER_MAX'
export const SET_NEW_OFFER_STEP_OPTION_NUMBER_SCORE =
  'SET_NEW_OFFER_STEP_OPTION_NUMBER_SCORE'
export const SET_NEW_OFFER_STEP_OPTION_NUMBER_DEALBREAKER =
  'SET_NEW_OFFER_STEP_OPTION_NUMBER_DEALBREAKER'

export const ADD_NEW_OFFER_STEP_OPTION_DATE = 'ADD_NEW_OFFER_STEP_OPTION_DATE'
export const REMOVE_NEW_OFFER_STEP_OPTION_DATE =
  'REMOVE_NEW_OFFER_STEP_OPTION_DATE'
export const SET_NEW_OFFER_STEP_OPTION_DATE_START =
  'SET_NEW_OFFER_STEP_OPTION_DATE_START'
export const SET_NEW_OFFER_STEP_OPTION_DATE_END =
  'SET_NEW_OFFER_STEP_OPTION_DATE_END'
export const SET_NEW_OFFER_STEP_OPTION_DATE_SCORE =
  'SET_NEW_OFFER_STEP_OPTION_DATE_SCORE'
export const SET_NEW_OFFER_STEP_OPTION_DATE_DEALBREAKER =
  'SET_NEW_OFFER_STEP_OPTION_DATE_DEALBREAKER'

export const SET_NEW_OFFER_STEPS = 'SET_NEW_OFFER_STEPS'
export const SET_NEW_OFFER_SPECIFIC_STEP = 'SET_NEW_OFFER_SPECIFIC_STEP'
export const ADD_NEW_OFFER_STEP = 'ADD_NEW_OFFER_STEP'
export const REMOVE_NEW_OFFER_STEP = 'REMOVE_NEW_OFFER_STEP'

export const SET_NEW_TEMPLATE_NAME = 'SET_NEW_TEMPLATE_NAME'
export const SET_ACTIONS = 'SET_ACTIONS'
export const SET_TAGS = 'SET_TAGS'

export const SET_PROCESS_STATUS = 'SET_PROCESS_STATUS'

export const SET_NEW_OFFER_STEP_OPTIONS = 'SET_NEW_OFFER_STEP_OPTIONS'

export const SET_JOB = 'SET_JOB'
export const SET_SUBJOBS = 'SET_SUBJOBS'
export const SET_SECTORS = 'SET_SECTORS'
export const SET_SUBSECTORS = 'SET_SUBSECTORS'
export const SET_EXPERIENCE = 'SET_EXPERIENCE'
export const SET_SCHOOLLEVEL = 'SET_SCHOOLLEVEL'
export const SET_SALARYRANGE = 'SET_SALARYRANGE'

export const setJob = (job) => ({
  type: SET_JOB,
  job,
})

export const setSubJobs = (subJob) => ({
  type: SET_SUBJOBS,
  subJob,
})

export const setSectors = (sector) => ({
  type: SET_SECTORS,
  sector,
})

export const setSubSectors = (subSector) => ({
  type: SET_SUBSECTORS,
  subSector,
})

export const setExperience = (experience) => ({
  type: SET_EXPERIENCE,
  experience,
})

export const setSchoolLevel = (schoolLevel) => ({
  type: SET_SCHOOLLEVEL,
  schoolLevel,
})

export const setSalaryRange = (salaryRange) => ({
  type: SET_SALARYRANGE,
  salaryRange,
})

export const resetNewOffer = (applicationType) => ({
  type: RESET_NEW_OFFER,
  applicationType,
})

export const setNewOffer = (offer) => ({
  type: SET_NEW_OFFER,
  offer,
})

export const setNewOfferId = (id) => ({
  type: SET_NEW_OFFER_ID,
  id,
})

export const setNewOfferName = (name) => ({
  type: SET_NEW_OFFER_NAME,
  name,
})

export const setNewOfferDisplayName = (displayName) => ({
  type: SET_NEW_OFFER_DISPLAY_NAME,
  displayName,
})

export const setNewOfferTeam = (team) => ({
  type: SET_NEW_OFFER_TEAM,
  team,
})

export const setNewOfferCompany = (company) => ({
  type: SET_NEW_OFFER_COMPANY,
  company,
})

export const setNewOfferLocation = (location) => ({
  type: SET_NEW_OFFER_LOCATION,
  location,
})

export const setNewOfferDescription = (description) => ({
  type: SET_NEW_OFFER_DESCRIPTION,
  description,
})

export const setNewOfferClientDescription = (clientDescription) => ({
  type: SET_NEW_OFFER_CLIENT_DESCRIPTION,
  clientDescription,
})

export const setNewOfferCandidateDescription = (candidateDescription) => ({
  type: SET_NEW_OFFER_CANDIDATE_DESCRIPTION,
  candidateDescription,
})
export const setNewOfferContractType = (contractType) => ({
  type: SET_NEW_OFFER_CONTRACT_TYPE,
  contractType,
})

export const setNewOfferRemoteWorking = (remoteWorking) => ({
  type: SET_NEW_OFFER_REMOTE_WORKING,
  remoteWorking,
})

export const setNewOfferAts = (ats) => ({
  type: SET_NEW_OFFER_ATS,
  ats,
})

export const setNewOfferExternalRef = (externalRef) => ({
  type: SET_NEW_OFFER_EXTERNAL_REF,
  externalRef,
})

export const setNewOfferExternalDocument = (externalDocument) => ({
  type: SET_NEW_OFFER_EXTERNAL_DOCUMENT,
  externalDocument,
})

export const setNewOfferMandatoryTree = (mandatoryTree) => ({
  type: SET_NEW_OFFER_MANDATORY_TREE,
  mandatoryTree,
})

export const setNewOfferSteps = (steps) => ({
  type: SET_NEW_OFFER_STEPS,
  steps,
})

export const setNewOfferRequirements = (requirements) => ({
  type: SET_NEW_OFFER_REQUIREMENTS,
  requirements,
})

export const addNewOfferRequirement = (data) => ({
  type: ADD_NEW_OFFER_REQUIREMENT,
  data,
})

export const removeNewOfferRequirement = (requirementId) => ({
  type: REMOVE_NEW_OFFER_REQUIREMENT,
  requirementId,
})

export const removeNewOfferSkill = (skillId) => ({
  type: REMOVE_NEW_OFFER_SKILL,
  skillId,
})

export const setNewOfferStepName = (data) => ({
  type: SET_NEW_OFFER_STEP_NAME,
  data,
})

export const setNewOfferStepSkill = (data) => ({
  type: SET_NEW_OFFER_STEP_SKILL,
  data,
})

export const setNewOfferStepRequirement = (data) => ({
  type: SET_NEW_OFFER_STEP_REQUIREMENT,
  data,
})

export const setNewOfferStepCondition = (data) => ({
  type: SET_NEW_OFFER_STEP_CONDITION,
  data,
})

export const setNewOfferStepType = (data) => ({
  type: SET_NEW_OFFER_STEP_TYPE,
  data,
})

export const setNewOfferStepSentence = (data) => ({
  type: SET_NEW_OFFER_STEP_SENTENCE,
  data,
})

export const setNewOfferStepEndChat = (data) => ({
  type: SET_NEW_OFFER_STEP_END_CHAT,
  data,
})

export const setNewOfferStepVariable = (data) => ({
  type: SET_NEW_OFFER_STEP_VARIABLE,
  data,
})

export const setNewOfferStepWeight = (data) => ({
  type: SET_NEW_OFFER_STEP_WEIGHT,
  data,
})

export const setNewOfferStepVideoUrl = (data) => ({
  type: SET_NEW_OFFER_STEP_VIDEO_URL,
  data,
})

export const setNewOfferStepAttachmentType = (data) => ({
  type: SET_NEW_OFFER_STEP_ATTACHMENT_TYPE,
  data,
})

export const addNewOfferStepOption = (stepId) => ({
  type: ADD_NEW_OFFER_STEP_OPTION,
  stepId,
})

export const setNewOfferStepOptions = (stepId, options) => ({
  type: SET_NEW_OFFER_STEP_OPTIONS,
  stepId,
  options,
})

export const removeNewOfferStepOption = (data) => ({
  type: REMOVE_NEW_OFFER_STEP_OPTION,
  data,
})

export const setNewOfferStepOptionLabel = (data) => ({
  type: SET_NEW_OFFER_STEP_OPTION_LABEL,
  data,
})

export const setNewOfferStepOptionValue = (data) => ({
  type: SET_NEW_OFFER_STEP_OPTION_VALUE,
  data,
})

export const setNewOfferStepOptionScore = (data) => ({
  type: SET_NEW_OFFER_STEP_OPTION_SCORE,
  data,
})

export const setNewOfferStepOptionDealbreaker = (data) => ({
  type: SET_NEW_OFFER_STEP_OPTION_DEALBREAKER,
  data,
})

export const addNewOfferStepOptionNumber = (data) => ({
  type: ADD_NEW_OFFER_STEP_OPTION_NUMBER,
  data,
})

export const removeNewOfferStepOptionNumber = (data) => ({
  type: REMOVE_NEW_OFFER_STEP_OPTION_NUMBER,
  data,
})

export const setNewOfferStepOptionNumberMin = (data) => ({
  type: SET_NEW_OFFER_STEP_OPTION_NUMBER_MIN,
  data,
})

export const setNewOfferStepOptionNumberMax = (data) => ({
  type: SET_NEW_OFFER_STEP_OPTION_NUMBER_MAX,
  data,
})

export const setNewOfferStepOptionNumberScore = (data) => ({
  type: SET_NEW_OFFER_STEP_OPTION_NUMBER_SCORE,
  data,
})

export const setNewOfferStepOptionNumberDealbreaker = (data) => ({
  type: SET_NEW_OFFER_STEP_OPTION_NUMBER_DEALBREAKER,
  data,
})

export const addNewOfferStepOptionDate = (stepId) => ({
  type: ADD_NEW_OFFER_STEP_OPTION_DATE,
  stepId,
})

export const removeNewOfferStepOptionDate = (data) => ({
  type: REMOVE_NEW_OFFER_STEP_OPTION_DATE,
  data,
})

export const setNewOfferStepOptionDateStart = (data) => ({
  type: SET_NEW_OFFER_STEP_OPTION_DATE_START,
  data,
})

export const setNewOfferStepOptionDateEnd = (data) => ({
  type: SET_NEW_OFFER_STEP_OPTION_DATE_END,
  data,
})

export const setNewOfferStepOptionDateScore = (data) => ({
  type: SET_NEW_OFFER_STEP_OPTION_DATE_SCORE,
  data,
})

export const setNewOfferStepOptionDateDealbreaker = (data) => ({
  type: SET_NEW_OFFER_STEP_OPTION_DATE_DEALBREAKER,
  data,
})

export const setNewOfferSpecificStep = (data) => ({
  type: SET_NEW_OFFER_SPECIFIC_STEP,
  data,
})

export const addNewOfferStep = (data) => ({
  type: ADD_NEW_OFFER_STEP,
  data,
})

export const removeNewOfferStep = (data) => ({
  type: REMOVE_NEW_OFFER_STEP,
  data,
})

export const setNewTemplateName = (templateName) => ({
  type: SET_NEW_TEMPLATE_NAME,
  templateName,
})

export const setActions = (actions) => ({
  type: SET_ACTIONS,
  actions,
})
export const setTags = (tags) => ({
  type: SET_TAGS,
  tags,
})

export const setProcessStatus = (processStatus) => ({
  type: SET_PROCESS_STATUS,
  processStatus,
})
