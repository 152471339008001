export const SET_OFFER = 'SET_OFFER'
export const SET_POOL = 'SET_POOL'
export const SET_CONVERSATION = 'SET_CONVERSATION'
export const SET_CANDIDATE = 'SET_CANDIDATE'
export const SET_CANDIDATE_ID = 'SET_CANDIDATE_ID'

export const SET_FILTER_CLASSIFICATION = 'SET_FILTER_CLASSIFICATION'
export const SET_SORT_FIELD = 'SET_SORT_FIELD'
export const SET_SORT_ORDER = 'SET_SORT_ORDER'
export const SET_CONVERSATION_TEXT_SEARCH = 'SET_CONVERSATION_TEXT_SEARCH'
export const SET_ENTRYPOINT_TEXT_SEARCH = 'SET_ENTRYPOINT_TEXT_SEARCH'

export const SET_ENTRYPOINT = 'SET_ENTRYPOINT'

export const SET_TEXT = 'SET_TEXT'
export const SET_MAIL = 'SET_MAIL'

export const SET_PLATFORM = 'SET_PLATFORM'

export const setOffer = (offer) => ({
  type: SET_OFFER,
  offer,
})

export const setPool = (pool) => ({
  type: SET_POOL,
  pool,
})

export const setConversation = (conversation) => ({
  type: SET_CONVERSATION,
  conversation,
})

export const setCandidate = (candidate) => ({
  type: SET_CANDIDATE,
  candidate,
})

export const setCandidateId = (candidateId) => ({
  type: SET_CANDIDATE_ID,
  candidateId,
})

export const setFilterClassification = (filter) => ({
  type: SET_FILTER_CLASSIFICATION,
  filter,
})

export const setSortOrder = (order) => ({
  type: SET_SORT_ORDER,
  order,
})

export const setSortField = (field) => ({
  type: SET_SORT_FIELD,
  field,
})

export const setConversationTextSearch = (text) => ({
  type: SET_CONVERSATION_TEXT_SEARCH,
  text,
})

export const setEntrypointTextSearch = (text) => ({
  type: SET_ENTRYPOINT_TEXT_SEARCH,
  text,
})

export const setEntrypoint = (entrypoint) => ({
  type: SET_ENTRYPOINT,
  entrypoint,
})

export const setText = (text) => ({
  type: SET_TEXT,
  text,
})

export const setMail = (text, subject) => ({
  type: SET_MAIL,
  text,
  subject,
})
