export const SET_NOTIFICATION_SEEN = 'SET_NOTIFICATION_SEEN'
export const SET_SWITCH = 'SET_SWITCH'
export const SET_ACTIVE_FILTER = 'SET_ACTIVE_FILTER'
export const SET_ALL_NOTIFICATION = 'SET_ALL_NOTIFICATION'
export const SET_UNREAD_NOTIFICATION = 'SET_UNREAD_NOTIFICATION'
export const SET_UNREAD_CONVERSATION = 'SET_UNREAD_CONVERSATION'
export const SET_TOGGLE_SEEN_APPLICATION = 'SET_TOGGLE_SEEN_APPLICATION'
export const SET_RECEIVED_MESSAGE = 'SET_RECEIVED_MESSAGE'
export const SET_SEEN_APPLICATION = 'SET_SEEN_APPLICATION'
export const SET_SHOULD_REFETCH_APPLICATIONS = 'SET_SHOULD_REFETCH_APPLICATIONS'

export const setNotificationSeen = (payload) => ({
  type: SET_NOTIFICATION_SEEN,
  payload,
})

export const setSwitch = (payload) => ({
  type: SET_SWITCH,
  payload,
})

export const setActiveFilter = (payload) => ({
  type: SET_ACTIVE_FILTER,
  payload,
})

/**
 * Concatenate all notifications together
 * and unread notifications together
 * @param {*} payload
 * @returns
 */
export const setAllNotifications = (payload) => ({
  type: SET_ALL_NOTIFICATION,
  payload,
})

export const setUnreadNotifications = (payload) => ({
  type: SET_UNREAD_NOTIFICATION,
  payload,
})

export const setUnreadConversations = (payload) => ({
  type: SET_UNREAD_CONVERSATION,
  payload,
})

export const setToggleSeenApplications = () => ({
  type: SET_TOGGLE_SEEN_APPLICATION,
})

export const setSeenApplications = (payload) => ({
  type: SET_SEEN_APPLICATION,
  payload,
})

export const setReceivedMessages = (payload) => ({
  type: SET_RECEIVED_MESSAGE,
  payload,
})

export const setShouldRefetchUnreadApplications = (payload) => ({
  type: SET_SHOULD_REFETCH_APPLICATIONS,
  payload,
})
