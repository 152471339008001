import { Alert, Popover, Button } from 'antd'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { nanoid } from 'nanoid'
import { useQuery } from 'react-query'
import { capitalize } from '../../../../services/utils'
import { FeathersContext } from '../../../../services/feathers'

const Circle = ({ status }) => {
  let bgColor = 'black'

  switch (status) {
    case 'importing':
      bgColor = 'orange'
      break
    default:
      bgColor = '#ff4154'
      break
  }

  return (
    <div
      style={{
        backgroundColor: bgColor,
        borderRadius: '50%',
        width: 10,
        height: 10,
      }}
    />
  )
}

const StatusImports = () => {
  const { t } = useTranslation(['common'], {
    useSuspense: false,
  })
  const feathers = useContext(FeathersContext)

  const user = useSelector((state) => state.user)

  const { data: statusImport, refetch } = useQuery({
    queryKey: ['fetch-imports'],
    queryFn: async () => {
      const response = await feathers.service('asynchrone-jobs').find({
        query: {
          status: {
            $in: ['importing', 'failed'],
          },
          company_id: user?.company_id?._id,
          $populate: ['candidatePool_id'],
        },
      })

      if (!response.total) {
        return null
      }
      return response.data
    },
    refetchInterval: 30 * 1000 * 60,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  })

  const handleFailedImport = async (poolId) => {
    await feathers.service('daemon-update-pools-from-ats').create({
      candidatePoolId: poolId,
      companyId: user.company_id._id,
    })

    refetch()
  }

  return (
    <>
      {statusImport && (
        <Popover
          overlayInnerStyle={{ borderRadius: 10 }}
          placement="bottom"
          content={
            <div>
              <h5 style={{ textAlign: 'center' }}>
                {t('common:atsImport.title')}
              </h5>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: 10,
                }}
              >
                {statusImport.map((el) => (
                  <Alert
                    key={nanoid()}
                    style={{ width: 250 }}
                    type={el.status === 'importing' ? 'warning' : 'error'}
                    message={el.candidatePool_id.name}
                    description={capitalize(el.status)}
                    action={
                      el.status === 'failed' && (
                        <Button
                          className="red-btn"
                          onClick={() =>
                            handleFailedImport(el.candidatePool_id._id)
                          }
                        >
                          {capitalize(t('common:tryAgain'))}
                        </Button>
                      )
                    }
                  />
                ))}
              </div>
            </div>
          }
        >
          <div
            style={{
              display: 'flex',
              gap: 5,
              alignItems: 'baseline',
              marginRight: 10,
            }}
          >
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                fontWeight: 600,
                borderRadius: 8,
              }}
            >
              {t('common:atsImports')}{' '}
              <Circle status={statusImport[0].status} />
            </Button>
          </div>
        </Popover>
      )}
    </>
  )
}

export default StatusImports
