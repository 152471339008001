// Mettre les différentes fonctions dans une hook React pour afficher une modal et des toast, notamment pour les permissions
const config = new URLSearchParams({
  domain: process.env.REACT_APP_API_URL || 'http://localhost:3000',
}).toString()

// Service worker URL w/config variables
const swUrl = `${process.env.PUBLIC_URL}/service.js?${config}`

// Check that service worker works in the browser and that pushManager is supported
const check = () => {
  if (!('serviceWorker' in navigator)) {
    throw new Error('No Service Worker support!')
  }
  if (!('PushManager' in window)) {
    throw new Error('No Push API Support!')
  }
}

// Ask for user permission in browser
const requestNotificationPermission = async () => {
  const permission = await window.Notification.requestPermission()
  // value of permission can be 'granted', 'default', 'denied'
  // granted: user has accepted the request
  // default: user has dismissed the notification permission popup by clicking on x
  // denied: user has denied the request.
  if (permission !== 'granted') {
    throw new Error('Permission not granted for Notification')
  }
}

// Register the service worker in user's browser
export const registerServiceWorker = async () => {
  try {
    const swRegistration = await navigator.serviceWorker.register(swUrl)
    return swRegistration
  } catch (error) {
    console.error('Error during service worker registration:', error)
  }
}

export const main = async () => {
  check()
  const permission = await requestNotificationPermission()
}

export const unregister = async () => {
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.ready
    await registration.unregister()
  }
}
