export const SET_TEAM_ID = 'SET_TEAM_ID'
export const SET_USER_ID = 'SET_USER_ID'
export const SET_PROCESS_STATUS_ID = 'SET_PROCESS_STATUS_ID'
export const SET_COMPANY_ID = 'SET_COMPANY_ID'
export const SET_DEAL_ID = 'SET_DEAL_ID'

export const setTeamId = (id) => ({
  type: SET_TEAM_ID,
  id,
})

export const setUserId = (id) => ({
  type: SET_USER_ID,
  id,
})

export const setProcessStatusId = (id) => ({
  type: SET_PROCESS_STATUS_ID,
  id,
})

export const setCompanyId = (id) => ({
  type: SET_COMPANY_ID,
  id,
})

export const setDealId = (id) => ({
  type: SET_DEAL_ID,
  id,
})
