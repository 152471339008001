import { Avatar, Drawer } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import { useTranslation } from 'react-i18next'
import { HiOutlineX } from 'react-icons/hi'
import OutsideClickHandler from 'react-outside-click-handler'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import toast from 'react-hot-toast'

import {
  setCandidate,
  setCandidateId,
} from '../../../../redux/actions/communicationActions'

import ResumeSlider from '../../../../components/Candidate/ResumeSlider'
import OfferIndicators from '../../../../components/OfferIndicators'
import AuthService from '../../../../services/AuthService'
import { reactSelectStyles, stringToColour } from '../../../../services/utils'

import { FeathersContext } from '../../../../services/feathers'

import * as s from './styles'

const Auth = new AuthService()

const SearchBar = ({ placeholder }) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const feathers = useContext(FeathersContext)

  const { t } = useTranslation(['common'], { useSuspense: false })
  const abortController = new AbortController()
  const dispatch = useDispatch()

  const [suggestions, setSuggestions] = useState([])
  const [value, setValue] = useState('')
  const [talentModal, setTalentModal] = useState(null)
  const [selectedSuggestion, setSelectedSuggestion] = useState(null)
  const [selectedTalent, setSelectedTalent] = useState(null)

  const { isFetching } = useQuery({
    queryKey: selectedSuggestion,
    refetchOnWindowFocus: false,
    enabled: !!selectedSuggestion,
    queryFn: async () => {
      try {
        const response = await feathers
          .service('/candidatesfull')
          .get(selectedSuggestion._id)

        setSelectedTalent(response)
        dispatch(setCandidateId(response._id))
        dispatch(setCandidate(response))
      } catch (err) {
        toast.error(t('common:toasts.error'))
        setTalentModal(false)
        setSelectedTalent(null)
        dispatch(setCandidateId(null))
        dispatch(setCandidate(null))
      }
    },
  })

  const user = useSelector((state) => state.user)
  const [typingTimeout, setTypingTimeout] = useState(null)
  const history = useHistory()
  const companyId = user?.company_id?._id

  const renderSectionTitle = (section) => <span>{section.title}</span>
  const getSectionSuggestions = (section) => section.data

  const getSuggestionValue = (suggestion) =>
    suggestion.firstname
      ? `${suggestion.firstname} ${suggestion.lastname}`
      : suggestion.name

  const renderSuggestion = (suggestion) => {
    if (suggestion.name) {
      return (
        <div style={{ display: 'flex' }}>
          {' '}
          <OfferIndicators offer={suggestion} />
          <span>{suggestion.name}</span>
        </div>
      )
    }

    const firstname =
      suggestion.firstname.charAt(0).toUpperCase() +
      suggestion.firstname.slice(1)
    const lastname =
      suggestion.lastname.charAt(0).toUpperCase() + suggestion.lastname.slice(1)

    return (
      <div>
        <span>
          <Avatar
            size={28}
            style={{
              backgroundColor: stringToColour(
                suggestion.firstname + suggestion.lastname,
                'B3',
              ),
              fontSize: '11px',
              marginRight: '5px',
              marginLeft: 0,
            }}
          >
            {firstname ? firstname[0] : ''}
            {lastname ? lastname[0] : ''}
          </Avatar>
        </span>
        <span>
          {' '}
          {firstname} {lastname}
        </span>
      </div>
    )
  }
  const getSuggestions = async (value) => {
    try {
      const inputValue = value.trim().toLowerCase()
      const inputLength = inputValue.length

      const offerQuery = `/offers?$search=${inputValue}&company_id=${companyId}&$limit=5`
      const talentsQuery = `/candidates?searchByName=${inputValue}&$limit=5`

      if (inputLength >= 3) {
        const [offers, talents] = await Promise.all([
          Auth.fetch(offerQuery, { signal: abortController.signal }),
          Auth.fetch(talentsQuery, { signal: abortController.signal }),
        ])

        const offersSuggestions = offers.data
        const talentsSuggestions = talents.data

        const suggestionsWithTitle = []

        if (talentsSuggestions.length) {
          suggestionsWithTitle.push({
            title: 'Talents',
            data: talentsSuggestions,
          })
        }

        if (offersSuggestions.length) {
          suggestionsWithTitle.push({
            title: 'Campagnes',
            data: offersSuggestions,
          })
        }
        setSuggestions(suggestionsWithTitle)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const onChange = (_, { newValue }) => {
    setValue(newValue)
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setValue(value)
    clearTimeout(typingTimeout)

    setTypingTimeout(
      setTimeout(() => {
        getSuggestions(value)
      }, 400),
    )
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const onSuggestionSelected = (_, selected) => {
    const { suggestion } = selected

    if (suggestion.firstname) {
      setSelectedSuggestion(suggestion)
      setTalentModal(true)
    } else {
      history.push(`/app/offers/${suggestion._id}`)
    }
  }

  const inputProps = {
    placeholder: placeholder ?? t('common:searchBar.placeholder'),
    value,
    onChange,
  }

  useEffect(() => {
    if (queryParams.has('tid')) {
      const id = queryParams.get('tid')
      setTimeout(() => {
        setSelectedSuggestion({ _id: id })
        setTalentModal(true)
      }, 500)
    }
  }, []) // Do not add dependencies here

  return (
    <s.SearchBarContainer>
      {value && (
        <div className="input-icons" onClick={() => setValue('')}>
          <i className="cross-icon">
            <HiOutlineX />
          </i>
        </div>
      )}

      <OutsideClickHandler
        onOutsideClick={() => {
          setValue('')
          setSelectedSuggestion(null)
        }}
      >
        <Autosuggest
          multiSection
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderSectionTitle={renderSectionTitle}
          onSuggestionSelected={onSuggestionSelected}
          getSectionSuggestions={getSectionSuggestions}
          inputProps={inputProps}
          styles={reactSelectStyles}
        />
        <Drawer
          visible={talentModal}
          placement="bottom"
          closable
          onClose={() => {
            setTalentModal(false)
            setCandidateId(null)
          }}
          height="98%"
          bodyStyle={{ width: '100%', margin: 'auto' }}
        >
          {!isFetching && selectedTalent ? (
            <ResumeSlider
              slider={false}
              candidate={selectedTalent}
              candidateId={selectedTalent?._id}
              defaultTab={queryParams.get('tab') || undefined}
              onClose={() => {
                setTalentModal(false)
                setCandidateId(null)
              }}
            />
          ) : (
            t('common:loadingData')
          )}
        </Drawer>{' '}
      </OutsideClickHandler>
    </s.SearchBarContainer>
  )
}

export default SearchBar
