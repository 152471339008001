import styled from 'styled-components'

const Styles = styled.div`
  #wrapper-error500 {
    position: relative;
    height: 100vh;
    background: #545cd8;
  }

  .server-error {
    position: absolute;
    left: 50%;
    top: 35%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 920px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
  }

  .content-500 {
    position: relative;
    height: 180px;
    margin-top: 13px;
    margin-bottom: 20px;
    z-index: -1;
  }

  .content-500 h1 {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 284px;
    font-weight: 900;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: -12px;
    color: #001628;
    text-transform: uppercase;
    text-shadow: -1px -1px 0px #8400ff, 1px 1px 0px #ff005a;
    letter-spacing: -20px;
  }

  .content-500 h2 {
    position: absolute;
    left: 0;
    right: 0;
    top: 110px;
    font-size: 42px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 2px 0 #8400ff;
    letter-spacing: 3px;
    margin: 0;
  }

  .content-500 p {
    font-family: 'Open Sans', sans-serif !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 160px;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-shadow: 0 2px 0 #8400ff;
    margin: 0;
  }

  .content-500 a {
    font-family: 'Open Sans', sans-serif !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 300px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-decoration: underline;
    margin: 0;
  }
`

export default Styles
