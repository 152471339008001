import produce from 'immer'

import {
  SET_OFFER,
  SET_POOL,
  SET_CONVERSATION,
  SET_CANDIDATE,
  SET_CANDIDATE_ID,
  SET_FILTER_CLASSIFICATION,
  SET_SORT_FIELD,
  SET_SORT_ORDER,
  SET_CONVERSATION_TEXT_SEARCH,
  SET_ENTRYPOINT_TEXT_SEARCH,
  SET_ENTRYPOINT,
  SET_TEXT,
  SET_MAIL,
  SET_PLATFORM,
} from '../actions/communicationActions'

const initialState = {
  offer: null,
  pool: null,
  conversation: null,
  candidate: null,
  candidateId: null,
  filterClassification: 'selected',
  sortField: 'lastInteractionDate',
  sortOrder: -1,
  conversationTextSearch: '',
  entrypointTextSearch: '',
  entrypoint: 'offers',
  text: '',
  mailText: '',
  mailSubject: '',
  platform: 'mail',
}

export default produce((draft, action) => {
  switch (action.type) {
    case SET_OFFER: {
      draft.offer = action.offer
      return
    }

    case SET_POOL: {
      draft.pool = action.pool
      return
    }

    case SET_CONVERSATION: {
      draft.conversation = action.conversation
      return
    }

    case SET_CANDIDATE: {
      draft.candidate = action.candidate
      return
    }

    case SET_CANDIDATE_ID: {
      draft.candidateId = action.candidateId
      return
    }

    case SET_FILTER_CLASSIFICATION: {
      draft.filterClassification = action.filter
      return
    }

    case SET_SORT_FIELD: {
      draft.sortField = action.field
      return
    }

    case SET_SORT_ORDER: {
      draft.sortOrder = action.order
      return
    }

    case SET_CONVERSATION_TEXT_SEARCH: {
      draft.conversationTextSearch = action.text
      return
    }

    case SET_ENTRYPOINT_TEXT_SEARCH: {
      draft.entrypointTextSearch = action.text
      return
    }

    case SET_ENTRYPOINT: {
      draft.entrypoint = action.entrypoint
      return
    }

    case SET_TEXT: {
      draft.text = action.text
      return
    }

    case SET_MAIL: {
      draft.mailSubject = action.subject
      draft.mailText = action.text
      return
    }

    case SET_PLATFORM: {
      draft.platform = action.platform
      break
    }

    default: {
    }
  }
}, initialState)
