/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-danger */
import React, { useEffect, useState, Suspense, useContext } from 'react'
import { Timeline } from 'antd'
import { FaRegCommentDots, FaRocket } from 'react-icons/fa'
import Linkify from 'react-linkify'
import { useTranslation } from 'react-i18next'

import moment from 'moment'
import { BsArrowRight, BsEye } from 'react-icons/bs'
import { nanoid } from 'nanoid'
import { useSelector } from 'react-redux'
import { BiSend } from 'react-icons/bi'
import {
  capitalize,
  findUsernameById,
  findUsernameByEmail,
  computeElapsed,
} from '../../../services/utils'
import * as s from './styles'
import Loader from '../../Loader'
import { FeathersContext } from '../../../services/feathers'

const ActivityFeed = ({
  conversation,
  processStatus,
  candidate,
  refreshParentData,
}) => {
  const { t } = useTranslation(['common', 'reactivation'], {
    useSuspense: false,
  })
  const feathers = useContext(FeathersContext)

  const user = useSelector((state) => state.user)

  const [collaborators, setCollaborators] = useState([])
  const [feeds, setFeeds] = useState([])
  const [status, setStatus] = useState('fetching')

  const markMessagesAsSeen = async (messages) => {
    const messagesSeen = JSON.parse(JSON.stringify(messages))
    messagesSeen.forEach((message) => {
      if (message.seen === false) message.seen = true
    })

    await feathers.service('candidates').patch(candidate._id, {
      messages: messagesSeen,
    })
  }

  const fecthFeed = async () => {
    const feedsArray = []
    const result = await feathers.service('users').find({
      query: {
        $limit: -1,
      },
    })

    const users = result.map((user) => {
      let name = user.login
      if (user?.firstname && user?.lastname) {
        name = `${user.firstname} ${user.lastname}`
      }
      return {
        id: user._id,
        display: name,
        email: user.email,
      }
    })
    setCollaborators(users)

    if (conversation?.updates) {
      const updates = conversation.updates.map((value) => {
        const newValue = { ...value }
        newValue.activityType = 'update-process-status'

        for (const collaborator of collaborators) {
          if (collaborator.id === value.user_id) {
            newValue.userName = collaborator.display
            break
          }
        }

        if (newValue.processStatus_id) {
          for (const process of processStatus) {
            if (process.value === newValue.processStatus_id) {
              newValue.classification = process.label
              break
            }
          }
        } else {
          const classificationKey = {
            NOT_SELECTED: t('common:processStatusOptions.notSelected'),
            SELECTED: t('common:processStatusOptions.selected'),
            NOT_CLASSIFIED: t('common:processStatusOptions.toProcess'),
            PENDING: t('common:processStatusOptions.pending'),
            CLIENT_R1: t('common:processStatusOptions.clientR1'),
          }
          newValue.classification = classificationKey[value.classification]
        }

        return newValue
      }, [])

      feedsArray.push(...updates)
    }

    if (candidate?.messages?.length) {
      if (candidate.messages.map((e) => e.seen).includes(false)) {
        // Issues creating the drawer candidateOnly to close multiple times just after being open
        // markMessagesAsSeen(candidate.messages).then(() => {
        //   if (refreshParentData) {
        //     refreshParentData()
        //   }
        // })
      }
      feedsArray.push(
        ...candidate.messages.map((message) => {
          message.activityType = 'message'
          return message
        }),
      )
    }

    if (candidate?.notes?.length) {
      feedsArray.push(
        ...candidate.notes.map((note) => {
          note.activityType = 'note'
          return note
        }),
      )
    }

    if (candidate?.presentationNotes?.length) {
      feedsArray.push(
        ...candidate.presentationNotes.map((presentationNote) => {
          presentationNote.activityType = 'presentationNote'
          return presentationNote
        }),
      )
    }

    if (candidate?.hirings?.length) {
      const parsed = []
      for (const hiring of candidate.hirings) {
        if (!hiring.name) {
          try {
            // eslint-disable-next-line no-await-in-loop
            const offer = await feathers
              .service('offers')
              .get(hiring.offer_id, {
                $select: 'name',
              })
            hiring.name = offer.name
          } catch (e) {}
        }
        hiring.activityType = 'hired'
        parsed.push(hiring)
      }
      feedsArray.push(...parsed)
    }

    feedsArray.push(
      ...(candidate?.researchStatusUpdates?.map((statusUpdate) => {
        statusUpdate.activityType = 'statusUpdate'
        return statusUpdate
      }) || []),
    )
    setFeeds(feedsArray)
  }

  useEffect(() => {
    fecthFeed()
    setStatus('fetched')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidate])

  if (status === 'fetching') {
    return <Loader />
  }

  return (
    <Suspense fallback={<Loader text={t('common:loader.text')} />}>
      <s.ActivityFeed>
        <Timeline style={{ margin: '10px' }}>
          {feeds
            .sort((a, b) => moment(b.timestamp) - moment(a.timestamp))
            .map((feed) => {
              const now = new Date().getTime() / 1000
              const activityDate = new Date(feed.timestamp).getTime() / 1000

              const elapsedTime = activityDate
                ? t('common:notification.elapsedTime', {
                    time: `${computeElapsed(now, activityDate)}`,
                  })
                : t('common:noDate')

              if (feed.activityType === 'message') {
                let sender
                let to

                if (feed.type === 'client') {
                  sender = capitalize(
                    findUsernameByEmail(feed.from.toString(), collaborators),
                  )
                  to = t('candidate:toClient')
                } else if (feed.type === 'candidate') {
                  sender = `${capitalize(candidate.firstname)} ${capitalize(
                    candidate.lastname,
                  )}`
                  to = t('candidate:toRecruiter', {
                    recruiter: capitalize(
                      findUsernameByEmail(feed.to.toString(), collaborators),
                    ),
                  })
                } else if (feed.channel === 'mail' && feed.from?.length > 0) {
                  sender = capitalize(
                    findUsernameByEmail(feed.from.toString(), collaborators),
                  )
                  to = t('candidate:toCandidate', {
                    candidate: `${capitalize(candidate.firstname)} ${capitalize(
                      candidate.lastname,
                    )}`,
                  })
                } else {
                  sender = capitalize(user.company_id.name)
                }

                return (
                  <Timeline.Item
                    key={feed._id}
                    dot={
                      <div className="icon-rounded-wrapper">
                        <BiSend size={14} color="#050505" />
                      </div>
                    }
                    color="grey"
                  >
                    <p className="email-feed-author">
                      <div style={{ position: 'relative' }}>
                        <strong
                          style={{
                            color: `${
                              feed.type === 'candidate' ? 'var(--primary)' : ''
                            }`,
                          }}
                        >
                          {sender}
                        </strong>{' '}
                        {t('talent:activity.sentMessage')} via {feed.channel}{' '}
                        {to}
                      </div>
                      <span className="history-date">{elapsedTime}</span>
                    </p>
                    <div className="chat-box">
                      {feed.contentType === 'attachment' ? (
                        <a
                          href={feed.signedUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {feed.text}
                        </a>
                      ) : (
                        <div className="email-content-wrapper">
                          <Linkify>
                            {' '}
                            {feed?.text?.split('\n\n').map((sentence) => (
                              <p key={nanoid()}>{sentence}</p>
                            ))}
                          </Linkify>
                        </div>
                      )}
                    </div>
                  </Timeline.Item>
                )
              }
              if (feed?.activityType === 'presentationNote') {
                return (
                  <Timeline.Item
                    key={feed._id}
                    dot={
                      <div className="icon-rounded-wrapper">
                        <FaRegCommentDots size={12} color="#050505" />
                      </div>
                    }
                    color="grey"
                  >
                    <p className="comment-author">
                      <strong>
                        {capitalize(
                          findUsernameById(feed?.user_id, collaborators),
                        )}
                      </strong>{' '}
                      {t('talent:activity.sentPresentationNote')}
                      <span className="history-date">{elapsedTime}</span>
                    </p>
                    <div className="note-content-wrapper">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: feed?.text,
                        }}
                      />
                    </div>
                  </Timeline.Item>
                )
              }
              if (feed?.activityType === 'note') {
                return (
                  <Timeline.Item
                    key={feed._id}
                    dot={
                      <div className="icon-rounded-wrapper">
                        <FaRegCommentDots size={12} color="#050505" />
                      </div>
                    }
                    color="grey"
                  >
                    <p className="comment-author">
                      <strong>
                        {capitalize(
                          findUsernameById(feed?.user_id, collaborators),
                        )}
                      </strong>{' '}
                      {t('talent:activity.sentNote')}
                      <span className="history-date">{elapsedTime}</span>
                    </p>
                    <div className="note-content-wrapper">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: feed?.text,
                        }}
                      />
                    </div>
                  </Timeline.Item>
                )
              }
              if (feed?.activityType === 'hired') {
                return (
                  <Timeline.Item
                    key={feed._id}
                    dot={
                      <div className="icon-rounded-wrapper">
                        <FaRocket size={14} color="#050505" />
                      </div>
                    }
                    color="grey"
                  >
                    <div style={{ marginLeft: 20 }}>
                      <span>
                        {t('campaign:hiredFor')}{' '}
                        <strong>
                          {findUsernameById(feed.user_id, collaborators)}
                        </strong>
                      </span>
                      <div
                        className="history-user"
                        style={{ margin: '1em 0px -1em 0px' }}
                      >
                        {t('common:campaign')}{' '}
                        <a href={`/app/offers/${feed.offer_id}`}>
                          {feed?.name || t('common:offerDeleted')}
                        </a>
                      </div>
                      <span className="history-date">{elapsedTime}</span>
                    </div>
                  </Timeline.Item>
                )
              }
              if (feed.activityType === 'update-process-status') {
                return (
                  <Timeline.Item
                    key={feed._id}
                    dot={
                      <div className="icon-rounded-wrapper">
                        <BsArrowRight size={14} color="#050505" />
                      </div>
                    }
                    color="grey"
                  >
                    <div className="history-item">
                      <p>
                        <span className="history-user">
                          <strong>
                            {findUsernameById(feed.user_id, collaborators)}
                          </strong>
                        </span>{' '}
                        <span>
                          {' '}
                          {t('talent:activity.moveApplication')}{' '}
                          <BsArrowRight size={14} color="#050505" />{' '}
                        </span>
                        <strong>{feed.classification}</strong>{' '}
                        <span className="history-date">{elapsedTime}</span>
                      </p>
                    </div>
                  </Timeline.Item>
                )
              }
              if (feed.activityType === 'statusUpdate') {
                const { status } = feed
                let researchStatusLabel = t(
                  'common:candidates.searchStatus.noStatus',
                )

                if (status === 'active-search') {
                  researchStatusLabel = t(
                    'common:candidates.searchStatus.activeSearch',
                  )
                }
                if (status === 'listening-market') {
                  researchStatusLabel = t(
                    'common:candidates.searchStatus.listeningMarket',
                  )
                }
                if (status === 'not-interested') {
                  researchStatusLabel = t(
                    'common:candidates.searchStatus.notInterested',
                  )
                }

                return (
                  <Timeline.Item
                    key={feed._id}
                    dot={
                      <div className="icon-rounded-wrapper">
                        <BsEye size={14} color="#050505" />
                      </div>
                    }
                    color="grey"
                  >
                    <div className="history-item">
                      <p>
                        <span>
                          {' '}
                          {t('talent:activity.updatedResearchStatus')}{' '}
                          <strong>{researchStatusLabel}</strong>
                        </span>
                        <strong>{feed.classification}</strong>{' '}
                        <span className="history-date">{elapsedTime}</span>
                      </p>
                    </div>
                  </Timeline.Item>
                )
              }
            })}
        </Timeline>
      </s.ActivityFeed>
    </Suspense>
  )
}

export default ActivityFeed
