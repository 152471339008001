import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const actionsWrapper = styled.div`
  .wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    margin-right: 32px;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 300ms ease;
  }

  .visible {
    visibility: visible;
    opacity: 1;
  }

  .table-action-elem {
    margin: 0px;
  }
`
