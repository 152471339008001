// TODO: hide communication component for fixing purpose
import React from 'react'

import {
  RocketLaunch,
  UsersThree,
  Faders,
  SquaresFour,
  Coins,
  MagnifyingGlass,
} from 'phosphor-react'

export const AdminHolloNav = (t) => [
  {
    label: t('common:sidebar.clients'),
    to: '/app/companies',
    icon: <RocketLaunch size={32} weight="fill" />,
  },
  {
    label: t('common:sidebar.deals'),
    to: '/app/deals',
    icon: <Coins size={32} weight="fill" />,
  },
  {
    label: null,
    to: null,
    icon: null,
  },
  {
    label: t('common:sidebar.templates'),
    to: '/app/chatbot-settings',
    icon: <Faders size={32} weight="bold" />,
  },
]

export const UserNav = (t, access) => {
  const campaigns = {
    label: t('common:sidebar.campaigns'),
    to: '/app/offers',
    icon: <RocketLaunch size={32} weight="fill" />,
  }
  const pools = {
    label: t('common:sidebar.talentPools'),
    to: '/app/pools',
    icon: <UsersThree size={32} weight="fill" />,
  }
  const table = [
    {
      label: t('common:sidebar.home'),
      to: '/app/dashboard',
      icon: <SquaresFour size={32} weight="fill" />,
    },
    ...(access.includes('crm')
      ? [
          {
            label: t('common:sidebar.campaigns'),
            to: '/app/offers',
            icon: <RocketLaunch size={32} weight="fill" />,
          },
        ]
      : []),
    ...(access.includes('talent-pools')
      ? [
          {
            label: t('common:sidebar.talentPools'),
            to: '/app/pools',
            icon: <UsersThree size={32} weight="fill" />,
          },
        ]
      : [
          {
            label: t('common:sidebar.talentsSearch'),
            to: '/app/candidates',
            icon: <MagnifyingGlass size={32} weight="fill" />,
          },
        ]),
    {
      label: null,
      to: null,
      icon: null,
    },
    {
      label: t('common:sidebar.templates'),
      to: '/app/chatbot-settings',
      icon: <Faders size={32} weight="bold" />,
    },
  ]

  return table
}

export const GuestNav = (t) => [
  {
    label: t('common:sidebar.home'),
    to: '/app/dashboard',
    icon: <SquaresFour size={32} weight="fill" />,
  },
  {
    label: t('common:sidebar.campaigns'),
    to: '/app/offers',
    icon: <RocketLaunch size={32} weight="fill" />,
  },
]
