import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const InfoLineWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  gap: 10px;

  .talent-info-label {
    font-weight: bold;
    opacity: 0.4;
    margin: 4px 0;
  }
  .talent-info-value {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    align-items: center;
    column-gap: 5px;
    font-weight: 700;
  }
`
export const TalentsInfoWrapper = styled.div`
  margin: 10px 0;

  .talent-info-title {
    font-size: 18px;
    font-weight: 700;
  }

  .lines-wrapper {
    margin-top: 20px;
  }
`
