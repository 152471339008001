export const SET_TEXT_SEARCH = 'SET_TEXT_SEARCH'
export const SET_CURSOR = 'SET_CURSOR'
export const SET_SORT = 'SET_SORT'

export const setTextSearch = (text) => ({
  type: SET_TEXT_SEARCH,
  text,
})

export const setCursor = (cursor) => ({
  type: SET_CURSOR,
  cursor,
})

export const setSort = (data) => ({
  type: SET_SORT,
  data,
})
