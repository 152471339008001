import { app } from '../../services/feathers'

// eslint-disable-next-line import/prefer-default-export
export const resetTalent = async (id) => {
  // fetch and delete one by one to avoid adding multi auth to likes service
  const allCandidatesLikes = await app.service('likes').find({
    query: {
      candidate_id: id,
      $limit: -1,
    },
  })

  // delete all likes
  await Promise.all(
    allCandidatesLikes.map((like) => app.service('likes').remove(like._id)),
  )

  // clean candidate object
  await app.service('candidates').patch(id, {
    researchStatus: 'unknown',
    researchStatusUpdatedAt: null,
    researchStatusUpdates: [],
    location: null,
    contractTypes: [],
    salaryRange: null,
    $unset: { jobDomain: '', experience: '' },
  })

  // clean candidatesfull object of likes
  await app.service('candidatesfull').patch(id, {
    likes: [],
  })
}

export async function getOneFullCandidate(id) {
  return app.service('candidatesfull').get(id)
}

export async function getOneCandidate(id) {
  return app.service('candidates').get(id)
}
