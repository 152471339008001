/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from 'react'
import { Modal as AntdModal } from 'antd'
import { RiCloseFill } from 'react-icons/ri'
import PropTypes from 'prop-types'

/**
 * Modal component made to display a modal built around Antd Modal
 * to prevent rendering of the Modal if visible is not true
 * @param {boolean} visible if true renders the modal
 * rest of params can be found at https://ant.design/components/modal/
 */
const Modal = ({ visible, ...props }) => {
  if (!visible) {
    return null
  }

  return (
    <AntdModal
      visible={visible}
      closeIcon={<RiCloseFill size={32} />}
      {...props}
    >
      {props.children}
    </AntdModal>
  )
}

Modal.propTypes = {
  visible: PropTypes.bool,
}
Modal.defaultProps = {
  visible: false,
}

export default Modal
