/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
import { EditorState } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { convertToHTML } from 'draft-convert'
import toast from 'react-hot-toast'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { nanoid } from 'nanoid'
import Dropzone from 'react-dropzone'
import { bool, func, object } from 'prop-types'
import { Input, Label } from 'reactstrap'
import { Modal, Button, Select } from 'antd'
import { useSelector } from 'react-redux'
import { capitalize, replaceInText } from '../../services/utils'
import AuthService from '../../services/AuthService'
import { getOneTeam } from '../../api/Team/api.team'
import './ClientEmailModal.css'
import RichEditor from '../RichEditor'

const Auth = new AuthService()

const propTypes = {
  isOpen: bool.isRequired,
  toggle: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  conversation: object,
}
const defaultProps = {
  conversation: null,
}

const ClientEmailModal = (props) => {
  const { conversation, isOpen, toggle } = props

  const user = useSelector((state) => state.user)
  const { t } = useTranslation(['common', 'candidate'], { useSuspense: false })

  const [team, setTeam] = useState()
  const [to, setTo] = useState()
  const [cc, setCc] = useState()
  const [subject, setSubject] = useState()
  const [attachments, setAttachments] = useState([])
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)

  const getTeam = async () => {
    const result = await getOneTeam(conversation?.team_id).then((res) =>
      Promise.resolve(res),
    )
    if (result) {
      setTeam(result)
      setSubject(
        `Nouvelle candidature : ${capitalize(
          conversation?.candidate_id?.firstname,
        )} ${capitalize(
          conversation?.candidate_id?.lastname,
        )} pour le poste de ${conversation?.offer_id?.displayName || ''}`,
      )
    }
  }

  const clientApplicationProposalLink = useMemo(() => {
    const currentUrl = window.location.href.split('/')[2]

    if (user?.company_id?._id === '5ede3f0a1746310018d42a3d') {
      return `${'https://job2bedone-partners.hollo.io'}/talent/${
        conversation?._id
      }`
    }

    if (currentUrl.includes('localhost')) {
      return `${'http://localhost:3001'}/talent/${conversation?._id}`
    }
    if (currentUrl.includes('staging')) {
      return `${'https://app-staging.hollo.io'}/talent/${conversation?._id}`
    }

    return `${'https://app.hollo.io'}/talent/${conversation?._id}`
  }, [user, conversation])

  const template = `<p>Bonjour,<p><p>J'espère que vous allez bien.</p><p>Je vous présente le profil de <a href="${clientApplicationProposalLink}">${capitalize(
    conversation?.candidate_id?.firstname,
  )} ${capitalize(
    conversation?.candidate_id?.lastname,
  )}</a> que j'ai rencontré et validé pour le poste de <b>${
    conversation?.offer_id?.displayName || ''
  }</b>.</p><p>Je vous laisse prendre connaissance de son profil, cliquer sur le bouton <b>Rencontrer ${capitalize(
    conversation?.candidate_id?.firstname,
  )} ${capitalize(
    conversation?.candidate_id?.lastname,
  )}</b> et m'indiquer vos disponibilités afin d'organiser une rencontre prochainement.<p><p>Je reste disponible pour en parler par téléphone.</p><p>À bientôt !</p><p>${
    user?.firstname
  }</p>`

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(stateFromHTML(template)),
  )

  const handleSendEmail = async () => {
    setIsLoadingBtn(true)
    try {
      const formData = new FormData()
      formData.append('teamId', team._id)
      formData.append('candidateId', conversation.candidate_id._id)
      formData.append('action', 'sendMail')
      formData.append('type', 'client')
      formData.append(
        'query',
        JSON.stringify({
          to: to?.length
            ? to?.map((to) => ({
                email: to,
              }))
            : undefined,
          cc: cc?.length
            ? cc?.map((cc) => ({
                email: cc,
              }))
            : undefined,
          subject: replaceInText(
            subject,
            false,
            conversation.candidate_id,
            conversation.offer_id,
          ),
          body: replaceInText(
            convertToHTML({
              entityToHTML: (entity, originalText) => {
                if (entity.type === 'LINK') {
                  return <a href={entity.data.url}>{originalText}</a>
                }
                return originalText
              },
            })(editorState.getCurrentContent()),
            true,
            conversation.candidate_id,
            conversation.offer_id,
          ),
        }),
      )

      attachments.forEach((file) => {
        formData.append('attachments', file)
      })

      await Auth.fetchWithoutHeaders('/nylas', {
        method: 'POST',
        body: formData,
      })

      toast.success(t('common:sendEmail.toast.success'))
      toggle()
    } catch (e) {
      console.error(e)
      toast.error(t('common:sendEmail.toast.nylasError'))
      setIsLoadingBtn(false)
    }
  }

  const onDropAttachment = (files) => {
    setAttachments([...attachments, files[0]])
  }

  const removeAttachment = (index) => {
    const clone = attachments.slice()
    clone.splice(index, 1)
    setAttachments(clone)
  }

  const onCancel = () => {}

  useEffect(() => {
    getTeam()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      visible={isOpen}
      onCancel={toggle}
      width={800}
      title={t(
        'candidate:sendMailToOneTalent',
        'Partager ce talent par email à {{teamName}}',
        {
          teamName: capitalize(team?.name),
        },
      )}
      footer={
        <div>
          <Button
            className="grey-btn"
            disabled={isLoadingBtn}
            onClick={() => toggle()}
          >
            {t('common:cancel')}
          </Button>
          <Button
            className="basic-btn"
            loading={isLoadingBtn}
            disabled={!subject || !to?.length}
            onClick={() => handleSendEmail()}
          >
            {t('common:send')}
          </Button>
        </div>
      }
    >
      <Label>Destinataires</Label>
      <Select
        style={{
          display: 'block',
        }}
        showSearch
        allowClear
        showArrow
        mode="multiple"
        options={team?.contacts
          // eslint-disable-next-line no-prototype-builtins
          ?.filter((item) => item.hasOwnProperty('email'))
          ?.map((contact) => ({
            label: `${contact?.firstname || ''} ${contact?.lastname || ''} - ${
              contact?.job || ''
            } (${contact?.email})`,
            value: contact?.email,
          }))}
        value={to}
        onChange={setTo}
      />
      <Label className="mt-4">Cc</Label>
      <Select
        style={{
          display: 'block',
        }}
        showSearch
        allowClear
        showArrow
        mode="multiple"
        options={team?.contacts
          // eslint-disable-next-line no-prototype-builtins
          ?.filter((item) => item.hasOwnProperty('email'))
          ?.map((contact) => ({
            label: `${contact?.firstname || ''} ${contact?.lastname || ''} (${
              contact?.email
            })`,
            value: contact?.email,
          }))}
        value={cc}
        onChange={setCc}
      />
      <Label className="mt-4" for="subject">
        {t('common:object')}
      </Label>
      <Input
        type="text"
        name="subject"
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
      />
      <Label className="mt-4" for="subject">
        {t('common:content')}
      </Label>
      <RichEditor
        editorState={editorState}
        setEditorState={setEditorState}
        mentions={false}
      />
      <Label className="mt-4">
        {t('common:sendEmail.inputs.file.label', 'Pièces jointes')}
      </Label>
      <div className="dropzone-wrapper dropzone-mail-attachments">
        <Dropzone
          onFileDialogCancel={onCancel.bind(this)}
          onDrop={onDropAttachment.bind(this)}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="email-dropzone dropzone-content">
                {t(
                  'common:sendEmail.inputs.file.add',
                  'Ajouter une pièce jointe',
                )}
              </div>
            </div>
          )}
        </Dropzone>
      </div>
      {attachments.map((attachment, index) => (
        <Button
          key={nanoid()}
          className="mb-2 mr-2 btn-icon btn-pill"
          outline
          color="light"
          onClick={() => removeAttachment(index)}
        >
          {attachment.name}
          <i className="pe-7s-trash btn-icon-wrapper"> </i>
        </Button>
      ))}
    </Modal>
  )
}

ClientEmailModal.propTypes = propTypes
ClientEmailModal.defaultProps = defaultProps
export default ClientEmailModal
