/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import { Modal, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import ReactLinkify from 'react-linkify'

/**
 * Cuts a given text for a specified number of characters
 * @param {string} text
 * @param {number} number
 * @returns the cut text with ... at the end and a button to see the full text
 */
export default function ExcerptLine({ text, number }) {
  const { t } = useTranslation(['common'], {
    useSuspense: false,
  })
  const [isOpen, setIsOpen] = useState(false)

  const excerptLine = (text) => text.slice(0, number).concat('...')

  if (text.length < number) {
    return (
      <ReactLinkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}
      >
        {text}
      </ReactLinkify>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <div>
        <ReactLinkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          {excerptLine(text)}
        </ReactLinkify>
        <span
          role="button"
          tabIndex={0}
          onClick={() => setIsOpen(true)}
          style={{
            marginLeft: '10px',
            textDecoration: 'underline',
            color: '#4090ff',
            cursor: 'pointer',
          }}
        >
          {t('common:seeMore')}
        </span>
      </div>
      <Modal
        destroyOnClose
        visible={isOpen}
        onCancel={() => setIsOpen(false)}
        title={t('common:detail', 'Détail')}
        footer={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              className="grey-btn"
              onClick={() => setIsOpen(false)}
              style={{ marginRight: '10px' }}
            >
              {t('common:cancel')}
            </Button>
          </div>
        }
      >
        <div>
          <p>
            <ReactLinkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {text}
            </ReactLinkify>
          </p>
        </div>
      </Modal>
    </div>
  )
}
