import { app } from '../../services/feathers'

/**
 *
 *
 * @param {*} user
 * @param {*} talent
 * @param {*} action
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export const createReactivation = (talents, type, companyId) =>
  app.service('reactivations').create({
    candidates: talents,
    type,
    domainJ2BD: ['5ede3f0a1746310018d42a3d'].includes(companyId)
      ? true
      : undefined,
  })
