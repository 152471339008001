import differenceInMinutes from 'date-fns/differenceInMinutes'
import { sendAmplitudeData } from '../../services/amplitude'
import { app } from '../../services/feathers'
import { replaceInText } from '../../services/utils'

/**
 * Will prepare the list to be sent on "/nylas"
 * @param {Array} list
 * @param {Object} action subject, text to be sent by email
 * @param {Ojbect} offer
 * @returns
 */
function createListEmail(list, action, offer) {
  if (!list.length) {
    list = [list]
  }

  const { subject, text } = action

  return list.map((conv) => {
    const { candidate_id: candidate } = conv

    return {
      conversationId: conv._id,
      candidateId: candidate._id,
      action: 'sendMail',
      query: JSON.stringify({
        to: [{ email: candidate.email }],
        subject: replaceInText(subject, false, candidate, offer),
        body: replaceInText(text, true, candidate, offer),
      }),
    }
  })
}

/**
 * Patch list conversations and send mail if action
 * @throws error if selectedConversations is not an array
 * @param {Array} selectedConversations
 * @param {Object} processStatus
 * @param {Object} offer
 * @param {Boolean} withAction
 * @returns
 */
export async function handleClassifyCandidate(
  selectedConversations,
  processStatus,
  _offer,
  withAction,
  classification = 'SELECTED',
) {
  // Prevent patching all collection
  if (!selectedConversations.length) {
    throw new Error('not an array or empty array')
  }

  const query = {
    classification,
    seen: true,
    lastRecruiterUpdate: new Date(),
    trigger: withAction,
  }

  // Current status of conversation(s)
  const status = selectedConversations[0].processStatus_id?._id || undefined

  if (processStatus && processStatus?.id !== status) {
    query.processStatus_id = processStatus.id
  }

  /**
   * If processStatus is not set but conversation(s) already have a processStatus we need to remove it because
   * this case means candidate(s) are sent back to "selected"
   */
  if (
    (processStatus && processStatus?.id === 'selected') ||
    (!processStatus && status)
  ) {
    query.processStatus_id = null
  }

  try {
    await app.service('conversations').patch(null, query, {
      query: {
        _id: {
          $in: selectedConversations.map((e) => e._id),
        },
      },
    })

    const times = selectedConversations.map((conv) =>
      differenceInMinutes(new Date(), new Date(conv.createdAt)),
    )

    const dealbreakers = selectedConversations.map((conv) => {
      const len = conv.scoringVariables.filter((e) => e.dealbreaker).length || 0
      return len
    })

    const scores = selectedConversations.map((conv) => conv.score)

    const sources = selectedConversations.map((conv) => conv.source)

    // Send Candidate Application Status Updated event to Amplitude
    sendAmplitudeData('Candidate Application Status Updated', {
      Time_Since_Application_Creation: times,
      Status: processStatus ? processStatus.name : classification,
      Number_Dealbreakers: dealbreakers,
      Score: scores,
      Previous_Status: null,
      Source: sources,
      Action_Source: 'classified_table',
    })
  } catch (err) {
    throw new Error(err)
  }
  return Promise.resolve()
}

/**
 * Reject list of conversations
 * @throws error if selectedConversations is not an array
 * @param {Array} selectedConversations
 * @param {Object} offer
 * @param {Boolean} mailOnReject
 * @returns
 */
export async function handleRejectMultiConversations(
  selectedConversations,
  offer,
  mailOnReject,
) {
  if (!selectedConversations.length) {
    selectedConversations = [selectedConversations]
  }

  // !!! To prevent patch all candidates in BDD !!!
  if (!selectedConversations.length) {
    throw new Error('not an array or empty array')
  }
  if (!offer) {
    throw new Error('Something wrong has happened')
  }

  const classification = 'NOT_SELECTED'

  try {
    await app.service('conversations').patch(
      null,
      {
        classification,
        seen: true,
        lastRecruiterUpdate: new Date(),
      },
      {
        query: {
          _id: {
            $in: selectedConversations.map((e) => e._id),
          },
        },
      },
    )
  } catch (error) {
    throw new Error(error)
  }

  const times = selectedConversations.map((conv) =>
    differenceInMinutes(new Date(), new Date(conv.createdAt)),
  )

  const dealbreakers = selectedConversations.map((conv) => {
    const len = conv.scoringVariables?.filter((e) => e.dealbreaker).length || 0
    return len
  })

  const scores = selectedConversations.map((conv) => conv.score)

  const sources = selectedConversations.map((conv) => conv.source)

  // Send Candidate Application Status Updated event to Amplitude
  sendAmplitudeData('Candidate Application Status Updated', {
    Time_Since_Application_Creation: times,
    Status: classification,
    Number_Dealbreakers: dealbreakers,
    Score: scores,
    Previous_Status: null,
    Source: sources,
    Action_Source: 'not_classified_table',
  })

  if (mailOnReject) {
    const mailOnRejectIndex = offer.actions.findIndex(
      (action) => action.trigger === 'notSelected',
    )

    if (mailOnRejectIndex >= 0) {
      const action = offer.actions[mailOnRejectIndex].actionsToTrigger[0]

      // Creating an array with all the mails to send by nylas service
      const bulkMail = createListEmail(selectedConversations, action, offer)

      app.service('nylas').create({
        list: bulkMail,
        action: 'sendMail',
      })
    }
  }

  return Promise.resolve()
}

export async function createMultipleConversations({
  candidates,
  offer,
  status,
}) {
  const list = candidates.map((candidate) => ({
    candidate_id: candidate._id,
    company_id: offer.company_id,
    // hack to fix problem in addCandidateToCampaign offer has value as _id
    offer_id: offer.value,
    team_id: offer.team_id,
    createdBy: 'user',
    classification: status,
  }))
  const response = await app.service('conversations').create(list)
  return response
}

export async function createConversation({
  candidate,
  offer,
  status,
  createdFrom,
}) {
  if (!candidate._id || !offer._id) {
    return null
  }
  return app.service('conversations').create({
    candidate_id: candidate._id,
    company_id: offer.company_id,
    // hack to fix problem in addCandidateToCampaign offer has value as _id
    offer_id: offer.value || offer._id,
    team_id: offer?.team_id,
    createdFrom: createdFrom,
    createdBy: 'user',
    classification: status,
  })
}

export async function getOneConversation(id) {
  if (!id) {
    return console.error('No id provided')
  }
  return app.service('conversations').get(id)
}

export async function getUnreadConversations(companyId, onlyCurrentUser) {
  const query = {
    dashboard_aggreg_conversations: true,
    onlyActiveOffers: true,
    company_id: companyId,
    seen: false,
    classification: 'NOT_CLASSIFIED',
    $limit: 1,
  }
  if (onlyCurrentUser) {
    query.onlyCurrentUser = true
  }
  return app.service('conversations').find({
    query,
  })
}

export async function getAllConversations(companyId, onlyCurrentUser) {
  const query = {
    company_id: companyId,
    $limit: 1,
  }
  if (onlyCurrentUser) {
    query.dashboard_aggreg_conversations = true
    query.onlyCurrentUser = true
  }
  return app.service('conversations').find({
    query,
  })
}

export async function getConversationsPerDay(companyId, onlyCurrentUser) {
  const query = {
    dashboard_aggreg: true,
    company_id: companyId,
    $limit: 1,
  }
  if (onlyCurrentUser) {
    query.onlyCurrentUser = true
  }
  return app.service('conversations').find({
    query,
  })
}
