import styled from 'styled-components'

export const CandidateProfileWrapper = styled.div`
  position: relative;

  .tinder-btns {
    display: flex;
    justify-content: center;
    gap: 30px;
    position: fixed;
    z-index: 1000;
    top: 80%;
    left: 50%;
    transform: translate(-50%, 50%);
    opacity: 0.8;
  }

  .tinder-btns > .grey-btn {
    color: #0084ff !important;
  }

  .candidate-fullname {
    font-weight: 800;
    text-transform: capitalize;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    color: var(--primary);
  }

  .display-icon {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
    font-size: 14px;
    opacity: 0.7;
  }

  .profile-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 10px;
    padding-right: 20px;
  }

  .profile-header-wrapper:hover .wrapper {
    visibility: visible;
    opacity: 1;
  }

  .canOpen:hover {
    cursor: pointer;
  }

  .contacts p {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 500;
    opacity: 0.55;
  }

  .btn-sm .badge-pill,
  .btn-group-sm > .btn .badge-pill {
    position: absolute;
    top: -17px;
    right: -9px;
  }

  .resume-btn {
    height: 31px;
    font-family: 'Open Sans', sans-serif !important;
    color: white !important;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600 !important;
    text-decoration: none;
    -webkit-box-align: center;
    background-color: var(--primary);
    align-items: center;
    flex-direction: row;
    -webkit-box-pack: center;
    flex-shrink: 0;
    margin: 0;
    border: none !important;
    border-radius: 6px !important;
    outline: 0;
    box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
    z-index: 11;
    margin-right: 3px;
  }

  .resume-btn:hover,
  .resume-btn:focus {
    background-color: var(--primary);
    border-color: var(--primary);
    transition: 0.2s ease all;
  }

  .no-applications {
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    margin-top: '15vh';
    flex-direction: 'column';
  }

  .ant-tabs-content-holder {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .ant-badge {
    color: #0070f3 !important;
  }
`
export const ModalTitle = styled.span``
export const ModalContentWrapper = styled.div``

export const ListApplications = styled.div`
  .popover-candidate .ant-popover-inner-content {
    max-height: 630px;
    overflow-y: scroll;
  }

  .candidate-application-list-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 10px;
    border: 1px solid #00000005;
    border-radius: 6px;
    -webkit-transition: 0.2s ease all;
    transition: 0.2s ease all;
    cursor: pointer;
    overflow: hidden;
    margin: 10px auto;
    border-radius: 15px;
  }

  .candidate-application-list-item:hover {
    background-color: #f5f5f594 !important;
  }

  .candidate-like-list-item {
    height: 100px;
    padding: 10px;
    padding-top: 5px;
    border: 1px solid #00000020;
    border-radius: 6px;
    -webkit-transition: 0.2s ease all;
    transition: 0.2s ease all;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    // display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px auto;
    border-radius: 15px;
  }

  .candidate-like-list-item:hover {
    background-color: #f5f5f594 !important;
  }

  .like-approve-btn:hover {
    color: white !important;
    background-color: #3ac47d !important;
  }
  .like-reject-btn:hover {
    color: white !important;
    background-color: red !important;
  }

  .vertical-divider {
    width: 2px;
    height: 50px;
    margin: 0px;
  }
`
