import React from 'react'
import Tooltip from '../Tooltip'

const OfferIndicators = ({ offer, size = '8px' }) => (
  <div style={{ display: 'flex', alignItems: 'center', marginRight: '7px' }}>
    {offer.published && (
      <Tooltip
        title="En ligne"
        placement="bottom"
        content={
          <span
            style={{
              width: size,
              height: size,
              display: 'flex',
              backgroundColor: '#3ac47d',
              borderRadius: '50%',
              marginRight: '3px',
            }}
          />
        }
      />
    )}

    {offer.published && offer.publishedCareers && (
      <Tooltip
        title="Sur page carrière"
        placement="bottom"
        content={
          <span
            style={{
              width: size,
              height: size,
              display: 'flex',
              backgroundColor: 'violet',
              borderRadius: '50%',
              marginRight: '3px',
            }}
          />
        }
      />
    )}
    {offer.externalRef && (
      <Tooltip
        title="Sur ATS"
        placement="bottom"
        content={
          <span
            style={{
              width: size,
              height: size,
              display: 'flex',
              backgroundColor: '#108ee9',
              borderRadius: '50%',
              marginRight: '3px',
            }}
          />
        }
      />
    )}
  </div>
)

export default OfferIndicators
