import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import { useTranslation } from 'react-i18next'
import { FormGroup } from 'reactstrap'
import { Button, Modal } from 'antd'
import AuthService from '../../services/AuthService'

const Auth = new AuthService()

const DisconnectedComponent = () => {
  const { t } = useTranslation(['settings'], {
    useSuspense: false,
  })
  const history = useHistory()

  const [intervalId, setIntervalId] = useState(null)
  const [modal, setModal] = useState(false)
  const [warningModal, setWarningModal] = useState(false)

  useEffect(() => {
    const intervalId = setInterval(() => {
      const logged = Auth.loggedIn()

      if (!logged) {
        setModal(true)
      }
    }, 60000)

    setIntervalId(intervalId)
  }, [])

  useEffect(() => () => clearInterval(intervalId), [])

  const stayLogged = () => {
    setWarningModal(false)

    if (Auth.loggedIn()) {
      Auth.fetch('/tags?$limit=0')
        .then(() => {})
        .catch(() => {})
    } else {
      setModal(true)
    }
  }

  const goToLogin = () => {
    history.push('/login')
  }

  const handleOnAction = () => {
    // update token if last update is a while ago
    if (Auth.loggedIn()) {
      const { iat } = Auth.getDecodedToken()

      // if token older than 5 minute and user is active
      if (Date.now() > iat * 1000 + 1000 * 60 * 2) {
        Auth.fetch('/tags?$limit=0')
          .then(() => {})
          .catch(() => {})
      }
    } else {
      setModal(true)
    }
  }

  // Warning after 8h of inactivity
  useIdleTimer({
    timeout: 1000 * 60 * 60 * 8, // 8h
    onIdle: () => setWarningModal(true),
    onActive: () => {},
    onAction: () => {},
    debounce: 500,
  })

  // It should disconnect after 12h of inactivity
  useIdleTimer({
    timeout: 1000 * 60 * 60 * 12,
    onIdle: () => {
      setWarningModal(false)
      setModal(true)
    },
    onActive: () => {},
    onAction: handleOnAction,
    debounce: 500,
  })

  return (
    <>
      <Modal
        footer={null}
        visible={modal && !warningModal}
        title={t('settings:disconnected.label.past')}
        closable={false}
      >
        <FormGroup>
          <p style={{ fontSize: '15px' }}>
            {t('settings:disconnected.content.past')}
          </p>
        </FormGroup>
        <div className="btns-container">
          <Button className="basic-btn" onClick={goToLogin}>
            {t('settings:reconnect.label')}
          </Button>
        </div>
      </Modal>
      {!modal ? (
        <Modal
          footer={null}
          visible={warningModal && !modal}
          title={t('settings:disconnected.label.futur')}
          closable
        >
          <FormGroup>
            <p style={{ fontSize: '15px' }}>
              {t('settings:disconnected.content.futur')}
            </p>
          </FormGroup>
          <div className="btns-container">
            <Button className="basic-btn" onClick={stayLogged}>
              {t('settings:stayConnected')}
            </Button>
          </div>
        </Modal>
      ) : (
        ''
      )}
    </>
  )
}

export default DisconnectedComponent
