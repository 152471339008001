import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import moment from 'moment'
import endTrial from '../../assets/hollo/trial-ended.svg'
// import PricingModal from '../PricingModal'

const TrialEndedModal = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'settings'], { useSuspense: false })

  const user = useSelector((state) => state.user)

  const [modal, setModal] = useState(false)
  // const [pricingModal, setPricingModal] = useState(false)

  useEffect(() => {
    if (user) {
      const { company_id: company } = user

      const now = moment()
      const trialExpiration = company?.trialExpiresAt

      if (
        company?.access === 'trial' &&
        trialExpiration &&
        moment(trialExpiration).valueOf() < now.valueOf()
      ) {
        setModal(true)
      }
    }
  }, [user])

  if (!modal) {
    return null
  }

  return (
    <>
      <Modal
        footer={null}
        visible={modal}
        title={t('settings:trial.ended.title', 'Votre essai est terminé')}
        closable={false}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <img
            height={200}
            src={endTrial}
            alt="start-trial"
            style={{ marginBottom: '40px' }}
          />
          <p
            style={{
              fontSize: '15px',
              fontStyle: 'italic',
              marginBottom: '40px',
              textAlign: 'center',
            }}
          >
            {t(
              'settings:trial.ended.content1',
              "Vous venez de terminer votre période d'essai sur Hollo, pour continuer à utiliser l'outil il vous faudra passer sur un plan payant.",
            )}
            <br />
            <br />
            {t(
              'settings:trial.ended.content2',
              "Quels que soit vos besoins, nous avons probablement une offre qui vous correspond, n'hésitez pas à cliquer ci-dessous pour les voir !",
            )}
          </p>
          {user?.company_id?.featuresAccess?.includes('talent-pools') ? (
            <a
              className="button basic-btn"
              href="mailto:support@hollo.io"
              style={{ textAlign: 'center' }}
            >
              {t('settings:contactUs')}
            </a>
          ) : (
            <Button
              className="basic-btn"
              onClick={() => /* setPricingModal(true) */ {}}
            >
              {t('settings:seeOffers', 'Voir les offres')}
            </Button>
          )}
        </div>
      </Modal>

      {/*  {pricingModal && (
        <PricingModal
          isOpen={pricingModal}
          toggle={() => setPricingModal(false)}
        />
      )} */}
    </>
  )
}

export default TrialEndedModal
