import React from 'react'
import Navbar from './Components/Navbar'

class WrapperNavBar extends React.PureComponent {
  render() {
    return (
      <div
        style={{
          position: 'fixed',
          right: '0',
          left: '83px',
          zIndex: '1000',
        }}
      >
        <Navbar {...this.props} />
      </div>
    )
  }
}

export default WrapperNavBar
