import { Button, Input, Modal, Col, Row } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Label } from 'reactstrap'
import validateEmail from 'validator/lib/isEmail'
import Dropzone from 'react-dropzone'
import AuthService from '../../../services/AuthService'
import '../../../routes/Conversations/Conversations.css'
import { FeathersContext } from '../../../services/feathers'
import AddCandidateToCampain from '../../../components/Candidate/AddCandidateToCampain'
import SelectPool from '../../../components/Candidate/SelectPool'
import { setToggleShouldRefetch } from '../../../redux/actions/reactivationActions'
const Auth = new AuthService()

const CreateCandidate = (props) => {
  const feathers = useContext(FeathersContext)
  const abortController = new AbortController()
  const dispatch = useDispatch()
  const { t } = useTranslation(['crm', 'common', 'reactivation'], {
    useSuspense: false,
  })
  const user = useSelector((state) => state.user)
  const { isOpen, toggle, createCandidateModal } = props

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [linkedinUrl, setLinkedinUrl] = useState('')
  const [isEmail, setIsEmail] = useState(false)
  const [filesUploading, setFilesUploading] = useState(false)

  const [fileNameCv, setFileNameCv] = useState('')
  const [fileNameCoverLetter, setFileNameCoverLetter] = useState('')
  const [fileType, setFileType] = useState(null)
  const [coverLetterfileType, setCoverLetterFileType] = useState(null)
  const [cvToAdd, setCvToAdd] = useState(null)
  const [coverLetterToAdd, setCoverLetterToAdd] = useState(null)
  const [selectedPool, setSelectedPool] = useState(null)
  const [offer, setOffer] = useState(null)
  const formData = new FormData()
  const coverLetterFormData = new FormData()

  const onDropCv = (files) => {
    setCvToAdd(files[0])
    setFileNameCv('CV')
    setFileType({ value: 'resume', label: 'CV' })
  }

  const onDropCoverLetter = (files) => {
    setCoverLetterToAdd(files[0])
    setFileNameCoverLetter(t('common:coverLetter'))
    setCoverLetterFileType({
      value: 'coverletter',
      label: t('common:coverLetter'),
    })
  }

  const onRemoveCv = () => {
    setCvToAdd(null)
    setFileNameCv('')
  }

  const onRemoveCoverLetter = () => {
    setCoverLetterToAdd(null)
    setFileNameCoverLetter('')
  }

  useEffect(() => () => abortController.abort(), [abortController])

  const onEmailValueChange = (e) => {
    setEmail(e.target.value)
    setIsEmail(validateEmail(e.target.value))
  }

  const handleCreateCandidate = async () => {
    try {
      const { total } = await feathers.service('candidates').find({
        query: {
          email,
        },
      })
      const candidateAlreadyExist = total > 0
      if (!candidateAlreadyExist) {
        const candidate = await feathers.service('candidates').create({
          firstname,
          lastname,
          email,
          phoneNumber,
          linkedinUrl,
          company_id: user.company_id._id,
          createdBy: 'user',
          candidatePool_id: selectedPool?.value,
        })
        if (offer) {
          await feathers.service('conversations').create({
            candidate_id: candidate._id,
            company_id: candidate.company_id,
            offer_id: offer._id,
            team_id: offer.team_id,
            createdFrom: 'manual',
            createdBy: 'user',
            seen: true,
          })
        }

        if (fileType) {
          formData.append('uri', cvToAdd)
          formData.append('name', fileNameCv)
          formData.append('type', fileType.value)
          formData.append('candidateId', candidate._id)
          setFilesUploading(true)

          await Auth.fetchWithoutHeaders('/upload-candidate-file', {
            method: 'POST',
            body: formData,
          })
        }
        if (coverLetterfileType) {
          coverLetterFormData.append('uri', coverLetterToAdd)
          coverLetterFormData.append('name', fileNameCoverLetter)
          coverLetterFormData.append('type', coverLetterfileType.value)
          coverLetterFormData.append('candidateId', candidate._id)
          setFilesUploading(true)

          await Auth.fetchWithoutHeaders('/upload-candidate-file', {
            method: 'POST',
            body: coverLetterFormData,
          })
        }
        dispatch(setToggleShouldRefetch(true))
        toast.success(t('common:registered.toast.success'))
        toggle()
      } else {
        toast.error(t('common:userAlreadyExists'))
      }
    } catch (err) {
      if (err?.message?.includes('email: value already exists')) {
        toast.error(t('crm:modals.createApplication.toast.duplicateError'))
      } else {
        toast.error(t('crm:modals.createApplication.toast.error'))
      }
    }
  }

  return (
    <Modal
      destroyOnClose
      visible={isOpen}
      onCancel={toggle}
      title={t('common:createTalent')}
      width="700px"
      footer={
        <div className="btns-container">
          <Button
            onClick={toggle}
            type="primary"
            size="middle"
            className="grey-btn"
          >
            {t('common:cancel')}
          </Button>
          <Button
            className="basic-btn"
            disabled={!firstname || !lastname || !isEmail}
            onClick={() => {
              handleCreateCandidate()
            }}
            size="middle"
          >
            {' '}
            {t('common:create')}
          </Button>
        </div>
      }
    >
      {createCandidateModal && (
        <>
          <Row justify="space-between" align="middle">
            <Col span={11}>
              {' '}
              <Label className="required" htmlFor="firstname">
                {t('common:firstname')}
                <Input
                  type="text"
                  name="firstname"
                  id="firstname"
                  className="input-create"
                  autoComplete="off"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                />
              </Label>
            </Col>
            <Col span={11}>
              {' '}
              <Label className="required">
                {t('common:lastname')}
                <Input
                  type="text"
                  name="lastname"
                  autoComplete="off"
                  className="input-create"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                />
              </Label>
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            {' '}
            <Col span={11}>
              <Label className="required" style={{ width: '100%' }}>
                {t('common:email')}
                <Input
                  type="email"
                  name="email"
                  className="input-create"
                  autoComplete="off"
                  value={email}
                  onChange={onEmailValueChange}
                />
              </Label>
            </Col>
            <Col span={11}>
              {' '}
              <Label style={{ width: '100%' }}>
                {t('common:phone')}
                <Input
                  type="number"
                  name="phoneNumber"
                  className="input-create"
                  value={phoneNumber}
                  autoComplete="off"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Label>
            </Col>
          </Row>

          <Label style={{ width: '100%' }}>
            {t('common:linkedinURL')}
            <Input
              style={{ borderRadius: 8, height: 45 }}
              type="text"
              name="linkedinUrl"
              value={linkedinUrl}
              autoComplete="off"
              onChange={(e) => setLinkedinUrl(e.target.value)}
            />
          </Label>

          <div className="add-file-section">
            {!cvToAdd ? (
              <div
                className="dropzone-wrapper dropzone-wrapper-sm"
                style={{ width: '30%' }}
              >
                <Dropzone onDrop={onDropCv} multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input data-testid="drop-input" {...getInputProps()} />
                      <div className="dropzone-content">
                        <p>{t('crm:modals.createApplication.addResume')}</p>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
            ) : (
              <Button
                title={t('common:delete')}
                onClick={onRemoveCv}
                className="mb-2 mr-2 btn-icon"
                color="warning"
              >
                <i className="pe-7s-trash btn-icon-wrapper"> </i>
                CV: {cvToAdd.name}
              </Button>
            )}
            {!coverLetterToAdd ? (
              <div
                className="dropzone-wrapper dropzone-wrapper-sm"
                style={{ width: '30%' }}
              >
                <Dropzone onDrop={onDropCoverLetter} multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input data-testid="drop-input" {...getInputProps()} />
                      <div className="dropzone-content">
                        <p style={{ padding: '5px' }}>
                          {t('crm:modals.createApplication.addCoverLetter')}
                        </p>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
            ) : (
              <Button
                title={t('common:delete')}
                onClick={onRemoveCoverLetter}
                className="mb-2 mr-2 btn-icon"
                color="warning"
              >
                <i className="pe-7s-trash btn-icon-wrapper"> </i>
                {t('common:coverLetter')} {coverLetterToAdd.name}
              </Button>
            )}
          </div>
          <div>
            {' '}
            <Row
              justify="space-between"
              align="middle"
              style={{ marginTop: '10px' }}
            >
              <Col span={11}>
                <AddCandidateToCampain
                  selectPosition="top"
                  createCandidateModal={createCandidateModal}
                  setOffer={setOffer}
                />
              </Col>
              <Col span={11}>
                <label htmlFor="select-offer">{t('common:selectPool')}</label>
                <SelectPool
                  selectPosition="top"
                  createCandidateModal={createCandidateModal}
                  setSelectedPool={setSelectedPool}
                />
              </Col>
            </Row>
          </div>
        </>
      )}
    </Modal>
  )
}
export default CreateCandidate
