import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const ActivityFeed = styled.div`
  margin-bottom: 120px;

  .icon-rounded-wrapper {
    background-color: #f0f2f594;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .email-content-wrapper,
  .note-content-wrapper {
    background-color: #f0f2f594;
    color: #050505;
    padding: 1rem;
    border-radius: 15px;
    font-size: 15px;
    margin-left: 15px;
    max-width: 80%;

    p {
      margin-bottom: 0em;
    }
  }

  .note-content-wrapper {
    background-color: transparent;
    border: 1px solid #f0f0f0;
  }

  .history-item p,
  .comment-author,
  .email-feed-author {
    font-size: 15px;
    margin-left: 20px;
  }

  .ant-timeline-item {
    padding-bottom: 30px;
  }
  .history-date {
    display: block;
    font-weight: 600;
    opacity: 0.3;
    font-size: 14px;
  }

  .link-campaign {
    cursor: pointer;
    font-weight: 500;
    padding: 0.6em;
    border-radius: 5px;
    background-color: var(--secondary);
  }
  .link-campaign:hover {
    box-shadow: 0 0.02rem 0.3rem rgb(0 0 0 / 15%);
  }
`
