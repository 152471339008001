import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { Tag, Tooltip, Rate } from 'antd'
import {
  stepTypeColors,
  badgeColors,
  sanitizeApplicationScoringData,
} from '../../../services/utils'
import ExcerptLine from '../../ExcerptLine/ExcerptLine'

function CandidateScoringTable({ conversation }) {
  const { t } = useTranslation(['talent', 'common'], {
    useSuspense: false,
  })

  const [variablesToDisplay, setVariablesToDisplay] = useState([])

  useEffect(() => {
    const data = sanitizeApplicationScoringData(conversation)
    setVariablesToDisplay(data)
  }, [conversation])

  if (!variablesToDisplay.length) {
    return null
  }

  let steps

  if (conversation?.offer?.steps) {
    steps = conversation.offer.steps
  } else {
    steps = conversation.offer_id.steps
  }

  const interactionType = ['qcm', 'numberfield', 'salaryfield', 'datefield']
  // remove files & info text for display data
  const filteredSteps = steps.filter(
    (step) => step.type !== 'attachment' && step.type !== 'text',
  )

  // eslint-disable-next-line array-callback-return
  variablesToDisplay.map((el) => {
    // eslint-disable-next-line array-callback-return
    filteredSteps.map((s) => {
      if (s.name === el.name) {
        el.weight = s.weight
        el.type = s.type
      }
    })
  })

  const stepTypeText = {
    qcm: t('common:stepType.qcm'),
    salaryfield: t('common:stepType.salaryfield'),
    attachment: t('common:stepType.attachment'),
    mailfield: t('common:stepType.mailfield'),
    textfield: t('common:stepType.textfield'),
    text: t('common:stepType.text'),
    datefield: t('common:stepType.datefield'),
    numberfield: t('common:stepType.numberfield'),
  }

  return (
    <Table className="mb-0 mt-20">
      <thead
        style={{ marginBottom: '20px!important', padding: 20, opacity: '.4' }}
      >
        <tr>
          <th>{t('talent:applicationCard.scoringTable.interaction')}</th>
          <th>{t('talent:applicationCard.scoringTable.weight')}</th>
          <th>{t('talent:applicationCard.scoringTable.candidateResponse')}</th>
        </tr>
      </thead>
      <tbody>
        {variablesToDisplay.map((e) => {
          let color
          let colorIcon
          let badgeColor

          if (e.score === undefined) {
            badgeColor = badgeColors.notDefined
          } else if (e.score === -1) {
            colorIcon = badgeColors.problematic
            badgeColor = badgeColors.problematic
          } else if (e.score === 0) {
            colorIcon = badgeColors.unaccounted
            badgeColor = badgeColors.unaccounted
          } else if (e.score === 2) {
            colorIcon = badgeColors.acceptable
            badgeColor = badgeColors.acceptable
          } else if (e.score === 5) {
            colorIcon = badgeColors.ideal
            badgeColor = badgeColors.ideal
          }
          if (e.dealbreaker) {
            colorIcon = badgeColors.dealBreaker
            color = badgeColors.dealBreaker
          }

          return (
            <tr key={e.id} style={{ fontSize: 17 }}>
              <th style={{ color, fontSize: '15px' }} scope="row">
                <Tooltip
                  placement="left"
                  title={
                    <span style={{ fontSize: '15px', fontWeight: '600' }}>
                      {stepTypeText[e.type]}
                    </span>
                  }
                >
                  <span
                    style={{
                      backgroundColor: stepTypeColors[e.type],
                      width: '8px',
                      height: '8px',
                      display: 'inline-block',
                      borderRadius: '50%',
                      marginRight: '10px',
                    }}
                  >
                    {' '}
                  </span>
                </Tooltip>
                {e.name}
                {e.dealbreaker && (
                  <FontAwesomeIcon
                    style={{
                      fontSize: '15px',
                      color: colorIcon,
                      marginLeft: 10,
                    }}
                    icon={faExclamationTriangle}
                  />
                )}
              </th>
              <th style={{ color }} scope="row">
                {e.weight && interactionType.includes(e.type) ? (
                  <Rate disabled defaultValue={e.weight} count={4} />
                ) : (
                  ''
                )}
              </th>
              <td
                style={{
                  fontSize: '15px',
                  maxWidth: '180px',
                }}
              >
                {e.type === 'textfield' ? (
                  <ExcerptLine text={e.values} number={90} />
                ) : (
                  <Tag
                    style={{
                      fontSize: '15px',
                      fontWeight: '400!important',
                      borderRadius: '8px',
                      padding: '.3em .8em',
                      transition: 'none',
                      whiteSpace: 'pre-wrap', // Will break lines for very long tags
                      color:
                        e.score === undefined || e.score === 0
                          ? '#424141'
                          : '#FFF',
                    }}
                    color={badgeColor}
                  >
                    {e.values}
                  </Tag>
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

CandidateScoringTable.propTypes = {
  conversation: PropTypes.shape({}).isRequired,
}

export default CandidateScoringTable
