/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, Modal } from 'antd'
import { ArrowUpRight, Eye, EyeSlash } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import holloLogo from '../../assets/hollo/LogoLoginHollo.svg'
import LoginFram from '../../assets/hollo/framLogin2.png'
import j2bdLogo from '../../assets/hollo/j2bd.svg'
import HelmetComponent from '../../components/Helmet'
import { setUser } from '../../redux/actions/userActions'
import AuthService from '../../services/AuthService'
import { sendAmplitudeData, setAmplitudeUserId } from '../../services/amplitude'
import { isDomainJ2BD } from '../../services/utils'
import ForgotPassword from './ForgotPassword'
import * as S from './styles'

const Auth = new AuthService()

function Login() {
  const { t } = useTranslation(['login', 'onboarding'])

  const { handleSubmit, errors, control } = useForm()
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()
  const history = useHistory()

  const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] =
    useState(false)
  const [loading, setLoading] = useState(false)

  const closeForgotPasswordModal = () => {
    setIsForgotPasswordModalVisible(false)
  }

  const onSubmit = async (data) => {
    setLoading(true)

    try {
      const { user } = await Auth.login(login, password)
      if (user.isVerified !== undefined && !user.isVerified) {
        throw new Error(
          t(
            'login:errors.account.notVerified',
            "Le compte n'a pas été vérifié",
          ),
        )
      }
      dispatch(setUser(user))

      setAmplitudeUserId(user?._id)
      sendAmplitudeData('Log In')

      setLoading(false)
      history.push('/')
    } catch (err) {
      setLoading(false)
      let errString = t(
        'login:errors.unknown',
        'Erreur de connexion: Une erreur est survenue',
      )
      if (err && err.message.includes('Login failure')) {
        errString = t(
          'login:errors.loginFailure',
          'Erreur de connexion: Identifiant inconnu, vérifiez votre identifiant',
        )
      }
      if (err && err.message === 'Invalid login') {
        errString = t(
          'login:errors.invalidLogin',
          'Erreur de connexion: Mot de passe erroné, vérifiez votre mot de passe',
        )
      }
      if (err && err.message.includes('Too many failed attempts')) {
        errString = t(
          'login:errors.tooManyAttempts',
          'Erreur de connexion: Suite à un nombre de tentatives infructueuses trop grand, le compte utilisateur a été bloqué, essayez de contacter un administrateur',
        )
      }
      if (err && err.message.includes("Le compte n'a pas été vérifié")) {
        errString = t(
          'login:errors.notVerified',
          "Erreur de connexion: Le compte n'a pas été confirmé. Vérifiez votre boite mail",
        )
      }
      toast.error(errString, {
        className: 'toast-login-error',
      })
    }
  }

  const goToLoginHR = () => {
    const currentUrl = window.location.href.split('/')[2]

    if (isDomainJ2BD) {
      window.location.href = 'https://job2bedone-partners-app.hollo.io/login'
    } else if (currentUrl.includes('localhost')) {
      window.location.href = 'http://localhost:3002/login'
    } else if (currentUrl.includes('staging')) {
      window.location.href = 'https://talent-staging.hollo.io/login'
    } else {
      window.location.href = 'https://talent.hollo.io/login'
    }
  }

  useEffect(() => {
    if (Auth.loggedIn()) {
      history.replace('/app')
    }
  }, [history])

  const [passwordVisible, setPasswordVisible] = useState(false)

  const handleTogglePassword = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <S.Login data-cy="login-page">
      <HelmetComponent
        faviconUrl={isDomainJ2BD ? j2bdLogo : holloLogo}
        name={isDomainJ2BD ? 'J2BD Partners' : 'Hollo'}
      />
      <div
        className="left-img"
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(13, 32, 95, 0.5), rgba(13, 32, 95, 0.5)), url(${LoginFram})`,
        }}
      >
        {/* <img src={LoginFram} alt="backgroundImage" /> */}
      </div>

      <div className="wrap">
        <div className="loginForm">
          <div className="title-row">
            <img
              src={isDomainJ2BD ? j2bdLogo : holloLogo}
              alt="logo"
              className="logo"
            />
            <h3 className="title">
              {isDomainJ2BD
                ? t('login:welcomJ2BD', 'Bienvenue sur J2BD Partners')
                : t('login:welcom', 'Bienvenue chez Hollo')}
            </h3>
          </div>
          <div className="switch-page">
            <Button onClick={goToLoginHR}>
              {t('login:candidat', 'Candidat')}
            </Button>
            <Button className="active">
              {t('login:recruiter', 'Recruteur')}
            </Button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="input-form">
            <div className="dual-input">
              <input
                type="text"
                name="login"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                placeholder={t('login:inputs.login.placeholder', 'Identifiant')}
                id="login"
              />

              <div style={{ position: 'relative' }}>
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={t(
                    'login:inputs.password.placeholder',
                    'Mot de passe',
                  )}
                  id="password"
                />
                <Button
                  className="password-visible"
                  onClick={handleTogglePassword}
                  ghost
                  type="link"
                  style={{
                    position: 'absolute',
                    top: 11,
                    right: 5,
                    zIndex: 1,
                  }}
                >
                  {passwordVisible ? (
                    <Eye size={20} color="#889096" weight="fill" />
                  ) : (
                    <EyeSlash size={20} color="#889096" weight="fill" />
                  )}
                </Button>
              </div>
            </div>

            <span
              className="forgot-password"
              onClick={() => setIsForgotPasswordModalVisible(true)}
              data-cy="forgot-password-link"
            >
              {t('login:forgotPasswordBtn', 'Mot de passe oublié ?')}
            </span>
            {isForgotPasswordModalVisible && (
              <Modal
                visible={isForgotPasswordModalVisible}
                onOk={closeForgotPasswordModal}
                onCancel={closeForgotPasswordModal}
                centered
                footer={null}
              >
                <ForgotPassword closeModal={closeForgotPasswordModal} />
              </Modal>
            )}

            <Button
              className="submit-button"
              type="submit"
              htmlType="submit"
              loading={loading}
            >
              {t('login:submitBtn')}
              <ArrowUpRight size={20} color="#F6F8FE" weight="bold" />
            </Button>
          </form>
        </div>
      </div>
    </S.Login>
  )
}

export default Login
