import { Button, Dropdown, Menu, Tooltip } from 'antd'
import { arrayOf, bool, func, object, string } from 'prop-types'
import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiSend } from 'react-icons/bi'
import { FiEye, FiMoreVertical } from 'react-icons/fi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import toast from 'react-hot-toast'

import { Modal } from '@nextui-org/react'
import { Rocket } from 'phosphor-react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Button as RsButton,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { resetTalent } from '../../api/Candidate/api.candidate'
import { FeathersContext } from '../../services/feathers'
import AlertConfirmModal from '../AlertConfirmModal'
import EditCandidate from '../Candidate/EditCandidate'
import EmailModal from '../EmailModal'
import ClientEmailModal from '../ClientEmailModal'
import ModalComp from '../Modal'
import ReminderButton from './ReminderButton'
import Reactivate from '../../routes/Pools/Pool/ActionsModal/Reactivate/Reactivate'
import Update from '../../routes/Pools/Pool/ActionsModal/Update/Update'
import { PoolContext } from '../../routes/Pools/Pool/PoolContext'
import { checkFeatureFlag } from '../../services/utils'
import AddCandidateToCampain from '../Candidate/AddCandidateToCampain/AddCandidateToCampaign'
import Notes from '../Candidate/Notes'
import SelectPool from '../Candidate/SelectPool'
import SendReactivation from '../Candidate/SendReactivation'
import * as s from './styles'

const defaultProps = {
  openResume: bool,
  display: string,
  // eslint-disable-next-line react/forbid-prop-types
  candidate: object,
  // eslint-disable-next-line react/forbid-prop-types
  conversation: object,
  featuresAccess: arrayOf(string),
  refetchCandidate: func,
  updateCandidateInParent: func,
  hasBeenHired: bool,
}

const CtasCandidateCard = ({
  openResume,
  display,
  candidate,
  featuresAccess,
  updateCandidateInParent,
  refetchCandidate,
  hasBeenHired,
  conversation,
}) => {
  const params = useParams()
  const user = useSelector((state) => state.user)

  const { t } = useTranslation(['common', 'talent', 'reactivation'], {
    useSuspense: false,
  })

  const feathers = useContext(FeathersContext)
  const poolContext = useContext(PoolContext)

  const [copyModal, setCopyModal] = useState(null)
  const [noteModal, setNoteModal] = useState(null)
  const [modalEmail, setModalEmail] = useState(null)
  const [clientModalEmail, setClientModalEmail] = useState(null)
  const [removeTalentModal, setRemoveTalentModal] = useState(false)
  const [sendReactivationModal, setSendReactivationModal] = useState(false)
  const [sendNewReactivationModal, setSendNewReactivationModal] =
    useState(false)
  const [sendInitializationModal, setSendInitializationModal] = useState(false)
  const [editCandidateIsOpen, setEditCandidateIsOpen] = useState(false)
  const [sendCandidateToPool, setSendCandidateToPool] = useState(false)
  const [sendCandidateToOffer, setSendCandidateToOffer] = useState(false)

  const initializeCandidate = () => {
    feathers
      .service('daemon-reactivation')
      .create({
        candidateId: candidate._id,
      })
      .then((res) => {
        refetchCandidate()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const clientApplicationProposalLink = useMemo(() => {
    const currentUrl = window.location.href.split('/')[2]

    if (user?.company_id?._id === '5ede3f0a1746310018d42a3d') {
      return `${'https://job2bedone-partners.hollo.io'}/talent/${
        conversation?._id
      }`
    }

    if (currentUrl.includes('localhost')) {
      return `${'http://localhost:3001'}/talent/${conversation?._id}`
    }
    if (currentUrl.includes('staging')) {
      return `${'https://app-staging.hollo.io'}/talent/${conversation?._id}`
    }

    return `${'https://app.hollo.io'}/talent/${conversation?._id}`
  }, [user, conversation])

  const onCopy = () =>
    toast.success('Le lien a été copié dans le presse papier')

  return (
    <s.actionsWrapper>
      <div className="wrapper visible">
        {checkFeatureFlag(user?.company_id?._id) &&
        candidate.active &&
        !hasBeenHired ? (
          <Tooltip
            title={t('reactivation:pool.setup.steps.reactivation')}
            placement="bottom"
            color="#080e27"
            zIndex={1000}
          >
            <Button
              className="table-action-elem more-action basic-btn"
              onClick={() => setSendNewReactivationModal(true)}
            >
              <Rocket size={20} />
            </Button>
          </Tooltip>
        ) : undefined}
        {checkFeatureFlag(user?.company_id?._id) &&
        !candidate.active &&
        !hasBeenHired ? (
          <Tooltip
            title={t('reactivation:pool.setup.steps.initialization')}
            placement="bottom"
            color="#080e27"
            zIndex={1000}
          >
            <Button
              className="table-action-elem more-action basic-btn"
              onClick={() => setSendInitializationModal(true)}
            >
              <Rocket size={20} />
            </Button>
          </Tooltip>
        ) : undefined}
        <Tooltip
          title={t('common:sendEmail.label')}
          placement="bottom"
          color="#080e27"
          zIndex={1000}
        >
          <Button
            className="table-action-elem more-action grey-btn"
            onClick={() => setModalEmail(true)}
          >
            <BiSend size={20} />
          </Button>
        </Tooltip>
        <ReminderButton candidateId={candidate?._id} />
        {display !== 'CV' && openResume && (
          <Tooltip
            title={t('common:resumeView')}
            placement="bottom"
            color="#080e27"
          >
            <Button
              className="grey-btn"
              onClick={() => {
                openResume()
              }}
            >
              <FiEye size={16} />
            </Button>
          </Tooltip>
        )}
        <Dropdown
          className="grey-btn"
          trigger="click"
          overlay={
            <Menu>
              <>
                {conversation?._id &&
                [
                  '5ede3f0a1746310018d42a3d',
                  '5fe0da154fbde1003fc656a3',
                ].includes(user?.company_id?._id) ? (
                  <>
                    <Menu.Item
                      key="shareApplicationProposalToClient"
                      onClick={() => setClientModalEmail(true)}
                    >
                      {t('common:shareApplicationProposalToClient')}
                    </Menu.Item>
                    <Menu.Item
                      key="copyClientApplicationProposal"
                      onClick={() => setCopyModal(true)}
                    >
                      {t('common:copyClientApplicationProposal')}
                    </Menu.Item>
                  </>
                ) : undefined}
                {featuresAccess.includes('crm') && (
                  <Menu.Item
                    key="send-to-offer"
                    onClick={() => setSendCandidateToOffer(true)}
                  >
                    {t('talent:applicationCard.actions.sendToCampaign')}
                  </Menu.Item>
                )}
                {featuresAccess.includes('talent-pools') && (
                  <Menu.Item
                    key="send-to-another-pool"
                    onClick={() => setSendCandidateToPool(true)}
                  >
                    {candidate.candidatePool_id
                      ? t('talent:card.actions.sendToAnotherPool')
                      : t('talent:applicationCard.actions.addToPool')}
                  </Menu.Item>
                )}
              </>
              <Menu.Item
                key="edit"
                onClick={() => setEditCandidateIsOpen(true)}
              >
                {t('common:edit')}
              </Menu.Item>
              {/* TODO: To be reactivated when reactivation is ready */}
              {/* We check if a candidatePool_id is present (we can't remove a talent from a pool if it's not in a pool) 
                  remove from pool should only be present when we are on pool's routes
              */}
              {/* {candidate.candidatePool_id &&
                location.pathname.includes('pools') &&
                setRemoveTalentModal && (
                  <Menu.Item
                    key="remove"
                    onClick={() => setRemoveTalentModal(true)}
                  >
                    {t('common:removeFromPool')}
                  </Menu.Item>
                )} */}
              {/* TODO: To be reactivated when reactivation is ready */}
              {/* {candidate.candidatePool_id &&
                candidate?.reactivationInitializationStatus !== 'sent' && (
                  <Menu.Item key="removeit" onClick={initializeCandidate}>
                    {t('talent:card.actions.manualInitialize')}
                  </Menu.Item>
                )} */}
              {(!process.env.REACT_APP_ENV ||
                process.env.REACT_APP_ENV === 'staging') && (
                <Menu.Item
                  key="reset"
                  onClick={() => resetTalent(candidate._id)}
                >
                  Reset talent (Dev & Staging only)
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button
            data-cy="btn-dropdown-candidate-only"
            className="grey-btn"
            style={{
              backgroundColor: '#f3f3f4',
              border: 'none',
              color: 'black',
            }}
          >
            <FiMoreVertical size={16} />
          </Button>
        </Dropdown>
      </div>

      {/* Send Client Mail */}
      {clientModalEmail && (
        <ClientEmailModal
          isOpen={clientModalEmail}
          toggle={() => setClientModalEmail(!clientModalEmail)}
          conversation={conversation}
        />
      )}

      {/* Send Email */}
      {modalEmail && (
        <EmailModal
          isOpen={modalEmail}
          toggle={() => setModalEmail(!modalEmail)}
          listCandidate={[candidate]}
          refetchCandidate={refetchCandidate}
        />
      )}

      {/* Remove candidate from pool */}
      {removeTalentModal && (
        <AlertConfirmModal
          isOpen={removeTalentModal}
          toggle={() => setRemoveTalentModal(!removeTalentModal)}
          onCancel={() => setRemoveTalentModal(false)}
          onConfirm={poolContext?.handleRemoveCandidate}
          onCancelBtnText={t('common:cancel')}
          onConfirmBtnText={t('common:confirm')}
          title={t('reactivation:pools.remove.talent.title')}
          content={
            <p>
              {t('reactivation:pools.poolCard.modals.delete.talent', {
                talentName: `${candidate?.firstname} ${candidate?.lastname}`,
              })}
            </p>
          }
        />
      )}

      {/* Edit Candidate */}
      <ModalComp
        destroyOnClose
        visible={editCandidateIsOpen}
        onCancel={() => setEditCandidateIsOpen(false)}
        footer={null}
      >
        <EditCandidate
          candidate={candidate}
          closeModal={() => setEditCandidateIsOpen(false)}
          refreshParentData={(candidate) => {
            updateCandidateInParent(candidate)
            refetchCandidate()
          }}
        />
      </ModalComp>

      {/* Send Candidate to pool */}
      <ModalComp
        destroyOnClose
        visible={sendCandidateToPool}
        onCancel={() => setSendCandidateToPool(false)}
        footer={null}
      >
        <SelectPool
          candidate={candidate}
          closeModal={() => setSendCandidateToPool(false)}
          refreshParentData={refetchCandidate}
        />
      </ModalComp>

      {/* Send Candidate to Campaign */}
      <ModalComp
        destroyOnClose
        onCancel={() => setSendCandidateToOffer(false)}
        footer={null}
        title={t('talent:applicationCard.actions.sendToCampaign')}
        visible={sendCandidateToOffer}
        maskClosable={false}
      >
        <AddCandidateToCampain
          candidate={candidate}
          candidates={[]}
          closeModal={() => setSendCandidateToOffer(false)}
          refetchConversation={refetchCandidate}
        />
      </ModalComp>

      {/* Send manual Reactivation */}
      <ModalComp
        destroyOnClose
        onCancel={() => setSendReactivationModal(false)}
        footer={null}
        title={t('talent:card.actions.manualReactivation')}
        visible={sendReactivationModal}
      >
        <SendReactivation
          candidate={candidate}
          closeModal={() => setSendReactivationModal(false)}
          refreshParentData={() => {}}
        />
      </ModalComp>

      {/* Send New Reactivation */}
      <Modal
        blur
        closeButton
        destroyOnClose
        width="1000px"
        open={sendNewReactivationModal}
        onClose={() => setSendNewReactivationModal(false)}
      >
        <Reactivate
          user={user}
          selectedTalents={[candidate]}
          cleanSelectedTalents={() => {}}
          closeModal={() => setSendNewReactivationModal(false)}
        />
      </Modal>

      {/* Send Initialization */}
      <Modal
        blur
        closeButton
        destroyOnClose
        width="1000px"
        open={sendInitializationModal}
        onClose={() => setSendInitializationModal(false)}
      >
        <Update
          user={user}
          type="init"
          title="Actualiser vos talents"
          subtitle="Vous êtes sur le point d’envoyer un mail à vos talents sélectionnés afin de créer leur espace candidat."
          selectedTalents={[candidate]}
          cleanSelectedTalents={() => {}}
          closeModal={() => setSendInitializationModal(false)}
        />
      </Modal>

      {/* Note modal */}
      <ModalComp
        visible={noteModal}
        onCancel={() => setNoteModal(false)}
        footer={null}
        width={900}
        title={t('common:notes')}
      >
        <Notes
          isCard={false}
          key={candidate._id}
          notes={candidate.notes || []}
          candidateId={candidate._id}
          refetchCandidate={refetchCandidate}
        />
      </ModalComp>

      {/* Copy modal */}
      <ModalComp
        blur
        closeButton
        destroyOnClose
        visible={copyModal}
        onCancel={() => setCopyModal(false)}
        title={t('common:clientApplicationProposalLink')}
        footer={null}
        width={800}
      >
        <InputGroup>
          <Input value={clientApplicationProposalLink} readOnly />
          <InputGroupAddon addonType="append">
            <CopyToClipboard
              onCopy={onCopy}
              text={clientApplicationProposalLink}
            >
              <RsButton color="primary">
                <FontAwesomeIcon icon={faCopy} />
              </RsButton>
            </CopyToClipboard>
          </InputGroupAddon>
        </InputGroup>
      </ModalComp>
    </s.actionsWrapper>
  )
}

CtasCandidateCard.defaultProps = defaultProps
export default CtasCandidateCard
