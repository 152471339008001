import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Input } from 'antd'
import toast from 'react-hot-toast'
import * as S from './styles'
import AuthService from '../../../services/AuthService'
import forgotPassword from '../../../assets/hollo/forgotPassword.jpg'
import HelmetComponent from '../../../components/Helmet'
import { isDomainJ2BD } from '../../../services/utils'
import j2bdLogo from '../../../assets/hollo/j2bd.svg'
import holloLogo from '../../../assets/hollo/LogoLoginHollo.svg'

const Auth = new AuthService()

function ForgotPassword(props) {
  const abortController = new AbortController()
  const { t } = useTranslation(['login', 'onboarding'])

  const [login, setLogin] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorText, setErrorText] = useState('')
  useEffect(
    () => () => {
      abortController.abort()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const handleResetPassword = async () => {
    setLoading(true)
    if (!login) {
      setErrorText(
        t('login:askNewPassword.emptyLogin', "L'identifiant est requis"),
      )
      setLoading(false)
      return
    }

    try {
      await Auth.fetch('/authManagement', {
        method: 'POST',
        body: JSON.stringify({
          action: 'sendResetPwd',
          value: {
            login,
          },
          notifierOptions: {
            domainJ2BD: isDomainJ2BD ? true : undefined,
          },
        }),
        signal: abortController.signal,
      })

      toast.success(
        t(
          'login:askNewPassword.toast.success',
          'Un mail vous a été envoyé pour réinitialiser votre mot de passe',
        ),
        {
          className: 'toast-ask-password-success',
        },
      )
      setLoading(false)
      props.closeModal()
    } catch (e) {
      setLoading(false)
      if (e.message === 'Invalid request: User not found.') {
        setErrorText(
          t(
            'login:askNewPassword.toast.noUserError',
            'Utilisateur non reconnu',
          ),
        )
      } else {
        toast.error(
          t(
            'login:askNewPassword.toast.error',
            'Erreur lors de la demande de réinitialisation du mot de passe',
          ),
        )
      }
    }
  }
  return (
    <S.ForgotPassword data-cy="ask-password-modal">
      <HelmetComponent
        faviconUrl={isDomainJ2BD ? j2bdLogo : holloLogo}
        name={isDomainJ2BD ? 'J2BD Partners' : 'Hollo'}
      />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <img src={forgotPassword} alt="forgot password" />
      </div>
      <p>
        {t(
          'login:askNewPassword.content',
          "Entrez l'identifiant associé à votre compte, un email vous sera envoyé avec les instructions pour réinitialiser votre mot de passe",
        )}
        {/* eslint-disable-next-line react/no-unescaped-entities,max-len */}
      </p>
      <label htmlFor="login">
        {t('login:inputs.login.label', 'Identifiant')}
        <Input
          type="input"
          name="login"
          id="login"
          disabled={loading}
          onChange={(e) => {
            setErrorText('')
            setLogin(e.target.value)
          }}
          placeholder={t('login:inputs.login.placeholder')}
        />
        {errorText && (
          <span className="inputError" data-cy="unknown-user">
            {errorText}
          </span>
        )}
      </label>
      <Button type="primary" loading={loading} onClick={handleResetPassword}>
        {t('login:askNewPassword.submitBtn', 'Réinitialiser mot de passe')}
      </Button>
    </S.ForgotPassword>
  )
}

export default ForgotPassword
