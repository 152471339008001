import { Empty, Tag } from 'antd'
import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  contractTypesI18,
  experienceI18,
  researchStatusTable,
  schoolLevelsI18,
} from '../../../services/utils'
import * as s from './TalentsInfo.styles'

const InfoLine = ({ label, value }) => (
  <s.InfoLineWrapper>
    <span className="talent-info-label">{label}</span>
    <span className="talent-info-value">{value}</span>
  </s.InfoLineWrapper>
)

const TalentsInfo = ({ candidate }) => {
  const { t } = useTranslation(['talent', 'common', 'talentArea', 'campaign'], {
    useSuspense: false,
  })
  const {
    salaryRange,
    location,
    jobDomain,
    experience,
    sector,
    schoolLevel,
    subSector,
    subJob,
    researchStatus,
    contractTypes,
    active,
    remoteWorking,
  } = candidate
  let salaryRangeValue

  if (salaryRange?.minValue) {
    salaryRangeValue = `${salaryRange.minValue}K -  ${salaryRange.maxValue}K`
  }

  const contractWantedValue = contractTypes?.map((el) => {
    const contract = contractTypesI18(t)[el]
    return (
      <Tag key={nanoid()} color={contract.color} className="basic-tag">
        {contract.label}
      </Tag>
    )
  })

  return (
    <s.TalentsInfoWrapper>
      {active ? (
        <div className="lines-wrapper">
          <InfoLine label={t('common:table.jobdomain')} value={jobDomain} />
          <InfoLine label={t('common:table.subjobs')} value={subJob} />
          <InfoLine label={t('common:table.sectors')} value={sector} />
          <InfoLine label={t('common:table.subsectors')} value={subSector} />
          <InfoLine
            label={t('common:workExperience')}
            value={experienceI18(t)[experience].label}
          />
          <InfoLine
            label={t('common:education')}
            value={schoolLevelsI18(t)[schoolLevel]}
          />
          <InfoLine
            label={t('talentArea:profile.contractType.label')}
            s
            value={contractWantedValue}
          />
          <InfoLine
            label={t('common:candidates.searchStatus.placeholder')}
            value={
              researchStatus === 'unknown'
                ? ''
                : researchStatusTable(t, researchStatus)
            }
          />
          <InfoLine
            label={t('common:table.location')}
            value={location?.formattedAddress}
          />
          <InfoLine
            label={t('talentArea:profile.salary.label')}
            value={salaryRangeValue}
          />
          <InfoLine
            label={t('talentArea:profile.remoteWorking.label', 'Remote')}
            value={
              remoteWorking
                ?.map((el) =>
                  t(`campaign:offer.remoteWorking.${el.toLowerCase()}`),
                )
                .join(', ') || ''
            }
          />
        </div>
      ) : (
        <div>
          <Empty />
        </div>
      )}
    </s.TalentsInfoWrapper>
  )
}

TalentsInfo.propTypes = {
  candidate: PropTypes.shape({}).isRequired,
}

export default TalentsInfo
