// TODO: hide communication component for fixing purpose
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Tooltip, Menu, Select } from 'antd'
import i18next from 'i18next'
import { Gb, Fr } from 'react-flags-select'
import { useTranslation } from 'react-i18next'
import j2bdLogo from '../../assets/hollo/j2bd-white.svg'
import { ReactComponent as Logo } from '../../assets/hollo/logo.svg'
import SupportModal from '../../components/SupportModal'
import { AdminHolloNav, UserNav, GuestNav } from './navData'

import * as styled from './styles'

const { Option } = Select

const Sidebar = () => {
  const { t } = useTranslation(['common'], { useSuspense: false })
  const location = useLocation()
  const user = useSelector((state) => state.user)
  const { permissions } = user
  const featuresAccess = user.company_id?.featuresAccess || ['crm']

  const [openSupport, setOpenSupport] = useState(false)
  const [selectedNavItem, setSelectedNavItem] = useState('/')

  useEffect(() => {
    setSelectedNavItem(location.pathname)
  }, [location])

  let navItems

  if (permissions?.includes('admin_hollo')) {
    navItems = AdminHolloNav(t)
  } else if (permissions?.includes('admin')) {
    navItems = UserNav(t, featuresAccess)
  } else if (permissions?.includes('guest')) {
    navItems = GuestNav(t)
  } else {
    navItems = UserNav(t, featuresAccess)
  }

  return (
    <div>
      <styled.Menu
        className="sidebar"
        selectedKeys={selectedNavItem}
        mode="vertical"
        theme="dark"
      >
        <Menu.Item style={{ margin: '7px 12px' }} key="logo">
          <Link to="/app/dashboard">
            {user?.company_id?._id === '5ede3f0a1746310018d42a3d' ? (
              <img
                src={j2bdLogo}
                alt="logo"
                className="logo"
                style={{ height: '2rem' }}
              />
            ) : (
              <Logo height="2rem" />
            )}
          </Link>
        </Menu.Item>
        {navItems.map((item) => {
          if (item.to) {
            return (
              <Menu.Item key={item.to}>
                <Tooltip
                  overlayClassName="menu-item-tooltip"
                  title={item.label}
                  placement="right"
                  style={{ left: '9rem!important' }}
                  color="#080e27"
                >
                  <Link style={{ height: '42px' }} to={item.to}>
                    {item.icon}
                  </Link>
                </Tooltip>
              </Menu.Item>
            )
          }
        })}
        <Menu.Item key="languages">
          <Select
            style={{ width: '45px', height: '35px' }}
            defaultValue={[i18next.language]}
            onChange={(lng) => i18next.changeLanguage(lng)}
            optionLabelProp="label"
            bordered={false}
            showArrow={false}
          >
            <Option value="fr" label={<Fr />}>
              <div className="d-center">
                <Fr />
              </div>
            </Option>
            <Option value="en" label={<Gb />}>
              <div className="d-center">
                <Gb />
              </div>
            </Option>
          </Select>
        </Menu.Item>
        {/* <Menu.Item key="intercom">
          <Tooltip
            overlayClassName="menu-item-tooltip"
            placement="right"
            style={{ left: '9rem!important' }}
            title="Help center"
          >
            <a
              className="help-link"
              href="https://intercom.help/holloio/en"
              target="_blank"
              rel="noopener noreferrer"
              style={{ height: '42px' }}
            >
              <Question size={32} />
            </a>
          </Tooltip>
      </Menu.Item> */}
      </styled.Menu>
      <SupportModal isOpen={openSupport} toggle={setOpenSupport} />
    </div>
  )
}

export default Sidebar
