import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AuthService from '../../services/AuthService'
import LoadingRedirection from '../../assets/hollo/nylas-loading.svg'
import BugSync from '../../assets/hollo/bug-sync.svg'
import Loader from '../../components/Loader'
import HelmetComponent from '../../components/Helmet'
import { isDomainJ2BD } from '../../services/utils'
import j2bdLogo from '../../assets/hollo/j2bd.svg'
import holloLogo from '../../assets/hollo/LogoLoginHollo.svg'

const Auth = new AuthService()

const CallbackLever = () => {
  const { t } = useTranslation(['settings', 'common'], { useSuspense: false })

  const abortController = new AbortController()
  const location = useLocation()
  const [synchronizing, setSynchronizing] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const params = queryString.parse(location.search)

    const { code, state, error, error_description } = params

    if (error) {
      throw new Error(error_description)
    }

    if (!code || !state) {
      throw new Error('Missing code or state')
    }

    Auth.fetch('/lever', {
      method: 'POST',
      body: JSON.stringify({
        action: 'sendCode',
        query: {
          state,
          code,
        },
      }),
      signal: abortController.signal,
    })
      .then(() => {
        localStorage.setItem('atsSynced', JSON.stringify(true))
        setSynchronizing(false)
        toast.success(t('settings:ats.lever.toasts.sync.success'))
      })
      .catch(async (err) => {
        console.error(err)
        const errString = t('common:toasts.error')
        toast.error(errString)
        setSynchronizing(false)
        setError(true)
      })
  }, [])

  useEffect(() => () => abortController.abort(), [])

  let content
  if (error) {
    content = (
      <>
        <img
          height={200}
          src={BugSync}
          alt="loading"
          style={{ marginBottom: '20px' }}
        />
        <p style={{ fontSize: '14px', fontWeight: '600', marginTop: '40px' }}>
          {t('settings:ats.lever.content.sync.error')}
        </p>
      </>
    )
  } else {
    content = (
      <>
        <img
          height={200}
          src={LoadingRedirection}
          alt="loading"
          style={{ marginBottom: '20px' }}
        />
        <p style={{ fontSize: '14px', fontWeight: '600', marginTop: '40px' }}>
          {t('common:nylasAccountSynchronized')}
        </p>
      </>
    )
  }

  return (
    <div
      style={{
        height: '100vh',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <HelmetComponent
        faviconUrl={isDomainJ2BD ? j2bdLogo : holloLogo}
        name={isDomainJ2BD ? 'J2BD Partners' : 'Hollo'}
      />
      {synchronizing ? (
        <Loader text={t('common:nylasAccountSynchronizing')} />
      ) : (
        content
      )}
    </div>
  )
}

export default CallbackLever
