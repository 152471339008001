/* eslint-disable no-underscore-dangle */
import { Alert, Modal, Button } from 'antd'
import React from 'react'
import { RiCloseFill } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'

const ProgrammedAutomaticMail = (props) => {
  const { t } = useTranslation(['common', 'candidate', 'campaign'], {
    useSuspense: false,
  })
  const {
    isOpen,
    toggle,
    handleChoiceWithoutAction,
    handleEditTrigger,
    isLoadingSendEmail,
    handleChoiceWithAction,
    // rememberChoice,
    // handleRememberChoiceChange,
  } = props

  return (
    <>
      <Modal
        visible={isOpen}
        onCancel={toggle}
        width={585}
        title={t('candidate:moveToAnotherPipeline')} // TODO: add dynamic
        closeIcon={<RiCloseFill size={32} />}
        bodyStyle={{ marginTop: -25 }}
        footer={
          <div className="btns-container">
            {/* RH-989 - remove modify email until further notice */}
            {/* <Button className="blue-btn" onClick={handleEditTrigger}>
              {t('common:modifyMail')}
            </Button> */}
            <Button className="grey-btn" onClick={handleChoiceWithoutAction}>
              {t('common:cancelAndMove')}
            </Button>
            <Button
              className="basic-btn"
              loading={isLoadingSendEmail}
              onClick={handleChoiceWithAction}
            >
              {t('common:sendAndMove')}
            </Button>
          </div>
        }
      >
        <Alert
          type="warning"
          message={t('candidate:warning.movingApplication')}
          showIcon
          style={{ textAlign: 'center' }}
        />
        <ul
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 20,
            marginTop: 30,
            marginBottom: 0,
          }}
        >
          <li>{t('campaign:warningMessage.one')}</li>
          <li>{t('campaign:warningMessage.two')}</li>
        </ul>
        {/* <label
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
          }}
        >
          <span style={{ marginRight: 20 }}>{t('common:rememberChoice')}</span>
          <input
            type="checkbox"
            name="rememberChoice"
            value={rememberChoice}
            onChange={handleRememberChoiceChange}
          />
        </label> */}
      </Modal>
    </>
  )
}

export default ProgrammedAutomaticMail
