import styled from 'styled-components'

export const ModalTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 0;
`
export const ModalTitle = styled.span`
  color: #d95762;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
`
export const ModalContentWrapper = styled.div`
  font-size: 14px;
  padding: 0 20px;
`
