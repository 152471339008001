import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const OnboardingWrapper = styled.div`
  .onboarding-footer {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }

  .onboarding-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    p {
      font-size: 16px;
      text-align: center;
    }

    .onboarding-users-info p {
      text-align: left !important;
    }
  }
`
