import React from 'react'
import { Link } from 'react-router-dom'
import { nanoid } from 'nanoid'
import { useTranslation } from 'react-i18next'
import Styles from './styles'
import logo from '../../assets/hollo/sad-logo.png'

export default function ErrorPage() {
  const { t } = useTranslation(['common'], { useSuspense: false })
  return (
    <Styles>
      <div id="wrapper-error500">
        <div className="server-error">
          <img src={logo} height={150} alt="hollo" />
          <div className="content-500">
            <h1>500</h1>
            <h2>{t('common:errorHappened', 'Une erreur est survenue')}</h2>
            <p>
              {t(
                'common:teamFixingProblem',
                "L'équipe d'Hollo est en train de régler le problème, nous serons bientôt opérationnels",
              )}
            </p>
            <Link to="/" key={nanoid()}>
              {t('common:tryAgain', 'réessayer')}
            </Link>
          </div>
        </div>
      </div>
    </Styles>
  )
}
