/* eslint-disable global-require */
import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { I18nextProvider } from 'react-i18next'

import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './assets/base.scss'
import './polyfills'

import { setDefaultLocale, registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'
import { IntercomProvider } from 'react-use-intercom'
import { unregister } from './serviceWorker'
import store from './redux/store'
import Main from './routes/Main/Main'
import { initAmplitude } from './services/amplitude'
import './styles/Main.scss'

import i18n from './i18n'
import { FeathersProvider } from './services/feathers'

const INTERCOM_APP_ID = 'asvjwivc'

const queryClient = new QueryClient()

registerLocale('fr', fr)
setDefaultLocale(fr)

Sentry.init({
  dsn: 'https://58e80cb3a2b540cdacd081896cc9fb7e@o435752.ingest.sentry.io/5395648',
  environment: process.env.REACT_APP_ENV || 'development',
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
  tracesSampler: () => (process.env.NODE_ENV === 'production' ? 1 : 0.2),
})

initAmplitude()

const rootElement = document.getElementById('root')

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <FeathersProvider>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
              <I18nextProvider i18n={i18n}>
                <Component />
              </I18nextProvider>
            </IntercomProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </FeathersProvider>
    </Provider>,
    rootElement,
  )
}

renderApp(Main)

if (module.hot) {
  module.hot.accept('./routes/Main/Main', () => {
    const NextApp = require('./routes/Main/Main').default
    renderApp(NextApp)
  })
}

if (window.Cypress) {
  window.store = store
}

unregister()
