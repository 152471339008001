/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Badge, Divider, Drawer, List } from 'antd'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { RiCloseFill } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { FolderUser } from 'phosphor-react'

import { arrayOf, func, shape, string } from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import {
  setSeenApplications,
  setToggleSeenApplications,
  setUnreadConversations,
  setShouldRefetchUnreadApplications,
} from '../../../../redux/actions/notificationActions'
import { getAggregUnreadConversationsPerUser } from '../../../../api/Offer/api.offer'

import yoga from '../../../../assets/hollo/yoga.svg'

const propTypes = {
  drawerKey: string.isRequired,
  visibleDrawers: shape({
    opened: string.isRequired,
    drawers: arrayOf(string).isRequired,
  }).isRequired,
  toggleDrawers: func.isRequired,
}

const DrawerUnseenApplications = ({
  drawerKey,
  visibleDrawers,
  toggleDrawers,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { unreadApplications } = useSelector((state) => state.notification)
  const { shouldRefetchUnreadApplications } = useSelector(
    (state) => state.notification,
  )
  const { data } = useQuery({
    queryKey: ['unreadApplications', shouldRefetchUnreadApplications],
    queryFn: async () => {
      const response = await getAggregUnreadConversationsPerUser()

      if (response[0]?.total[0]?.count > unreadApplications.total) {
        dispatch(setSeenApplications(false))
      }

      dispatch(setUnreadConversations(response))
      dispatch(setShouldRefetchUnreadApplications(false))
    },
    refetchOnWindowFocus: true,
  })

  const handleToggleDrawer = () => {
    if (unreadApplications.seen === false || !unreadApplications.seen) {
      dispatch(setToggleSeenApplications())
    }
    toggleDrawers()
  }

  const unreadOnlineApplications = unreadApplications.data.reduce(
    (acc, current) => acc + current.conversations.length,
    0,
  )

  return (
    <>
      <Drawer
        placement="right"
        mask={false}
        trigger="click"
        closeIcon={<RiCloseFill size={32} />}
        closable
        onClose={() => {
          toggleDrawers()
        }}
        visible={visibleDrawers.opened === drawerKey}
        width="480px"
      >
        <header
          className="flex column"
          style={{ gap: 10, marginBottom: 10, marginRight: 15 }}
        >
          <div className="flex items-center" style={{ gap: 5 }}>
            <h3 style={{ fontSize: '1.5em', fontWeight: 'bold', margin: 0 }}>
              {t('dashboard:indicators.notSeenConversations')}
            </h3>
          </div>
          <Divider style={{ margin: 0 }} />
        </header>

        {unreadApplications.total ? (
          <List
            itemLayout="horizontal"
            dataSource={unreadApplications?.data}
            renderItem={(item) => (
              <List.Item
                className="nav-notification flex items-baseline justify-between"
                role="button"
                tabIndex={-1}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (location.pathname !== `/app/offers/${item._id}`) {
                    history.push(`/app/offers/${item._id}`)
                  }
                  toggleDrawers()
                }}
              >
                <List.Item.Meta
                  title={
                    <span style={{ fontWeight: 'bold' }}>{item.name}</span>
                  }
                  description={
                    <div className="not-seen">
                      <span>
                        {t('common:unreadConversations', {
                          num: item.conversations.length,
                          withS: item.conversations.length > 1 ? 's' : '',
                        })}
                      </span>
                    </div>
                  }
                />
                <span className="btn-read-notif">
                  <span
                    className="pointer"
                    style={{
                      visibility: 'visible',
                    }}
                  />
                </span>
              </List.Item>
            )}
          />
        ) : (
          <div
            className="flex column items-center justify-center"
            style={{
              position: 'absolute',
              bottom: '50%',
              left: '50%',
              transform: 'translate(-50%, +50%)',
              width: '100%',
              marginTop: 20,
            }}
          >
            <h4 className="title-notifs">
              {t('common:notification.noMoreApplication')}
            </h4>
            <img src={yoga} alt="yoga illustration" width={150} height={150} />
          </div>
        )}
      </Drawer>
      <Badge
        onClick={() => handleToggleDrawer()}
        count={unreadApplications.seen ? 0 : unreadOnlineApplications}
        showZero={false}
        overflowCount={9}
      >
        <FolderUser size={26} />
      </Badge>
    </>
  )
}

DrawerUnseenApplications.propTypes = propTypes

export default DrawerUnseenApplications
