import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const ContactBarWrapper = styled.div`
  display: flex;
  gap: 5px;

  .cursor {
    cursor: pointer;
  }

  .clicked {
    max-height: 30px;
    padding: 5px;
    background-color: lightgray;
    border-radius: 5px;
    & > svg {
      width: 20px;
      height: auto;
    }
  }
`
