/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import {
  Plus,
  ArrowsClockwise,
  Gear,
  Calendar,
  WarningCircle,
} from 'phosphor-react'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { Dropdown, Menu, Modal as ModalAnt, Tag, Tooltip, Button } from 'antd'
import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import DatePicker from 'react-datepicker'
import { useIntercom } from 'react-use-intercom'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import './UserBox.scss'
import { useLocation, useHistory } from 'react-router-dom'
import {
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  Row,
} from 'reactstrap'
import { nanoid } from 'nanoid'
import i18next from 'i18next'
import { setUserId } from '../../../redux/actions/administrationActions'
import { resetUser } from '../../../redux/actions/userActions'
import AuthService from '../../../services/AuthService'
import { createSchedulingPage, computeElapsed } from '../../../services/utils'
import Loader from '../../../components/Loader'
// import PricingModal from '../../../components/PricingModal'
import StatusImports from './StatusImports'
import SearchBar from './SearchBar'
import { FeathersContext } from '../../../services/feathers'
import DrawerUnseenApplications from './DrawerUnseenApplications/DrawerUnseenApplications'
import CreateCandidate from './CreateCandidate'

const Auth = new AuthService()

const Navbar = () => {
  const abortController = useMemo(() => new AbortController(), [])
  const { t } = useTranslation([
    'common',
    'models',
    'navbar',
    'dashboard',
    'reactivation',
    'searchCandidate',
    'settings',
    'campaign',
    'chatbotSetup',
  ])
  const { show } = useIntercom()
  const feathers = useContext(FeathersContext)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const { company_id: company } = user
  const features = company?.featuresAccess || ['crm']
  const ui = useSelector((state) => state.ui)
  const location = useLocation()
  const history = useHistory()
  const [modalEvent, setModalEvent] = useState(false)
  const [title, setTitle] = useState('')
  const [eventLocation, setEventLocation] = useState('')
  const [description, setDescription] = useState('')
  const [participants, setParticipants] = useState('')
  const [startTime, setStartTime] = useState(new Date())
  const [endTime, setEndTime] = useState(new Date())
  const [upcomingEvents, setUpcomingEvents] = useState(null)
  const [upcomingEventsOpen, setUpcomingEventsOpen] = useState(false)
  const [isCalendarActionModalVisible, setIsCalendarActionModalVisible] =
    useState(false)
  const [status, setStatus] = useState('fetching')
  // const [pricingModalOpen, setPricingModalOpen] = useState(false)
  const [createCandidateModal, setCreateCandidateModal] = useState(false)
  const [pageTitle, setPageTitle] = useState('')
  const [size, setSize] = useState()
  // Keeping track of which drawer is open to only keep one drawer open
  // drawers is here only to show which drawers are related
  const [visibleDrawers, setVisibleDrawers] = useState({
    opened: '',
    drawers: ['application', 'notification'],
  })

  const toggleDrawers = (key) => {
    setVisibleDrawers((prevState) => ({
      ...prevState,
      opened: visibleDrawers.opened === key ? '' : key,
    }))
  }

  useEffect(() => {
    setSize(window.innerWidth)
    return () => abortController.abort()
  }, [abortController])

  const updateUpcomingEvents = () => {
    Auth.fetch('/nylas?type=calendars', { signal: abortController.signal })
      .then((calendars) => {
        const email = user.nylasEmail || user.email
        const calendarIndex = calendars.map((e) => e.name).indexOf(email)
        const calendarId = calendars[calendarIndex].id
        const currentDate = parseInt(new Date().getTime() / 1000, 10)

        Auth.fetch(
          `/nylas?type=events&calendar_id=${calendarId}&starts_after=${currentDate}`,
          {
            signal: abortController.signal,
          },
        )
          .then((events) => {
            setUpcomingEvents(events)
            setStatus('fetched')
          })
          .catch((err) => {
            console.error(err)
            toast.error(t('common:navbar.nylas.getEvents.toast.error'))
          })
      })
      .catch((err) => {
        console.error(err)
        toast.error(t('common:navbar.nylas.getCalendar.toast.error'))
      })
  }

  const toggleUpcomingEvents = () => {
    setUpcomingEventsOpen(!upcomingEventsOpen)

    if (!upcomingEventsOpen) {
      updateUpcomingEvents()
    }
  }
  const dashboard = /\/app\/dashboard/
  // const offers = /\/app\/offers/
  const offers = /\/app\/offers(\/.*)?/
  const pools = /\/app\/pools/
  const candidates = /\/app\/candidates/
  const chabotSettings = /\/app\/chatbot-settings/
  const newTree = /\/app\/new\/tree/
  const newMandatoryTree = /\/app\/new\/mandatorytree/
  const settings = /\/app\/settings/

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const displayTitle = (location) => {
    const { pathname } = location

    let title = ''
    // basic page titles
    switch (true) {
      case dashboard.test(pathname):
        title = 'dashboard:heading'
        break

      case offers.test(pathname):
        title = 'campaign:heading'
        break
      case pools.test(pathname):
        title = 'reactivation:pools.heading'
        break
      case candidates.test(pathname):
        title = 'searchCandidate:heading'
        break
      case chabotSettings.test(pathname):
        title = 'models:heading'
        break
      case newTree.test(pathname):
        title = 'chatbotSetup:createTree.title'
        break
      case newMandatoryTree.test(pathname):
        title = 'chatbotSetup:createTree.title'
        break
      case settings.test(pathname):
        title = 'settings:heading'
        break
      default:
    }
    if (
      location.pathname === '/app/new/offer' ||
      location.pathname === '/app/update/offer'
    ) {
      if (ui.pageTitle) {
        title = `${ui.pageTitle}`
      }
    }
    return title
  }
  useEffect(() => {
    setPageTitle(displayTitle(location))
  }, [location, ui, displayTitle])

  useEffect(() => {
    i18next.on('languageChanged', () => {
      setPageTitle(displayTitle(location))
    })
    setPageTitle(displayTitle(location))

    return () => {
      i18next.off('languageChanged')
    }
  }, [location, displayTitle])

  const showModal = () => {
    toggleUpcomingEvents()
    setIsCalendarActionModalVisible(!isCalendarActionModalVisible)
  }

  const toggleEvent = () => setModalEvent(!modalEvent)

  const handleStartChange = (e) => {
    setStartTime(e)

    if (e > endTime) {
      setEndTime(e)
    }
  }

  const handleEndChange = (e) => {
    setEndTime(e)
  }

  const handleAddEvent = () => {
    Auth.fetch('/nylas?type=calendars', { signal: abortController.signal })
      .then((calendars) => {
        const calendarIndex = calendars.map((e) => e.name).indexOf(user.email)
        const calendarId = calendars[calendarIndex].id

        Auth.fetch('/nylas', {
          method: 'POST',
          body: JSON.stringify({
            action: 'addEvent',
            query: {
              calendarId,
              title,
              location: eventLocation,
              description,
              participants:
                participants !== ''
                  ? participants.split(',').map((e) => ({ email: e }))
                  : [],
              when: {
                start_time: parseInt(startTime.getTime() / 1000, 10),
                end_time: parseInt(endTime.getTime() / 1000, 10),
              },
            },
          }),
          signal: abortController.signal,
        })
          .then(() => {
            toast.success(t('common:navbar.nylas.addEvent.toast.success'))
            toggleEvent()
          })
          .catch((err) => {
            console.error(err)
            toast.error(t('common:navbar.nylas.addEvent.toast.error'))
          })
      })
      .catch((err) => {
        console.error(err.message)
        toast.error(t('common:navbar.nylas.addEvent.toast.error'))
      })
  }

  function checkUserSynced() {
    const item = localStorage.getItem('nylasSynced')
    if (item) {
      window.location.reload()
      window.removeEventListener('storage', checkUserSynced)
      localStorage.removeItem('company_id')
      localStorage.removeItem('nylasSynced')
    }
  }

  const handleAddNylasLink = async () => {
    try {
      window.addEventListener('storage', checkUserSynced)
      // eslint-disable-next-line no-underscore-dangle
      localStorage.setItem('user_id', user._id)

      const link = await Auth.fetch('/nylas', {
        method: 'POST',
        body: JSON.stringify({
          action: 'authenticate',
          query: {
            address: user.email,
          },
        }),
        signal: abortController.signal,
      })
      window.open(link)
    } catch (e) {
      console.error(e)
    }
  }

  const handleEditProfile = () => {
    dispatch(setUserId(user._id))
    history.push('/app/settings')
  }

  const handleDisconnect = async () => {
    const { pathname } = location

    if (pathname === '/app/new/offer' || pathname === '/app/update/offer') {
      ModalAnt.confirm({
        title: 'Attention',
        content: t('common:navbar.preventDisconnect'),
        icon: <WarningCircle size={32} color="#d95762" />,
        okText: t('common:disconnect'),
        okType: 'danger',
        cancelButtonProps: { className: 'grey-btn' },
        okButtonProps: { className: 'red-btn' },
        cancelText: t('common:cancel'),
        onOk: async () => {
          Auth.logout()
          await dispatch(resetUser())
          history.push('/login')
        },
        onCancel() {},
      })
    } else {
      Auth.logout()
      await dispatch(resetUser())
      history.push('/login')
    }
  }

  useEffect(
    () => () => {
      window.removeEventListener('storage', checkUserSynced)
    },
    [],
  )

  const upcomingEventsDisplay = upcomingEvents || []
  const hasLimitedPlan = user.company_id?.access === 'trial'
  const trialExpire = user.company_id?.trialExpiresAt
  let trialTimeLeft

  if (trialExpire) {
    const now = moment()
    const trialExpireDate = moment(trialExpire)

    if (now.valueOf() < trialExpireDate.valueOf()) {
      trialTimeLeft = computeElapsed(
        trialExpireDate.valueOf() / 1000,
        now.valueOf() / 1000,
      )
    } else {
      trialTimeLeft = 'expiré'
    }
  }
  const menuNylas = (
    <Menu>
      <Menu.Item key={nanoid()}>
        <p
          className="menu-item"
          onClick={() =>
            user.nylasActive ? toggleEvent() : handleEditProfile()
          }
        >
          {user.nylasActive
            ? t('common:navbar.nylas.addEvent.nylasInactive')
            : t('common:syncNylas.title')}
        </p>
      </Menu.Item>
      <Menu.Item key={nanoid()}>
        <p
          className="menu-item"
          onClick={() =>
            user.nylasActive
              ? createSchedulingPage(user.nylasAccessToken)
              : handleEditProfile()
          }
        >
          {user.nylasActive
            ? t('common:navbar.nylas.openPlanificator')
            : t('common:syncNylas.title')}
        </p>
      </Menu.Item>
      <Menu.Item key={nanoid()}>
        <p
          className="menu-item"
          onClick={() => (user.nylasActive ? showModal() : handleEditProfile())}
        >
          {user.nylasActive
            ? t('common:navbar.nylas.seeEvent')
            : t('common:syncNylas.title')}
        </p>
      </Menu.Item>
    </Menu>
  )
  const menuNoNylas = (
    <Menu>
      <Menu.Item
        key={nanoid()}
        onClick={() => {
          dispatch(setUserId(user._id))
          history.push('/app/settings')
        }}
      >
        <p className="menu-item">{t('common:syncNylas.title')}</p>
      </Menu.Item>
    </Menu>
  )

  return (
    <div
      style={{
        height: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '4px 20px',
        backgroundColor: 'white',
        borderBottom: '1px solid #00000014',
      }}
    >
      <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
        {!user.permissions.includes('admin_hollo') && (
          <span className="page-title">{t(pageTitle)}</span>
        )}
      </div>

      <div className="userbox-wrapper">
        <Dropdown
          placement="bottomLeft"
          arrow
          overlay={
            <Menu>
              <Menu.Item
                key="createTalent"
                onClick={() => {
                  setCreateCandidateModal(!createCandidateModal)
                }}
              >
                {t('common:createTalent')}
              </Menu.Item>
            </Menu>
          }
        >
          <Button
            type="primary"
            className="grey-btn"
            style={{
              padding: '8px 10px',
              textShadow: 'none',
              boxShadow: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '14px !important',
            }}
          >
            <Plus size={20} weight="bold" />
          </Button>
        </Dropdown>
        {!user.permissions.includes('guest') &&
          !user.permissions.includes('admin_hollo') && (
            <SearchBar
              placeholder={t('common:searchBar.placeholderSearchTalent')}
            />
          )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 18,
          }}
        >
          {!user.nylasActive && (
            <Button
              type="default"
              className="red-btn"
              onClick={handleAddNylasLink}
              style={{
                padding: '10px 12px',
                textShadow: 'none',
                boxShadow: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ArrowsClockwise size={15} weight="bold" />
            </Button>
          )}
          <StatusImports />
          {hasLimitedPlan && (
            <Tooltip
              title={t('common:plans.limitedAccess')}
              placement="bottom"
              zIndex={1500}
            >
              <Tag
                color="var(--orange)"
                className="basic-tag"
                onClick={() => /* setPricingModalOpen(true) */ {}}
              >
                {t('common:plans.trialAccess')}
                {' : '}
                {trialTimeLeft}
              </Tag>
            </Tooltip>
          )}
          <span className="userbox-icon-wrapper">
            <DrawerUnseenApplications
              drawerKey="application"
              visibleDrawers={visibleDrawers}
              toggleDrawers={() => toggleDrawers('application')}
            />
          </span>
          {/* <span className="userbox-icon-wrapper">
            <Notifications
              drawerKey="notification"
              visibleDrawers={visibleDrawers}
              toggleDrawers={() => toggleDrawers('notification')}
            />
          </span> */}
          {!user.permissions.includes('guest') && features.includes('crm') && (
            <Dropdown
              placement="bottomLeft"
              arrow
              overlay={user.nylasActive ? menuNylas : menuNoNylas}
            >
              <span className="userbox-icon-wrapper">
                <Calendar size={24} color="var(--dark)" />
              </span>
            </Dropdown>
          )}

          <Tooltip
            title={t('navbar:tooltip.settings')}
            placement="bottom"
            zIndex={1500}
          >
            <span className="userbox-icon-wrapper">
              <Gear size={24} onClick={handleEditProfile} color="var(--dark)" />
            </span>
          </Tooltip>
          <>
            <span className="profile-btn-wrapper">
              <Dropdown
                placement="bottomRight"
                arrow
                overlay={
                  <Menu>
                    <Menu.Item key="disconnect" onClick={handleDisconnect}>
                      {t('navbar:disconnect')}
                    </Menu.Item>
                  </Menu>
                }
              >
                <a
                  className="ant-dropdown-link"
                  trigger="click"
                  style={{ fontSize: '14px' }}
                  onClick={(e) => e.preventDefault()}
                >
                  {user?.login} - {company?.name}
                  <FontAwesomeIcon
                    className="ml-2 opacity-8"
                    icon={faAngleDown}
                    style={{ color: 'black' }}
                  />
                </a>
              </Dropdown>
            </span>
          </>
        </div>
        <ModalAnt
          visible={isCalendarActionModalVisible}
          onCancel={showModal}
          footer={null}
        >
          {status === 'fetching' ? (
            <Loader />
          ) : upcomingEventsDisplay.length === 0 ? (
            <NavItem className="nav-item-header">
              {t('common:navbar.nylas.noEvent')}
            </NavItem>
          ) : (
            <PerfectScrollbar
              style={{
                maxHeight: '300px',
                maxWidth: '350px',
              }}
            >
              <Nav vertical>
                {upcomingEventsDisplay.length > 0 &&
                  upcomingEventsDisplay.map((event) => {
                    let { id, title, when } = event
                    let start = null
                    let end = null

                    if (when.time) {
                      start = new Date(when.time * 1000).toLocaleString()
                    } else {
                      start = new Date(when.start_time * 1000).toLocaleString()
                      end = new Date(when.end_time * 1000).toLocaleString()
                    }

                    if (title === '') {
                      title = 'Sans titre'
                    }

                    return (
                      <NavItem key={id} className="nav-upcoming-event">
                        <strong>{title.toLowerCase()}</strong>
                        <br />
                        {end ? (
                          <span className="upcoming-event-date">
                            Du {start} au {end}
                          </span>
                        ) : (
                          <span className="upcoming-event-date">
                            Le {start}
                          </span>
                        )}
                      </NavItem>
                    )
                  })}
              </Nav>
            </PerfectScrollbar>
          )}
        </ModalAnt>
        <Modal isOpen={modalEvent} toggle={toggleEvent}>
          <ModalHeader toggle={toggleEvent}>
            {t('common:navbar.nylas.modals.addEvent.title')}
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label className="required" for="title">
                {t('common:navbar.nylas.modals.addEvent.eventName')}
              </Label>
              <Input
                type="text"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                autoComplete="off"
              />
            </FormGroup>
            <FormGroup>
              <Label for="location">{t('common:location')}</Label>
              <Input
                type="text"
                name="location"
                value={eventLocation}
                onChange={(e) => setEventLocation(e.target.value)}
                autoComplete="off"
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">{t('common:description')}</Label>
              <Input
                type="text"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="participants">{t('common:participants')}</Label>
              <Input
                type="text"
                name="participants"
                value={participants}
                onChange={(e) => setParticipants(e.target.value)}
              />
            </FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="startTime">{t('common:start')}</Label>
                  <DatePicker
                    selected={startTime}
                    className="form-control"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeCaption="Heure"
                    timeIntervals={15}
                    onChange={handleStartChange}
                    dateFormat="dd/MM/yyyy HH:mm"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="endTime">{t('common:end')}</Label>
                  <DatePicker
                    selected={endTime}
                    className="form-control"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeCaption="Heure"
                    timeIntervals={15}
                    onChange={handleEndChange}
                    dateFormat="dd/MM/yyyy HH:mm"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button className="grey-btn" onClick={toggleEvent}>
              {t('common:cancel')}
            </Button>
            <Button
              className="basic-btn"
              onClick={handleAddEvent}
              disabled={!title}
            >
              {t('common:create')}
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
      {createCandidateModal && (
        <>
          <CreateCandidate
            isOpen={createCandidateModal}
            toggle={() => {
              setCreateCandidateModal(!createCandidateModal)
            }}
            createCandidateModal={createCandidateModal}
          />
        </>
      )}

      {/*   <PricingModal
        isOpen={pricingModalOpen}
        toggle={() => setPricingModalOpen(false)}
      /> */}
    </div>
  )
}

export default Navbar
