import PropTypes from 'prop-types'
import React from 'react'
import { Tooltip } from 'antd'

const TooltipComponent = ({ title, placement, content, children }) => (
  <Tooltip title={title} placement={placement} zIndex={1001}>
    <span>{content || children}</span>
  </Tooltip>
)

TooltipComponent.propTypes = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  content: PropTypes.element,
  children: PropTypes.element,
}
TooltipComponent.defaultProps = {
  content: null,
  children: null,
}
export default TooltipComponent
