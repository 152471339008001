import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@nextui-org/react'
import { Empty } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiArrowNarrowLeft, HiArrowNarrowRight } from 'react-icons/hi'
import Loader from 'react-loader-spinner'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import LoadingBar from 'react-top-loading-bar'
import { getOneFullCandidate } from '../../../api/Candidate/api.candidate'
import { handleClassifyCandidate } from '../../../api/Conversation/api.conversation'
import CandidateOnly from '../../CandidateOnly'
import * as styles from './styles'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function ResumeSlider({
  candidate,
  candidateId,
  conversation,
  prevResume,
  nextResume,
  selectedIndex,
  handleReject,
  total,
  offer,
  mailOnReject,
  cursor,
  fetching,
  refreshParentData = () => {},
  slider = true,
  updateCandidateInParent,
  defaultTab,
  onClose,
}) {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [resume, setResume] = useState(null)
  const { t } = useTranslation(['common'], { useSuspense: false })

  const ref = useRef(null)

  useEffect(() => {
    if (candidate?.files?.length) {
      window.setTimeout(async () => {
        let lastResume =
          [...candidate.files]
            .filter((elt) => elt.type === 'resume')
            .sort((x, y) => new Date(y.timestamp) - new Date(x.timestamp))[0] ||
          [...candidate.files].sort(
            (x, y) => new Date(y.timestamp) - new Date(x.timestamp),
          )[0]

        if (lastResume) {
          const { fileName } = lastResume

          if (!lastResume.signedUrl) {
            const myCandidate = await getOneFullCandidate(candidate._id)
            // eslint-disable-next-line prefer-destructuring
            lastResume = [...myCandidate.files].sort(
              (x, y) => new Date(y.timestamp) - new Date(x.timestamp),
            )[0]
          }

          setResume({
            ...lastResume,
            type: fileName
              ? fileName.split('.').pop()
              : lastResume.id.split('.').pop(),
          })
        } else {
          setResume({ type: 'missing' })
        }
      }, 100)
    } else if (conversation?.candidate_id?.files?.length) {
      window.setTimeout(() => {
        const lastResume =
          [...conversation.candidate_id.files]
            .filter((elt) => elt.type === 'resume')
            .sort((x, y) => new Date(y.timestamp) - new Date(x.timestamp))[0] ||
          [...conversation.candidate_id.files].sort(
            (x, y) => new Date(y.timestamp) - new Date(x.timestamp),
          )[0]

        if (lastResume) {
          const { fileName } = lastResume

          setResume({
            ...lastResume,
            type: fileName
              ? fileName.split('.').pop()
              : lastResume.id.split('.').pop(),
          })
        } else {
          setResume({ type: 'missing' })
        }
      }, 100)
    } else {
      setResume({ type: 'missing' })
    }
  }, [conversation, candidate])

  const handlePageChange = useCallback((type) => {
    setPageNumber((prevPageNumber) => {
      if (type === 'prev') {
        return prevPageNumber - 1
      }
      return prevPageNumber + 1
    })
  }, [])

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages)

  if (!resume) {
    return null
  }
  let resumeContent

  if (resume.type === 'pdf') {
    resumeContent = (
      <div className="resume-pdf-wrapper">
        {numPages > 1 ? (
          <div className="resume-pagination-wrapper">
            <Button
              disabled={pageNumber <= 1}
              onClick={() => handlePageChange('prev')}
              size="sm"
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{ fontSize: '10px' }}
              />
            </Button>
            <span>
              {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
            </span>
            {pageNumber >= numPages ? (
              ''
            ) : (
              <Button
                disabled={pageNumber >= numPages}
                onClick={() => handlePageChange('next')}
                size="sm"
              >
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{ fontSize: '10px' }}
                />
              </Button>
            )}
          </div>
        ) : (
          ''
        )}
        <Document
          file={resume.signedUrl}
          loading="Chargement"
          options={{
            cMapUrl: 'cmaps/',
            cMapPacked: true,
          }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <div style={{ margin: 'auto' }}>
            <Page loading="" height={900} pageNumber={pageNumber} />
          </div>
        </Document>
      </div>
    )
  } else if (['csv', 'xslx', 'docx'].includes(resume.type)) {
    resumeContent = (
      <div style={{ margin: 'auto' }}>
        <Empty description={t('common:cannotReadFile')} />
      </div>
    )
  } else if (['png', 'jpeg', 'jpg'].includes(resume.type)) {
    resumeContent = (
      <div style={{ margin: 'auto' }}>
        <img src={resume.signedUrl} className="imgPreview" alt="resume" />
      </div>
    )
  } else if (resume.type === 'missing') {
    resumeContent = (
      <div
        style={{
          marginTop: '40%',
        }}
      >
        <Empty description={t('common:noCV')} />
      </div>
    )
  }

  if (fetching) {
    return (
      <div
        style={{
          marginTop: '20%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Loader type="TailSpin" color="#3ac47d" height={80} width={80} />
      </div>
    )
  }

  return (
    <styles.ResumeSliderWrapper>
      <LoadingBar color="#0070f3" ref={ref} height={5} continuousStart={10} />

      <div style={{ width: '42%' }}>
        {slider && (
          <p className="slider-info">
            <span style={{ marginBottom: '2rem' }}>
              {selectedIndex + 1 + cursor} sur {total}{' '}
            </span>
          </p>
        )}

        <CandidateOnly
          display="CV"
          handleClassifyCandidate={handleClassifyCandidate}
          candidateId={candidateId || candidate._id}
          updateCandidateInParent={updateCandidateInParent}
          conversation={conversation}
          offer={offer}
          handleReject={handleReject}
          mailOnReject={mailOnReject}
          refreshParentData={refreshParentData}
          defaultSelectedTab={defaultTab}
          onClose={onClose}
        />
      </div>
      <div
        style={{
          width: '50%',
          height: '100%',
          position: 'relative',
          marginLeft: '1.5rem',
        }}
      >
        {resumeContent}
      </div>

      {slider && (
        <div
          style={{
            position: 'fixed',
            bottom: '2rem',
            zIndex: 100,
            display: 'flex',
            gap: 10,
          }}
        >
          {slider && selectedIndex + cursor > 0 && (
            <Button
              auto
              shadow
              color="primary"
              onClick={async () => {
                ref.current.continuousStart()
                await prevResume()
                if (ref.current) {
                  ref.current.complete()
                }
              }}
              icon={<HiArrowNarrowLeft style={{ fontSize: '22px' }} />}
            />
          )}
          {selectedIndex + 1 + cursor < total && (
            <Button
              auto
              shadow
              color="primary"
              icon={<HiArrowNarrowRight style={{ fontSize: '22px' }} />}
              onClick={async () => {
                ref.current.continuousStart()
                await nextResume()
                if (ref.current) {
                  ref.current.complete()
                }
              }}
            />
          )}
        </div>
      )}
    </styles.ResumeSliderWrapper>
  )
}

export default ResumeSlider
