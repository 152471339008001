import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const OfferDetailsWrapper = styled.div`
  text-align: left;
  line-height: 1.9;

  h2 {
    font-size: 22px;
    margin-bottom: 5px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
  }

  h3 {
    font-weight: 700;
    font-size: 16px;
  }

  .location {
    opacity: 0.55;
    font-size: 14px;
  }
  .description {
    max-width: 980px;
  }

  .jobDetails {
    margin: 20px 0;
  }

  .jobDetails p {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .jobDetails span {
    margin-left: 2px;
    margin-right: 5px;
    font-size: 14px;
    font-weight: 600;
  }
`
