import { Button as AntdButton, Empty, Tag } from 'antd'
import DOMPurify from 'dompurify'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import {
  contractColors,
  remote,
  validateAutomaticActions,
  validateMandatoryTree,
  validateSteps,
} from '../../services/utils'
import OfferIndicators from '../OfferIndicators'
import * as s from './styles'

const OfferDetails = ({ offer, currentPage, toggle, onClose }) => {
  const { t } = useTranslation(['common', 'campaign', 'settings'], {
    useSuspense: false,
  })
  const { id } = useParams()

  const history = useHistory()
  const user = useSelector((state) => state.user)
  const featureAccess =
    user.permissions.includes('crm') || user.permissions.includes('admin')

  const createMarkup = (html) => ({
    __html: DOMPurify.sanitize(html),
  })

  const handlePreviewChatbot = async () => {
    const issues = [
      ...validateSteps(offer.steps, false, t),
      ...validateAutomaticActions(offer.actions, t),
      ...(await validateMandatoryTree(offer.mandatoryTree_id, t)),
    ]

    if (issues.length) {
      return toast.error(t('settings:errors.needsFixing'))
    }

    const offerUrl = process.env.REACT_APP_CHATBOT_URL
      ? `${process.env.REACT_APP_CHATBOT_URL}/${offer._id}`
      : `http://localhost:3002/${offer._id}`
    const previewUrl = `${offerUrl}?preview=true`

    window.open(previewUrl, '_blank')
  }

  return (
    <s.OfferDetailsWrapper>
      <div>
        <div style={{ marginTop: '10px' }}>
          <h2 style={{ display: 'flex' }}>
            <OfferIndicators offer={offer} />
            {offer.displayName || offer.name}
          </h2>

          <div className="jobDetails">
            <p>
              <span>{t('common:location')}</span>
              {offer.location || t('common:noData')}
            </p>
            <p>
              <span>{t('common:createOn')}</span>
              {new Date(offer.createdAt).toLocaleDateString()}
              <span>{t('common:by')}</span>
              {offer?.user_id?.login || t('common:deletedUser')}
            </p>
            <p>
              <span>{t('common:teams')}:</span>
              {offer?.team_id?.name || t('common:deletedTeam')}
            </p>
            <p>
              <span>{t('common:applicationsCompletion')}</span>
              {Math.round(offer.averageCompletion)}%
            </p>
            <Tag
              color={contractColors[offer.contractType]}
              style={{
                fontSize: '13px',
                fontWeight: '800',
                borderRadius: '8px',
                padding: '.3em .8em',
              }}
            >
              {offer.contractType}
            </Tag>
          </div>
          <div>
            <div className="btns-container">
              {featureAccess && offer?.applicationType === 'full' && (
                <AntdButton
                  className="basic-btn"
                  onClick={handlePreviewChatbot}
                >
                  {t('common:chatbotPreview')}
                </AntdButton>
              )}
              {currentPage === 'offer-list' && id !== offer._id && (
                <AntdButton
                  className="blue-btn"
                  onClick={() => {
                    history.push(`/app/offers/${offer._id}`)
                    if (toggle instanceof Function) {
                      toggle()
                    }
                    onClose()
                  }}
                  style={{ marginLeft: 5 }}
                >
                  {t('common:applicationsManagement')}
                </AntdButton>
              )}
            </div>
          </div>
          {offer.clientDescription && (
            <div>
              <div className="description" style={{ marginTop: '40px' }}>
                <h3>{t('common:company', 'Entreprise')}</h3>
                {offer.clientDescription ? (
                  <div
                    className="preview"
                    dangerouslySetInnerHTML={createMarkup(
                      offer.clientDescription,
                    )}
                  />
                ) : (
                  <Empty description="N/C" />
                )}
              </div>
              <div className="description" style={{ marginTop: '40px' }}>
                <h3>{t('common:jobDescription', 'Description du poste')}</h3>
                {offer.description ? (
                  <div
                    className="preview"
                    dangerouslySetInnerHTML={createMarkup(offer.description)}
                  />
                ) : (
                  <Empty description="N/C" />
                )}
              </div>
              <div className="remoteWorking" style={{ marginTop: '40px' }}>
                <h3>
                  {t(
                    'campaign:createOffer.generalStep.remoteWorking.label',
                    'Télétravail',
                  )}
                </h3>
                {offer.remoteWorking && (
                  <div
                    className="preview"
                    dangerouslySetInnerHTML={createMarkup(
                      remote(offer.remoteWorking, t),
                    )}
                  />
                )}
              </div>
              <div className="description" style={{ marginTop: '40px' }}>
                <h3>{t('common:profileNeeded', 'Profil recherché')}</h3>
                {offer.candidateDescription ? (
                  <div
                    className="preview"
                    dangerouslySetInnerHTML={createMarkup(
                      offer.candidateDescription,
                    )}
                  />
                ) : (
                  <Empty description="N/C" />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </s.OfferDetailsWrapper>
  )
}

export default OfferDetails
