import React, { useEffect, Suspense, lazy } from 'react'
import { Toaster } from 'react-hot-toast'
import { usePageVisibility } from 'react-page-visibility'
import { Redirect, Route, Switch } from 'react-router-dom'
import AppMain from '../../Layout/Main'
import CallbackNylas from '../CallbackNylas'
import CallbackLever from '../CallbackLever'
import CallbackStripe from '../CallbackStripe'
import ForgotPassword from '../Login/ForgotPassword'
import Login from '../Login'
import Register from '../Register'
import ResetPassword from '../ResetPassword'
import Verify from '../Verify'
import { toastOptions } from '../../services/utils'

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

const ClientApplicationProposal = lazy(() =>
  import('../ClientApplicationProposal'),
)

let broadcast

if (window.BroadcastChannel) {
  broadcast = new window.BroadcastChannel('sw-channel')
}

const Main = () => {
  const isVisible = usePageVisibility()

  useEffect(() => {
    if (broadcast) {
      broadcast.postMessage({
        type: 'pageVisibility',
        visible: isVisible,
      })
    }
  }, [isVisible])

  return (
    <Suspense fallback="">
      <Toaster toastOptions={toastOptions} />
      <Switch>
        <Route path="/" exact render={() => <Redirect to="/app" />} />
        <Route path="/app" component={AppMain} />
        <Route exact path="/login" component={Login} />
        <Route
          exact
          path="/talent/:conversationId"
          component={ClientApplicationProposal}
        />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgot" component={ForgotPassword} />
        <Route exact path="/reset" component={ResetPassword} />
        <Route exact path="/verify" component={Verify} />
        <Route exact path="/nylas" component={CallbackNylas} />
        <Route exact path="/lever" component={CallbackLever} />
        <Route exact path="/stripe" component={CallbackStripe} />
        <Route path="*" render={() => <Redirect to="/app" />} />
      </Switch>
    </Suspense>
  )
}

export default Main
