import produce from 'immer'
import { mongoObjectId } from '../../services/utils'

import {
  RESET_NEW_OFFER,
  SET_NEW_OFFER,
  SET_NEW_OFFER_ID,
  SET_NEW_OFFER_NAME,
  SET_NEW_OFFER_DISPLAY_NAME,
  SET_NEW_OFFER_TEAM,
  SET_NEW_OFFER_COMPANY,
  SET_NEW_OFFER_LOCATION,
  SET_NEW_OFFER_DESCRIPTION,
  SET_NEW_OFFER_CANDIDATE_DESCRIPTION,
  SET_NEW_OFFER_CLIENT_DESCRIPTION,
  SET_NEW_OFFER_CONTRACT_TYPE,
  SET_NEW_OFFER_ATS,
  SET_NEW_OFFER_EXTERNAL_REF,
  SET_NEW_OFFER_EXTERNAL_DOCUMENT,
  SET_NEW_OFFER_MANDATORY_TREE,
  SET_NEW_OFFER_REQUIREMENTS,
  ADD_NEW_OFFER_REQUIREMENT,
  REMOVE_NEW_OFFER_REQUIREMENT,
  REMOVE_NEW_OFFER_SKILL,
  SET_NEW_OFFER_STEP_NAME,
  SET_NEW_OFFER_STEP_SKILL,
  SET_NEW_OFFER_STEP_REQUIREMENT,
  SET_NEW_OFFER_STEP_CONDITION,
  SET_NEW_OFFER_STEP_TYPE,
  SET_NEW_OFFER_STEP_SENTENCE,
  SET_NEW_OFFER_STEP_END_CHAT,
  SET_NEW_OFFER_STEP_VARIABLE,
  SET_NEW_OFFER_STEP_WEIGHT,
  SET_NEW_OFFER_STEP_VIDEO_URL,
  SET_NEW_OFFER_STEP_ATTACHMENT_TYPE,
  ADD_NEW_OFFER_STEP_OPTION,
  REMOVE_NEW_OFFER_STEP_OPTION,
  SET_NEW_OFFER_STEP_OPTION_LABEL,
  SET_NEW_OFFER_STEP_OPTION_VALUE,
  SET_NEW_OFFER_STEP_OPTION_SCORE,
  SET_NEW_OFFER_STEP_OPTION_DEALBREAKER,
  ADD_NEW_OFFER_STEP_OPTION_NUMBER,
  REMOVE_NEW_OFFER_STEP_OPTION_NUMBER,
  SET_NEW_OFFER_STEP_OPTION_NUMBER_MIN,
  SET_NEW_OFFER_STEP_OPTION_NUMBER_MAX,
  SET_NEW_OFFER_STEP_OPTION_NUMBER_SCORE,
  SET_NEW_OFFER_STEP_OPTION_NUMBER_DEALBREAKER,
  ADD_NEW_OFFER_STEP_OPTION_DATE,
  REMOVE_NEW_OFFER_STEP_OPTION_DATE,
  SET_NEW_OFFER_STEP_OPTION_DATE_START,
  SET_NEW_OFFER_STEP_OPTION_DATE_END,
  SET_NEW_OFFER_STEP_OPTION_DATE_SCORE,
  SET_NEW_OFFER_STEP_OPTION_DATE_DEALBREAKER,
  SET_NEW_OFFER_STEPS,
  SET_NEW_OFFER_SPECIFIC_STEP,
  ADD_NEW_OFFER_STEP,
  REMOVE_NEW_OFFER_STEP,
  SET_NEW_TEMPLATE_NAME,
  SET_ACTIONS,
  SET_TAGS,
  SET_PROCESS_STATUS,
  SET_NEW_OFFER_STEP_OPTIONS,
  SET_JOB,
  SET_SUBJOBS,
  SET_SECTORS,
  SET_SUBSECTORS,
  SET_EXPERIENCE,
  SET_SCHOOLLEVEL,
  SET_SALARYRANGE,
  SET_NEW_OFFER_REMOTE_WORKING,
} from '../actions/newOfferActions'

const initialState = {
  id: null,
  name: '',
  displayName: '',
  team: null,
  company: null,
  location: '',
  description: '',
  clientDescription: '',
  candidateDescription: '',
  contractType: 'CDI',
  remoteWorking: null,
  applicationType: null,
  ats: null,
  externalRef: null,
  externalDocument: null,
  requirements: [],
  skills: [],
  steps: [],
  mandatoryTree: null,
  published: false,
  publishDate: null,
  actions: [],
  tags: [],
  processStatus: [],
  jobDomain: [],
  subJob: [],
  sector: [],
  subSector: [],
  experience: [],
  schoolLevel: [],
  salaryRange: {
    minValue: 25,
    maxValue: 80,
  },
}

export default produce((draft, action) => {
  switch (action.type) {
    case RESET_NEW_OFFER:
      return {
        ...initialState,
        applicationType: action.applicationType,
      }

    case SET_NEW_OFFER: {
      const { offer } = action

      draft.id = offer._id !== undefined ? offer._id : initialState.id
      draft.name = offer.name !== undefined ? offer.name : initialState.name
      draft.displayName =
        offer.displayName !== undefined
          ? offer.displayName
          : initialState.displayName
      draft.team =
        offer.team_id !== undefined ? offer.team_id : initialState.team
      draft.company =
        offer.company_id !== undefined ? offer.company_id : initialState.company
      draft.location =
        offer.location !== undefined ? offer.location : initialState.location
      draft.description =
        offer.description !== undefined
          ? offer.description
          : initialState.description
      draft.clientDescription =
        offer.clientDescription !== undefined
          ? offer.clientDescription
          : initialState.clientDescription
      draft.candidateDescription =
        offer.candidateDescription !== undefined
          ? offer.candidateDescription
          : initialState.candidateDescription
      draft.contractType =
        offer.contractType !== undefined
          ? offer.contractType
          : initialState.contractType
      draft.remoteWorking =
        offer.remoteWorking !== undefined
          ? offer.remoteWorking
          : initialState.remoteWorking
      draft.applicationType =
        offer.applicationType !== undefined
          ? offer.applicationType
          : initialState.applicationType
      draft.ats = offer.ats_id !== undefined ? offer.ats_id : initialState.ats
      draft.externalRef =
        offer.externalRef !== undefined
          ? offer.externalRef
          : initialState.externalRef
      draft.externalDocument =
        offer.externalDocument !== undefined
          ? offer.externalDocument
          : initialState.externalRef
      draft.requirements =
        offer.requirements !== undefined
          ? offer.requirements
          : initialState.requirements
      draft.skills =
        offer.skills !== undefined ? offer.skills : initialState.skills
      draft.steps = offer.steps !== undefined ? offer.steps : initialState.steps
      draft.mandatoryTree =
        offer.mandatoryTree_id !== undefined
          ? offer.mandatoryTree_id
          : initialState.mandatoryTree
      draft.published =
        offer.published !== undefined ? offer.published : initialState.published
      draft.templateName =
        offer.templateName !== undefined
          ? offer.templateName
          : initialState.templateName
      draft.actions = offer.actions ? offer.actions : initialState.actions
      draft.tags = offer.tags || initialState.tags
      draft.jobDomain = offer.jobDomain
        ? offer.jobDomain
        : initialState.jobDomain
      draft.subJob = offer.subJob ? offer.subJob : initialState.subJob
      draft.sector = offer.sector ? offer.sector : initialState.sector
      draft.subSector = offer.subSector
        ? offer.subSector
        : initialState.subSector
      draft.experience = offer.experience
        ? offer.experience
        : initialState.experience
      draft.schoolLevel = offer.schoolLevel
        ? offer.schoolLevel
        : initialState.schoolLevel
      draft.salaryRange = offer.salaryRange
        ? offer.salaryRange
        : initialState.salaryRange

      return
    }

    case SET_JOB:
      draft.jobDomain = action.job
      return

    case SET_SUBJOBS:
      draft.subJob = action.subJob
      return

    case SET_SECTORS:
      draft.sector = action.sector
      return

    case SET_SUBSECTORS:
      draft.subSector = action.subSector
      return

    case SET_EXPERIENCE:
      draft.experience = action.experience
      return

    case SET_SCHOOLLEVEL:
      draft.schoolLevel = action.schoolLevel
      return

    case SET_SALARYRANGE:
      draft.salaryRange = action.salaryRange
      return

    case SET_NEW_TEMPLATE_NAME:
      draft.templateName = action.templateName
      return

    case SET_NEW_OFFER_ID:
      draft.id = action.id
      return

    case SET_NEW_OFFER_NAME:
      draft.name = action.name
      return

    case SET_NEW_OFFER_DISPLAY_NAME:
      draft.displayName = action.displayName
      return

    case SET_NEW_OFFER_TEAM:
      draft.team = action.team
      return

    case SET_NEW_OFFER_COMPANY:
      draft.company = action.company
      return

    case SET_NEW_OFFER_LOCATION:
      draft.location = action.location
      return
    case SET_NEW_OFFER_DESCRIPTION:
      draft.description = action.description
      return
    case SET_NEW_OFFER_CANDIDATE_DESCRIPTION:
      draft.candidateDescription = action.candidateDescription
      return
    case SET_NEW_OFFER_CLIENT_DESCRIPTION:
      draft.clientDescription = action.clientDescription
      return
    case SET_NEW_OFFER_CONTRACT_TYPE:
      draft.contractType = action.contractType
      return
    case SET_NEW_OFFER_REMOTE_WORKING:
      draft.remoteWorking = action.remoteWorking
      return

    case SET_NEW_OFFER_ATS:
      draft.ats = action.ats
      return

    case SET_NEW_OFFER_EXTERNAL_REF:
      draft.externalRef = action.externalRef
      return

    case SET_NEW_OFFER_EXTERNAL_DOCUMENT:
      draft.externalDocument = action.externalDocument
      return

    case SET_NEW_OFFER_MANDATORY_TREE: {
      // draft.newOfferSteps = removeMandatoryMentions(draft.newOfferSteps, draft.newOfferMandatoryTree);
      draft.mandatoryTree = action.mandatoryTree
      return
    }

    case SET_NEW_OFFER_REQUIREMENTS:
      draft.requirements = action.requirements
      return

    case ADD_NEW_OFFER_REQUIREMENT: {
      const { defaultStep, _id } = action.data.requirement
      const { step } = action.data
      draft.requirements.push(_id)
      if (step !== undefined) {
        draft.steps.push(step)
      } else {
        draft.steps.push({
          ...defaultStep,
          _id: mongoObjectId(),
          requirement_id: _id,
          level: 0,
        })
      }
      return
    }

    case REMOVE_NEW_OFFER_REQUIREMENT: {
      const stepIndex = draft.steps
        .map((e) => e.requirement_id)
        .indexOf(action.requirementId)
      if (stepIndex >= 0) {
        if (draft.steps[stepIndex].hasChild) {
          return
        }

        if (
          draft.steps[stepIndex].condition &&
          draft.steps[stepIndex].condition.step_id
        ) {
          const numberOfChildren = draft.steps.filter((e) => {
            if (e.condition && e.condition.step_id) {
              if (
                e.condition.step_id === draft.steps[stepIndex].condition.step_id
              ) {
                return e
              }
              return false
            }
            return false
          })

          if (numberOfChildren.length <= 1) {
            const parentStepIndex = draft.steps
              .map((e) => e._id)
              .indexOf(draft.steps[stepIndex].condition.step_id)
            if (parentStepIndex >= 0) {
              draft.steps[parentStepIndex].hasChild = false
            }
          }
        }

        draft.steps.splice(stepIndex, 1)
      }

      const requirementIndex = draft.requirements.indexOf(action.requirementId)
      if (requirementIndex >= 0) {
        draft.requirements.splice(requirementIndex, 1)
      }

      return
    }

    case REMOVE_NEW_OFFER_SKILL: {
      const stepIndex = draft.steps
        .map((e) => e.skill_id)
        .indexOf(action.skillId)
      if (stepIndex >= 0) {
        if (draft.steps[stepIndex].hasChild) {
          return
        }

        if (
          draft.steps[stepIndex].condition &&
          draft.steps[stepIndex].condition.step_id
        ) {
          const numberOfChildren = draft.steps.filter((e) => {
            if (e.condition && e.condition.step_id) {
              if (
                e.condition.step_id === draft.steps[stepIndex].condition.step_id
              ) {
                return e
              }
              return false
            }
            return false
          })

          if (numberOfChildren.length <= 1) {
            const parentStepIndex = draft.steps
              .map((e) => e._id)
              .indexOf(draft.steps[stepIndex].condition.step_id)
            if (parentStepIndex >= 0) {
              draft.steps[parentStepIndex].hasChild = false
            }
          }
        }

        draft.steps.splice(stepIndex, 1)
      }

      const skillIndex = draft.skills.indexOf(action.skillId)
      if (skillIndex >= 0) {
        draft.skills.splice(skillIndex, 1)
      }

      return
    }

    case SET_NEW_OFFER_STEP_NAME: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].name = action.data.name
      draft.steps[stepIndex].variable = action.data.name
      return
    }

    case SET_NEW_OFFER_STEP_SKILL: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)

      const prevSkill = draft.steps[stepIndex].skill_id
      const prevRequirement = draft.steps[stepIndex].requirement_id

      draft.steps[stepIndex].skill_id = action.data.skillId
      draft.steps[stepIndex].requirement_id = null
      if (draft.skills.indexOf(action.data.skillId) < 0) {
        draft.skills.push(action.data.skillId)
      }

      if (prevSkill) {
        if (draft.steps.map((e) => e.skill_id).indexOf(prevSkill) < 0) {
          const prevSkillIndex = draft.skills.indexOf(prevSkill)

          draft.skills.splice(prevSkillIndex, 1)
        }
      }

      if (prevRequirement) {
        if (
          draft.steps.map((e) => e.requirement_id).indexOf(prevRequirement) < 0
        ) {
          const prevRequirementIndex =
            draft.requirements.indexOf(prevRequirement)

          draft.requirements.splice(prevRequirementIndex, 1)
        }

        // clear step type if requirement was previously used
        draft.steps[stepIndex].type = 'textfield'
      }

      return
    }

    case SET_NEW_OFFER_STEP_REQUIREMENT: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)

      const prevSkill = draft.steps[stepIndex].skill_id
      const prevRequirement = draft.steps[stepIndex].requirement_id

      draft.steps[stepIndex].requirement_id = action.data.requirementId
      draft.steps[stepIndex].skill_id = null
      if (draft.requirements.indexOf(action.data.requirementId) < 0) {
        draft.requirements.push(action.data.requirementId)
      }

      if (prevSkill) {
        if (draft.steps.map((e) => e.skill_id).indexOf(prevSkill) < 0) {
          const prevSkillIndex = draft.skills.indexOf(prevSkill)

          draft.skills.splice(prevSkillIndex, 1)
        }
      }

      if (prevRequirement) {
        if (
          draft.steps.map((e) => e.requirement_id).indexOf(prevRequirement) < 0
        ) {
          const prevRequirementIndex =
            draft.requirements.indexOf(prevRequirement)

          draft.requirements.splice(prevRequirementIndex, 1)
        }
      }

      return
    }

    case SET_NEW_OFFER_STEP_CONDITION: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].condition.values = action.data.values
      return
    }

    case SET_NEW_OFFER_STEP_TYPE: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].type = action.data.type
      draft.steps[stepIndex].endChat = false
      return
    }

    case SET_NEW_OFFER_STEP_SENTENCE: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].sentence = action.data.sentence
      return
    }

    case SET_NEW_OFFER_STEP_END_CHAT: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].endChat = action.data.endChat
      return
    }

    case SET_NEW_OFFER_STEP_VARIABLE: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].variable = action.data.variable
      return
    }

    case SET_NEW_OFFER_STEP_WEIGHT: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].weight = action.data.weight
      return
    }

    case SET_NEW_OFFER_STEP_VIDEO_URL: {
      const { id, videoUrl } = action.data
      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].videoUrl = videoUrl
      return
    }

    case SET_NEW_OFFER_STEP_ATTACHMENT_TYPE: {
      const { id, attachmentType } = action.data
      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].attachmentType = attachmentType
      return
    }

    case ADD_NEW_OFFER_STEP_OPTION: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.stepId)
      draft.steps[stepIndex].options.push({
        _id: mongoObjectId(),
        label: '',
        value: '',
        score: 0,
        dealbreaker: false,
      })
      return
    }
    case SET_NEW_OFFER_STEP_OPTIONS: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.stepId)
      draft.steps[stepIndex].options = action.options
      return
    }

    case REMOVE_NEW_OFFER_STEP_OPTION: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].options.splice(action.data.optionIndex, 1)
      return
    }

    case SET_NEW_OFFER_STEP_OPTION_LABEL: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].options[action.data.optionIndex].label =
        action.data.label
      draft.steps[stepIndex].options[action.data.optionIndex].value =
        action.data.label
      return
    }

    case SET_NEW_OFFER_STEP_OPTION_VALUE: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].options[action.data.optionIndex].value =
        action.data.value
      return
    }

    case SET_NEW_OFFER_STEP_OPTION_SCORE: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].options[action.data.optionIndex].score =
        action.data.score
      return
    }

    case SET_NEW_OFFER_STEP_OPTION_DEALBREAKER: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].options[action.data.optionIndex].dealbreaker =
        action.data.dealbreaker
      return
    }

    case ADD_NEW_OFFER_STEP_OPTION_NUMBER: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)

      draft.steps[stepIndex].numberScores.push({
        minValue: action.data?.min || 0,
        maxValue: action.data?.max || 0,
        score: action.data?.score || 0,
        dealbreaker: false,
      })
      return
    }

    case REMOVE_NEW_OFFER_STEP_OPTION_NUMBER: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].numberScores.splice(action.data.scoreIndex, 1)
      return
    }

    case SET_NEW_OFFER_STEP_OPTION_NUMBER_MIN: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].numberScores[action.data.scoreIndex].minValue =
        action.data.min
      return
    }

    case SET_NEW_OFFER_STEP_OPTION_NUMBER_MAX: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].numberScores[action.data.scoreIndex].maxValue =
        action.data.max
      return
    }

    case SET_NEW_OFFER_STEP_OPTION_NUMBER_SCORE: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].numberScores[action.data.scoreIndex].score =
        action.data.score
      return
    }

    case SET_NEW_OFFER_STEP_OPTION_NUMBER_DEALBREAKER: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].numberScores[action.data.scoreIndex].dealbreaker =
        action.data.dealbreaker
      return
    }

    case ADD_NEW_OFFER_STEP_OPTION_DATE: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.stepId)
      if (draft.steps[stepIndex].dateScores === undefined) {
        draft.steps[stepIndex].dateScores = []
      }
      draft.steps[stepIndex].dateScores.push({
        minValue: new Date(),
        maxValue: new Date(),
        score: 5, // The score initialized here have to be present in the available values in the date scoring options to prevent a difference between the displayed value to the user and the value stored
        dealbreaker: false,
      })
      return
    }

    case REMOVE_NEW_OFFER_STEP_OPTION_DATE: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].dateScores.splice(action.data.scoreIndex, 1)
      return
    }

    case SET_NEW_OFFER_STEP_OPTION_DATE_START: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].dateScores[action.data.scoreIndex].startValue =
        action.data.start
      return
    }

    case SET_NEW_OFFER_STEP_OPTION_DATE_END: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].dateScores[action.data.scoreIndex].endValue =
        action.data.end
      return
    }

    case SET_NEW_OFFER_STEP_OPTION_DATE_SCORE: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].dateScores[action.data.scoreIndex].score =
        action.data.score
      return
    }

    case SET_NEW_OFFER_STEP_OPTION_DATE_DEALBREAKER: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].dateScores[action.data.scoreIndex].dealbreaker =
        action.data.dealbreaker
      return
    }

    case SET_NEW_OFFER_STEPS: {
      draft.steps = action.steps
      return
    }

    case SET_NEW_OFFER_SPECIFIC_STEP: {
      const { id, step } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)

      draft.steps[stepIndex] = {
        ...draft.steps[stepIndex],
        ...{
          name: step.name,
          type: step.type,
          sentence: step.sentence,
          dateScores: step.dateScores,
          endChat: step.endChat,
          numberScores: step.numberScores,
          options: step.options,
          requirement: step.requirement,
          requirement_id: step.requirement_id,
          skill: step.skill,
          skill_id: step.skill_id,
          weight: step.weight,
          variable: step.variable,
        },
      }

      return
    }

    case ADD_NEW_OFFER_STEP: {
      draft.steps.push(action.data)
      if (action.data.skill_id !== undefined) {
        draft.skills.push(action.data.skill_id)
      }
      if (action.data.requirement_id !== undefined) {
        draft.requirements.push(action.data.requirement_id)
      }
      return
    }

    case REMOVE_NEW_OFFER_STEP: {
      const { id, requirementId, skillId } = action.data
      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)

      if (
        draft.steps[stepIndex].condition &&
        draft.steps[stepIndex].condition.step_id
      ) {
        const numberOfChildren = draft.steps.filter((e) => {
          if (e.condition && e.condition.step_id) {
            if (
              e.condition.step_id === draft.steps[stepIndex].condition.step_id
            ) {
              return e
            }
            return false
          }
          return false
        })

        if (numberOfChildren.length <= 1) {
          const parentStepIndex = draft.steps
            .map((e) => e._id)
            .indexOf(draft.steps[stepIndex].condition.step_id)
          if (parentStepIndex >= 0) {
            draft.steps[parentStepIndex].hasChild = false
          }
        }
      }

      draft.steps.splice(stepIndex, 1)

      if (skillId !== null) {
        const skillIndex = draft.skills.indexOf(skillId)
        if (skillIndex >= 0) draft.skills.splice(skillIndex, 1)
      }
      if (requirementId !== null) {
        const requirementIndex = draft.requirements.indexOf(requirementId)
        if (requirementIndex >= 0)
          draft.requirements.splice(requirementIndex, 1)
      }
      return
    }

    case SET_ACTIONS: {
      draft.actions = action.actions
      break
    }

    case SET_TAGS: {
      draft.tags = action.tags
      break
    }

    case SET_PROCESS_STATUS: {
      draft.processStatus = action.processStatus
      break
    }

    default: {
    }
  }
}, initialState)
