import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import AuthService from '../../../services/AuthService'
import { reactSelectStyles } from '../../../services/utils'
import ChatSVG from '../../../assets/hollo/chat.svg'
import Loader from '../../Loader'

import * as s from './styles'

const Auth = new AuthService()

const SendReactivation = ({ candidate, closeModal }) => {
  const { t } = useTranslation(['common'], {
    useSuspense: false,
  })
  const abortController = new AbortController()
  const [selectedReactivation, setSelectedReactivation] = useState(null)
  const [status, setStatus] = useState('fetching')
  const [reactivations, setReactivations] = useState([])

  useEffect(() => {
    Auth.fetch(`/candidate-pools/${candidate.candidatePool_id}`, {
      signal: abortController.signal,
    })
      .then((candidatePool) => {
        const data = candidatePool.workflows
        setReactivations(
          data.map((action) => ({
            value: action._id,
            label: action.name,
          })),
        )
        setStatus('fetched')
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  const sendReactivation = async () => {
    try {
      await Auth.fetch('/daemon-reactivation', {
        method: 'POST',
        body: JSON.stringify({
          candidateId: candidate._id,
          candidatePoolId: candidate.candidatePool_id,
          actionId: selectedReactivation.value,
        }),
        signal: abortController.signal,
      })
      toast.success(
        t(
          'reactivation:createReactivation.toast.success',
          'La réactivation a bien été créée',
        ),
      )
      closeModal()
    } catch (e) {
      console.error(e)
      toast.error(
        t(
          'reactivation:createReactivation.toast.error',
          'Erreur lors de la création de la réactivation',
        ),
      )
    }
  }

  if (status === 'fetching') {
    return <Loader />
  }
  return (
    <s.SendReactivationWrapper>
      <img
        src={ChatSVG}
        alt="chat"
        height={220}
        style={{ margin: '40px auto', display: 'block' }}
      />

      <div>
        <label htmlFor="select-offer">
          {t(
            'reactivation:selectReactivation.label',
            'Sélectionner la réactivation',
          )}
        </label>
        <Select
          closeMenuOnSelect
          className="react-select"
          classNamePrefix="react-select"
          value={selectedReactivation}
          options={reactivations}
          onChange={(e) => setSelectedReactivation(e)}
          placeholder={t(
            'reactivation:selectReactivation.placeholder',
            'Sélectionner la réactivation',
          )}
          styles={reactSelectStyles}
        />
      </div>
      <div
        className="btns-container"
        style={{
          marginTop: '20px',
        }}
      >
        <Button className="basic-btn" onClick={sendReactivation}>
          {t('common:send')}
        </Button>
      </div>
    </s.SendReactivationWrapper>
  )
}

export default SendReactivation
