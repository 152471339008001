import { Dropdown, Menu } from 'antd'
import { EditorState, Modifier } from 'draft-js'
import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { RiArrowDownSFill } from 'react-icons/ri'
import { nanoid } from 'nanoid'
import { stateFromHTML } from 'draft-js-import-html'
import { getSelectedBlock } from 'draftjs-utils'
import * as s from './styles'
import { suggestions } from '../../services/utils'
import './styles.css'
/**
 * Renders a new button in the editor's toolbar which lists
 * all the suggestions we send through
 * @param {array} suggestions list
 * @param {function} editorState implicit comes when using toolbarCustomButtons property
 * @param {function} onChange implicit comes when using toolbarCustomButtons property
 * @returns React.component
 */

const Suggestions = ({ suggestion, editorState, onChange }) => {
  // adds to editor state the value of the dropdown item
  const handleClick = (el) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `@${el.text}`,
      ['BOLD'],
    )
    onChange(EditorState.push(editorState, contentState, 'insert-characters'))
  }

  // Renders the menu
  const menu = (
    <Menu>
      {suggestion.map((el) => (
        <Menu.Item key={nanoid()} onClick={() => handleClick(el)}>
          {el.text}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <s.WrapperDropdown>
      <Dropdown
        overlay={menu}
        trigger={['click']}
        overlayClassName="dropdown-suggestions"
      >
        <div className="dropdown-label">
          <span>Mentions</span>
          <RiArrowDownSFill fontSize={20} />
        </div>
      </Dropdown>
    </s.WrapperDropdown>
  )
}

export default function RichEditor({
  editorState,
  setEditorState,
  withChatbot = false,
  withOffer = false,
  specialSuggestions,
  mentions = true,
}) {
  const toolbarCustomButtons = []
  let activeSuggestions

  if (mentions) {
    if (specialSuggestions) {
      activeSuggestions = specialSuggestions
    } else {
      activeSuggestions = suggestions({
        withChatbot,
        withOffer,
      }).map((e) => ({ text: e.display, value: e.display }))
    }

    toolbarCustomButtons.push(<Suggestions suggestion={activeSuggestions} />)
  }

  return (
    <Editor
      handlePastedText={(text, html, editorState, onChange) => {
        const selectedBlock = getSelectedBlock(editorState)
        if (selectedBlock && selectedBlock.type === 'code') {
          const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            text,
            editorState.getCurrentInlineStyle(),
          )
          onChange(
            EditorState.push(editorState, contentState, 'insert-characters'),
          )
          return true
        }
        if (html) {
          const { blockMap } = stateFromHTML(html)
          const newState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            blockMap,
          )
          onChange(EditorState.push(editorState, newState, 'insert-fragment'))
          return true
        }
        return false
      }}
      editorState={editorState}
      onEditorStateChange={setEditorState}
      toolbarCustomButtons={toolbarCustomButtons}
      toolbar={{
        // https://jpuri.github.io/react-draft-wysiwyg/#/docs
        options: [
          // 'history',
          'inline',
          // 'blockType',
          'colorPicker',
          'fontSize',
          'fontFamily',
          'textAlign',
          'link',
          'list',
          'emoji',
        ],
        inline: {
          inDropdown: false,
          options: [
            'bold',
            'italic',
            'underline',
            // 'strikethrough',
            // 'monospace',
          ],
        },
        blockType: {
          inDropdown: true,
          options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
        },
        fontSize: {
          options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        },
        list: {
          inDropdown: false,
          options: ['unordered', 'ordered', 'indent', 'outdent'],
        },
        textAlign: {
          inDropdown: true,
          options: ['left', 'center', 'right', 'justify'],
        },
        link: {
          inDropdown: true,
          showOpenOptionOnHover: true,
          defaultTargetOption: '_self',
          options: ['link', 'unlink'],
        },
      }}
      toolbarStyle={{}}
      editorStyle={{
        overflow: 'auto',
        resize: 'vertical',
        width: '100%',
        border: '1px solid #e7e7e7',
        borderRadius: '8px',
        padding: '10px',
        height: '250px',
        overflowY: 'auto',
        fontSize: 14,
      }}
      mention={
        mentions
          ? {
              separator: ' ',
              trigger: '@',
              suggestions: specialSuggestions,
            }
          : undefined
      }
    />
  )
}
