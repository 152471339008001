import { css } from 'styled-components'

/* eslint-disable import/prefer-default-export */
// Define breakpoints based on application needs
const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
}

export const devices = {
  mobileS: `(min-width: ${sizes.mobileS})`,
  mobileM: `(min-width: ${sizes.mobileM})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,
}

export const mobileS = (props) => css`
  @media only screen and (max-width: ${sizes.mobileS}) {
    ${props}
  }
`

export const mobileM = (props) => css`
  @media only screen and (max-width: ${sizes.mobileM}) {
    ${props}
  }
`

export const mobileL = (props) => css`
  @media only screen and (max-width: ${sizes.mobileL}) {
    ${props}
  }
`

export const tablet = (props) => css`
  @media only screen and (max-width: ${sizes.tablet}) {
    ${props}
  }
`
