import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const EditCandidateFormWrapper = styled.div`
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
`
