/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Empty, Tabs, Tag, Timeline } from 'antd'
import { capitalize } from 'lodash'
import { nanoid } from 'nanoid'
import { array, func, string } from 'prop-types'
import React, { Suspense, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsCheckCircleFill } from 'react-icons/bs'
import { MdReadMore } from 'react-icons/md'
import { useMutation, useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import AuthService from '../../services/AuthService'
import { FeathersContext } from '../../services/feathers'
import { computeElapsed, makeToastError } from '../../services/utils'
import CandidateScoringTable from '../Candidate/CandidateScoringTable'
import ConversationSpider from '../Candidate/ConversationSpider'
import ScoreCircle from '../Candidate/ScoreCircle'
import Loader from '../Loader'
import Modal from '../Modal'
import OfferDetails from '../OfferDetails'
import TinderBtns from '../TinderBtns'
import * as styled from './styles'

const Auth = new AuthService()

const { TabPane } = Tabs

/**
 * AtsApplicationDetails component containing the details of an ATS (only Lever) application
 * @param {object} details Details of the ats application
 */
const AtsApplicationDetails = ({ details }) => {
  const { t } = useTranslation(['common', 'talent'], {
    useSuspense: false,
  })

  if (!details) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Empty description={t('talent:atsApplicationNotFound')} />
      </div>
    )
  }

  const { archived, stage, stageChanges, createdAt, lastInteractionAt, urls } =
    details

  const now = new Date().getTime() / 1000
  const creationDate = new Date(createdAt).getTime() / 1000
  const lastInteractionDate = new Date(lastInteractionAt).getTime() / 1000

  const elapsedCreationTime = computeElapsed(now, creationDate)
  const elapsedLastInteractionTime = computeElapsed(now, lastInteractionDate)

  let state = stage?.text

  if (archived) {
    state = t('common:archived')

    stageChanges.push({
      toStageId: t('common:archived'),
      updatedAt: archived.archivedAt,
    })
  }

  return (
    <>
      <Tag style={{ fontSize: '20px', padding: '10px' }}>{state}</Tag>
      <div
        className="pull-right"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <span style={{ textTransform: 'lowercase' }}>
          {t('common:created')}{' '}
          <strong>
            {t('common:notification.elapsedTime', {
              time: `${elapsedCreationTime}`,
            })}
          </strong>
        </span>
        <span style={{ textTransform: 'lowercase' }}>
          {t('common:updated')}{' '}
          <strong>
            {t('common:notification.elapsedTime', {
              time: `${elapsedLastInteractionTime}`,
            })}
          </strong>
        </span>
      </div>

      <Timeline style={{ marginTop: '40px' }}>
        {stageChanges.reverse().map((stageChange, index) => {
          const activityDate = new Date(stageChange.updatedAt).getTime() / 1000

          const elapsedTime = computeElapsed(now, activityDate)

          return (
            <Timeline.Item key={nanoid()} color="blue">
              <p>
                <span>
                  <strong>{t('common:atsUser')}</strong>
                </span>
                <span> {t('talent:activity.moveApplication')} </span>
                <strong>{stageChange.toStageId}</strong>{' '}
                <span style={{ textTransform: 'lowercase' }}>
                  {t('common:notification.elapsedTime', {
                    time: `${elapsedTime}`,
                  })}
                </span>
              </p>
            </Timeline.Item>
          )
        })}
      </Timeline>
      <Button
        className="blue-btn"
        style={{ width: '100%' }}
        onClick={() => window.open(urls?.show, '_blank')}
      >
        {t('talent:seeMoreAts')}
        <MdReadMore style={{ marginLeft: '10px' }} />
      </Button>
    </>
  )
}

/**
 * AtsApplication component made to display a card for an ATS application
 * @param {object} application Application object
 * @param {string} externalRef Id of the candidate in the ATS
 */
const AtsApplication = ({ application, externalRef }) => {
  const { t } = useTranslation(['common', 'talent'], {
    useSuspense: false,
  })

  const feathers = useContext(FeathersContext)

  const [detailsVisible, setDetailsVisible] = useState(false)

  const { data: details, isFetching } = useQuery({
    queryKey: [detailsVisible],
    queryFn: async () => {
      if (detailsVisible) {
        const res = await feathers.service('lever').find({
          query: {
            type: 'opportunities',
            params: {
              contact_id: externalRef,
              expand: [
                'applications',
                'stage',
                'owner',
                'followers',
                'sourcedBy',
              ],
            },
          },
        })

        const { data } = res

        // find the right opportunity in the array
        // need to use this instead of passing the posting_id parameter
        // because Lever doesn't return the opportunity if we pass the
        // posting_id parameter and the opportunity is archived
        for (const opportunity of data) {
          const { applications = [] } = opportunity

          if (
            applications.map((e) => e.posting).includes(application.externalRef)
          ) {
            return opportunity
          }
        }

        return null
      }
    },
    refetchOnWindowFocus: false,
  })

  return (
    <>
      <div
        role="button"
        tabIndex={-1}
        className="candidate-application-list-item"
        onClick={() => {
          setDetailsVisible(true)
        }}
      >
        <span
          style={{
            fontSize: '12px',
            marginRight: 20,
            opacity: '.6',
            lineHeight: '60px',
          }}
        >
          {t('talent:fromAts')}
        </span>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontSize: '13px', fontWeight: '700' }}>
            {application.offerName}
          </div>
        </div>
      </div>
      <Modal
        visible={detailsVisible}
        title={application.offerName}
        onCancel={() => setDetailsVisible(false)}
        footer={null}
      >
        <>
          {isFetching ? (
            <Loader />
          ) : (
            <AtsApplicationDetails details={details} />
          )}
        </>
      </Modal>
    </>
  )
}

/**
 * Application component made to display a card for hollo application
 * @param {object} application Application object
 */
const Application = ({ application, processStatus, onClose }) => {
  const { t } = useTranslation(['common', 'talent', 'reactivation'], {
    useSuspense: false,
  })

  const abortController = new AbortController()

  const user = useSelector((state) => state.user)
  const featuresAccess = user.company_id?.featuresAccess || ['crm']

  const [offer, setOffer] = useState(null)
  const [detailsVisible, setDetailsVisible] = useState(false)
  const [hired, setHired] = useState(null)

  const { classification, processStatus_id, offer_id } = application
  const score = Math.round(application.score * 100)

  useEffect(() => {
    ;(async () => {
      if (application.offer?._id) {
        try {
          const offer = await Auth.fetch(
            `/offers/${application.offer._id}?$populate[0]=team_id&$populate[1]=user_id&$populate[2]=skills&addAnalyticsConversations=true&disableSoftDelete=true`,
            { signal: abortController.signal },
          )
          setOffer(offer)
        } catch (err) {
          if (err.name !== 'NotFound') {
            throw err
          }
        }
      }
    })()
  }, [application.offer?._id])

  useEffect(() => {
    const { hirings } = application?.offer

    if (hirings && hirings.length) {
      const hired = hirings.filter(
        (hiring) => hiring.conversation_id === application._id,
      )

      if (hired.length) {
        setHired(hired[hired.length - 1])
      }
    }
  }, [])

  if (!offer) {
    return ''
  }

  let statusName = t('common:processStatusOptions.toProcess')
  let statusColor = 'default'

  if (classification === 'NOT_SELECTED') {
    statusName = t('common:processStatusOptions.notSelected')
    statusColor = 'error'
  } else if (classification === 'SELECTED') {
    statusName = t('common:processStatusOptions.selected')
    statusColor = 'success'

    const index = processStatus.map((e) => e._id).indexOf(processStatus_id)

    if (index >= 0) {
      statusName = processStatus[index].name
    }
  }

  const dealbreakers = application.scoringVariables.filter(
    (cv) => cv.dealbreaker,
  )
  const numberOfDealbreakers = dealbreakers.length

  return (
    <>
      <div
        role="button"
        tabIndex={-1}
        onClick={() => {
          if (featuresAccess.includes('crm')) {
            setDetailsVisible(true)
          }
        }}
        className="candidate-application-list-item"
      >
        {score ? (
          <div
            style={{ width: '50px', marginRight: '20px', lineHeight: '60px' }}
          >
            <ScoreCircle
              conversation={{ ...application, score }}
              style={{ size: '50', lineWidth: '20' }}
            />
          </div>
        ) : (
          <span
            style={{
              fontSize: '12px',
              marginRight: 20,
              opacity: '.6',
              lineHeight: '20px',
            }}
          >
            {t('common:noData')}
          </span>
        )}

        <div className="flex column">
          <div
            className="flex items-center"
            style={{ fontSize: '13px', fontWeight: '700', gap: 5 }}
          >
            {offer.name}{' '}
            {featuresAccess.includes('crm') && !hired && (
              <Tag className="basic-tag">{statusName}</Tag>
            )}
            {hired && (
              <div className="flex items-center" style={{ gap: 5 }}>
                <Tag className="basic-tag">
                  {t('reactivation:pipelines.stage.hired')}
                </Tag>
                <BsCheckCircleFill size={20} color="var(--success)" />
              </div>
            )}
          </div>
          <span style={{ fontStyle: 'italic', fontSize: 'smaller' }}>
            {new Date(application.createdAt).toLocaleDateString()}
          </span>
        </div>
      </div>

      <Modal
        visible={detailsVisible}
        toggle={() => setDetailsVisible(false)}
        onCancel={() => setDetailsVisible(false)}
        width={800}
        footer={null}
      >
        <Tabs defaultActiveKey={score ? '1' : '2'}>
          {score && (
            <TabPane
              tab={t('talent:applicationCard.tabs.prequalification')}
              key="1"
            >
              <>
                <ConversationSpider
                  data={offer?.analytics?.scoringVariables}
                  conversation={application}
                />
                {numberOfDealbreakers > 0 && (
                  <p
                    style={{
                      fontSize: '12px',
                      fontWeight: '600',
                      color: '#d92550',
                    }}
                  >
                    <FontAwesomeIcon
                      style={{
                        fontSize: '13px',
                        cursor: 'pointer',
                        color: '#d92550',
                      }}
                      icon={faExclamationTriangle}
                    />{' '}
                    <span>
                      <span>{numberOfDealbreakers}</span> Dealbreaker
                    </span>
                  </p>
                )}
                <CandidateScoringTable conversation={application} />
              </>
            </TabPane>
          )}
          <TabPane tab={t('common:details')} key="2">
            <OfferDetails
              offer={offer}
              currentPage="offer-list"
              toggle={() => setDetailsVisible(false)}
              onClose={onClose}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  )
}

/**
 * Like component made to display a card for a like
 * @param {object} like Like object
 */
export const Like = ({ like, refetchCandidate }) => {
  const { t } = useTranslation(['talent'], {
    useSuspense: false,
  })

  const colors = {
    pending: '#a55eea',
    approved: '#20bf6b',
    rejected: '#ef9b9b',
  }

  const {
    _id: likeId,
    offer,
    lastCandidateUpdateAt,
    userStatus,
    candidateStatus,
  } = like

  const feathers = useContext(FeathersContext)

  const updateLikeStatus = useMutation(
    (status) =>
      feathers.service('likes').patch(likeId, {
        userStatus: status,
        lastUserUpdateAt: new Date(),
      }),
    {
      onSuccess: () => {
        refetchCandidate()
      },
      onError: (error) => {
        makeToastError(t('talent:updateTags.toasts.error'), error)
      },
    },
  )

  const fontWeight = 600
  const hasMatched = userStatus === 'approved' && candidateStatus === 'approved'
  const userIsPending = userStatus === 'pending'

  return (
    <>
      <div
        className="candidate-like-list-item"
        style={{ borderColor: hasMatched ? '#60cc8a' : '#00000005' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: userIsPending ? 'flex-end' : 'space-between',
            alignItems: 'center',
          }}
        >
          {!userIsPending && (
            <span
              style={{ color: colors[userStatus], fontSize: 10, fontWeight }}
            >
              {capitalize(userStatus)}
            </span>
          )}
          {hasMatched && (
            <span style={{ fontSize: 10, fontWeight }}>Matched!</span>
          )}
          <div>
            <span style={{ fontSize: 10 }}>Candidate: </span>
            <span
              style={{
                color: colors[candidateStatus],
                fontSize: 10,
                fontWeight,
              }}
            >
              {capitalize(candidateStatus)}
            </span>
          </div>
        </div>
        <Divider style={{ margin: '0px', padding: 0 }} />
        <div style={{ display: 'flex', width: '100%' }}>
          {!userIsPending && (
            <Divider
              type="vertical"
              className="vertical-divider"
              style={{ backgroundColor: colors[userStatus] }}
            />
          )}
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center',
              marginLeft: '10px',
            }}
          >
            <div style={{ fontSize: '13px', fontWeight: '700' }}>
              {offer.name}
            </div>
            {lastCandidateUpdateAt && (
              <span style={{ fontStyle: 'italic', fontSize: 'smaller' }}>
                {new Date(lastCandidateUpdateAt).toLocaleDateString()}
              </span>
            )}
          </div>
          {userIsPending && (
            <div className="tinder-btns-wrapper">
              <TinderBtns
                disabled={false}
                actions={{
                  rejected: () => updateLikeStatus.mutate('rejected'),
                  approved: () => updateLikeStatus.mutate('approved'),
                }}
                btnStyle={{
                  height: 40,
                  width: 40,
                  padding: 0,
                }}
              />
            </div>
          )}
          <Divider
            type="vertical"
            className="vertical-divider"
            style={{
              backgroundColor: colors[candidateStatus],
              justifySelf: 'flex-end',
            }}
          />
        </div>
      </div>
    </>
  )
}

/**
 * ListApplications component made to display a card for hollo application
 * @param {array} applications Array of hollo applications
 * @param {array} atsApplications Array of ats applications
 * @param {string} externalRef Id of the candidate in the ATS
 * @param {func} refetchCandidate function to refetch candidate
 * @param {array} processStatus Array of process status
 */
const ListApplications = ({
  applications,
  atsApplications,
  externalRef,
  refetchCandidate,
  processStatus,
  onClose,
}) => {
  const { t } = useTranslation(['common'], {
    useSuspense: false,
  })

  return (
    <Suspense fallback={<Loader text={t('common:loader.text')} />}>
      <styled.ListApplications>
        {applications.map((application) => (
          <Application
            application={application}
            key={application._id}
            processStatus={processStatus}
            onClose={onClose}
          />
        ))}
        <Divider />
        {atsApplications.map((atsApplication) => (
          <AtsApplication
            application={atsApplication}
            key={atsApplication._id}
            externalRef={externalRef}
          />
        ))}
      </styled.ListApplications>
    </Suspense>
  )
}

ListApplications.propTypes = {
  applications: array.isRequired,
  atsApplications: array.isRequired,
  externalRef: string,
  refetchCandidate: func.isRequired,
  processStatus: array.isRequired,
}

ListApplications.defaultProps = {
  externalRef: '',
}

export default React.memo(ListApplications)
