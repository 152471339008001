import styled from 'styled-components'

export const ResumeSliderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  margin-top: 10px;

  .resume-pdf-wrapper {
    max-height: 90vh;
    overflow: scroll;
  }
  .resume-pagination-wrapper {
    display: flex;
    position: absolute;
    top: -1rem;
    left: 5em;
    background-color: white;
    z-index: 999;

    span {
      margin: 5px;
    }
  }
  .slider-info {
    font-weight: 700;
    font-size: 12px;
    position: absolute;
    top: 5px;
    left: 5px;
  }
  .resume-slide-btn {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    border: none;
    background-color: #080e27;
    color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .react-pdf__Page__canvas {
    margin: auto;
  }

  .imgPreview {
    max-width: 100%;
    max-height: 100%;
  }
`
export const ModalTitle = styled.span``
export const ModalContentWrapper = styled.div``
