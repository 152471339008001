import React, { useState, useContext } from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { useMutation, useQuery } from 'react-query'
import { func, object, string } from 'prop-types'
import {
  makeToastError,
  makeToastSuccess,
  processStatusOptionsUtils,
  reactSelectStyles,
} from '../../../services/utils'
import Loader from '../../Loader'
import * as s from './styles'
import { FeathersContext } from '../../../services/feathers'
import { createConversation } from '../../../api/Conversation/api.conversation'

const propTypes = {
  candidate: object.isRequired,
  closeModal: func.isRequired,
  selectPosition: string,
}

const AddCandidateToCampain = ({
  setOffer,
  candidate,
  candidates,
  closeModal,
  refetchConversation,
  createCandidateModal,
  selectPosition = 'bottom',
}) => {
  const { t } = useTranslation(['reactivation', 'common', 'crm', 'talent'], {
    useSuspense: false,
  })

  const feathers = useContext(FeathersContext)

  const [selectedOffer, setSelectedOffer] = useState(null)
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const [sendProcessStatusOptions, setSendProcessStatusOptions] = useState(
    processStatusOptionsUtils(t),
  )
  const [sendSelectedProcessStatus, setSendSelectedProcessStatus] = useState(
    processStatusOptionsUtils(t)[0],
  )

  /**
   * Used to be a useEffect, it will filter campaigns the talent already sent an application to
   */
  const {
    refetch: refetchOffers,
    isLoading,
    data: availableOffers,
  } = useQuery({
    queryKey: ['availableOffers'],
    queryFn: async () => {
      const offers = await feathers.service('offers').find({
        query: {
          published: true,
          $populate: ['processStatus_id'],
          $limit: -1,
        },
      })

      if (candidate) {
        const offersCandidateAlreadyAppliedTo = candidate.conversations.map(
          (conversation) => conversation.offer_id?._id,
        )
        const filteredOffers = offers.filter(
          (offer) => !offersCandidateAlreadyAppliedTo.includes(offer._id),
        )
        return filteredOffers.map((offer) => ({
          ...offer,
          value: offer._id,
          label: offer.name,
          teamId: offer.team_id,
        }))
      }

      if (candidates?.length) {
        const existingOffers = candidates.reduce((acc, candidate) => {
          // For each conversations of each candidate, if not in object we create an entry with the offerId
          // Then in any case we add the candidateId to a list to have all the applications for each offers
          candidate.conversations.forEach((conv) => {
            const { _id, name } = conv.offer
            if (!acc[_id]) {
              acc[_id] = {}
              acc[_id].name = name
              acc[_id].candidates = []
            }

            acc[_id].candidates.push(candidate._id)
          })
          return acc
        }, {})

        // Then we filter to keep only the offers nobody already applied to
        const nobodyAppliedOffers = offers.reduce((acc, offer) => {
          if (!existingOffers[offer._id]) {
            acc.push({
              ...offer,
              value: offer._id,
              label: offer.name,
              teamId: offer.team_id,
            })
          }
          return acc
        }, [])

        return nobodyAppliedOffers
      }

      return offers.map((offer) => ({
        ...offer,
        value: offer._id,
        label: offer.name,
        teamId: offer.team_id,
      }))
    },
    refetchOnWindowFocus: false,
  })

  /**
   * Mutation to create an application in an active campaign
   * Creates the application/conversation then on success it will
   * reset states and refetch data to be up-to-date
   * In case of error we display a toast
   * In any case we set loadingBtn to false
   * @param {object} application/conversation to be created
   */
  const applicationMutation = useMutation(
    ({ candidate, offer, status }) => {
      if (!candidate || !offer) {
        return
      }

      const listCandidate = candidate?.length ? candidate : [candidate]
      const promises = listCandidate.map((el) =>
        createConversation({
          candidate: el,
          offer,
          status: 'NOT_CLASSIFIED',
          createdFrom: 'manual',
        }),
      )

      return Promise.all(promises)
    },
    {
      onSuccess: () => {
        makeToastSuccess(t('crm:modals.createCandidate.toast'), () => {
          setSelectedOffer(null)
          setSendSelectedProcessStatus(sendProcessStatusOptions[0])
          if (refetchConversation) {
            refetchConversation()
          }
          refetchOffers()
          closeModal()
        })
      },
      onError: (error) => {
        makeToastError(
          t('crm:modals.createApplication.toast.error'),
          error,
          null,
        )
      },
    },
  )

  // const multipleApplicationMutation = useMutation(
  //   ({ candidates, offer, status }) => {
  //     const promises = candidates.map((candidate) =>
  //       feathers.service('conversations').create({
  //         candidate_id: candidate._id,
  //         company_id: candidate.company_id,
  //         // hack to fix problem in addCandidateToCampaign offer has value as _id
  //         offer_id: offer.value,
  //         team_id: offer.team_id,
  //         createdBy: 'user',
  //         classification: status,
  //       }),
  //     )
  //     return Promise.all(promises)
  //   },
  //   {
  //     onSuccess: () => {
  //       makeToastSuccess(t('crm:modals.createCandidate.toast'), () => {
  //         setSelectedOffer(null)
  //         setSendSelectedProcessStatus(sendProcessStatusOptions[0])
  //         if (refetchConversation) {
  //           refetchConversation()
  //         }
  //         refetchOffers()
  //         closeModal()
  //       })
  //     },
  //     onError: (error) => {
  //       makeToastError(
  //         t('crm:modals.createApplication.toast.error'),
  //         error,
  //         null,
  //       )
  //     },
  //   },
  // )

  /* RH-988 - we decided to always send to not classified in case there is an action 
      linked to a processStatus
      We plan to make the transfer of candidates betwen status more centrale
      At this point we could reactivate this feature */
  /**
   * It will check if campaign has more status than default ones, if so it will add them to the list
   */
  // useEffect(() => {
  //   // Using the reference object from utils
  //   const processStatus = processStatusOptionsUtils(t)

  //   if (selectedOffer) {
  //     const offerIndex = availableOffers
  //       .map((offer) => offer._id)
  //       .indexOf(selectedOffer.value)

  //     if (offerIndex >= 0) {
  //       processStatus.push(
  //         ...availableOffers[offerIndex].processStatus_id.map((status) => ({
  //           label: status.name,
  //           value: status._id,
  //           actions: status.actions,
  //         })),
  //       )
  //     }
  //   }

  //   setSendProcessStatusOptions(processStatus)
  // }, [selectedOffer])
  // It's for setting the value of chosen campaign when creating candidate from navbar
  const handleCallbackOffer = (e) => {
    if (createCandidateModal) {
      setOffer(e)
    }
  }

  if (isLoading) {
    return <Loader />
  }
  return (
    <s.AddCandidateToCampainWrapper data-cy="add-candidate-to-campaign-modal">
      <div>
        <label htmlFor="select-offer">{t('common:selectCampaign')}</label>
        <Select
          closeMenuOnSelect
          className="react-select"
          classNamePrefix="react-select"
          value={selectedOffer}
          options={availableOffers}
          menuPlacement={selectPosition}
          onChange={(e) => {
            setSelectedOffer(e)
            handleCallbackOffer(e)
          }}
          placeholder={t('common:sidebar.campaigns')}
          styles={reactSelectStyles}
        />
      </div>

      {/* RH-988 - we decided to always send to not classified in case there is an action 
      linked to a processStatus
      We plan to make the transfer of candidates betwen status more centrale
      At this point we could reactivate this feature */}
      {/* {selectedOffer && (
        <div className="selected-offer">
          <label htmlFor="select-offer">
            {t('talent:applicationCard.sendToCampaign.defineStatus.label')}
          </label>
          <Select
            className="react-select"
            classNamePrefix="react-select"
            name="select-offer"
            closeMenuOnSelect
            value={sendSelectedProcessStatus}
            options={sendProcessStatusOptions}
            onChange={(e) => setSendSelectedProcessStatus(e)}
            placeholder={t(
              'talent:applicationCard.sendToCampaign.defineStatus.select.placeholder',
            )}
            styles={reactSelectStyles}
          />

        </div>
      )} */}
      {/* When create candidate from Navbar button we call the Select above without the buttons cancel/send */}
      {!createCandidateModal && (
        <div className="btns-container " style={{ marginTop: 10 }}>
          <Button className="grey-btn" onClick={closeModal}>
            {t('common:cancel')}
          </Button>
          <Button
            className="basic-btn"
            onClick={() =>
              applicationMutation.mutate({
                candidate: candidate || candidates,
                offer: selectedOffer,
                status: sendSelectedProcessStatus.value,
              })
            }
            loading={applicationMutation.isLoading}
            disabled={!selectedOffer}
          >
            {t('common:send')}
          </Button>
        </div>
      )}
    </s.AddCandidateToCampainWrapper>
  )
}

AddCandidateToCampain.propTypes = propTypes
export default AddCandidateToCampain
