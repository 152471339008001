import { Button, Popover } from 'antd'
import React from 'react'
import TagsSelect from '../TagsSelect'
import * as s from './styles'

const createTag = (tag) => ({
  label: tag,
  value: tag,
})

const TagSelectWithPopover = (props) => {
  const { tags } = props
  return (
    <s.WrapperTagSelectWithPopover>
      <Popover
        content={<TagsSelect {...props} />}
        trigger="click"
        placement="bottom"
        overlayStyle={{ width: 500 }}
      >
        <Button
          className="grey-btn"
          style={{
            height: 41,
            display: 'flex',
            fontSize: 13,
            placeItems: 'center',
            padding: '0.4em .5em',
          }}
        >
          Tags ({tags ? tags.length : 0})
        </Button>
      </Popover>
    </s.WrapperTagSelectWithPopover>
  )
}

export default TagSelectWithPopover
