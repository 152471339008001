import auth from '@feathersjs/authentication-client'
import feathers from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'
import localForage from 'localforage'
import React, { createContext } from 'react'

export const app = feathers()
const restClient = rest(
  process.env.REACT_APP_API_URL || 'http://localhost:3000',
).fetch(window.fetch)

app.configure(restClient)
app.configure(
  auth({
    storage: window.localStorage,
    storageKey: 'id_token',
  }),
)

const addJWT =
  (options = {}) =>
  async (context) => {
    context.params.headers = {
      ...context.params.headers,
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    }

    return context
  }

const updateJWT =
  (options = {}) =>
  async (context) => {
    const { result } = context

    if (result.newAccessToken) {
      app.authentication.setAccessToken(result.newAccessToken)
      localForage.setItem('id_token', result.newAccessToken)

      context.result = result.data
    }

    return context
  }

app.hooks({
  before: {
    all: [addJWT()],
  },
  after: {
    all: [updateJWT()],
  },
})

export const FeathersContext = createContext(null)

// eslint-disable-next-line import/prefer-default-export
export const FeathersProvider = ({ children }) => (
  <FeathersContext.Provider value={app}>{children}</FeathersContext.Provider>
)
