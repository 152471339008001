import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Circle from 'react-circle'
import { scoreClasses } from '../../../services/utils'

const proptypes = {
  conversation: PropTypes.shape({
    score: PropTypes.number,
  }).isRequired,
  style: PropTypes.shape({
    lineWidth: PropTypes.string,
    size: PropTypes.string,
  }).isRequired,
}

const ScoreCircle = ({ conversation, style }) => {
  const [scoreIndex, setScoreIndex] = useState(0)

  useEffect(() => {
    for (let i = 0; i < scoreClasses.length; i += 1) {
      if (conversation.score > scoreClasses[i].valueMax) {
        setScoreIndex((state) => state + 1)
      }
    }

    return () => setScoreIndex(0)
  }, [conversation, scoreClasses])

  if (!conversation) {
    return null
  }

  return (
    <Circle
      animate
      responsive={false} // Boolean: Make SVG adapt to parent size@&
      size={style.size} // String: Defines the size of the circle.
      lineWidth={style.lineWidth} // String: Defines the thickness of the circle's stroke.
      progress={conversation.score} // String: Update to change the progress and percentage.
      progressColor={scoreClasses[scoreIndex].colorClass} // String: Color of "progress" portion of circle.
      bgColor="#ecedf0" // String: Color of "empty" portion of circle.
      textColor={scoreClasses[scoreIndex].colorClass} // String: Color of percentage text color.
      textStyle={{
        fontFamily: '',
        font: "700 7.5rem 'Open Sans', Arial, sans-serif",
      }} // CSSProperties: Custom styling for percentage. }}
      roundedStroke // Boolean: Rounded/Flat line ends
      showPercentage // Boolean: Show/hide percentage.
      showPercentageSymbol={false}
    />
  )
}
ScoreCircle.propTypes = proptypes

export default ScoreCircle
