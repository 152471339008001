import produce from 'immer'

import {
  SET_TEXT_SEARCH,
  SET_CURSOR,
  SET_SORT,
} from '../actions/candidatesActions'

const initialState = {
  textSearch: [],
  cursor: 0,
  sortField: 'createdAt',
  sortOrder: -1,
}

export default produce((draft, action) => {
  switch (action.type) {
    case SET_TEXT_SEARCH: {
      draft.textSearch = action.text
      return
    }

    case SET_CURSOR: {
      draft.cursor = action.cursor
      return
    }

    case SET_SORT: {
      draft.sortField = action.data.sortField
      draft.sortOrder = action.data.sortOrder
      break
    }

    default: {
    }
  }
}, initialState)
