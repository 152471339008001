import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import toast from 'react-hot-toast'
import AuthService from '../../services/AuthService'
import LoadingRedirection from '../../assets/hollo/outerspace.svg'
import Loader from '../../components/Loader'
import { setUser } from '../../redux/actions/userActions'
import HelmetComponent from '../../components/Helmet'
import { isDomainJ2BD } from '../../services/utils'
import j2bdLogo from '../../assets/hollo/j2bd.svg'
import holloLogo from '../../assets/hollo/LogoLoginHollo.svg'

const Auth = new AuthService()

const CallbackStripe = () => {
  const abortController = new AbortController()

  const dispatch = useDispatch()

  const history = useHistory()

  const userId = localStorage.getItem('user_id')

  const [synchronizing, setSynchronizing] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      Auth.fetch(`/users/${userId}`, {
        signal: abortController.signal,
      })
        .then((user) => {
          setSynchronizing(false)
          dispatch(setUser(user))
          setTimeout(() => {
            history.push('/app')
          }, 5000)
        })
        .catch((err) => {
          console.error(err)
          const errString = 'Erreur lors du changement de plan'
          toast.error(errString)
          setSynchronizing(false)
          setError(true)
        })

      localStorage.removeItem('user_id')
    }, 3000)
  }, [])

  useEffect(() => () => abortController.abort(), [])

  if (error) {
    return 'Une erreur est survenue...'
  }
  return (
    <div
      style={{
        height: '100vh',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <HelmetComponent
        faviconUrl={isDomainJ2BD ? j2bdLogo : holloLogo}
        name={isDomainJ2BD ? 'J2BD Partners' : 'Hollo'}
      />
      {synchronizing ? (
        <Loader text="Synchronisation du compte, veuillez patienter..." />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <img
            height={200}
            src={LoadingRedirection}
            alt="loading"
            style={{ marginBottom: '20px' }}
          />
          <p style={{ fontSize: '14px', fontWeight: '600', marginTop: '40px' }}>
            Votre plan a bien été changé, vous allez être redirigé dans quelques
            secondes
          </p>
        </div>
      )}
    </div>
  )
}

export default CallbackStripe
