import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const SearchBarContainer = styled.div`
  .input-icons i {
    position: absolute;
  }

  .input-icons {
    width: 100%;
    margin-left: 87%;
  }

  .cross-icon{
    padding: 3px;
    margin-top: 2px;

    border-radius: 25%;
    
    :hover{
        cursor: pointer;
    }
`
