export const RESET_CONVERSATIONS_UI = 'RESET_CONVERSATIONS_UI'
export const SET_CONVERSATIONS_VIEW = 'SET_CONVERSATIONS_VIEW'
export const SET_CONVERSATIONS_CURSOR = 'SET_CONVERSATIONS_CURSOR'
export const SET_CONVERSATIONS_SORT = 'SET_CONVERSATIONS_SORT'

export const RESET_OFFERS_UI = 'RESET_OFFERS_UI'
export const SET_OFFERS_NAME = 'SET_OFFERS_NAME'
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE'
export const SET_OFFERS_CURSOR = 'SET_OFFERS_CURSOR'
export const SET_OFFERS_SORT = 'SET_OFFERS_SORT'
export const SET_OFFERS_ARCHIVED = 'SET_OFFERS_ARCHIVED'

export const SET_VIEW = 'SET_VIEW'

export const SET_ONLY_USER_OFFERS = 'SET_ONLY_USER_OFFERS'
export const SET_TEAMS_OFFERS = 'SET_TEAMS_OFFERS'
export const SET_USERS_OFFERS = 'SET_USERS_OFFERS'

export const resetConversationsUi = () => ({
  type: RESET_CONVERSATIONS_UI,
})

export const setConversationsView = (view) => ({
  type: SET_CONVERSATIONS_VIEW,
  view,
})

export const setConversationsCursor = (cursor) => ({
  type: SET_CONVERSATIONS_CURSOR,
  cursor,
})

export const setConversationsSort = (data) => ({
  type: SET_CONVERSATIONS_SORT,
  data,
})

export const resetOffersUi = () => ({
  type: RESET_OFFERS_UI,
})

export const setOffersName = (name) => ({
  type: SET_OFFERS_NAME,
  name,
})

export const setOffersCursor = (cursor) => ({
  type: SET_OFFERS_CURSOR,
  cursor,
})

export const setOffersSort = (data) => ({
  type: SET_OFFERS_SORT,
  data,
})

export const setOffersArchived = (offersDisplayArchived) => ({
  type: SET_OFFERS_ARCHIVED,
  offersDisplayArchived,
})

export const setOnlyUserOffers = (onlyUser) => ({
  type: SET_ONLY_USER_OFFERS,
  onlyUser,
})

export const setPageTitle = (pageTitle) => ({
  type: SET_PAGE_TITLE,
  pageTitle,
})
export const setTeamsOffers = (teams) => ({
  type: SET_TEAMS_OFFERS,
  teams,
})

export const setUsersOffers = (users) => ({
  type: SET_USERS_OFFERS,
  users,
})

export const setView = (view) => ({
  type: SET_VIEW,
  view,
})
