import React from 'react'
import UseAnimations from 'react-useanimations'
import PropTypes from 'prop-types'
import alertCircle from 'react-useanimations/lib/alertCircle'
import { Button, ModalBody, ModalFooter } from 'reactstrap'

import { Modal } from 'antd'

import { RiCloseFill } from 'react-icons/ri'
import * as styled from './styles'

const AlertConfirmModal = ({
  isOpen,
  toggle,
  onCancel,
  onConfirm,
  className,
  title,
  content,
  onCancelBtnText,
  onConfirmBtnText,
}) => (
  <Modal
    visible={isOpen}
    closeIcon={<RiCloseFill size={32} />}
    toggle={toggle}
    onCancel={toggle}
    footer={null}
    className={className}
  >
    <styled.ModalTitleWrapper>
      <UseAnimations animation={alertCircle} size={80} strokeColor="#d95762" />
      <styled.ModalTitle>{title}</styled.ModalTitle>
    </styled.ModalTitleWrapper>

    <ModalBody>
      <styled.ModalContentWrapper>{content}</styled.ModalContentWrapper>
    </ModalBody>
    <ModalFooter>
      {onCancel && (
        <Button className="grey-btn" onClick={onCancel}>
          {onCancelBtnText}
        </Button>
      )}
      <Button className="red-btn" onClick={onConfirm}>
        {onConfirmBtnText}
      </Button>{' '}
    </ModalFooter>
  </Modal>
)

AlertConfirmModal.propTypes = {
  content: PropTypes.element.isRequired,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onCancelBtnText: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onConfirmBtnText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

AlertConfirmModal.defaultProps = {
  className: '',
  isOpen: false,
  onCancel: null,
  onCancelBtnText: null,
}

export default AlertConfirmModal
