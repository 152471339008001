import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const SelectPoolStyles = styled.div`
  .visual {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;

    p {
      margin-top: 30px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .action-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 40px;
    gap: 10px;
  }
`
