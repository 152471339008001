import React from 'react'
import Helmet from 'react-helmet'

function HelmetComponent({ faviconUrl, name }) {
  return (
    <Helmet>
      <title>{name || ''}</title>
      <link rel="icon" type="image/png" href={faviconUrl} sizes="16x16" />
    </Helmet>
  )
}

export default HelmetComponent
