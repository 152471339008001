import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const ForgotPassword = styled.div`
  img {
    height: 22rem;
    margin: auto;
  }

  .ant-input {
    margin-top: 10px;
    height: 50px !important;
    display: block;
    opacity: 0.75;
    font-size: 16px;
    width: 100%;
    font-weight: 500;
    letter-spacing: 0.6px;
  }

  .ant-input:-webkit-autofill:focus {
    font-family: 'Open Sans', sans-serif !important;
    height: 50px !important;
    display: block;
    opacity: 0.75;
    font-size: 16px !important;
    font-weight: 500;
    letter-spacing: 0.6px;
    background-color: white !important;
  }

  .ant-btn {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    background-color: var(--primary);
    border: none;
    margin-bottom: 15px;
  }

  label {
    margin-bottom: 1.5rem;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
  }

  .inputError {
    position: absolute;
    color: #dc3545;
    font-weight: 600;
    font-size: 12px;
  }
`
