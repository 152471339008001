import { Button, Modal } from 'antd'
import { WarningCircle } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { pdfjs } from 'react-pdf'
import { useSelector } from 'react-redux'

import { FiDownload, FiEye, FiImage, FiTrash } from 'react-icons/fi'
import AuthService from '../../../services/AuthService'
import * as s from './styles'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const Auth = new AuthService()

const FilePreview = (props) => {
  const { t } = useTranslation(['common', 'candidate'], { useSuspense: false })
  const [file, setFile] = useState(props.file)

  const user = useSelector((state) => state.user)

  useEffect(() => {
    setFile({
      ...props.file,
      fileType:
        props.file?.fileName?.split('.')?.pop() ||
        props.file?.id?.split('.')?.pop(),
    })
  }, [props.file])

  const deleteFile = () => {
    Modal.confirm({
      title: t('candidate:removeFile.warning.title'),
      icon: <WarningCircle size={32} color="#d95762" />,
      okText: t('common:yes'),
      okType: 'danger',
      cancelText: t('common:cancel'),
      cancelButtonProps: { className: 'grey-btn' },
      okButtonProps: { className: 'red-btn' },
      onOk() {
        Auth.fetch(
          `/candidates/${props.candidateId}?deleteFile=${props.file._id}`,
          {
            method: 'PATCH',
          },
        )
          .then(() => {
            toast.success(
              t(
                'candidate:file.removed.toast.success',
                'Le fichier a été supprimé avec succès',
              ),
            )
            if (props.refreshCandidateFiles) {
              props.refreshCandidateFiles()
            }
            if (props.refetchCandidate) {
              props.refetchCandidate()
            }
          })
          .catch((err) => {
            console.error(err)
            toast.error(
              t(
                'candidate:file.remove.toast.error',
                'La suppression du fichier a échouée',
              ),
            )
          })
      },
      onCancel() {},
    })
  }

  const downloadFile = async (file) => {
    try {
      const response = await fetch(file.signedUrl)
      const blob = await response.blob()

      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')

      let fileName = file.fileName || file.name || file.id

      if (!fileName.includes('.')) {
        fileName = `${fileName}.${file.fileType}`
      }

      link.setAttribute('href', url)
      link.setAttribute('download', fileName)

      document.body.appendChild(link)
      // Start download
      link.click()
      // Clean up and remove the link
      link.parentNode.removeChild(link)
    } catch (e) {
      toast.error(
        t(
          'candidate:file.download.toast.error',
          'Erreur lors du téléchargement du ficher',
        ),
      )
    }
  }

  const fileType = {
    resume: 'CV',
    coverletter: 'LM',
    other: '-',
  }

  return (
    <div style={{ margin: '10px 0' }}>
      <s.FileBtnWrapper>
        <div>
          <FiImage size={16} />
          <span style={{ marginLeft: '5px', fontWeight: '600' }}>
            {file.name} [{fileType[file.type]}]
            <span style={{ fontSize: '12px' }}>
              {' '}
              (.
              {file.fileType})
            </span>
          </span>
        </div>
        <div style={{ display: 'flex', gap: 10 }}>
          <Button
            disabled={file.fileType !== 'pdf'}
            className="grey-btn"
            onClick={() => props.toggle(file)}
            target="_blank"
            data-testid="eye-icon"
          >
            <FiEye size={16} />
          </Button>
          <Button
            className="grey-btn"
            onClick={() => downloadFile(file)}
            target="_blank"
            data-testid="download-icon"
          >
            <FiDownload size={16} />
          </Button>
          {user?.permissions?.includes('admin_hollo') ||
          (['5ede3f0a1746310018d42a3d', '5fe0da154fbde1003fc656a3'].includes(
            user?.company_id?._id,
          ) &&
            !user?.permissions?.includes('guest') &&
            props?.showDelete) ? (
            <Button
              className="grey-btn"
              onClick={deleteFile}
              target="_blank"
              data-testid="trash-icon"
            >
              <FiTrash size={16} />
            </Button>
          ) : undefined}
        </div>
      </s.FileBtnWrapper>
    </div>
  )
}

export default FilePreview
