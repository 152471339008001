import queryString from 'query-string'
import React, { useState, useEffect } from 'react'
import { Input, Button } from 'antd'
import toast from 'react-hot-toast'

import { useLocation, useHistory } from 'react-router-dom'
import AuthService from '../../services/AuthService'

import * as S from './styles'
import HelmetComponent from '../../components/Helmet'
import { isDomainJ2BD } from '../../services/utils'
import j2bdLogo from '../../assets/hollo/j2bd.svg'
import holloLogo from '../../assets/hollo/LogoLoginHollo.svg'

const Auth = new AuthService()

function ResetPassword() {
  const abortController = new AbortController()

  const [token, setToken] = useState(null)
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordBis, setNewPasswordBis] = useState('')
  const [fetching, setFetching] = useState(false)

  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    Auth.logout()
    const params = queryString.parse(location.search)
    setToken(params.token || null)
    return () => {
      abortController.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpdatePassword = async (e) => {
    e.preventDefault()

    if (!newPassword || !newPasswordBis) {
      return toast.error('Tous les champs sont requis')
    }
    if (newPassword !== newPasswordBis) {
      return toast.error('Les nouveaux mot de passe ne correspondent pas')
    }
    setFetching(true)

    try {
      await Auth.fetch('/authManagement', {
        method: 'POST',
        body: JSON.stringify({
          action: 'resetPwdLong',
          value: {
            token,
            password: newPassword,
          },
          notifierOptions: {
            domainJ2BD: isDomainJ2BD ? true : undefined,
          },
        }),
        signal: abortController.signal,
      })
      toast.success('Le mot de passe a été changé avec succès')
      history.push('/login')
    } catch (err) {
      console.error(err)
      let errString = 'Erreur lors du changement du mot de passe'
      if (
        err.message ===
        'The provided password does not comply to the password policy'
      ) {
        errString = `${errString} \nLe mot de passe ne respecte pas la politique de sécurité (8 caractères minimum, au moins une minuscule, une majuscule, un chiffre et un symbole)`
      }
      toast.error(errString)
    }

    setFetching(false)
  }

  return (
    <S.ResetPassword>
      <HelmetComponent
        faviconUrl={isDomainJ2BD ? j2bdLogo : holloLogo}
        name={isDomainJ2BD ? 'J2BD Partners' : 'Hollo'}
      />
      <div>
        <img src={isDomainJ2BD ? j2bdLogo : holloLogo} alt="logo" />
      </div>
      <form className="resetPasswordForm">
        <h3>Nouveau mot de passe</h3>
        <p className="resetPasswordFormRules">
          Celui-ci doit contenir au moins une minuscule, une majuscule, un
          chiffre ainsi qu'un caractère spécial
        </p>
        <label htmlFor="newPassword">
          Nouveau mot de passe
          <Input
            autoComplete="new-password"
            type="password"
            name="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </label>
        <label htmlFor="newPasswordBis">
          Répéter le mot de passe
          <Input
            autoComplete="new-password"
            type="password"
            name="newPasswordBis"
            value={newPasswordBis}
            onChange={(e) => setNewPasswordBis(e.target.value)}
          />
        </label>
        <Button
          type="primary"
          htmlType="submit"
          role="button"
          onClick={handleUpdatePassword}
          loading={fetching}
        >
          Changer le mot de passe
        </Button>
        <a href="/login" className="loginLink">
          Se connecter avec un compte existant
        </a>
      </form>
    </S.ResetPassword>
  )
}

export default ResetPassword
