import { combineReducers, compose, createStore } from 'redux'
import administration from './reducers/Administration'
import fetchedData from './reducers/FetchedData'
import newMessage from './reducers/NewMessage'
import newOffer from './reducers/NewOffer'
import newStep from './reducers/NewStep'
import newTree from './reducers/NewTree'
import ui from './reducers/Ui'
import user from './reducers/User'
import communication from './reducers/Communication'
import candidates from './reducers/Candidates'
import reactivation from './reducers/Reactivation'
import notification, { initialStateNotifs } from './reducers/Notification'

const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state')

    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    sessionStorage.setItem('state', serializedState)
  } catch (err) {}
}

const persistedState = loadState()

const store = createStore(
  combineReducers({
    newOffer,
    newStep,
    newTree,
    newMessage,
    user,
    ui,
    fetchedData,
    administration,
    communication,
    candidates,
    reactivation,
    notification,
  }),
  {
    newOffer: persistedState?.newOffer || {},
    notification: persistedState?.notification || initialStateNotifs,
  }, // persistedState,
  compose(
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__(),
  ),
)

if (global.window && global.window.Cypress) {
  global.window.store = store
}
store.subscribe(() => {
  saveState(store.getState())
})

export default store
