import { SET_USER, RESET_USER } from '../actions/userActions'

export default function reducer(state = {}, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.user,
      }

    case RESET_USER:
      return {}

    default: {
      return state
    }
  }
}
