import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { Modal, Button, Loading } from '@nextui-org/react'
import { Result } from 'antd'
import { func, shape, string, arrayOf } from 'prop-types'

import { createReactivation } from '../../../../../api/Reactivation/api.reactivation'
import updateActionsSVG from '../../../../../assets/hollo/updateActions.svg'

const UpdateTalents = ({
  selectedTalents,
  user,
  goBack,
  cleanSelectedTalents,
  closeModal,
  type,
  title,
  subtitle,
}) => {
  const companyId = user?.company_id?._id
  const [isLoading, setIsLoading] = useState(false)

  const sendUpdate = async () => {
    try {
      setIsLoading(true)
      await createReactivation(selectedTalents, type, companyId)
      setIsLoading(false)
      toast.success('Le message à bien été envoyé !')
      cleanSelectedTalents()
      closeModal()
    } catch (error) {
      const errorMsg = 'Error while sending update to talents :('
      toast.error(errorMsg)
      console.error(errorMsg, error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <Modal.Body
        css={{
          padding: '40px',
        }}
      >
        <Result
          icon={<img height={80} src={updateActionsSVG} alt="update talents" />}
          title={title}
          subTitle={subtitle}
        />
      </Modal.Body>
      <Modal.Footer>
        {goBack && (
          <Button
            auto
            flat
            color="#EEEFF1"
            onClick={() => {
              goBack()
            }}
          >
            Retour
          </Button>
        )}
        <Button
          color="primary"
          auto
          disabled={isLoading}
          onClick={async () => {
            await sendUpdate()
          }}
        >
          {isLoading ? (
            <Loading color="currentColor" size="sm" type="spinner" />
          ) : (
            'Envoyer'
          )}
        </Button>
      </Modal.Footer>
    </div>
  )
}

UpdateTalents.propTypes = {
  cleanSelectedTalents: func.isRequired,
  closeModal: func.isRequired,
  goBack: func.isRequired,
  selectedTalents: arrayOf(
    shape({
      id: string.isRequired,
    }),
  ).isRequired,
  user: shape({
    company_id: string.isRequired,
  }).isRequired,
}

export default UpdateTalents
