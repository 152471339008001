/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setUser } from '../redux/actions/userActions'
import AuthService from './AuthService'
import ErrorPage from '../routes/ErrorPages'

export default function withAuth(AuthComponent) {
  const Auth = new AuthService()

  const mapStateToProps = (state) => ({
    user: state.user,
  })

  const mapDispatchToProps = (dispatch) => ({
    setUser: (user) => dispatch(setUser(user)),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class AuthWrapped extends Component {
      abortController = new AbortController()

      constructor(props) {
        super(props)
        this.state = {
          httpError: null,
        }
      }

      componentDidMount() {
        if (!Auth.loggedIn()) {
          this.props.history.push('/login')
        } else {
          try {
            const profile = Auth.getProfile()
            if (this.props.user._id === undefined) {
              Auth.fetch(`/users/${profile.sub}`, {
                signal: this.abortController.signal,
              })
                .then((res) => {
                  this.props.setUser(res)
                })
                .catch(() => {
                  this.setState({
                    httpError: 'HTTP_500_ERROR',
                  })
                })
            }
          } catch (err) {
            console.error(err)
            Auth.logout()
            this.props.history.replace('/login')
          }
        }
      }

      componentDidUpdate() {
        if (!Auth.loggedIn()) {
          this.props.history.push('/login')
        }
      }

      componentWillUnmount() {
        this.abortController.abort()
      }

      render() {
        if (this.state.httpError === 'HTTP_500_ERROR') {
          return <ErrorPage />
        }
        if (this.props.user._id !== undefined) {
          return (
            <AuthComponent
              history={this.props.history}
              user={this.props.user}
            />
          )
        }
        return null
      }
    },
  )
}
