import { Button, Loading, Modal } from '@nextui-org/react'
import { Result, Steps } from 'antd'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import Select from 'react-select'
import { createLikes } from '../../../../../api/Like/api.like'
import { findAllOffersNotAppliedTo } from '../../../../../api/Offer/api.offer'
import reactivationActionsSVG from '../../../../../assets/hollo/reactivationActions.svg'
import { reactSelectStyles } from '../../../../../services/utils'

const { Step } = Steps

const Reactivate = ({
  selectedTalents,
  goBack,
  user,
  closeModal,
  cleanSelectedTalents,
}) => {
  const [selectedOffer, setSelectedOffer] = useState(null)
  const [isBtnLoading, setIsBtnLoading] = useState(false)

  const { t } = useTranslation(['common'], {
    useSuspense: false,
  })
  /**
   * We have a hook to get the list of offers none of the candidates applied to
   * At the moment it's very long to get the result of offers - ~= 5 sec
   * Used to be a useEffect, it will filter campaigns the talent already sent an application to
   */
  const { isLoading, data: availableOffers } = useQuery({
    queryKey: ['availableOffers'],
    queryFn: async () => {
      const result = await findAllOffersNotAppliedTo(selectedTalents)
      return result.map((offer) => ({
        ...offer,
        value: offer._id,
        label: offer.name,
        teamId: offer.team_id,
      }))
    },
    refetchOnWindowFocus: false,
  })

  const handleCreateLikes = async () => {
    try {
      setIsBtnLoading(true)

      await createLikes(selectedTalents, selectedOffer, user, 'approved')
      toast.success('Réactivation envoyée')
      cleanSelectedTalents()
      closeModal()
    } catch (error) {
      toast.error("Erreur lors de l'envoi de la réactivation :(")
      console.error('Error while sending reactivation to talents', error)
    } finally {
      setIsBtnLoading(false)
    }
  }

  return (
    <div>
      <Modal.Body>
        <Result
          icon={
            <img
              height={80}
              src={reactivationActionsSVG}
              alt="reactivate talents"
            />
          }
          title="Réactivez vos talents vers une offre active"
          subTitle="Vous êtes sur le point d’envoyer un mail à vos talents. Un fois envoyé, voilà ce qui va se passer :"
          extra={[
            <div>
              <Steps
                progressDot
                size="small"
                direction="vertical"
                style={{ marginBottom: '20px' }}
              >
                <Step
                  status="finish"
                  title="Les profils sélectionnés reçoivent votre message"
                />
                <Step
                  status="finish"
                  title="Ils cliquent sur le lien de l’offre"
                />
                <Step
                  status="finish"
                  title="Arrivent sur leur plateforme et prennent connaissance de l’offre."
                />
                <Step status="finish" title="Acceptent ou déclinent" />
              </Steps>
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                <Select
                  closeMenuOnSelect
                  className="react-select"
                  classNamePrefix="react-select"
                  value={selectedOffer}
                  options={availableOffers}
                  menuPlacement="top"
                  onChange={setSelectedOffer}
                  placeholder={t('common:sidebar.campaigns')}
                  styles={reactSelectStyles}
                />
              )}
            </div>,
          ]}
        />
      </Modal.Body>
      <Modal.Footer>
        {goBack ? (
          <Button
            auto
            flat
            color="#EEEFF1"
            onClick={() => {
              goBack()
            }}
          >
            Retour
          </Button>
        ) : undefined}
        <Button
          auto
          disabled={!selectedOffer || isBtnLoading}
          onClick={async () => {
            await handleCreateLikes(
              selectedTalents,
              selectedOffer,
              user,
              'approved',
            )
          }}
        >
          {isBtnLoading ? (
            <Loading color="currentColor" size="sm" type="spinner" />
          ) : (
            'Envoyer'
          )}
        </Button>
      </Modal.Footer>
    </div>
  )
}

export default Reactivate
