import {
  Button,
  Tooltip,
  Popconfirm,
  Popover,
  Input,
  List,
  DatePicker,
} from 'antd'
import { string } from 'prop-types'
import React, { useContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { BiBell, BiBellOff } from 'react-icons/bi'
import { FeathersContext } from '../../services/feathers'
import { makeToastError } from '../../services/utils'

const { TextArea } = Input

const propTypes = {
  candidateId: string.isRequired,
}

const defaultProps = {}

const ReminderButton = ({ candidateId }) => {
  const { t } = useTranslation(['common', 'talent'], {
    useSuspense: false,
  })

  const data = [
    {
      title: t('talent:card.reminder.oneWeek'),
      value: 7,
    },
    {
      title: t('talent:card.reminder.oneMonth'),
      value: 30,
    },
    {
      title: t('talent:card.reminder.threeMonths'),
      value: 90,
    },
    {
      title: t('talent:card.reminder.sixMonths'),
      value: 180,
    },
    {
      title: t('talent:card.reminder.custom'),
      value: 'custom',
    },
  ]

  const feathers = useContext(FeathersContext)

  const user = useSelector((state) => state.user)

  const [reminderFormOpen, setReminderFormOpen] = useState(false)
  const [text, setText] = useState('')
  const [customDate, setCustomDate] = useState(null)
  const [datePickerVisible, setDatePickerVisible] = useState(false)
  const [datePickerOpen, setDatePickerOpen] = useState(false)

  useEffect(() => {
    if (!reminderFormOpen) {
      setText('')
      setCustomDate(null)
      setDatePickerVisible(false)
    }
  }, [reminderFormOpen])

  useEffect(() => {
    setDatePickerOpen(datePickerVisible)
  }, [datePickerVisible])

  const disabledDate = (current) =>
    // Can not select days before today and today
    current && current < new Date().getTime()

  const { data: reminders, refetch: refetchReminders } = useQuery({
    queryKey: ['reminders', candidateId],
    queryFn: () =>
      feathers.service('reminders').find({
        query: {
          user_id: user._id,
          candidate_id: candidateId,
          triggered: false,
          $limit: 1,
        },
      }),
    refetchOnWindowFocus: false,
  })

  const addReminder = useMutation(
    async (daysUntilReminder) => {
      let scheduledAt

      if (daysUntilReminder) {
        scheduledAt = new Date()
        scheduledAt.setDate(scheduledAt.getDate() + daysUntilReminder)
      } else {
        scheduledAt = new Date(customDate)
      }

      scheduledAt.setHours(0)

      await feathers.service('reminders').create({
        user_id: user._id,
        candidate_id: candidateId,
        type: ['email', 'notification'],
        scheduledAt,
        text,
      })
    },
    {
      onSuccess: () => {
        setReminderFormOpen(false)
      },
      onError: (error) => {
        makeToastError(t('common:errorHappened'), error)
      },
      onSettled: () => {
        refetchReminders()
      },
    },
  )

  const deleteReminder = useMutation(
    async (id) => {
      await feathers.service('reminders').remove(id)
    },
    {
      onError: (error) => {
        makeToastError(t('common:errorHappened'), error)
      },
      onSettled: () => {
        refetchReminders()
      },
    },
  )

  const reminder = reminders?.data[0]

  if (reminder) {
    return (
      <Tooltip title={t('common:reminder')} placement="bottom" color="#080e27">
        <Popconfirm
          title={t('talent:card.reminder.delete.title')}
          onConfirm={() => deleteReminder.mutate(reminder?._id)}
          okText="Yes"
          cancelText="No"
          placement="left"
          cancelButtonProps={{
            className: 'grey-btn',
          }}
          okButtonProps={{
            style: { marginLeft: '10px' },
          }}
          zIndex={1000}
        >
          <Button className="table-action-elem more-action blue-btn">
            <BiBellOff size={20} />
          </Button>
        </Popconfirm>
      </Tooltip>
    )
  }

  return (
    <Tooltip title={t('common:reminder')} placement="bottom" color="#080e27">
      <Popover
        content={
          <>
            <TextArea
              rows={4}
              placeholder={t('talent:card.reminder.input.placeholder')}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item) => {
                if (item.value === 'custom') {
                  if (!datePickerVisible) {
                    return (
                      <List.Item
                        style={{ cursor: 'pointer' }}
                        onClick={() => setDatePickerVisible(true)}
                      >
                        <List.Item.Meta title={item.title} />
                      </List.Item>
                    )
                  }
                  return (
                    <List.Item
                      style={{ cursor: 'pointer' }}
                      onClick={() => setDatePickerOpen(true)}
                    >
                      <List.Item.Meta
                        title={
                          <DatePicker
                            disabledDate={disabledDate}
                            renderExtraFooter={() => (
                              <div style={{ width: '100%' }}>
                                <Button
                                  className="blue-btn"
                                  style={{
                                    width: '48%',
                                    padding: '4px',
                                    margin: '8px 2px',
                                  }}
                                  onClick={() => setDatePickerVisible(false)}
                                >
                                  {t('common:cancel')}
                                </Button>
                                <Button
                                  className="blue-btn"
                                  style={{
                                    width: '48%',
                                    padding: '4px',
                                    margin: '8px 2px',
                                  }}
                                  disabled={!customDate}
                                  onClick={() => addReminder.mutate(null)}
                                >
                                  {t('talent:card.reminder.set.btn')}
                                </Button>
                              </div>
                            )}
                            showToday={false}
                            open={datePickerOpen}
                            value={customDate}
                            onChange={(e) => setCustomDate(e)}
                          />
                        }
                      />
                    </List.Item>
                  )
                }
                return (
                  <List.Item
                    style={{ cursor: 'pointer' }}
                    onClick={() => addReminder.mutate(item.value)}
                  >
                    <List.Item.Meta title={item.title} />
                  </List.Item>
                )
              }}
            />
          </>
        }
        title={t('talent:card.reminder.title')}
        trigger="click"
        visible={reminderFormOpen}
        onVisibleChange={setReminderFormOpen}
      >
        <Button className="table-action-elem more-action grey-btn">
          <BiBell size={20} />
        </Button>
      </Popover>
    </Tooltip>
  )
}

ReminderButton.propTypes = propTypes
ReminderButton.defaultProps = defaultProps
export default ReminderButton
