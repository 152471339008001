import React from 'react'
import Chart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'

function normalize(val, max, min) {
  return (val - min) / (max - min)
}

function ConversationSpider(props) {
  const { t } = useTranslation(['talent', 'common'], {
    useSuspense: false,
  })

  const { data, conversation } = props
  const { scoringVariables } = conversation

  let avgValues = []
  let candidateValues = []
  const categories = []

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const variable in data) {
    categories.push(data[variable].name)
    avgValues.push(
      normalize(
        data[variable].avgScore,
        data[variable].maxScore,
        data[variable].minScore - 1,
      ),
    )

    const variableIndex = scoringVariables
      .map((e) => e.step_id)
      .indexOf(variable)
    if (variableIndex >= 0) {
      candidateValues.push(
        normalize(
          scoringVariables[variableIndex].score,
          data[variable].maxScore,
          data[variable].minScore - 1,
        ),
      )
    } else {
      candidateValues.push(
        normalize(
          data[variable].minScore,
          data[variable].maxScore,
          data[variable].minScore - 1,
        ),
      )
    }
  }

  avgValues = avgValues.map((e) => (Number.isNaN(e) ? 0.1 : e))
  candidateValues = candidateValues.map((e) => (Number.isNaN(e) ? 0.1 : e))

  const series = [
    {
      name: t('common:average'),
      data: avgValues,
    },
    {
      name: t('common:candidate'),
      data: candidateValues,
    },
  ]
  const options = {
    chart: {
      type: 'radar',
      dropShadow: {
        enabled: true,
        blur: 1,
        left: 1,
        top: 1,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      padding: {
        left: 0,
        right: 15,
        bottom: -30,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'white',
        type: 'vertical',
        shadeIntensity: 0.5,
        opacityFrom: 0.4,
        opacityTo: 0.3,
        stops: [0, 50, 100],
        colorStops: [],
      },
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'right',
    },

    markers: {
      size: 0,
    },
    stroke: {
      width: 1.5,
    },
    xaxis: {
      categories,
    },
    colors: ['#85b8f6', '#00e396'],
    yaxis: {
      show: false,
    },
  }

  return (
    <Chart
      options={options}
      series={series}
      type="radar"
      width="100%"
      height="340px"
    />
  )
}

export default ConversationSpider
