import queryString from 'query-string'
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Result } from 'antd'
import AuthService from '../../services/AuthService'

import Loader from '../../components/Loader'
import HelmetComponent from '../../components/Helmet'
import { isDomainJ2BD } from '../../services/utils'
import j2bdLogo from '../../assets/hollo/j2bd.svg'
import holloLogo from '../../assets/hollo/LogoLoginHollo.svg'

const Auth = new AuthService()

const Verify = () => {
  const history = useHistory()
  const location = useLocation()

  const [content, setContent] = useState(<Loader />)

  useEffect(() => {
    const params = queryString.parse(location.search)
    const token = params.token !== undefined ? params.token : null

    Auth.fetch('/authManagement', {
      method: 'POST',
      body: JSON.stringify({
        action: 'verifySignupLong',
        value: token,
      }),
    })
      .then(() => {
        setContent(
          <Result
            status="success"
            title="Vérification de votre compte réussie"
            subTitle="Votre compte est vérifié. Vous avez reçu un nouvel email avec vos informations de connexion, vous allez être redirigé vers la page de connexion d'ici quelques secondes."
          />,
        )
        setTimeout(() => {
          history.push('/login')
        }, 10000)
      })
      .catch(async (err) => {
        console.error(err)
        setContent(
          <Result
            status="warning"
            title="Erreur lors de la vérification du compte"
            subTitle="Le token de vérification n'est pas valide. Il est possible qu'il soit expiré ou bien que le compte ait déjà été vérifié."
          />,
        )
      })
  }, [])

  return (
    <>
      <HelmetComponent
        faviconUrl={isDomainJ2BD ? j2bdLogo : holloLogo}
        name={isDomainJ2BD ? 'J2BD Partners' : 'Hollo'}
      />
      <p style={{ fontSize: '18px', marginTop: '10%' }}>{content}</p>
    </>
  )
}

export default Verify
