import PropTypes, { func } from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Comment, List, Modal, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import moment from 'moment'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import { convertToHTML } from 'draft-convert'
import AuthService from '../../../services/AuthService'
import Loader from '../../Loader'
import './PresentationNotes.css'
import RichEditor from '../../RichEditor'
import { removeTags } from '../../../services/utils'
import draftToHtml from 'draftjs-to-html'

const updatePresentationNotesWithNames = (
  presentationNotes,
  collaborators,
  t,
) =>
  presentationNotes.map((note) => {
    const index = collaborators.map((e) => e.id).indexOf(note.user_id)
    if (index >= 0) {
      note.user = collaborators[index].display
    } else {
      note.user = t('candidate:user.removed.message')
    }
    return note
  })

const Auth = new AuthService()

const propTypes = {
  candidateId: PropTypes.string.isRequired,
  conversationId: PropTypes.string,
  presentationNotes: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      timestamp: PropTypes.string,
      user_id: PropTypes.string,
      text: PropTypes.string,
      conversation_id: PropTypes.string,
    }),
  ).isRequired,
  refetchCandidate: func,
  whitelistedGuests: PropTypes.array,
}

const defaultProps = {
  conversationId: '',
  refetchCandidate: null,
  whitelistedGuests: [],
}

const PresentationNotes = ({
  refetchCandidate,
  presentationNotes: prNotes,
  candidateId,
  conversationId,
  whitelistedGuests,
}) => {
  const { t } = useTranslation(['common', 'candidate'], { useSuspense: false })
  const abortController = new AbortController()

  const user = useSelector((state) => state.user)

  const [presentationNotes, setPresentationNotes] = useState(prNotes)
  const [status, setStatus] = useState('fetching')
  const [editPresentationNoteState, setEditPresentationNoteState] = useState(
    EditorState.createEmpty(),
  )
  const [presentationNoteState, setPresentationNoteState] = useState(
    EditorState.createEmpty(),
  )
  const [collaborators, setCollaborators] = useState([])
  const [isDeleteNoteOpen, setIsDeleteNoteOpen] = useState(false)
  const [isEditNoteOpen, setIsEditNoteOpen] = useState(false)
  const [selectedNote, setSelectedNote] = useState(null)

  useEffect(() => {
    const fetchCollaborators = async () => {
      try {
        const users = await Auth.fetch('/users?$limit=-1', {
          signal: abortController.signal,
        })
        const collaborators = users.map((user) => {
          let name = user.login
          if (user?.firstname && user?.lastname) {
            name = `${user.firstname} ${user.lastname}`
          }
          return {
            id: user._id,
            display: name,
            profile: user.permissions[0],
          }
        })

        // here we generate a fake user for auto suggest to allow to ping all collaborators.

        collaborators.unshift({
          id: undefined,
          display: 'all',
          profile: 'user',
        })

        const updatedPresentationNotes = updatePresentationNotesWithNames(
          JSON.parse(JSON.stringify(presentationNotes)),
          collaborators,
          t,
        )
        setPresentationNotes(updatedPresentationNotes)
        setCollaborators(collaborators)
        setStatus('fetched')
      } catch (e) {
        console.error('Error while fetching users', e)
      }
    }

    fetchCollaborators()
  }, [candidateId])

  useEffect(() => () => abortController.abort(), [])

  const handleDeleteNote = async () => {
    try {
      await Auth.fetch(`/candidates/${candidateId}`, {
        method: 'PATCH',
        body: JSON.stringify({
          $pull: {
            presentationNotes: {
              _id: selectedNote.id,
            },
          },
        }),
      })

      const updatedPresentationNotes = presentationNotes.filter(
        (note) => note._id !== selectedNote.id,
      )

      setIsDeleteNoteOpen(false)
      setSelectedNote(null)
      setPresentationNotes(updatedPresentationNotes)

      if (refetchCandidate) {
        refetchCandidate()
      }
      toast.success(
        t('candidate:note.removed.toast.success', 'Note supprimée !'),
      )
    } catch (e) {
      console.error(e)
      toast.error(
        t(
          'candidate:note.removed.toast.error',
          'Erreur lors de la suppression de la note',
        ),
      )
    }
  }

  const handleClickEditNote = async (note) => {
    setIsEditNoteOpen(true)

    const noteIndex = presentationNotes.map((e) => e._id).indexOf(note.id)
    const noteToEdit = presentationNotes[noteIndex]

    setSelectedNote(noteToEdit)

    const { rawContent, text } = noteToEdit

    const editorState = EditorState.createWithContent(
      rawContent
        ? convertFromRaw(JSON.parse(rawContent))
        : stateFromHTML(text || ''),
    )

    setEditPresentationNoteState(editorState)
  }

  const handleEditNote = async () => {
    try {
      const text = draftToHtml(
        convertToRaw(editPresentationNoteState.getCurrentContent()),
      )

      const parsedUpdatedPresentationNotes = JSON.parse(
        JSON.stringify(presentationNotes),
      )
      const editedNoteIndex = presentationNotes
        .map((e) => e._id)
        .indexOf(selectedNote._id)

      parsedUpdatedPresentationNotes[editedNoteIndex] = {
        ...parsedUpdatedPresentationNotes[editedNoteIndex],
        text,
        rawContent: JSON.stringify(
          convertToRaw(editPresentationNoteState.getCurrentContent()),
        ),
      }

      const promises = [
        Auth.fetch(`/candidates/${candidateId}`, {
          method: 'PATCH',
          body: JSON.stringify({
            presentationNotes: parsedUpdatedPresentationNotes,
            conversationId,
          }),
        }),
      ]

      const [candidate] = await Promise.all(promises)

      const updatedPresentationNotes = updatePresentationNotesWithNames(
        candidate.presentationNotes,
        collaborators,
        t,
      )

      setIsEditNoteOpen(false)
      setPresentationNotes(updatedPresentationNotes)
      setEditPresentationNoteState(EditorState.createEmpty())

      if (refetchCandidate) {
        refetchCandidate()
      }
    } catch (e) {
      console.error('Error while updating notes', e)
      toast.error(
        t(
          'candidate:note.updated.toast.error',
          'Erreur lors de la mise à jour de la note',
        ),
      )
    }
  }

  const handleSubmitNotes = async () => {
    try {
      const text = draftToHtml(
        convertToRaw(presentationNoteState.getCurrentContent()),
      )

      const candidate = await Auth.fetch(`/candidates/${candidateId}`, {
        method: 'PATCH',
        body: JSON.stringify({
          $push: {
            presentationNotes: {
              text,
              user_id: user._id,
              ...(conversationId && {
                conversation_id: conversationId,
              }),
              rawContent: JSON.stringify(
                convertToRaw(presentationNoteState.getCurrentContent()),
              ),
            },
          },
        }),
      })

      if (conversationId && whitelistedGuests) {
        await Auth.fetch(`/conversations/${conversationId}`, {
          method: 'PATCH',
          body: JSON.stringify({
            whitelistedGuests,
          }),
        })
      }

      const updatedPresentationNotes = updatePresentationNotesWithNames(
        candidate.presentationNotes,
        collaborators,
        t,
      )

      setPresentationNotes(updatedPresentationNotes)
      setPresentationNoteState(EditorState.createEmpty())
      toast.success(t('candidate:note.added.toast.success'))
    } catch (e) {
      console.error('Error while submitting presentationNotes', e)
      toast.error(
        t(
          'candidate:note.added.toast.error',
          "Erreur lors de l'ajout de la note à la candidature",
        ),
      )
    }
  }

  let displayedPresentationNotes = [...presentationNotes]

  if (user.permissions?.includes('guest')) {
    displayedPresentationNotes = displayedPresentationNotes.filter(
      (note) => !!(note.user_id === user._id),
    )
  }

  // This is were we go gfrom string with html data and sanitize it
  // todo : use convertFromHTML and convertToHTML from draft-convert : https://github.com/facebookarchive/draft-js/issues/2170
  const data = displayedPresentationNotes.map((note) => ({
    id: note._id,
    userId: note.user_id,
    author: note.user,
    content: (
      <div
        className="comment"
        dangerouslySetInnerHTML={{
          __html: note.text,
        }}
      />
    ),
    datetime: <span>{moment(new Date(note.timestamp)).fromNow()}</span>,
  }))

  if (status === 'fetching') {
    return <Loader />
  }

  const content = (
    <>
      <div className="input-wrapper">
        <RichEditor
          editorState={presentationNoteState}
          setEditorState={setPresentationNoteState}
          mentions={false}
        />
        <Button
          className="basic-btn"
          disabled={
            !removeTags(
              convertToHTML(presentationNoteState.getCurrentContent()),
            ).trim()
          }
          onClick={handleSubmitNotes}
        >
          {t('common:add')}
        </Button>
      </div>

      <div>
        {presentationNotes.length > 0 ? (
          <List
            className="comment-list"
            header={`${data.length} notes`}
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
              <li>
                <Comment
                  style={{ whiteSpace: 'pre' }}
                  actions={[
                    <div
                      role="button"
                      onClick={() => {
                        handleClickEditNote(item)
                      }}
                      style={{ marginRight: '10px', cursor: 'pointer' }}
                    >
                      <p>{t('common:edit')}</p>
                    </div>,
                    <div
                      role="button"
                      onClick={() => {
                        setIsDeleteNoteOpen(true)
                        setSelectedNote(item)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <p>{t('common:delete')}</p>
                    </div>,
                  ]}
                  author={item.author}
                  content={item.content}
                  datetime={item.datetime}
                />
              </li>
            )}
          />
        ) : (
          ''
        )}
      </div>
    </>
  )

  return (
    <div className="client-notes-wrapper">
      {content}
      <Modal
        visible={isDeleteNoteOpen}
        onCancel={() => {
          setIsDeleteNoteOpen(false)
          setSelectedNote(null)
        }}
        footer={
          <div className="btns-container">
            <button
              type="button"
              className="grey-btn"
              onClick={() => setIsDeleteNoteOpen(false)}
            >
              {t('common:cancel')}
            </button>
            <button
              type="button"
              className="basic-btn"
              onClick={handleDeleteNote}
            >
              {t('common:delete')}
            </button>
          </div>
        }
      >
        {t('candidate:remove.note.message', 'Voulez vous supprimer la note ?')}
      </Modal>
      <Modal
        width="80%"
        maskClosable={false}
        visible={isEditNoteOpen}
        onCancel={() => {
          setIsEditNoteOpen(false)
          setSelectedNote(null)
        }}
        footer={
          <div className="btns-container">
            <button
              type="button"
              className="grey-btn"
              onClick={() => {
                setIsEditNoteOpen(false)
              }}
            >
              {t('common:cancel')}
            </button>
            <button
              type="button"
              className="basic-btn"
              onClick={handleEditNote}
              disabled={
                !removeTags(
                  convertToHTML(editPresentationNoteState.getCurrentContent()),
                ).trim()
              }
            >
              {t('common:save')}
            </button>
          </div>
        }
      >
        <div className="input-wrapper">
          <RichEditor
            editorState={editPresentationNoteState}
            setEditorState={setEditPresentationNoteState}
            suggestions={collaborators}
            mentions={false}
          />
        </div>
      </Modal>
    </div>
  )
}

PresentationNotes.propTypes = propTypes
PresentationNotes.defaultProps = defaultProps

export default PresentationNotes
