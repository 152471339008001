import React from 'react'
import ReactLoading from 'react-loading'

import './Loader.css'

const CustomLoader = ({ text }) => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    {text && (
      <span
        style={{
          fontSize: '15px',
          fontWeight: '400',
          marginBottom: '20px',
        }}
      >
        {text}
      </span>
    )}
    <ReactLoading type="spin" color="#0070f3" height={70} width={70} />
  </div>
)

export default CustomLoader
