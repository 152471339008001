/* eslint-disable react/forbid-prop-types */
import { Button } from 'antd'
import { array, func } from 'prop-types'
import React, { Suspense, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MdCancel, MdCheck } from 'react-icons/md'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { FeathersContext } from '../../services/feathers'
import Loader from '../Loader'
import { Like } from './ListApplications'
import * as styled from './styles'

/**
 * Application component made to display a card for hollo opportunity
 * @param {object} offer Application object
 */
const Opportunity = ({ offer, candidateId, refetchCandidate }) => {
  const user = useSelector((state) => state.user)
  const feathers = useContext(FeathersContext)

  const createLike = useMutation(
    (status) =>
      feathers.service('likes').create({
        company_id: user.company_id._id,
        candidate_id: candidateId,
        offer_id: offer._id,
        userStatus: status,
        lastUserUpdateAt: new Date(),
        seenByUser: true,
        createdBy: 'user',
        domainJ2BD: ['5ede3f0a1746310018d42a3d'].includes(user.company_id._id)
          ? true
          : undefined,
      }),
    {
      onSuccess: () => {
        refetchCandidate()
      },
      onError: (error) => {
        // makeToastError(t('talent:updateTags.toasts.error'), error)
      },
    },
  )

  const buttons = (
    <>
      <Button
        className="grey-btn like-reject-btn"
        onClick={() => createLike.mutate('rejected')}
      >
        <MdCancel size={26} />
      </Button>
      <Button
        className="grey-btn like-approve-btn"
        style={{ marginLeft: '10px' }}
        onClick={() => createLike.mutate('approved')}
      >
        <MdCheck size={26} />
      </Button>
    </>
  )

  return (
    <>
      <div className="candidate-like-list-item">
        <div style={{ display: 'flex', width: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center',
              marginLeft: '10px',
            }}
          >
            <div style={{ fontSize: '13px', fontWeight: '700' }}>
              {offer.name}
            </div>
            <span style={{ fontStyle: 'italic', fontSize: 'smaller' }}>
              {new Date(offer.createdAt).toLocaleDateString()}
            </span>
          </div>
          <div
            style={{
              marginLeft: 'auto',
              alignSelf: 'center',
              marginRight: '20px',
            }}
          >
            {buttons}
          </div>
        </div>
      </div>
    </>
  )
}

const ListOpportunities = ({ refetchCandidate, likes }) => {
  const { t } = useTranslation(['common', 'talentArea'], { useSuspense: false })
  return (
    <Suspense fallback={<Loader text={t('common:loader.text')} />}>
      <styled.ListApplications>
        {likes?.map((like) => (
          <Like
            like={like}
            key={like._id}
            refetchCandidate={refetchCandidate}
          />
        ))}
      </styled.ListApplications>
    </Suspense>
  )
}

ListOpportunities.propTypes = {
  refetchCandidate: func.isRequired,
  likes: array,
}

ListOpportunities.defaultProps = {
  likes: [],
}

export default React.memo(ListOpportunities)
