import produce from 'immer'
import { mongoObjectId } from '../../services/utils'

import {
  RESET_NEW_TREE,
  SET_NEW_TREE,
  SET_NEW_TREE_ID,
  SET_NEW_TREE_NAME,
  SET_NEW_TREE_DESCRIPTION,
  SET_NEW_TREE_REQUIREMENTS,
  ADD_NEW_TREE_REQUIREMENT,
  REMOVE_NEW_TREE_REQUIREMENT,
  SET_NEW_TREE_SKILLS,
  ADD_NEW_TREE_SKILL,
  REMOVE_NEW_TREE_SKILL,
  SET_NEW_TREE_STEP_NAME,
  SET_NEW_TREE_STEP_SKILL,
  SET_NEW_TREE_STEP_REQUIREMENT,
  SET_NEW_TREE_STEP_CONDITION,
  SET_NEW_TREE_STEP_TYPE,
  SET_NEW_TREE_STEP_SENTENCE,
  SET_NEW_TREE_STEP_END_CHAT,
  SET_NEW_TREE_STEP_WEIGHT,
  SET_NEW_TREE_STEP_VIDEO_URL,
  SET_NEW_TREE_STEP_ATTACHMENT_TYPE,
  ADD_NEW_TREE_STEP_OPTION,
  REMOVE_NEW_TREE_STEP_OPTION,
  SET_NEW_TREE_STEP_OPTION_LABEL,
  SET_NEW_TREE_STEP_OPTION_SCORE,
  SET_NEW_TREE_STEP_OPTION_DEALBREAKER,
  ADD_NEW_TREE_STEP_OPTION_NUMBER,
  REMOVE_NEW_TREE_STEP_OPTION_NUMBER,
  SET_NEW_TREE_STEP_OPTION_NUMBER_MIN,
  SET_NEW_TREE_STEP_OPTION_NUMBER_MAX,
  SET_NEW_TREE_STEP_OPTION_NUMBER_SCORE,
  SET_NEW_TREE_STEP_OPTION_NUMBER_DEALBREAKER,
  ADD_NEW_TREE_STEP_OPTION_DATE,
  REMOVE_NEW_TREE_STEP_OPTION_DATE,
  SET_NEW_TREE_STEP_OPTION_DATE_START,
  SET_NEW_TREE_STEP_OPTION_DATE_END,
  SET_NEW_TREE_STEP_OPTION_DATE_SCORE,
  SET_NEW_TREE_STEP_OPTION_DATE_DEALBREAKER,
  SET_NEW_TREE_STEPS,
  SET_NEW_TREE_SPECIFIC_STEP,
  ADD_NEW_TREE_STEP,
  REMOVE_NEW_TREE_STEP,
  SET_TAGS,
  SET_NEW_TREE_STEP_OPTIONS,
} from '../actions/newTreeActions'

const initialState = {
  id: null,
  name: '',
  description: '',
  requirements: [],
  skills: [],
  steps: [],
  tags: [],
}

export default produce((draft, action) => {
  switch (action.type) {
    case RESET_NEW_TREE:
      return initialState

    case SET_NEW_TREE: {
      const { tree } = action

      draft.id = tree._id !== undefined ? tree._id : initialState.id
      draft.name = tree.name !== undefined ? tree.name : initialState.name
      draft.description =
        tree.description !== undefined
          ? tree.description
          : initialState.description
      draft.skills =
        tree.skills !== undefined ? tree.skills : initialState.skills
      draft.requirements =
        tree.requirements !== undefined
          ? tree.requirements
          : initialState.requirements
      draft.steps = tree.steps !== undefined ? tree.steps : initialState.steps
      draft.tags = tree.tags || initialState.tags

      return
    }

    case SET_NEW_TREE_ID: {
      draft.id = action.id
      return
    }

    case SET_NEW_TREE_NAME: {
      draft.name = action.name
      return
    }
    case SET_NEW_TREE_DESCRIPTION: {
      draft.description = action.description
      return
    }

    case SET_NEW_TREE_REQUIREMENTS: {
      draft.requirements = action.requirements
      return
    }

    case ADD_NEW_TREE_REQUIREMENT: {
      const { defaultStep, _id } = action.data.requirement
      const { step } = action.data

      draft.requirements.push(_id)

      if (step !== undefined) {
        draft.steps.push(step)
      } else {
        draft.steps.push({
          ...defaultStep,
          _id: mongoObjectId(),
          requirement_id: _id,
          level: 0,
        })
      }
      return
    }

    case REMOVE_NEW_TREE_REQUIREMENT: {
      const stepIndex = draft.steps
        .map((e) => e.requirement_id)
        .indexOf(action.requirementId)
      if (stepIndex >= 0) {
        if (draft.steps[stepIndex].hasChild) {
          return
        }

        if (
          draft.steps[stepIndex].condition &&
          draft.steps[stepIndex].condition.step_id
        ) {
          const numberOfChildren = draft.steps.filter((e) => {
            if (e.condition && e.condition.step_id) {
              if (
                e.condition.step_id === draft.steps[stepIndex].condition.step_id
              ) {
                return e
              }
              return false
            }
            return false
          })

          if (numberOfChildren.length <= 1) {
            const parentStepIndex = draft.steps
              .map((e) => e._id)
              .indexOf(draft.steps[stepIndex].condition.step_id)
            if (parentStepIndex >= 0) {
              draft.steps[parentStepIndex].hasChild = false
            }
          }
        }

        draft.steps.splice(stepIndex, 1)
      }

      const requirementIndex = draft.requirements.indexOf(action.requirementId)
      if (requirementIndex >= 0) {
        draft.requirements.splice(requirementIndex, 1)
      }

      return
    }

    case SET_NEW_TREE_SKILLS: {
      draft.skills = action.skills
      return
    }

    case ADD_NEW_TREE_SKILL: {
      const { value, label } = action.data.skill
      const { step } = action.data
      draft.skills.push(value)
      if (step !== undefined) {
        draft.steps.push(step)
      } else {
        draft.steps.push({
          _id: mongoObjectId(),
          name: label,
          skill: label,
          skill_id: value,
          variable: label,
          type: 'textfield',
          sentence: `Avez vous de l'expérience avec ${label} ?`,
          weight: 3,
          options: [],
          dateScores: [],
          numberScores: [],
          level: 0,
          endChat: false,
        })
      }
      return
    }

    case REMOVE_NEW_TREE_SKILL: {
      const stepIndex = draft.steps
        .map((e) => e.skill_id)
        .indexOf(action.skillId)
      if (stepIndex >= 0) {
        if (draft.steps[stepIndex].hasChild) {
          return
        }

        if (
          draft.steps[stepIndex].condition &&
          draft.steps[stepIndex].condition.step_id
        ) {
          const numberOfChildren = draft.steps.filter((e) => {
            if (e.condition && e.condition.step_id) {
              if (
                e.condition.step_id === draft.steps[stepIndex].condition.step_id
              ) {
                return e
              }
              return false
            }
            return false
          })

          if (numberOfChildren.length <= 1) {
            const parentStepIndex = draft.steps
              .map((e) => e._id)
              .indexOf(draft.steps[stepIndex].condition.step_id)
            if (parentStepIndex >= 0) {
              draft.steps[parentStepIndex].hasChild = false
            }
          }
        }

        draft.steps.splice(stepIndex, 1)
      }

      const skillIndex = draft.skills.indexOf(action.skillId)
      if (skillIndex >= 0) {
        draft.skills.splice(skillIndex, 1)
      }

      return
    }

    case SET_NEW_TREE_STEP_NAME: {
      const { id, name } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].name = name
      draft.steps[stepIndex].variable = name
      return
    }

    case SET_NEW_TREE_STEP_SKILL: {
      const { id, skillId } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)

      const prevSkill = draft.steps[stepIndex].skill_id
      const prevRequirement = draft.steps[stepIndex].requirement_id

      draft.steps[stepIndex].skill_id = skillId
      draft.steps[stepIndex].requirement_id = null
      if (draft.skills.indexOf(skillId) < 0) {
        draft.skills.push(skillId)
      }

      if (prevSkill && prevSkill !== null) {
        if (draft.steps.map((e) => e.skill_id).indexOf(prevSkill) < 0) {
          const prevSkillIndex = draft.skills.indexOf(prevSkill)
          draft.skills.splice(prevSkillIndex, 1)
        }
      }

      if (prevRequirement && prevRequirement !== null) {
        if (draft.steps.map((e) => e.skill_id).indexOf(prevRequirement) < 0) {
          const prevRequirementIndex =
            draft.requirements.indexOf(prevRequirement)
          draft.requirements.splice(prevRequirementIndex, 1)
        }
      }

      return
    }

    case SET_NEW_TREE_STEP_REQUIREMENT: {
      const { id, requirementId } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)

      const prevSkill = draft.steps[stepIndex].skill_id
      const prevRequirement = draft.steps[stepIndex].requirement_id

      draft.steps[stepIndex].requirement_id = requirementId
      draft.steps[stepIndex].skill_id = null
      if (draft.requirements.indexOf(requirementId) < 0) {
        draft.requirements.push(requirementId)
      }

      if (prevSkill && prevSkill !== null) {
        if (draft.steps.map((e) => e.skill_id).indexOf(prevSkill) < 0) {
          const prevSkillIndex = draft.skills.indexOf(prevSkill)
          draft.skills.splice(prevSkillIndex, 1)
        }
      }

      if (prevRequirement && prevRequirement !== null) {
        if (draft.steps.map((e) => e.skill_id).indexOf(prevRequirement) < 0) {
          const prevRequirementIndex =
            draft.requirements.indexOf(prevRequirement)
          draft.requirements.splice(prevRequirementIndex, 1)
        }
      }

      return
    }

    case SET_NEW_TREE_STEP_CONDITION: {
      const { id, values } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].condition.values = values
      return
    }

    case SET_NEW_TREE_STEP_TYPE: {
      const { id, type } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].type = type
      draft.steps[stepIndex].endChat = false
      return
    }

    case SET_NEW_TREE_STEP_SENTENCE: {
      const { id, sentence } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].sentence = sentence
      return
    }

    case SET_NEW_TREE_STEP_END_CHAT: {
      const { id, endChat } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].endChat = endChat
      return
    }

    case SET_NEW_TREE_STEP_VIDEO_URL: {
      const { id, videoUrl } = action.data
      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].videoUrl = videoUrl
      return
    }

    case SET_NEW_TREE_STEP_ATTACHMENT_TYPE: {
      const { id, attachmentType } = action.data
      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].attachmentType = attachmentType
      return
    }

    case SET_NEW_TREE_STEP_WEIGHT: {
      const { id, weight } = action.data
      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].weight = weight
      return
    }

    case ADD_NEW_TREE_STEP_OPTION: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.stepId)
      draft.steps[stepIndex].options.push({
        _id: mongoObjectId(),
        label: '',
        value: '',
        score: 0,
        dealbreaker: false,
      })
      return
    }

    case REMOVE_NEW_TREE_STEP_OPTION: {
      const { id, optionIndex } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].options.splice(optionIndex, 1)
      return
    }

    case SET_NEW_TREE_STEP_OPTION_LABEL: {
      const { id, optionIndex, label } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].options[optionIndex].label = label
      draft.steps[stepIndex].options[optionIndex].value = label
      return
    }

    case SET_NEW_TREE_STEP_OPTION_SCORE: {
      const { id, optionIndex, score } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].options[optionIndex].score = score
      return
    }

    case SET_NEW_TREE_STEP_OPTION_DEALBREAKER: {
      const { id, optionIndex, dealbreaker } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].options[optionIndex].dealbreaker = dealbreaker
      return
    }

    case ADD_NEW_TREE_STEP_OPTION_NUMBER: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)

      draft.steps[stepIndex].numberScores.push({
        minValue: action.data?.min || 0,
        maxValue: action.data?.max || 0,
        score: action.data?.score || 0,
        dealbreaker: false,
      })
      return
    }

    case REMOVE_NEW_TREE_STEP_OPTION_NUMBER: {
      const { id, scoreIndex } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].numberScores.splice(scoreIndex, 1)
      return
    }

    case SET_NEW_TREE_STEP_OPTION_NUMBER_MIN: {
      const { id, scoreIndex, min } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].numberScores[scoreIndex].minValue = min
      return
    }

    case SET_NEW_TREE_STEP_OPTION_NUMBER_MAX: {
      const { id, scoreIndex, max } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].numberScores[scoreIndex].maxValue = max
      return
    }

    case SET_NEW_TREE_STEP_OPTION_NUMBER_SCORE: {
      const { id, scoreIndex, score } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].numberScores[scoreIndex].score = score
      return
    }

    case SET_NEW_TREE_STEP_OPTION_NUMBER_DEALBREAKER: {
      const { id, scoreIndex, dealbreaker } = action.data

      const stepIndex = draft.steps.map((e) => e._id).indexOf(id)
      draft.steps[stepIndex].numberScores[scoreIndex].dealbreaker = dealbreaker
      return
    }

    case ADD_NEW_TREE_STEP_OPTION_DATE: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.stepId)
      if (draft.steps[stepIndex].dateScores === undefined) {
        draft.steps[stepIndex].dateScores = []
      }
      draft.steps[stepIndex].dateScores.push({
        minValue: new Date(),
        maxValue: new Date(),
        score: 5, // The score initialized here have to be present in the available values in the date scoring options to prevent a difference between the displayed value to the user and the value stored
        dealbreaker: false,
      })
      return
    }

    case REMOVE_NEW_TREE_STEP_OPTION_DATE: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].dateScores.splice(action.data.scoreIndex, 1)
      return
    }

    case SET_NEW_TREE_STEP_OPTION_DATE_START: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].dateScores[action.data.scoreIndex].startValue =
        action.data.start
      return
    }

    case SET_NEW_TREE_STEP_OPTION_DATE_END: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].dateScores[action.data.scoreIndex].endValue =
        action.data.end
      return
    }

    case SET_NEW_TREE_STEP_OPTION_DATE_SCORE: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].dateScores[action.data.scoreIndex].score =
        action.data.score
      return
    }

    case SET_NEW_TREE_STEP_OPTION_DATE_DEALBREAKER: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.data.id)
      draft.steps[stepIndex].dateScores[action.data.scoreIndex].dealbreaker =
        action.data.dealbreaker
      return
    }

    case SET_NEW_TREE_STEPS: {
      draft.steps = action.steps
      return
    }

    case SET_NEW_TREE_SPECIFIC_STEP: {
      const { index, step } = action.data

      draft.steps[index] = step
      return
    }

    case ADD_NEW_TREE_STEP: {
      const { step, index } = action

      if (!index) {
        draft.steps.push(step)
      } else {
        draft.steps.splice(index, 0, step)
      }

      if (step.skill_id !== undefined) {
        draft.skills.push(step.skill_id)
      }
      if (step.requirement_id !== undefined) {
        draft.requirements.push(step.requirement_id)
      }
      return
    }

    case REMOVE_NEW_TREE_STEP: {
      const { stepId } = action

      const stepIndex = draft.steps.map((e) => e._id).indexOf(stepId)

      if (
        draft.steps[stepIndex].condition &&
        draft.steps[stepIndex].condition.step_id
      ) {
        const numberOfChildren = draft.steps.filter((e) => {
          if (e.condition && e.condition.step_id) {
            if (
              e.condition.step_id === draft.steps[stepIndex].condition.step_id
            ) {
              return e
            }
            return false
          }
          return false
        })

        if (numberOfChildren.length <= 1) {
          const parentStepIndex = draft.steps
            .map((e) => e._id)
            .indexOf(draft.steps[stepIndex].condition.step_id)
          if (parentStepIndex >= 0) {
            draft.steps[parentStepIndex].hasChild = false
          }
        }
      }

      const removedStep = draft.steps.splice(stepIndex, 1)

      if (removedStep.skill_id !== undefined) {
        const skillIndex = draft.skills.indexOf(removedStep.skill_id)
        if (skillIndex >= 0) draft.skills.splice(skillIndex, 1)
      }
      if (removedStep.requirement_id !== null) {
        const requirementIndex = draft.requirements.indexOf(
          removedStep.requirement_id,
        )
        if (requirementIndex >= 0)
          draft.requirements.splice(requirementIndex, 1)
      }
      break
    }

    case SET_TAGS: {
      draft.tags = action.tags
      break
    }

    case SET_NEW_TREE_STEP_OPTIONS: {
      const stepIndex = draft.steps.map((e) => e._id).indexOf(action.stepId)
      draft.steps[stepIndex].options = action.options
    }

    default: {
    }
  }
}, initialState)
