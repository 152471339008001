import { app } from '../../services/feathers'

// eslint-disable-next-line import/prefer-default-export
export async function getOneOffer(id) {
  return app.service('offers').get(id, {
    query: {
      $populate: ['team_id', 'user_id'],
      addAnalyticsConversations: true,
    },
  })
}

export async function getAggregUnreadConversationsPerUser() {
  return app.service('offers').find({
    query: {
      unreadConversationsAggreg: true,
    },
  })
}

// `/offers?$limit=0&company_id=${companyId}&published=true`

export async function getOnlineOffers(companyId, userId, onlyCurrentUser) {
  const query = {
    published: true,
    // completed:false,
    company_id: companyId,
    $limit: 0,
  }
  if (onlyCurrentUser) {
    query.user_id = userId
  }
  return app.service('offers').find({
    query,
  })
}

export async function findAllOffersNotAppliedTo(candidates) {
  const response = await app.service('offers').find({
    query: {
      listOffersAggreg: true,
      candidateIds: candidates.map((candidate) => candidate._id),
    },
  })
  if (response.length) {
    return response
  }
  return []
}
