/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
import { AnnotationFactory } from 'annotpdf'
import { Statistic, Tag, Tooltip } from 'antd'
import moment from 'moment'
import React from 'react'
import { toast } from 'react-hot-toast'
import { BsCircleFill } from 'react-icons/bs'
import sw from 'stopword'
import AuthService from './AuthService'

const Auth = new AuthService()

/**
 * TODO: Feature flag function
 * If current env is production, depends on the companyId
 * Otherwise always returns true
 * @param {string} companyId
 * @returns {boolean}
 */
export const checkFeatureFlag = (companyId) => {
  if (!companyId) {
    return false
  }

  /* return [
    '5ede3f0a1746310018d42a3d', // J2BD
    '5ee08e58ad56dd00271a433f', // Hollo
    '5fe0d317ce309d0029cebbc5', // Staging
    '5fe0da154fbde1003fc656a3', // Develop
  ].includes(companyId) */

  return true
}

export const isDomainJ2BD = ['job2bedone-partners.hollo.io'].includes(
  window?.location?.hostname,
)

/**
 * capitalize first letter of string
 * @param s
 * @returns {string}
 */
export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

/**
 * Don't forget it's a function
 * @param {function} t i18
 * @returns
 */
export const processStatusOptionsUtils = (t) => [
  {
    label: t('common:processStatusOptions.toProcess'),
    value: 'NOT_CLASSIFIED',
  },
  {
    label: t('common:processStatusOptions.notSelected'),
    value: 'NOT_SELECTED',
  },
  { label: t('common:processStatusOptions.selected'), value: 'SELECTED' },
]

/**
 * Made to make toast success more centralised
 * @param {string} message we wanna show in toast
 * @param {Function} callback function(s) we wanna call after toast
 */

export const makeToastSuccess = (message, callback) => {
  toast.success(message, {
    className: 'toast-success',
  })

  if (callback) {
    callback()
  }
}

/**
 * Made to make toast error more centralised
 * @param {string} message we wanna show in toast
 * @param {Error} error from catch
 * @param {Function} callback function(s) we wanna call after toast
 */
export const makeToastError = (message, error, callback) => {
  if (error) {
    console.error(error)
  }
  toast.error(message, {
    className: 'toast-error',
  })

  if (callback) {
    callback()
  }
}
export const checkNumber = (myString) => /\d/.test(myString)
export const checkUppercase = (myString) => /[A-Z]/.test(myString)
export const checkLowercase = (myString) => /[a-z]/.test(myString)

export const replaceInText = (text, isMail, candidate, offer) => {
  if (candidate) {
    const parsedFirstname = candidate?.firstname
      ? capitalize(candidate?.firstname)
      : ' '
    const parsedLastname = candidate?.lastname
      ? capitalize(candidate?.lastname)
      : ' '

    text = text.replace('@[Prénom](firstname)', parsedFirstname)
    text = text.replace('@[Nom](lastname)', parsedLastname)
    text = text.replace('@[Mail](email)', candidate?.email)
    text = text.replace('@Prénom', parsedFirstname)
    text = text.replace('@firstname', parsedFirstname)
    text = text.replace('@Nom', parsedLastname)
    text = text.replace('@lastname', parsedLastname)
    text = text.replace('@Mail', candidate?.email)
    text = text.replace('@email', candidate?.email)
  }
  if (offer) {
    text = text.replace('@[Offre](offerName)', offer?.name || '@Offer')
    text = text.replace('@Offre', offer?.name || '@Offer')
    text = text.replace('@offer', offer?.name || '@Offer')
  }
  if (isMail) {
    text = text.replace(/\n/g, '<br>')
  }

  return text
}

export const mongoObjectId = () => {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16)

  const objectId =
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, () => ((Math.random() * 16) | 0).toString(16))
      .toLowerCase()

  return objectId
}

// TODO: do translation
export const computeElapsed = (currentDate, notificationDate) => {
  const elapsed = currentDate - notificationDate
  // Calculate the number of days left
  const days = Math.floor(elapsed / 86400)
  // After deducting the days calculate the number of hours left
  const hours = Math.floor((elapsed - days * 86400) / 3600)
  // After days and hours , how many minutes are left
  const minutes = Math.floor((elapsed - days * 86400 - hours * 3600) / 60)
  // Finally how many seconds left after removing days, hours and minutes.

  let str = ''

  if (days > 0) {
    str += `${days} jour${days === 1 ? '' : 's'}`
  } else if (hours > 0) {
    str += `${hours} heure${hours === 1 ? '' : 's'}`
  } else if (minutes > 0) {
    str += `${minutes} minute${minutes === 1 ? '' : 's'}`
  } else {
    str += 'quelques secondes'
  }

  return str
}

export const flattenSteps = (steps, level = 0) => {
  const flattenedSteps = []

  for (let i = 0; i < steps.length; i++) {
    const step = steps[i]

    const { children } = step
    delete step.children

    if (level === 0) {
      delete step.condition
    }

    if (children !== undefined && children.length > 0) {
      flattenedSteps.push({ ...step, level, hasChild: true })

      children.forEach((childStep) => {
        let values = []
        if (childStep.condition !== undefined) {
          if (step._id !== childStep.condition.step_id) {
            values = []
          } else {
            values = childStep.condition.values
          }
        }

        childStep.condition = {
          step_id: step._id,
          values,
        }
      })
      flattenedSteps.push(...flattenSteps(children, level + 1))
    } else {
      flattenedSteps.push({ ...step, level, hasChild: false })
    }
  }

  return flattenedSteps
}

export const unflattenSteps = (steps) => {
  steps = JSON.parse(JSON.stringify(steps))

  steps.forEach((step) => {
    if (!step.level && !step.condition) {
      step.level = 0
    }

    if (step.level > 0) {
      const stepId = step.condition.step_id

      if (stepId === undefined) {
        step.level = 0
        step.condition = { values: [] }
      } else {
        const stepIndex = steps.map((s) => s._id).indexOf(stepId)
        if (stepIndex < 0) {
          step.level = 0
          step.condition = { values: [] }
        }
      }
    }
  })

  const maxLevel = Math.max(...steps.map((step) => step.level || 0)) + 1

  for (let level = maxLevel; level > 0; level--) {
    for (let index = 0; index < steps.length; index++) {
      if (steps[index].level === undefined || steps[index].level === level) {
        const parentId = steps[index].condition?.step_id
        const parentIndex = steps.map((e) => e._id).indexOf(parentId)

        if (steps[parentIndex].children === undefined) {
          steps[parentIndex].children = []
        }

        steps[parentIndex].children.push(steps[index])
        steps.splice(index, 1)
        index--
      }
    }
  }

  return steps
}

function addDays(date, days) {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const validateSteps = (steps = [], returnSteps = false, t) => {
  const issues = []

  if (steps.length === 0) {
    if (returnSteps) {
      return steps
    }
    return [
      t(
        'settings:noInteraction.message.warning',
        'Votre offre ne possède aucune interaction, veuillez en ajouter au moins une',
      ),
    ]
  }

  const stepNameArr = steps.map((e) => e.name)

  if (new Set(stepNameArr).size !== stepNameArr.length) {
    issues.push(
      t(
        'settings:interaction.withSameName.error',
        'Au moins deux interactions portent le même nom',
      ),
    )
  }

  steps = JSON.parse(JSON.stringify(steps))

  steps.forEach((step) => {
    step.isValid = true
    step.issues = []

    if (stepNameArr.filter((e) => e === step.name).length > 1) {
      const issue = t(
        'settings:multipleInteractions.withSameName.error',
        'Plusieurs interactions possèdent le nom {{name}}',
        {
          name: step.name.length > 0 ? step.name : '" "',
        },
      )
      issues.push(issue)
      step.issues.push(issue)
      step.isValid = false
    }

    if (step.type === 'placeholder') {
      return
    }

    if (!step.name) {
      const issue = t(
        'settings:interactionName.missing.error',
        "Le nom de l'interaction {{name}} n'est pas renseigné",
        {
          name: step.name,
        },
      )
      issues.push(issue)
      step.issues.push(issue)
      step.isValid = false
    }

    if (!step.sentence) {
      const issue = t(
        'settings:interactionText.missing.error',
        "Le texte de l'interaction {{name}} n'est pas renseigné",
        {
          name: step.name,
        },
      )
      issues.push(issue)
      step.issues.push(issue)
      step.isValid = false
    }

    if (step.condition && step.condition.step_id) {
      const conditionalStepIndex = steps
        .map((e) => e._id.toString())
        .indexOf(step.condition.step_id.toString())

      if (conditionalStepIndex < 0) {
        const issue = t(
          'settings:interaction.missing.chatbot.error',
          "L'interaction dont dépend l'interaction {{name}} n'est pas présente dans le chatbot",
          {
            name: step.name,
          },
        )
        issues.push(issue)
        step.issues.push(issue)
        step.isValid = false
      } else {
        const conditionalStep = steps[conditionalStepIndex]

        if (conditionalStep.type !== 'qcm') {
          const issue = t(
            'settings:interaction.wrongType.error',
            "L'interaction dont dépend l'interaction {{name}} n'est pas de type Boutons",
            {
              name: step.name,
            },
          )
          issues.push(issue)
          step.issues.push(issue)
          step.isValid = false
        }

        if (step.condition.values.length === 0) {
          const issue = t(
            'settings:interaction.withoutCondition.error',
            "L'interaction {{name}} dépend d'une autre interaction mais ne possède pas de condition d'activation",
            {
              name: step.name,
            },
          )
          issues.push(issue)
          step.issues.push(issue)
          step.isValid = false
        }

        if (step.condition.values.length === conditionalStep.options.length) {
          const issue = t(
            'settings:interaction.dependsFromOtherInteraction.error',
            "L'interaction {{name}} dépend d'une autre interaction mais possède toutes les conditions d'activation",
            {
              name: step.name,
            },
          )
          issues.push(issue)
          step.issues.push(issue)
          step.isValid = false
        }
      }
    }

    if (step.type === 'qcm') {
      if (step.options.length < 2) {
        const issue = t(
          'settings:interaction.type.button.missingAnswers.error',
          "L'interaction {{name}} est de type Boutons et doit possèder au moins 2 réponses possibles",
          {
            name: step.name,
          },
        )
        issues.push(issue)
        step.issues.push(issue)
        step.isValid = false
      }

      let missingLabel = false

      step.options.forEach((step) => {
        if (!step.label) {
          missingLabel = true
        }
      })

      if (missingLabel) {
        const issue = t(
          'settings:interaction.missing.label.error',
          "L'interaction {{name}} possède des options dont le label n'est pas rempli",
          {
            name: step.name,
          },
        )
        issues.push(issue)
        step.issues.push(issue)
        step.isValid = false
      }
    }

    if (step.type === 'numberfield' || step.type === 'salaryfield') {
      if (step.numberScores.length < 2) {
        const issue = t(
          'settings:interaction.type.number.missing.error',
          "L'interaction {{name}} est de type Nombre/Rémunération et doit possèder au moins 2 intervalles possibles",
          {
            name: step.name,
          },
        )
        issues.push(issue)
        step.issues.push(issue)
        step.isValid = false
      } else {
        let holeInNumbers = false

        const minValueArr = step.numberScores.map((e) =>
          parseInt(e.minValue, 10),
        )
        const maxValueArr = step.numberScores.map((e) =>
          parseInt(e.maxValue, 10),
        )

        const minValue = Math.min(...minValueArr)
        const maxValue = Math.max(...maxValueArr)

        step.numberScores.forEach((score) => {
          if (
            parseInt(score.minValue, 10) !== minValue &&
            !maxValueArr.includes(parseInt(score.minValue, 10))
          ) {
            holeInNumbers = true
          }
          if (
            parseInt(score.maxValue, 10) !== maxValue &&
            !minValueArr.includes(parseInt(score.maxValue, 10))
          ) {
            holeInNumbers = true
          }
        })

        if (holeInNumbers) {
          const issue = t(
            'settings:interaction.type.number.interval.missing.error',
            "L'interaction {{name}} est de type Nombre/Rémunération et il y a des trous dans les intervalles renseignés",
            {
              name: step.name,
            },
          )
          issues.push(issue)
          step.issues.push(issue)
          step.isValid = false
        }
      }
    }

    if (step.type === 'datefield') {
      if (!step.dateScores || step?.dateScores?.length < 1) {
        const issue = t(
          'settings:interaction.type.date.interval.minimum.error',
          "L'interaction {{name}} est de type Date et doit possèder au moins 1 intervalle possible",
          {
            name: step.name,
          },
        )
        issues.push(issue)
        step.issues.push(issue)
        step.isValid = false
      } else {
        let holeInDates = false

        const startValueArr = step.dateScores.map((e) =>
          new Date(e.startValue).getTime(),
        )
        const endValueArr = step.dateScores.map((e) =>
          addDays(new Date(e.endValue), 1).getTime(),
        )

        const startValue = Math.min(...startValueArr)
        const endValue = Math.max(...endValueArr)

        step.dateScores.forEach((score) => {
          if (!score?.startValue || !score?.endValue) {
            holeInDates = true
            return
          }

          const startTime = new Date(score.startValue).getTime()
          const endTime = addDays(new Date(score.endValue), 1).getTime()

          if (startTime !== startValue && !endValueArr.includes(startTime)) {
            holeInDates = true
          }
          if (endTime !== endValue && !startValueArr.includes(endTime)) {
            holeInDates = true
          }
        })

        if (holeInDates) {
          const issue = t(
            'settings:interaction.type.date.interval.missing.error',
            "L'interaction {{name}} est de type Date et il y a des trous dans les intervalles renseignés",
            {
              name: step.name,
            },
          )
          issues.push(issue)
          step.issues.push(issue)
          step.isValid = false
        }
      }
    }

    if (step.type === 'video') {
      if (!step.videoUrl) {
        const issue = t(
          'settings:interaction.type.video.urlMissing.error',
          "L'interaction {{name}} est de type Vidéo mais l'url de vidéo n'est pas renseigné",
          {
            name: step.name,
          },
        )
        issues.push(issue)
        step.issues.push(issue)
        step.isValid = false
      }
    }

    if (step.type === 'attachment') {
      if (!step.attachmentType) {
        const issue = t(
          'settings:interaction.type.attachment.type.missing.error',
          "L'interaction {{name}} est de type Pièce Jointe mais le type de fichier n'est pas renseigné",
          {
            name: step.name,
          },
        )
        issues.push(issue)
        step.issues.push(issue)
        step.isValid = false
      }
    }
  })

  if (returnSteps) {
    return steps
  }
  return issues
}

export const validateAutomaticActions = (triggers = [], t) => {
  const issues = []

  let isScheduleAppointmentPresent = false

  triggers.forEach((trigger) => {
    const { actionsToTrigger, triggerScore } = trigger

    if (parseInt(triggerScore, 10) > 100 || parseInt(triggerScore, 10) < 0) {
      issues.push(
        t(
          'settings:score.outOfRange',
          'Le score de déclenchement doit être compris entre 0 et 100 mais vous avez indiqué {{score}}',
          {
            score: parseInt(triggerScore, 10),
          },
        ),
      )
    }

    actionsToTrigger.forEach((action) => {
      const { type } = action

      if (type === 'scheduleAppointment') {
        if (isScheduleAppointmentPresent) {
          issues.push(
            t(
              'settings:automaticAction.alreadyExist',
              "Il existe déjà une action automatique de planification d'entretien, vous ne pouvez pas en avoir plusieurs",
            ),
          )
        }

        isScheduleAppointmentPresent = true

        const { url } = action

        if (!url) {
          issues.push(
            t(
              'settings:automaticAction.missingUrl',
              "Vous ne pouvez pas avoir d'action automatique de planification d'entretien sans indiquer d'url lié",
            ),
          )
        }
      }
    })
  })

  return issues
}

export const validateMandatoryTree = async (
  mandatoryTree,
  t,
  haveToFetch = false,
) => {
  const issues = []

  if (!mandatoryTree) {
    return [t('settings:genericSteps.missing.error')]
  }

  if (haveToFetch) {
    try {
      const res = await Auth.fetch(`/tree-templates/${mandatoryTree}`)
      if (!res) {
        issues.push(t('settings:genericSteps.missing.error'))
      }
    } catch (err) {
      console.error(err)
      issues.push(t('settings:genericSteps.missing.error'))
    }
  }

  return issues
}

export const removeMandatoryMentions = (steps, previousMandatoryTree) => {
  if (previousMandatoryTree !== null && previousMandatoryTree.steps) {
    previousMandatoryTree.steps.forEach((mandatoryStep) => {
      const id = mandatoryStep._id
      const regex = new RegExp(`@\\[[A-zÀ-ú0-9 !?-_,;]+\\]\\(${id}\\)`, 'g')
      steps.forEach((step) => {
        step.sentence = step.sentence.replace(regex, '')
      })
    })
  }

  return steps
}

export const standardDeviation = (arr) => {
  let stddev = 1

  const n = arr.length
  let sum = 0

  arr.forEach((data) => {
    sum += data
  })

  const mean = sum / n

  let variance = 0.0
  let v1 = 0.0
  let v2 = 0.0

  if (n !== 1) {
    for (let i = 0; i < n; i++) {
      v1 += (arr[i] - mean) * (arr[i] - mean)
      v2 += arr[i] - mean
    }

    v2 = (v2 * v2) / n
    variance = (v1 - v2) / (n - 1)
    if (variance < 0) {
      variance = 0
    }
    stddev = Math.sqrt(variance)
  }

  return Math.round(stddev * 10) / 10
}

export const getJobUrl = (ats, jobId) => {
  if (
    ats === undefined ||
    ats === null ||
    jobId === undefined ||
    jobId === null
  ) {
    return null
  }
  if (ats.name === 'Zenploy') {
    return `https://www.zenploy.io/app/job/${jobId}`
  }
  if (ats.name === 'SuccessFactors') {
    return 'https://www.google.com'
  }

  return null
}

export const getConversationUrl = (ats, conversationId, candidateId) => {
  if (
    ats === undefined ||
    ats === null ||
    conversationId === undefined ||
    conversationId === null
  ) {
    return null
  }

  if (ats.name === 'Zenploy') {
    if (candidateId === undefined || candidateId === null) {
      return null
    }

    return `https://www.zenploy.io/app/candidate/${candidateId}?application_id=${conversationId}`
  }
  return null
}

export const getCandidateUrl = (ats, candidateId) => {
  if (
    ats === undefined ||
    ats === null ||
    candidateId === undefined ||
    candidateId === null
  ) {
    return null
  }

  if (ats.name === 'Zenploy') {
    // return `https://www.zenploy.io/app/candidate/${candidateId}`;
    return null
  }
  return null
}

export const createSchedulingPage = (nylasAccessToken) => {
  if (!window.nylas) {
    throw new Error('Nylas is not correctly imported')
  }

  window.nylas.scheduler.show({
    auth: {
      // Account access_token with active calendar scope
      accessToken: nylasAccessToken,
    },
    style: {
      // Style the schdule editor
      tintColor: '#32325d',
      backgroundColor: 'white',
    },
    defaults: {
      event: {
        title: 'Entretien téléphonique',
        duration: 30,
      },
    },
  })
}

/**
 * Accepts an input conversation and returns a new object with sanitized data
 * @param {Oject} conversation candidate's application
 * @returns {Object} new object that contains the cleared scoring information
 */
export const sanitizeApplicationScoringData = ({
  scoringVariables,
  variablesById,
  offer = undefined,
  // eslint-disable-next-line camelcase
  offer_id,
}) => {
  let steps
  const data = []

  if (offer) {
    steps = offer.steps
  } else {
    steps = offer_id.steps
  }

  for (const varId in variablesById) {
    const id = varId.substring(4)
    const stepIndex = steps.map((step) => step._id).indexOf(id)

    if (stepIndex >= 0) {
      if (steps[stepIndex].type !== 'attachment') {
        const variable = { ...variablesById[varId] }
        variable.id = id

        const index = scoringVariables
          .map((variable) => variable.step_id)
          .indexOf(id)

        if (index >= 0) {
          variable.score = scoringVariables[index].score
          variable.dealbreaker = scoringVariables[index].dealbreaker
        }

        if (steps[stepIndex].type === 'datefield') {
          variable.values = new Date(variable.values).toLocaleDateString()
        }

        data.push(variable)
      }
    }
  }
  return data
}

/**
 *
 * @param {*} score
 * @param {*} t
 * @returns
 */
export const displayApplicationScoreInfo = (score, t) => {
  let label = 'Unknown '
  let tag
  let color

  const style = { width: 80, textAlign: 'center' }

  if (score <= 40) {
    label = 'Faible'
    color = '#FF7466'
    tag = (
      <Tag className="basic-tag" color="#FF7466" style={style}>
        {t('common:lowMatch', 'Faible')}
      </Tag>
    )
  } else if (score > 40 && score <= 50) {
    label = 'Moyen'
    color = '#ffc107'
    tag = (
      <Tag className="basic-tag" color="#ffc107" style={style}>
        {t('common:mediumMatch', 'Moyen')}
      </Tag>
    )
  } else if (score > 50 && score < 80) {
    label = 'Bon'
    color = '#6BCB3A'

    tag = (
      <Tag className="basic-tag" color="#6BCB3A" style={style}>
        {t('common:goodMatch', 'Bon')}
      </Tag>
    )
  } else if (score >= 80) {
    label = 'Excellent'
    color = '#20BF6B'
    tag = (
      <Tag className="basic-tag" color="#20BF6B" style={style}>
        {t('common:excellentMatch', 'Excellent')}
      </Tag>
    )
  }
  return { label, tag, color }
}

/**
 * Returns a 6-digit colour code from string
 *
 * @param str
 * @returns {string}  str string to transform (required)
 * @returns {string} alpha opacity for returned color (optional)
 */
export const stringToColour = (str, alpha = '') => {
  let hash = 0

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'

  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    colour += `00${value.toString(16)}`.substr(-2)
  }
  // eslint-disable-next-line no-return-assign
  return (colour += alpha)
}

/**
 * Returns true if step is savable
 *
 * @param str
 * @returns {Boolean}
 */
export const isStepSavable = (step) => {
  let canSave = true

  const { sentence, type, options, numberScores } = step

  if (!sentence) {
    canSave = false
  }

  if (type === 'qcm') {
    if (options.length <= 1) {
      canSave = false
    }
    options.forEach((option) => {
      if (!option.label) {
        canSave = false
      }
    })
  }

  if (type === 'numberfield') {
    if (numberScores.length <= 1) {
      canSave = false
    }
  }

  if (type === 'salaryfield') {
    if (numberScores.length <= 1) {
      canSave = false
    }
  }

  return canSave
}

export const findUsernameById = (userId, collaborators) => {
  const index = collaborators.map((e) => e.id).indexOf(userId)
  if (index >= 0) {
    return collaborators[index].display
  }
  return 'Utilisateur supprimé'
}

export const findUsernameByEmail = (email, collaborators) => {
  const index = collaborators.map((e) => e.email).indexOf(email)
  if (index >= 0) {
    return collaborators[index].display
  }
  return 'Utilisateur supprimé'
}

export const contractColors = {
  CDI: 'var(--primary)',
  CDD: 'var(--info)',
  STAGE: 'var(--orange)',
  ALTERNANCE: 'var(--purple)',
  INTERIM: 'var(--green)',
  TRANSITION_MANAGEMENT: '#eccc68',
  default: 'var(--primary)',
}

export const applicationTypeColors = {
  soft: '#3dd09d',
  default: '#eb5aac',
}

export const scoreClasses = [
  { colorClass: 'var(--danger)', valueMax: 40 },
  { colorClass: 'var(--warning)', valueMax: 60 },
  { colorClass: 'var(--green)', valueMax: 75 },
  { colorClass: 'var(--success)', valueMax: 100 },
]

export const reactSelectStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 1000,
  }),
}

export const creatableStyle = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    marginBottom: 0,
    paddingRight: 5,
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: '10px',
    fontSize: '16px',
    padding: '.05em 0',
  }),
  options: (provided) => ({
    ...provided,
    fontSize: '15px',
    fontWeight: '600!important',
    borderRadius: '12px!important',
  }),
  multiValue: (provided) => ({
    ...provided,
    fontSize: '16px',
    fontWeight: '600!important',
    borderRadius: '12px!important',
    backgroundColor: '#e6e6e67a',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 1000,
  }),
}

export const researchStatusColor = {
  'active-search': '#857CE2',
  'listening-market': '#8CCFEF',
  'not-interested': '#ffbe45',
  unknown: '#cecece',
}

export const researchStatus = (t, value = false) => {
  const table = [
    {
      value: 'active-search',
      label: t('common:candidates.searchStatus.activeSearch'),
      color: '#857CE2',
    },
    {
      value: 'listening-market',
      label: t('common:candidates.searchStatus.listeningMarket'),
      color: '#8CCFEF',
    },
    {
      value: 'not-interested',
      label: t('common:candidates.searchStatus.notInterested'),
      color: '#ffbe45',
    },
  ]

  if (value) {
    const result = table.filter((el) => el.value === value)
    return result.length ? result[0] : ''
  }

  return table
}
export const createdFrom = (value = false) => {
  const table = [
    {
      value: 'campaign',
      label: 'campaign',
      color: '#0e668f',
    },
    {
      value: 'suggestion',
      label: 'suggestion',
      color: '#cfb8cc',
    },
    {
      value: 'reactivation',
      label: 'reactivation',
      color: '#16b8a2',
    },
    {
      value: 'linkedIn',
      label: 'linkedIn',
      color: '#ffa502',
    },
    {
      value: 'manual',
      label: 'manual',
      color: '#a60067',
    },
  ]

  if (value) {
    const result = table.filter((el) => el.value === value)
    return result.length ? result[0] : ''
  }

  return table
}

export const researchStatusTable = (t, value = false) => {
  const table = [
    {
      value: 'active-search',
      label: t('common:candidates.searchStatus.activeSearch'),
      color: '#857CE2',
    },
    {
      value: 'listening-market',
      label: t('common:candidates.searchStatus.listeningMarket'),
      color: '#8CCFEF',
    },
    {
      value: 'not-interested',
      label: t('common:candidates.searchStatus.notInterested'),
      color: '#ffbe45',
    },
    {
      value: 'unknown',
      label: t('common:candidates.searchStatus.noStatus'),
      color: '#cecece',
    },
  ]

  if (value) {
    const result = table.filter((el) => el.value === value)
    return result.length ? result[0].label : ''
  }

  return table
}

export const delayOptions = (t) => [
  { value: 'hours', label: t('common:hours') },
  { value: 'days', label: t('common:days') },
]

/**
 * Set an array with the possible mentions
 * @param {boolean} withChatbot if we need chatbot mention
 * @param {boolean} withOffer if we need offer mention
 * @returns array
 */
export const suggestions = ({ withChatbot = false, withOffer = false }) => {
  const tags = [
    {
      id: 'firstname',
      display: 'firstname',
    },
    {
      id: 'lastname',
      display: 'lastname',
    },
    {
      id: 'email',
      display: 'email',
    },
  ]

  if (withChatbot) {
    tags.push({
      id: 'chatbotLink',
      display: 'ChatbotLink',
    })
  }

  if (withOffer) {
    tags.push({
      id: 'offerName',
      display: 'offer',
    })
  }
  return tags
}

/**
 * Defaults texts for communication
 */
export const defaultChatbotSubject = '@offer'
export const defaultChatbotText =
  "<p>Bonjour @firstname @lastname, </p><p>Tu as postulé il y a quelque temps chez nous et nous avons une offre de @offer qui pourrait t'intéresser, n'hésites pas à cliquer ici si ça t'intéresse de postuler: @ChatbotLink</p><p>A très vite,</p>"
export const defaultChatbotMessage =
  "Bonjour @firstname @lastname, tu as postulé il y a quelque temps chez nous et nous avons une offre de @offer qui pourrait t'intéresser, n'hésites pas à cliquer ici si ça t'intéresse de postuler: @ChatbotLink"

export const defaultNurturingSubject = '@firstname @lastname'
export const defaultNurturingText = '<p>Bonjour @firstname @lastname</p>'
export const defaultNurturingMessage = 'Bonjour @firstname @lastname'

/**
 * To be used in a tag
 * @param {object} interaction
 * @param {string} userConnected
 * @returns object, with a color and a value
 */
export const formatCreatedBy = (interaction, userConnected) => {
  if ((interaction.user_id?._id || interaction.user_id) === userConnected._id)
    return { color: '#118ee9', value: 'Mine' }
  if (interaction.company_id === userConnected.company_id?._id)
    return { color: '#00bcd4', value: 'My Company' }
  return { color: '#fb501e', value: 'Global' }
}

/**
 *  Removes words from input
 * @param {String [] | null} textArray
 * @returns String
 */
export const removeWords = (textArray) => {
  if (!textArray || textArray.length === 0) return ''
  return sw.removeStopwords(textArray, [...sw.fr, ...sw.en]).join(' ')
}

/**
 * Indicates if pool configuration is valid or not
 * @param {object} pool
 * @returns object with isValid and issues
 */
export const validatePoolConfig = (pool, t) => {
  let isValid = true
  const issues = []

  const { config } = pool

  if (!config) {
    isValid = false
  }

  if (!config?.initialization?.message?.includes('@ChatbotLink')) {
    issues.push(
      t('reactivation:pool.setup.initialization.message.missingChatbotLink'),
    )
    isValid = false
  }

  if (!config?.initialization?.mailContent?.includes('@ChatbotLink')) {
    issues.push(
      t('reactivation:pool.setup.initialization.mail.missingChatbotLink'),
    )
    isValid = false
  }

  return { isValid, issues }
}

/**
 * Strip html tags from string
 * @param {string} str
 * @returns
 */
export const removeTags = (str) => {
  if (str === null || str === '') return false
  str = str.toString()
  return str.replace(/(<([^>]+)>)/gi, '')
}

/**
 * Colors for candidateScoringTable
 */

export const stepTypeColors = {
  qcm: '#9c27b0',
  salaryfield: '#ff5722',
  attachment: '#795548',
  mailfield: '#ffeb3b',
  textfield: '#00bcd4',
  text: '#1473e6',
  datefield: 'grey',
  numberfield: '#6E24E0',
}
export const badgeColors = {
  notDefined: '#f3f3f4',
  dealBreaker: '#d92550',
  unaccounted: '#F3F3F4',
  acceptable: '#FFCF00',
  ideal: '#3bc37d',
  problematic: '#d92550',
}

/**
 * returns a notification with proper language
 * @param {function} t I18
 * @param {string} type one of the 4 types of notification
 * @param {object} data variable to pass in locize
 * @returns the proper notification
 */
export const notificationTypes = (t, type, data = null) => {
  const dataT = {}
  if (data) {
    if (data.login) dataT.login = data.login
    if (data.firstname) dataT.firstname = data.firstname
    if (data.lastname) dataT.lastname = data.lastname
    if (data.text) dataT.text = data.text
  }

  const notifications = {
    failed_message: t(
      'common:notification.type.failedMessage',
      "L'envoi d'un message programmé a échoué",
    ),
    sync_alert: t(
      'common:notification.type.syncAlert',
      'Nous rencontrons des soucis de synchronisation, veuillez reconnecter votre compte pour profiter des services mail et agenda',
    ),
    mention: t(
      'common:notification.type.mention',
      'Vous avez été mentionné dans une note de {{login}}',
      dataT,
    ),
    received_message: t(
      'common:notification.type.receivedMessage',
      'Vous avez reçu un nouveau message de la part de {{firstname}} {{lastname}}',
      dataT,
    ),
    reminder: t('common:notification.type.reminder', dataT),
  }
  return notifications[type]
}

/**
 * @param {number} num
 * @returns percentage of this number
 */
export const calculatePercentage = (num) => `${Math.round(100 / num)}%`

export class RegexMatcher {
  /**
   * Will check if a FR or US number is present in the string
   * @param {string} rawContent
   * @returns string
   */
  static phoneNumber(rawContent = '') {
    // Old regex found at https://regex101.com/library/GmDIbg
    // Current regex found at https://www.regextester.com/1978

    const regex =
      /((?:\+|00)[17](?: |-)?|(?:\+|00)[1-9]\d{0,2}(?: |-)?|(?:\+|00)1-\d{3}(?: |-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |-)[0-9]{3}(?: |-)[0-9]{4})|([0-9]{7}))/gm

    const content = rawContent.replace(/() /g, '')

    return content.match(regex)
  }

  static githubProfile(rawContent = '') {
    const regex =
      /(?:http?:\/\/|https?:\/\/)?(?:www\.)?github\.com\/(?:\/*)[\w\-./]*/

    return rawContent.match(regex)
  }

  static githubByAnnotation(pdfFile) {
    AnnotationFactory.loadFile(pdfFile.signedUrl).then((factory) => {
      const annotations = factory.getAnnotations()
    })
  }
}

/**
 * Will search if we need to find a number for this candidate
 * looping through its file to find the most up-to-date resume
 * @param {array} files
 * @param {string} type 'github' | 'phone'
 * @returns undefined | phone number
 */
export const findByRegex = (files, type) => {
  const regexType = {
    github: (content) => RegexMatcher.githubProfile(content),
    phone: (content) => RegexMatcher.phoneNumber(content),
  }

  const mostRecent = {
    index: 0,
    timestamp: 0,
  }

  files.forEach((file, index) => {
    if (file.type === 'resume') {
      const tp = new Date(file.timestamp).getTime()
      // RegexMatcher.githubByAnnotation(file)

      if (mostRecent.timestamp < tp) {
        mostRecent.index = index
        mostRecent.timestamp = tp
      }
    }
  })

  const content = files[mostRecent.index].rawContent
  return regexType[type](content)
}

/**
 * Styles for toaster component
 */
export const toastOptions = {
  // Define default options
  success: {
    style: {
      background: '#0070f3',
      color: '#fff',
      padding: '16px',
      fontWeight: '600',
      fontSize: '16px',
      boxShadow:
        'rgb(45 80 230 / 40%) 0px 4px 24px, rgb(0 0 0 / 12%) 0px 4px 24px',
    },
    iconTheme: {
      primary: 'white',
      secondary: '#0070f3',
    },
  },
  error: {
    style: {
      background: '#d95762',
      color: '#fff',
      padding: '16px',
      fontSize: '16px',
      fontWeight: '600',
      boxShadow:
        'rgb(233 30 99 / 50%) 0px 4px 24px, rgb(0 0 0 / 12%) 0px 4px 24px',
      width: '700px!important',
    },
    iconTheme: {
      primary: 'white',
      secondary: '#d95762',
    },
  },
}

export const isLiveApplication = (date) =>
  moment(date).isAfter(moment().subtract(10, 'minutes'))

export const makeScoreConversations = (conversations, offer) =>
  conversations.map((conversation) => {
    conversation.score =
      conversation.score && Math.round(conversation.score * 100)
    if (conversation.candidate_id?.messages) {
      conversation.candidate_id.messages =
        conversation?.candidate_id?.messages || []
    }

    conversation.notSeenMessages = conversation?.candidate_id?.messages?.filter(
      (m) => !m.seen,
    ).length
    conversation.offer = offer
    return conversation
  })

/**
 * Will add or remove element from a list, then set the new list in its state
 * @param {Object} element to look for in the array
 * @param {Boolean} isSelect to know if selected
 * @param {Array} currentList List of selected Items
 * @param {Function} setList React function to set the new list in its state
 */
export const updateFilteredList = (element, isSelect, currentList, setList) => {
  const newList = JSON.parse(JSON.stringify(currentList || []))
  // When false, it says I want to uncheck this line
  // I look for the index in the array then I remove it from the array
  if (!isSelect) {
    const index = newList.findIndex((item) => item._id === element._id)
    if (index >= 0) {
      newList.splice(index, 1)
    }
  } else {
    // Unshifting instead of push because when we look for indices
    // we usually start from beginning so it should be faster
    newList.unshift(element)
  }
  setList(newList)
}

/**
 * Will remove duplicate in array of object depending on key
 * @param {Array} arr of object
 * @param {String} key to use in order to remove duplicate
 */
export const getUniqueListBy = (arr, key) => [
  ...new Map(arr.map((item) => [item[key], item])).values(),
]

/**
 * Generate offer url depending on application type
 * @param {Object} offer
 * @returns {String} offer url
 */
export const generateOfferUrl = (offer) => {
  if (Object.keys(offer).length === 0) return

  let baseURL = process.env.REACT_APP_CHATBOT_URL || 'http://localhost:3002'

  if (
    offer.applicationType === 'soft' &&
    offer.company_id === '5ede3f0a1746310018d42a3d'
  ) {
    baseURL = 'https://job2bedone-partners-app.hollo.io'
  }

  const offerURL =
    offer.applicationType !== 'soft'
      ? `${baseURL}/${offer._id}`
      : `${baseURL}/soft/${offer._id}?companyId=${offer.company_id}&teamId=${
          offer.team_id?._id ? offer.team_id?._id : offer.team_id
        }&offerName=${offer.name}`

  return offerURL
}

export const manageCandidateSort = (candidates, minScoreValue) => {
  if (!minScoreValue) {
    return candidates
  }

  return candidates.filter(
    (candidate) => candidate.relativeScore > minScoreValue,
  )
}

export const manageCandidateRecord = (candidates, t, minScoreValue) => {
  const excellentCandidates = candidates?.filter(
    (candidate) => candidate.relativeScore > 80,
  )

  const highCandidates = candidates?.filter(
    (candidate) =>
      candidate.relativeScore > 60 && candidate.relativeScore <= 80,
  )

  const mediumCandidates = candidates?.filter(
    (candidate) =>
      candidate.relativeScore > 40 && candidate.relativeScore <= 60,
  )

  const candidatesLow = candidates?.filter(
    (candidate) => candidate.relativeScore <= minScoreValue,
  )

  const notLow = (
    <Tooltip title={t('common:suggestedTalent')}>
      <Statistic
        value={candidates?.length}
        prefix={<BsCircleFill color="blue" size={22} />}
      />
    </Tooltip>
  )
  const excellent = (
    <Tooltip title={t('common:excellentMatch')}>
      <Statistic
        value={excellentCandidates?.length}
        prefix={<BsCircleFill color="#20BF6B" size={22} />}
      />
    </Tooltip>
  )
  const high = (
    <Tooltip title={t('common:highMatch')}>
      <Statistic
        value={highCandidates?.length}
        prefix={<BsCircleFill color="#6BCB3A" size={22} />}
      />
    </Tooltip>
  )

  const medium = (
    <Tooltip title={t('common:mediumMatch')}>
      <Statistic
        value={mediumCandidates?.length}
        prefix={<BsCircleFill color="#ffc107" size={22} />}
      />
    </Tooltip>
  )
  // eslint-disable-next-line max-len
  // eslint-disable-next-line no-return-assign
  return [notLow, excellent, high, medium]
}

export const schoolLevelsI18 = (t) => ({
  2: t('common:bac2'),
  3: t('common:bac3'),
  4: t('common:bac4'),
  5: t('common:bac5'),
  above: t('common:bacAbove'),
  undefined: '',
})

export const contractTypesI18 = (t) => ({
  CDI: {
    value: 'CDI',
    label: t('campaign:offer.type.cdi'),
    color: 'var(--primary)',
  },
  CDD: {
    value: 'CDD',
    label: t('campaign:offer.type.cdd'),
    color: 'var(--info)',
  },
  internship: {
    value: 'internship',
    label: t('campaign:offer.type.internship'),
    color: 'var(--orange)',
  },
  Interim: {
    value: 'Interim',
    label: t('campaign:offer.type.interim'),
    color: 'var(--green)',
  },
  trainingCourse: {
    value: 'trainingCourse',
    label: t('campaign:offer.type.trainingCourse'),
    color: 'var(--purple)',
  },
  TRANSITION_MANAGEMENT: {
    label: t('campaign:offer.type.transitionManagement'),
    value: 'transitionManagement',
    color: '#eccc68',
  },
  undefined: { label: '' },
})

export const experienceI18 = (t) => ({
  junior: { label: t('common:exp.junior'), value: 'junior' },
  intermediate: { label: t('common:exp.intermediate'), value: 'intermediate' },
  senior: { label: t('common:exp.senior'), value: 'senior' },
  expert: { label: t('common:exp.expert'), value: 'expert' },
  moreThanTen: { label: t('common:exp.moreThanTen'), value: 'moreThanTen' },
  undefined: { label: '' },
})

export const displayExperience = (value, t) => {
  switch (value) {
    case 'junior':
      return t('common:experienceForm.lessThanOneYear', '< 1 an')
    case 'intermediate':
      return t('common:experienceForm.betweenOneAndTwo')
    case 'senior':
      return t('common:experienceForm.between3and5', '3 à 5 ans')
    case 'expert':
      return t('common:experienceForm.between6and10', '6 à 10 ans')
    case 'moreThanTen':
      return t('common:experienceForm.moreThanTen', '> 10 ans')
    default:
      console.error(`unknown value ${value}.`)
  }
}

export const experienceOptions = (t) => [
  { label: t('common:exp.junior'), value: 'junior' },
  { label: t('common:exp.intermediate'), value: 'intermediate' },
  { label: t('common:exp.senior'), value: 'senior' },
  { label: t('common:exp.expert'), value: 'expert' },
  { label: t('common:exp.moreThanTen'), value: 'moreThanTen' },
]

export const contractTypesOptions = (t) => [
  { value: 'CDI', label: t('campaign:offer.type.cdi') },
  { value: 'CDD', label: t('campaign:offer.type.cdd') },
  { value: 'internship', label: t('campaign:offer.type.internship') },
  { value: 'Interim', label: t('campaign:offer.type.interim') },
  { value: 'trainingCourse', label: t('campaign:offer.type.trainingCourse') },
]

export const schoolLevelOptions = (t) => [
  { value: '2', label: t('common:bac2') },
  { value: '3', label: t('common:bac3') },
  { value: '4', label: t('common:bac4') },
  { value: '5', label: t('common:bac5') },
  { value: 'above', label: t('common:bacAbove') },
]

export const remoteOptions = (t) => [
  { value: 'FULL', label: t('campaign:offer.remoteWorking.full') },
  { value: 'PARTIAL', label: t('campaign:offer.remoteWorking.partial') },
  { value: 'PUNCTUAL', label: t('campaign:offer.remoteWorking.punctual') },
  {
    value: 'UNAUTHORIZED',
    label: t('campaign:offer.remoteWorking.unauthorized'),
  },
]

export const remote = (target, t) => {
  switch (target) {
    case 'FULL':
      return t('campaign:offer.remoteWorking.full')
    case 'PARTIAL':
      return t('campaign:offer.remoteWorking.partial')
    case 'PUNCTUAL':
      return t('campaign:offer.remoteWorking.punctual')
    case 'UNAUTHORIZED':
      return t('campaign:offer.remoteWorking.unauthorized')
    default:
      return 'Pas de précision'
  }
}
