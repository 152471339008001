import { SET_TOGGLE_SHOULD_REFETCH } from '../actions/reactivationActions'

const initialState = {
  shouldRefetchCurrentPool: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOGGLE_SHOULD_REFETCH:
      return {
        ...state,
        shouldRefetchCurrentPool: action.payload,
      }

    default: {
      return state
    }
  }
}
