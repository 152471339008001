import produce from 'immer'

import {
  RESET_CONVERSATIONS_UI,
  SET_CONVERSATIONS_VIEW,
  SET_CONVERSATIONS_CURSOR,
  SET_CONVERSATIONS_SORT,
  RESET_OFFERS_UI,
  SET_OFFERS_NAME,
  SET_PAGE_TITLE,
  SET_OFFERS_CURSOR,
  SET_OFFERS_SORT,
  SET_OFFERS_ARCHIVED,
  SET_ONLY_USER_OFFERS,
  SET_TEAMS_OFFERS,
  SET_VIEW,
  SET_USERS_OFFERS,
} from '../actions/uiActions'

const initialState = {
  conversationsView: 'notClassified',
  conversationsCursor: 0,
  conversationsSortField: 'createdAt',
  conversationsSortOrder: -1,
  offersName: '',
  offersCursor: 0,
  offersSortField: 'createdAt',
  offersSortOrder: -1,
  offersDisplayArchived: false,
  onlyUserOffers: true,
  teamsOffers: [],
  usersOffers: [],
  view: 'classic',
  pageTitle: '',
}

export default produce((draft, action) => {
  switch (action.type) {
    case RESET_CONVERSATIONS_UI: {
      draft.conversationsView = initialState.conversationsView
      draft.conversationsCursor = initialState.conversationsCursor
      draft.conversationsSortField = initialState.conversationsSortField
      draft.conversationsSortOrder = initialState.conversationsSortOrder
      return
    }

    case SET_CONVERSATIONS_VIEW: {
      draft.conversationsView = action.view
      return
    }

    case SET_PAGE_TITLE: {
      draft.pageTitle = action.pageTitle
      return
    }

    case SET_CONVERSATIONS_CURSOR: {
      draft.conversationsCursor = action.cursor
      return
    }

    case SET_CONVERSATIONS_SORT: {
      draft.conversationsSortField = action.data.sortField
      draft.conversationsSortOrder = action.data.sortOrder
      return
    }

    case RESET_OFFERS_UI: {
      draft.offersCursor = initialState.offersCursor
      draft.offersSortField = initialState.offersSortField
      draft.offersSortOrder = initialState.offersSortOrder
      return
    }

    case SET_OFFERS_NAME: {
      draft.offersName = action.name
      draft.offersCursor = initialState.offersCursor
      return
    }

    case SET_OFFERS_CURSOR: {
      draft.offersCursor = action.cursor
      return
    }

    case SET_OFFERS_SORT: {
      draft.offersSortField = action.data.sortField
      draft.offersSortOrder = action.data.sortOrder
      return
    }

    case SET_OFFERS_ARCHIVED: {
      draft.offersDisplayArchived = action.offersDisplayArchived
      return
    }

    case SET_ONLY_USER_OFFERS: {
      draft.onlyUserOffers = action.onlyUser
      return
    }

    case SET_TEAMS_OFFERS: {
      draft.teamsOffers = action.teams
      return
    }

    case SET_USERS_OFFERS: {
      draft.usersOffers = action.users
      return
    }

    case SET_VIEW: {
      draft.view = action.view
      break
    }

    default: {
    }
  }
}, initialState)
