import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const WrapperDropdown = styled.div`
  .dropdown-label {
    border-radius: 6px;
    display: flex;
    // gap: 5px;
    align-items: center;
    border: 1px solid #e7e7e7;
    padding: 4px;
    // margin-bottom: 2px;
    height: 28px;
  }

  .dropdown-label:hover {
    // box-shadow: 1px 1px 1px rgb(0 0 0 / 30%);
    cursor: pointer;
  }

  .ant-dropdown-menu-title-content {
    font-weight: 400;
  }
`
