import {
  SET_NOTIFICATION_SEEN,
  SET_SWITCH,
  SET_ACTIVE_FILTER,
  SET_ALL_NOTIFICATION,
  SET_UNREAD_CONVERSATION,
  SET_TOGGLE_SEEN_APPLICATION,
  SET_RECEIVED_MESSAGE,
  SET_SEEN_APPLICATION,
  SET_SHOULD_REFETCH_APPLICATIONS,
} from '../actions/notificationActions'

export const initialStateNotifs = {
  unreadApplications: {
    data: [],
    total: 0,
    seen: false,
  },
  allNotifications: [],
  unreadNotifications: [],
  receivedMessages: {
    data: [],
    total: 0,
    totalUnread: 0,
  },
  opened: false,
  allNotifs: false,
  activeFilters: ['mention', 'reminder'],
  shouldRefetchUnreadApplications: false,
}

export default function reducer(state = initialStateNotifs, action) {
  switch (action.type) {
    case SET_ALL_NOTIFICATION:
      return {
        ...state,
        allNotifications: action.payload,
        unreadNotifications: [...action.payload.filter((el) => !el.seen)],
      }

    case SET_RECEIVED_MESSAGE:
      return {
        ...state,
        receivedMessages: action.payload,
      }

    case SET_UNREAD_CONVERSATION:
      return {
        ...state,
        unreadApplications: {
          ...state.unreadApplications,
          data: action.payload[0].data,
          total: action.payload[0]?.total[0]?.count || 0,
        },
      }

    case SET_TOGGLE_SEEN_APPLICATION:
      return {
        ...state,
        unreadApplications: {
          ...state.unreadApplications,
          seen: !state.unreadApplications.seen,
        },
      }

    case SET_SEEN_APPLICATION:
      return {
        ...state,
        unreadApplications: {
          ...state.unreadApplications,
          seen: action.payload,
        },
      }

    case SET_NOTIFICATION_SEEN:
      return {
        ...state,
        opened: action.payload,
        unreadApplications: {
          ...state.unreadApplications,
          seen: true,
        },
      }

    case SET_SWITCH:
      return {
        ...state,
        allNotifs: action.payload,
      }

    case SET_ACTIVE_FILTER:
      return {
        ...state,
        activeFilters: action.payload,
      }
    case SET_SHOULD_REFETCH_APPLICATIONS:
      return {
        ...state,
        shouldRefetchUnreadApplications: action.payload,
      }

    default: {
      return state
    }
  }
}
