import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Button, Col, FormGroup, Input, Label } from 'reactstrap'
import { Modal } from 'antd'
import { RiCloseFill } from 'react-icons/ri'
import toast from 'react-hot-toast'
import AuthService from '../../services/AuthService'

const Auth = new AuthService()

function SupportModal(props) {
  const abortController = new AbortController()

  const [subject, setSubject] = useState('')
  const [text, setText] = useState('')

  useEffect(() => () => {
    abortController.abort()
  })

  const toggle = () => props.toggle(false)

  const handleSend = async () => {
    if (text === '' || subject === '') {
      return toast.error(
        "Vous devez remplir l'objet et le message pour envoyer un message au support",
      )
    }

    try {
      await Auth.fetch('/support', {
        method: 'POST',
        body: JSON.stringify({
          subject,
          text,
        }),
        signal: abortController.signal,
      })

      toast.success(
        'Le message a été transmis au support, nous revenons vers vous dès que possible',
      )
      setText('')
      setSubject('')
      props.toggle()
    } catch (err) {
      console.error(err)
      toast.error(
        "Erreur lors de l'envoi du message au support, envoyez un mail à support@hollo.io si le problème persiste",
      )
    }
  }

  const { isOpen } = props

  return (
    <Modal
      visible={isOpen}
      onCancel={toggle}
      closeIcon={<RiCloseFill size={32} />}
      footer={null}
      width={700}
      title="Envoyer un message au support"
    >
      <FormGroup row>
        <Label sm={2} for="subject">
          Objet
        </Label>
        <Col sm={10}>
          <Input
            type="text"
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2} for="text">
          Message
        </Label>
        <Col sm={10}>
          <Input
            type="textarea"
            name="text"
            style={{ minHeight: 300, fontSize: 14 }}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </Col>
      </FormGroup>
      <div className="btns-container">
        <Button className="grey-btn" onClick={toggle}>
          Annuler
        </Button>
        <Button className="basic-btn" onClick={handleSend}>
          Envoyer
        </Button>
      </div>
    </Modal>
  )
}

SupportModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
}

SupportModal.defaultProps = {
  isOpen: false,
}

export default SupportModal
