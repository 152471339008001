import styled from 'styled-components'
import { mobileL, tablet } from '../utils/mediaQueries'

// eslint-disable-next-line import/prefer-default-export
export const Login = styled.div`
  display: flex;
  flex-direction: row;
  align-content: space-around;
  background: #ecf0fc;

  .left-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 47.4%;
    height: 100vh;
    position: sticky;

    background-size: cover;
    background-repeat: no-repeat;

    ${tablet({
      display: 'none',
    })};
  }

  .left-img::after {
    content: '';
    position: absolute;
    height: 100vh;
    width: 110px;
    right: 0px;
    bottom: 0px;
    background: #fafafa;
    clip-path: ellipse(41% 52% at 106% 50%);
    background-color: #ecf0fc;
  }

  .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 54.9%;

    justify-content: center;

    ${tablet({
      width: '100%',
      height: '100vh',
    })};
  }

  .loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 40px;
  }

  .title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-items: center;
    gap: 20px;
  }

  .logo {
    width: 40px;
    height: 40px;
  }

  .active {
    background-color: #3e84ff;
    color: #ffffff;
    cursor: default;
  }

  .title {
    font-family: 'Karla';
    font-style: bold;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    padding-top: 10px;
    color: #0d205f;
  }

  .switch-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    background: #ffffff;
    border-radius: 4px;
    width: 503px;
    height: 55px;

    ${mobileL({
      width: '280px',
    })};

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      padding: 16px;
      gap: 10px;
      width: 251.5px;
      height: 55px;
      border-radius: 4px;
      border: #0e1c36;

      font-family: 'Karla';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
    }
  }

  .input-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;

    ${mobileL({
      alignItems: 'center',
    })};

    .dual-input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 16px;

      input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 20px;
        gap: 16px;

        width: 503px;
        height: 56px;

        background: #ffffff;

        border: 1px solid #dae1fa;
        border-radius: 4px;

        font-family: 'Karla';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;

        transition: 0.5s;

        ::placeholder {
          opacity: 0.4;
        }

        &:hover {
          border: 1px solid #3e84ff;
        }

        &:focus {
          border-right-width: 1px !important;
          outline: 0;
          box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
        }

        ${mobileL({
          width: '280px',
        })};
      }
    }
  }

  .submit-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 16px;

    width: 503px;
    height: 64px;

    background: #0e1c36;
    border-radius: 4px;
    border: #0e1c36;

    color: #f6f8fe;
    font-family: 'Karla';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;

    transition: 0.3s ease-in-out;

    &:hover {
      opacity: 0.7;
    }

    ${mobileL({
      width: '280px',
    })};
  }

  .forgot-password {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-family: 'Karla';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    cursor: pointer;

    text-decoration-line: underline;

    /* Dark Blue */
    color: #0d205f;
  }
`
