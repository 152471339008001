export const RESET_NEW_STEP = 'RESET_NEW_STEP'
export const SET_NEW_STEP = 'SET_NEW_STEP'

export const SET_NEW_STEP_NAME = 'SET_NEW_STEP_NAME'
export const SET_NEW_STEP_SKILL = 'SET_NEW_STEP_SKILL'
export const SET_NEW_STEP_REQUIREMENT = 'SET_NEW_STEP_REQUIREMENT'
export const SET_NEW_STEP_TYPE = 'SET_NEW_STEP_TYPE'
export const SET_NEW_STEP_SENTENCE = 'SET_NEW_STEP_SENTENCE'
export const SET_NEW_STEP_WEIGHT = 'SET_NEW_STEP_WEIGHT'
export const SET_NEW_STEP_VIDEO_URL = 'SET_NEW_STEP_VIDEO_URL'
export const SET_NEW_STEP_ATTACHMENT_TYPE = 'SET_NEW_STEP_ATTACHMENT_TYPE'

export const ADD_NEW_STEP_OPTION = 'ADD_NEW_STEP_OPTION'
export const REMOVE_NEW_STEP_OPTION = 'REMOVE_NEW_STEP_OPTION'
export const SET_NEW_STEP_OPTION_LABEL = 'SET_NEW_STEP_OPTION_LABEL'
export const SET_NEW_STEP_OPTION_SCORE = 'SET_NEW_STEP_OPTION_SCORE'
export const SET_NEW_STEP_OPTION_DEALBREAKER = 'SET_NEW_STEP_OPTION_DEALBREAKER'

export const ADD_NEW_STEP_OPTION_NUMBER = 'ADD_NEW_STEP_OPTION_NUMBER'
export const REMOVE_NEW_STEP_OPTION_NUMBER = 'REMOVE_NEW_STEP_OPTION_NUMBER'
export const SET_NEW_STEP_OPTION_NUMBER_MIN = 'SET_NEW_STEP_OPTION_NUMBER_MIN'
export const SET_NEW_STEP_OPTION_NUMBER_MAX = 'SET_NEW_STEP_OPTION_NUMBER_MAX'
export const SET_NEW_STEP_OPTION_NUMBER_SCORE =
  'SET_NEW_STEP_OPTION_NUMBER_SCORE'
export const SET_NEW_STEP_OPTION_NUMBER_DEALBREAKER =
  'SET_NEW_STEP_OPTION_NUMBER_DEALBREAKER'

export const SET_NEW_STEP_OPTIONS = 'SET_NEW_STEP_OPTIONS'

export const resetNewStep = () => ({
  type: RESET_NEW_STEP,
})

export const setNewStep = (step) => ({
  type: SET_NEW_STEP,
  step,
})

export const setNewStepName = (name) => ({
  type: SET_NEW_STEP_NAME,
  name,
})

export const setNewStepRequirement = (requirementId) => ({
  type: SET_NEW_STEP_REQUIREMENT,
  requirementId,
})

export const setNewStepType = (stepType) => ({
  type: SET_NEW_STEP_TYPE,
  stepType,
})

export const setNewStepSentence = (sentence) => ({
  type: SET_NEW_STEP_SENTENCE,
  sentence,
})

export const setNewStepWeight = (weight) => ({
  type: SET_NEW_STEP_WEIGHT,
  weight,
})

export const addNewStepOption = () => ({
  type: ADD_NEW_STEP_OPTION,
})

export const removeNewStepOption = (index) => ({
  type: REMOVE_NEW_STEP_OPTION,
  index,
})

export const setNewStepOptionLabel = (data) => ({
  type: SET_NEW_STEP_OPTION_LABEL,
  data,
})

export const setNewStepOptionScore = (data) => ({
  type: SET_NEW_STEP_OPTION_SCORE,
  data,
})

export const setNewStepOptionDealbreaker = (data) => ({
  type: SET_NEW_STEP_OPTION_DEALBREAKER,
  data,
})

export const addNewStepOptionNumber = (data) => ({
  type: ADD_NEW_STEP_OPTION_NUMBER,
  data,
})

export const removeNewStepOptionNumber = (data) => ({
  type: REMOVE_NEW_STEP_OPTION_NUMBER,
  data,
})

export const setNewStepOptionNumberMin = (data) => ({
  type: SET_NEW_STEP_OPTION_NUMBER_MIN,
  data,
})

export const setNewStepOptionNumberMax = (data) => ({
  type: SET_NEW_STEP_OPTION_NUMBER_MAX,
  data,
})

export const setNewStepOptionNumberScore = (data) => ({
  type: SET_NEW_STEP_OPTION_NUMBER_SCORE,
  data,
})

export const setNewStepOptionNumberDealbreaker = (data) => ({
  type: SET_NEW_STEP_OPTION_NUMBER_DEALBREAKER,
  data,
})

export const setNewStepOptions = (options) => ({
  type: SET_NEW_STEP_OPTIONS,
  options,
})

export const setNewStepVideoUrl = (data) => ({
  type: SET_NEW_STEP_VIDEO_URL,
  data,
})

export const setNewStepAttachmentType = (data) => ({
  type: SET_NEW_STEP_ATTACHMENT_TYPE,
  data,
})
