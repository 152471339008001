import produce from 'immer'

import {
  SET_COMPANY_ID,
  SET_TEAM_ID,
  SET_USER_ID,
  SET_PROCESS_STATUS_ID,
  SET_DEAL_ID,
} from '../actions/administrationActions'

const initialState = {
  companyId: null,
  teamId: null,
  userId: null,
  processStatusId: null,
  dealId: null,
}

export default produce((draft, action) => {
  switch (action.type) {
    case SET_COMPANY_ID: {
      draft.companyId = action.id
      return
    }

    case SET_TEAM_ID: {
      draft.teamId = action.id
      return
    }

    case SET_USER_ID: {
      draft.userId = action.id
      return
    }

    case SET_PROCESS_STATUS_ID: {
      draft.processStatusId = action.id
      break
    }

    case SET_DEAL_ID: {
      draft.dealId = action.id
      break
    }

    default: {
    }
  }
}, initialState)
