/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { FormGroup, Input, Label } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Modal, Button } from 'antd'
import toast from 'react-hot-toast'
import { reactSelectStyles } from '../../../services/utils'
import AuthService from '../../../services/AuthService'
import { FeathersContext } from '../../../services/feathers'

const Auth = new AuthService()

const AddFileModal = (props) => {
  const { isOpen, toggle } = props
  const abortController = new AbortController()
  const feathers = useContext(FeathersContext)

  const { t } = useTranslation(['common', 'candidate'], { useSuspense: false })

  const fileTypes = [
    { value: 'resume', label: 'CV' },
    {
      value: 'coverletter',
      label: t('common:coverLetter'),
    },
    { value: 'other', label: t('common:other') },
  ]
  const [fileName, setFileName] = useState('')
  const [fileType, setFileType] = useState(null)
  const [fileToAdd, setFileToAdd] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => () => abortController.abort(), [])

  const onCancel = () => {}

  const onDropFile = (files) => {
    if (files.length) {
      setFileToAdd(files[0])
      setFileName(fileName === '' ? files[0].name : fileName)
    } else {
      console.error('No files provided')
    }
  }

  const onRemoveFile = () => {
    setFileToAdd(null)
    setFileName('')
  }

  // const handleAddFile = useMutation(
  //   () => {
  //     const formData = new FormData()
  //     formData.append('uri', fileToAdd)
  //     formData.append('name', fileName)
  //     formData.append('type', fileType.value)
  //     formData.append('candidateId', props.candidateId)
  //     return feathers.service('upload-candidate-file').create(formData)
  //   },
  //   {
  //     onSuccess: () => {
  //     },
  //     onError: (error) => {
  //     },
  //   },
  // )

  // TODO: use useMutation and the isloading to show it in the button
  const handleAddFile = async () => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append('uri', fileToAdd)
    formData.append('name', fileName)
    formData.append('type', fileType.value)
    formData.append('candidateId', props.candidateId)
    try {
      await Auth.fetchWithoutHeaders('/upload-candidate-file', {
        method: 'POST',
        body: formData,
        signal: abortController.signal,
      })
      if (props.refreshData) {
        props.refreshData() // refresh data after upload success
      }
      if (props.refetchCandidate) {
        props.refetchCandidate()
      }
      toast.success(
        t(
          'candidate:file.added.toast.success',
          'Le fichier a été ajouté au candidat avec succès',
        ),
      )
      props.toggle()
    } catch (e) {
      toast.error(
        t(
          'candidate:file.added.toast.error',
          "Erreur lors de l'ajout du fichier du candidat",
        ),
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      visible={isOpen}
      onCancel={toggle}
      title={t('candidate:file.add.title', 'Ajouter un fichier au candidat')}
      footer={
        <div className="btns-container">
          <Button className="grey-btn" onClick={toggle}>
            {t('common:cancel')}
          </Button>
          <Button
            loading={isLoading}
            className="basic-btn"
            onClick={handleAddFile}
            disabled={!fileName || !fileType || !fileToAdd}
          >
            {t('common:addFile', 'Ajouter le fichier')}
          </Button>{' '}
        </div>
      }
    >
      {fileToAdd && (
        <FormGroup>
          <Label for="fileName">{t('common:fileName', 'Nom du fichier')}</Label>
          <Input
            type="text"
            name="fileName"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
          />
        </FormGroup>
      )}

      <form data-testid="form">
        <FormGroup>
          <Label htmlFor="fileType" for="fileType">
            {t('common:fileType.title', 'Type du fichier')}
          </Label>
          <Select
            closeMenuOnSelect
            components={makeAnimated()}
            value={fileType}
            options={fileTypes}
            onChange={(e) => setFileType(e)}
            placeholder={t(
              'common:filetype.placeholder',
              'Sélectionner le type du fichier',
            )}
            name="fileType"
            inputId="fileType"
            styles={reactSelectStyles}
          />
        </FormGroup>
      </form>
      <FormGroup>
        {!fileToAdd ? (
          <div className="dropzone-wrapper dropzone-wrapper-sm">
            <Dropzone
              onDrop={onDropFile}
              onFileDialogCancel={onCancel}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input data-testid="drop-input" {...getInputProps()} />
                  <div className="dropzone-content">
                    <p>
                      {t(
                        'common:dragAndDropFile',
                        'Faites glisser le fichier PDF ou cliquez pour le sélectionner parmi vos fichiers.',
                      )}
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        ) : (
          <Button
            title={t('common:removeFile', 'Supprimer le fichier')}
            onClick={onRemoveFile}
            className="mb-2 mr-2 btn-icon"
            color="warning"
          >
            <i className="pe-7s-trash btn-icon-wrapper"> </i>
            {fileToAdd.name}
          </Button>
        )}
      </FormGroup>
    </Modal>
  )
}

AddFileModal.propTypes = {
  candidateId: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  refreshData: PropTypes.func.isRequired,
}

export default AddFileModal
