import { Button } from 'antd'
import { bool, func, object, objectOf } from 'prop-types'
import React from 'react'
import { FaHeart } from 'react-icons/fa'
import { VscChromeClose } from 'react-icons/vsc'
import * as s from './styles'

const propTypes = {
  disabled: bool,
  style: object,
  btnStyle: object,
  actions: objectOf(func).isRequired,
}

const defaultProps = {
  disabled: false,
  style: {},
  btnStyle: {},
}

const TinderBtns = ({
  disabled = false,
  style = {},
  btnStyle = {},
  actions,
}) => (
  <s.TinderBtnsWrapper style={style}>
    <Button
      className="white-btn tinder-btn dislike"
      disabled={disabled}
      onClick={actions.rejected}
      style={btnStyle}
    >
      <VscChromeClose size={22} color="#ff4154" />
    </Button>
    <Button
      className="white-btn tinder-btn like"
      disabled={disabled}
      onClick={actions.approved}
      style={btnStyle}
    >
      <FaHeart size={22} color="#3560de" />
    </Button>
  </s.TinderBtnsWrapper>
)

TinderBtns.propTypes = propTypes
TinderBtns.defaultProps = defaultProps

export default TinderBtns
