import styled from 'styled-components'

export const DeleteFileBadge = styled.div`
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--dark);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  position: absolute;
  top: -8px;
  height: 22px;
  padding: 0 8px;
  color: #fff;
  line-height: 22px;
  white-space: nowrap;
  border-radius: 2px;
  right: -8px;
  border-bottom-right-radius: 0;
  background-color: darkgrey;
  cursor: pointer;
  z-index: 10000;
`

export const FileBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid #00000017;
  width: 100%;
  border-radius: 12px;
  transition: 0.2s ease all;

  &:hover {
    border: 1px solid var(--primary);
    background-color: #2d51e514 !important;
    color: var(--primary);
  }
`
