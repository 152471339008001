import styled from 'styled-components'
import { Menu as antdMenu } from 'antd'

// Create a Title component that'll render an <h1> tag with some styles
// eslint-disable-next-line import/prefer-default-export
export const Menu = styled(antdMenu)`
  position: fixed;
  height: 100vh;

  .ant-tooltip-inner {
    border-radius: 5px;
    padding: 8px !important;
  }

  .sidebar-item-separator {
    width: 80px;
    height: 1px;
    background-color: #ffffff21;
    display: block;
    margin: 25px 0;
  }

  .ant-menu-item,
  .ant-menu-submenu-title,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 20px !important;
    display: flex;
    margin: 20px 0;
    justify-content: center;
    align-items: center;
  }

  .ant-menu-submenu .ant-menu-title-content {
    display: none !important;
  }

  .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    display: none;
  }

  .sidebar-logo-wrapper {
    margin-bottom: 40px;
    margin-top: 40px;
    padding: 10px;
  }

  svg {
    font-size: 22px !important;
  }

  .support-btn-wrapper {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1rem 0;
    border-top: 1px solid #8080804a;
    cursor: pointer;

    svg {
      font-size: 20px !important;
    }
  }

  .menu-item-tooltip {
    z-index: 9999;
  }
`
