import { Button, Divider, Input, Select, Space } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import AuthService from '../../services/AuthService'

const Auth = new AuthService()

const createTag = (tag) => ({
  label: tag,
  value: tag,
})

const TagsSelect = (props) => {
  const { t } = useTranslation(['common'], {
    useSuspense: false,
  })

  const abortController = new AbortController()

  const user = useSelector((state) => state.user)

  const inputRef = useRef(null)
  const [name, setName] = useState('')

  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState(
    props.tags?.map((tag) => createTag(tag)) || [],
  )
  const [tagsFetched, setTagsFetched] = useState(false)
  const [creatableLoading, setCreatableLoading] = useState(false)

  useEffect(() => {
    if (!tagsFetched) {
      Auth.fetch('/tags?$limit=-1', {
        signal: abortController.signal,
      })
        .then((tags) => {
          setTags(tags.map((tag) => ({ label: tag.label, value: tag.label })))
          setTagsFetched(true)
        })
        .catch((err) => {
          console.error(err)
        })
      return () => {
        abortController.abort()
      }
    }
  }, [])

  const onNameChange = (event) => {
    setName(event?.target?.value)
  }

  const handleTagCreate = () => {
    setCreatableLoading(true)

    Auth.fetch('/tags', {
      method: 'POST',
      body: JSON.stringify({
        label: name,
        company_id: user.company_id?._id,
      }),
      signal: abortController.signal,
    })
      .then((tag) => {
        const createdTag = createTag(tag.label)

        setTags([...tags, createdTag])
        const updatedSelectedTags = [...selectedTags, createdTag]
        setSelectedTags(updatedSelectedTags)
        setCreatableLoading(false)

        if (props.updateParent) {
          props.updateParent(updatedSelectedTags || [])
        }
        setName('')
      })
      .catch((err) => {
        console.error(err)
        toast.error(t('common:tags.tagCreate.toasts.error'))
      })
  }

  const handleTagChange = (selectedTags) => {
    setSelectedTags(selectedTags)

    if (props.updateParent) {
      props.updateParent(selectedTags || [])
    }
  }

  return (
    <Select
      placeholder={t(
        'campaign:createOffer.generic.placeholder',
        'Non renseigné',
      )}
      options={tags}
      value={selectedTags}
      onChange={(value) => handleTagChange(value)}
      labelInValue
      showArrow
      size="large"
      bordered={false}
      mode="multiple"
      style={{ width: '100%' }}
      disabled={user.permissions.includes('guest')}
      loading={creatableLoading}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Input
              placeholder="Ajouter un nouveau tag"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
            />
            <Button
              className="grey-btn"
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                handleTagCreate()
              }}
            >
              {t('common:add', 'Ajouter')}
            </Button>
          </Space>
        </>
      )}
    />
  )
}

export default TagsSelect
