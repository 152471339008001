import React, { useState } from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { useMutation, useQuery } from 'react-query'
import { func, arrayOf, objectOf, any } from 'prop-types'
import {
  makeToastError,
  makeToastSuccess,
  processStatusOptionsUtils,
  reactSelectStyles,
} from '../../../services/utils'
import Loader from '../../Loader'
import * as s from './styles'
import { createMultipleConversations } from '../../../api/Conversation/api.conversation'
import { findAllOffersNotAppliedTo } from '../../../api/Offer/api.offer'

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  candidate: objectOf(any),
  closeModal: func.isRequired,
  candidates: arrayOf(objectOf(any)).isRequired,
  refetchConversation: func.isRequired,
}

const defaultProps = {
  candidate: {},
}

const AddCandidateToCampain = ({
  candidate,
  candidates,
  closeModal,
  refetchConversation,
}) => {
  const { t } = useTranslation(['reactivation', 'common', 'crm', 'talent'])

  const [selectedOffer, setSelectedOffer] = useState(null)
  const [sendProcessStatusOptions, setSendProcessStatusOptions] = useState(
    processStatusOptionsUtils(t),
  )
  const [sendSelectedProcessStatus, setSendSelectedProcessStatus] = useState(
    processStatusOptionsUtils(t)[0],
  )

  const isMulti = candidates?.length

  const { data: offers, isLoading } = useQuery({
    queryFn: async () => {
      const appliedTo = await findAllOffersNotAppliedTo(
        isMulti ? candidates : [candidate],
      )
      return appliedTo.map((offer) => ({
        ...offer,
        value: offer._id,
        label: offer.name,
        teamId: offer.team_id,
      }))
    },
    refetchOnWindowFocus: false,
  })

  /**
   * Mutation to create an application in an active campaign
   * Creates the application/conversation then on success it will
   * reset states and refetch data to be up-to-date
   * In case of error we display a toast
   * In any case we set loadingBtn to false
   * @param {object} application/conversation to be created
   */
  const applicationMutation = useMutation(
    async ({ candidate, offer, status }) => {
      if (!candidate || !offer) {
        return
      }

      const response = await createMultipleConversations({
        candidates: isMulti ? candidates : [candidate],
        offer,
        status: 'NOT_CLASSIFIED',
      })
      return response
    },
    {
      onSuccess: () => {
        makeToastSuccess(t('crm:modals.createCandidate.toast'), () => {
          setSelectedOffer(null)
          setSendSelectedProcessStatus(sendProcessStatusOptions[0])
          if (refetchConversation) {
            refetchConversation()
          }
          closeModal()
        })
      },
      onError: (error) => {
        makeToastError(
          t('crm:modals.createApplication.toast.error'),
          error,
          null,
        )
      },
    },
  )

  if (isLoading) {
    return <Loader />
  }
  return (
    <s.AddCandidateToCampainWrapper data-cy="add-candidate-to-campaign-modal">
      <div>
        <label htmlFor="select-offer">{t('common:selectCampaign')}</label>
        <Select
          closeMenuOnSelect
          className="react-select"
          classNamePrefix="react-select"
          value={selectedOffer}
          options={offers}
          onChange={(e) => setSelectedOffer(e)}
          placeholder={t('common:sidebar.campaigns')}
          styles={reactSelectStyles}
        />
      </div>

      {/* RH-988 - we decided to always send to not classified in case there is an action 
      linked to a processStatus
      We plan to make the transfer of candidates betwen status more centrale
      At this point we could reactivate this feature */}
      {/* {selectedOffer && (
        <div className="selected-offer">
          <label htmlFor="select-offer">
            {t('talent:applicationCard.sendToCampaign.defineStatus.label')}
          </label>
          <Select
            className="react-select"
            classNamePrefix="react-select"
            name="select-offer"
            closeMenuOnSelect
            value={sendSelectedProcessStatus}
            options={sendProcessStatusOptions}
            onChange={(e) => setSendSelectedProcessStatus(e)}
            placeholder={t(
              'talent:applicationCard.sendToCampaign.defineStatus.select.placeholder',
            )}
            styles={reactSelectStyles}
          />

        </div>
      )} */}
      <div className="btns-container " style={{ marginTop: 10 }}>
        <Button className="grey-btn" onClick={closeModal}>
          {t('common:cancel')}
        </Button>
        <Button
          className="basic-btn"
          onClick={() =>
            applicationMutation.mutate({
              candidate: candidate || candidates,
              offer: selectedOffer,
              status: sendSelectedProcessStatus.value,
            })
          }
          loading={applicationMutation.isLoading}
          disabled={!selectedOffer}
        >
          {t('common:send')}
        </Button>
      </div>
    </s.AddCandidateToCampainWrapper>
  )
}

AddCandidateToCampain.propTypes = propTypes
AddCandidateToCampain.defaultProps = defaultProps
export default AddCandidateToCampain
