/* eslint-disable import/prefer-default-export */
import { app } from '../../services/feathers'

/**
 * Create likes for talents
 *
 * @throws error if selectedTalents is not an array
 * @param {Array} selectedTalents
 * @param {Object} offer
 * @param {Object} user
 * @returns
 */
export async function createLikes(selectedTalents, offer, user, status) {
  if (!selectedTalents.length || !Array.isArray(selectedTalents)) {
    throw new Error('not an array or empty array')
  }
  try {
    const promises = selectedTalents.map((selectedTalent) =>
      app.service('likes').create({
        company_id: user.company_id._id,
        offer_id: offer._id,
        status,
        lastUserUpdateAt: new Date(),
        candidate_id: selectedTalent._id,
        talent_id: selectedTalent.talentId,
        user_id: user._id,
        seenByUser: true,
        createdBy: 'user',
        domainJ2BD: ['5ede3f0a1746310018d42a3d'].includes(user.company_id._id)
          ? true
          : undefined,
      }),
    )
    await Promise.all(promises)
  } catch (error) {
    throw new Error(error)
  }
}
